import React from "react";
import { useState } from "react";

import { Switch } from "@headlessui/react";

// icon
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
// import { FiInfo } from "react-icons/fi";

import classNames from "classnames";

//MyToast

import Affiliate from "./ServicesBlog/Affiliate";
import Beamer from "./ServicesBlog/Beamer";
import Cloudflare from "./ServicesBlog/Cloudflare";
import Cognigy from "./ServicesBlog/Cognigy";

const services = [
  {
    id: 2,
    title: "Affiliate",
    description: "Other data processing",
    content: <Affiliate />,
    isOpen: false,
    isEnabled: false,
  },
  {
    id: 3,
    title: "Beamer",
    description: "Other data processing",
    content: <Beamer />,
    isOpen: false,
    isEnabled: false,
  },
  {
    id: 4,
    title: "Cloudflare",
    description: "Essential",
    content: <Cloudflare />,
    isOpen: false,
    isEnabled: false,
  },
  {
    id: 5,
    title: "Cognigy",
    description: "Other data processing",
    content: <Cognigy />,
    isOpen: false,
    isEnabled: false,
  },
];

const ServicesPage: React.FC = () => {
  // show section State
  const [serviceList, setServiceList] = useState(services);
  const handledShow = (index: number) => {
    const newList = [...serviceList];
    newList[index].isOpen = !newList[index].isOpen;
    setServiceList(newList);
  };

  // Active Toggle switch State

  const handleToastOpen = (index: number) => {
    const newList = [...serviceList];
    newList[index].isEnabled = !newList[index].isEnabled;
    setServiceList(newList);
  };

  return (
    <div className="flex flex-col gap-3 overflow-auto px-6">
      {serviceList.map((item, idx) => (
        <div
          key={item.id}
          className="flex flex-col rounded border border-white bg-blue-950 p-0.5 text-white "
        >
          {/* flex */}
          <div className="flex flex-col  sm:flex-row sm:items-center  sm:justify-between">
            {/* content */}
            <div className="flex-grow border-b border-white p-3 sm:mr-2 sm:border-none">
              {/* content wrapper */}
              <button
                onClick={() => {
                  handledShow(idx);
                }}
                className="text-left text-sm"
              >
                {/* title */}
                <div className="mt-0.5 font-bold ">{item.title}</div>
                <div className="mt-1 text-xs leading-tight">
                  {item.description}
                </div>
              </button>
            </div>

            <div className="flex items-center  justify-between px-3 ">
              <div className="my-3">
                <Switch
                  checked={item.isEnabled}
                  onChange={() => {
                    handleToastOpen(idx);
                  }}
                  className={classNames(
                    item.isEnabled
                      ? "bg-indigo-600"
                      : "border-2 border-gray-500 bg-white",
                    "relative inline-flex h-5 w-10 items-center rounded-full"
                  )}
                >
                  <span
                    className={classNames(
                      item.isEnabled
                        ? "translate-x-5 bg-white"
                        : "translate-x-0.5 bg-gray-500",
                      "inline-block h-4 w-4 transform rounded-full border transition-all duration-300 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
              {/* toggle button */}
              <button
                className="mx-4"
                onClick={() => {
                  handledShow(idx);
                }}
              >
                {item.isOpen ? (
                  <span className="mt-1 inline-block h-5 w-5 md:mt-0">
                    <HiChevronUp className="h-full w-full " />
                  </span>
                ) : (
                  <span className="mt-1 inline-block h-5 w-5 md:mt-0">
                    <HiChevronDown className="h-full w-full " />
                  </span>
                )}
              </button>
            </div>
          </div>

          <section
            className={classNames(
              item.isOpen ? "opacity-100" : "hidden",
              "duration-600 overflow-hidden transition-all ease-in-out"
            )}
          >
            <div className="flex flex-col">
              {/* divider */}
              <hr className="border-b-1 border-gray-100" />
              {/* content */}
              <div className="p-3">
                <div className="flex w-full items-center justify-between text-sm">
                  {item.content}
                </div>
              </div>
            </div>
          </section>
        </div>
      ))}
    </div>
  );
};

export default ServicesPage;
