import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  connect,
  ConnectedProps,
  useSelector,
  useDispatch,
  shallowEqual,
} from "react-redux";
import { Auth } from "aws-amplify";
//redux action
import { authActions } from "../../stores/actions";
// components
import AuthLayout from "../../layouts/AuthLayout";
import LoginPage from "./LoginPage";

import type { AuthInitProps } from "../type";

type Props = ConnectedProps<typeof connector> & AuthInitProps;

const AuthInit: React.FC<Props> = (props) => {
  const didRequest = React.useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const login_at = useSelector(({ auth }) => auth.login_at, shallowEqual);
  const [isLogin, setIsLogin] = React.useState(login_at);

  const { logout, filledUser } = props;

  const requestUser = React.useCallback(async () => {
    try {
      const { attributes } = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });

      dispatch(filledUser(attributes));
      if (location.pathname === "/auth/login") {
        navigate("/");
      } else {
        setIsLogin(true);
      }
    } catch (error) {
      console.log("login_at cannot request user info");
      console.log(error);
      dispatch(logout());
      navigate("/");
    }
  }, [dispatch, navigate, location.pathname, filledUser, logout]);

  React.useEffect(() => {
    if (!didRequest.current) {
      if (login_at) {
        requestUser();
      } else {
        dispatch(logout());
      }
    }

    return () => {
      didRequest.current = true;
    };
  }, [dispatch, login_at, logout, requestUser]);

  if (isLogin) {
    return <>{props.children}</>;
  } else {
    return (
      <AuthLayout>
        <LoginPage />
      </AuthLayout>
    );
  }
};

const connector = connect(null, { ...authActions });

export default connector(AuthInit);
