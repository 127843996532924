const industries = [
  { name: "Accounting and bookkeeping", category: "Services" },
  { name: "Human resources", category: "Services" },
  { name: "Payroll services", category: "Services" },
  { name: "Tax preparation", category: "Services" },
  { name: "Management consulting", category: "Services" },
  { name: "IT consulting", category: "Services" },
  { name: "Marketing consulting", category: "Services" },
  { name: "Financial consulting", category: "Services" },
  { name: "Banking", category: "Services" },
  { name: "Credit cards and payment processing", category: "Services" },
  { name: "Investment management", category: "Services" },
  { name: "Insurance", category: "Services" },
  { name: "Corporate law", category: "Services" },
  { name: "Intellectual property law", category: "Services" },
  { name: "Litigation", category: "Services" },
  { name: "Real estate law", category: "Services" },
  { name: "Advertising", category: "Services" },
  { name: "Public relations", category: "Services" },
  { name: "Digital marketing", category: "Services" },
  { name: "Market research", category: "Services" },
  { name: "Residential real estate", category: "Services" },
  { name: "Commercial real estate", category: "Services" },
  { name: "Property management", category: "Services" },
  { name: "Real estate appraisal", category: "Services" },
  { name: "Software development", category: "Services" },
  { name: "IT support and consulting", category: "Services" },
  { name: "Web development", category: "Services" },
  { name: "Cybersecurity", category: "Services" },
  { name: "Logistics and distribution", category: "Services" },
  { name: "Freight and cargo transportation", category: "Services" },
  { name: "Passenger transportation", category: "Services" },
  { name: "Warehousing and storage", category: "Services" },
  { name: "Advertising photography", category: "Photography" },
  { name: "Product photography", category: "Photography" },
  { name: "Architectural photography", category: "Photography" },
  { name: "Wedding photography", category: "Photography" },
  { name: "Concert photography", category: "Photography" },
  { name: "Conference photography", category: "Photography" },
  { name: "Landscape photography", category: "Photography" },
  { name: "Portrait photography", category: "Photography" },
  { name: "Abstract photography", category: "Photography" },
  { name: "Family photography", category: "Photography" },
  { name: "Senior portraits", category: "Photography" },
  { name: "Newborn photography", category: "Photography" },
  { name: "Traditional wedding photography", category: "Photography" },
  { name: "Photojournalistic wedding photography", category: "Photography" },
  { name: "Destination wedding photography", category: "Photography" },
  { name: "Acupuncture", category: "Health & Wellness" },
  { name: "Chiropractic", category: "Health & Wellness" },
  { name: "Dentistry", category: "Health & Wellness" },
  { name: "Nutrition and dietetics", category: "Health & Wellness" },
  { name: "Physical therapy", category: "Health & Wellness" },
  { name: "Occupational therapy", category: "Health & Wellness" },
  { name: "Speech therapy", category: "Health & Wellness" },
  { name: "Mental health counseling", category: "Health & Wellness" },
  { name: "Psychiatry", category: "Health & Wellness" },
  { name: "Alternative medicine", category: "Health & Wellness" },
  { name: "Massage therapy", category: "Health & Wellness" },
  { name: "Personal training", category: "Health & Wellness" },
  { name: "Yoga instruction", category: "Health & Wellness" },
  { name: "Pilates instruction", category: "Health & Wellness" },
  { name: "Meditation instruction", category: "Health & Wellness" },
  { name: "Spa services", category: "Health & Wellness" },
  { name: "Cosmetic surgery", category: "Health & Wellness" },
  { name: "Pharmaceuticals", category: "Health & Wellness" },
  { name: "Medical device manufacturing", category: "Health & Wellness" },
  { name: "Hospitality", category: "Hospitality & Tourism" },
  { name: "Travel agencies", category: "Hospitality & Tourism" },
  { name: "Tour operators", category: "Hospitality & Tourism" },
  { name: "Hotels and resorts", category: "Hospitality & Tourism" },
  { name: "Restaurants", category: "Hospitality & Tourism" },
  { name: "Food and beverage services", category: "Hospitality & Tourism" },
  { name: "Cruise lines", category: "Hospitality & Tourism" },
  { name: "Amusement parks", category: "Hospitality & Tourism" },
  { name: "Museums and historical sites", category: "Hospitality & Tourism" },
  { name: "Sports teams and leagues", category: "Sports & Recreation" },
  { name: "Fitness centers", category: "Sports & Recreation" },
  { name: "Golf courses and country clubs", category: "Sports & Recreation" },
  { name: "Ski resorts", category: "Sports & Recreation" },
  { name: "Sports equipment manufacturing", category: "Sports & Recreation" },
  { name: "Sports apparel manufacturing", category: "Sports & Recreation" },
  { name: "Entertainment production", category: "Arts & Entertainment" },
  { name: "Music production", category: "Arts & Entertainment" },
  { name: "Film and video production", category: "Arts & Entertainment" },
  { name: "Television production", category: "Arts & Entertainment" },
  { name: "Radio production", category: "Arts & Entertainment" },
  { name: "Publishing", category: "Arts & Entertainment" },
  { name: "Fine arts", category: "Arts & Entertainment" },
  { name: "Performing arts", category: "Arts & Entertainment" },
  { name: "Crafts", category: "Arts & Entertainment" },
  { name: "Fashion", category: "Arts & Entertainment" },
  { name: "Beauty", category: "Arts & Entertainment" },
  { name: "Interior design", category: "Home & Garden" },
  { name: "Landscape design", category: "Home & Garden" },
  { name: "Home improvement", category: "Home & Garden" },
  { name: "Home cleaning services", category: "Home & Garden" },
  { name: "Pool and spa services", category: "Home & Garden" },
  { name: "Pest control", category: "Home & Garden" },
  { name: "Pet care services", category: "Pets & Animals" },
  { name: "Veterinary medicine", category: "Pets & Animals" },
  { name: "Pet food and supplies", category: "Pets & Animals" },
  { name: "Pet grooming", category: "Pets & Animals" },
  { name: "Pet training", category: "Pets & Animals" },
  {
    name: "Animal shelters and rescue organizations",
    category: "Pets & Animals",
  },
];
export default industries;
