// layouts
import AuthLayout from "../layouts/AuthLayout";
// pages
import LoginPage from "../pages/Auth/LoginPage";
import RegisterConfirmPage from "../pages/Auth/RegisterConfirmPage";
import RegisterPage from "../pages/Auth/RegisterPage";
import ForgotPasswordPage from "../pages/Auth/ForgotPasswordPage";
import ResetPasswordPage from "../pages/Auth/ResetPasswordPage";
import LogoutPage from "../pages/Auth/LogoutPage";
import NotFoundPage from "../pages/NotFoundPage";
import TermsOfService from "../pages/Auth/TermsOfService";

const AuthRoutes = [
  {
    path: "/auth/login",
    element: <AuthLayout children={<LoginPage />} />,
  },
  {
    path: "/auth/register",
    element: <AuthLayout children={<RegisterPage />} />,
  },
  {
    path: "/auth/register_confirm/:username",
    element: <AuthLayout children={<RegisterConfirmPage />} />,
  },
  {
    path: "/auth/forgot_password",
    element: <AuthLayout children={<ForgotPasswordPage />} />,
  },
  {
    path: "/auth/reset_password/:username",
    element: <AuthLayout children={<ResetPasswordPage />} />,
  },
  {
    path: "/auth/logout",
    element: <AuthLayout children={<LogoutPage />} />,
  },
  {
    path: "/auth/terms_of_service",
    element: <TermsOfService />,
  },
  {
    path: "/auth/*",
    element: <NotFoundPage />,
  },
];

export default AuthRoutes;
