import React, { Fragment } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { MdOutlineEdit } from "react-icons/md";
import { HiExternalLink, HiPlus, HiTrash } from "react-icons/hi";
import { BsStars } from "react-icons/bs";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";

//models
import * as ProjectModel from "../../models/Project";
// store
import { projectActions } from "../../stores/actions";
import { FormattedMessage } from "react-intl";

type Props = ConnectedProps<typeof connector> & {};

const ProjectPage: React.FC<Props> = ({ ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);

  const [projects, setProjects] = React.useState<any>({});
  const requestProjects = React.useCallback(async () => {
    try {
      const resp = await ProjectModel.get({
        userId: user?.sub,
        "status[0]": "published",
        "status[1]": "unpublish",
      });
      setProjects(resp);
      if (!resp || !resp.data || !resp.data.Items) {
        window.scrollTo(0, 0);
        navigate("/wizard/persons");
      }
    } catch (error) {
      console.log(error);
    }
  }, [user, navigate]);

  const handleDashboard = (project: any) => {
    dispatch(props.filledProject(project));
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const handleEdit = (project: any) => {
    dispatch(props.filledProject(project));
    setTimeout(() => {
      window.location.replace("/cms");
    }, 1000);
  };

  const handlePreview = (project: any) => {
    dispatch(props.filledProject(project));
    setTimeout(() => {
      window.location.replace("/cms?preview=1");
    }, 1000);
  };

  const handlePublish = (project: any) => {
    dispatch(props.filledProject(project));
    setTimeout(() => {
      window.location.replace("/cms/publish");
    }, 1000);
  };

  const handleDelete = (project: any) => {
    dispatch(props.filledProject(project));
    setTimeout(() => {
      navigate("/cms/setting/delete");
    }, 1000);
  };

  React.useEffect(() => {
    requestProjects();
  }, [requestProjects]);

  return (
    <div className="mx-auto mb-auto mt-24 max-w-7xl flex-1 px-4 pb-12 sm:px-6 lg:px-8">
      <h1 className="mb-5 text-4xl font-bold leading-tight text-gray-800">
        <FormattedMessage id="LangsProjects" />
      </h1>
      <p className="mb-10 text-base text-gray-800">
        <FormattedMessage id="projectViewOrEdit" />
      </p>
      <h2 className="text-2xl font-medium leading-tight text-gray-800">
        <FormattedMessage id="LangsYourWebsites" />
      </h2>
      <p className="mb-3 text-base font-normal text-gray-800">
        <FormattedMessage id="projectHeresWhere" />
      </p>
      <div className="mb-16 grid grid-cols-1 gap-x-5 gap-y-10  lg:grid-cols-2">
        {projects?.data &&
          projects?.data.Items &&
          projects.data.Items.map((project: any) => (
            <div
              key={project.id}
              className="col-span-1 transition duration-300 ease-in-out hover:drop-shadow-xl"
            >
              <div
                className={classNames(
                  project.status === "published" ? "" : "bg-slate-900",
                  "flex flex-1 flex-col items-stretch justify-between p-5 md:flex-row md:items-start"
                )}
                style={
                  project.status === "published" && project.thumbnail
                    ? {
                        backgroundImage: `url(${project?.thumbnail})`,
                        backgroundSize: "cover",
                      }
                    : {}
                }
              >
                <div className="flex items-start gap-4">
                  {project.status === "published" ? (
                    <div className="relative flex h-12 w-12 overflow-hidden rounded-full bg-white">
                      <img
                        alt={project.slug}
                        src={project.logo}
                        className="absolute left-2 top-2 h-8 w-8 object-cover"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mb-5">
                    <h3 className="whitespace-nowrap text-lg font-bold text-white">
                      {project.slug}
                    </h3>
                    <p className="mb-2 text-sm font-normal leading-normal text-white">
                      <FormattedMessage id="LangsCurrentPlan" />
                      <strong className="ml-1 text-sm font-bold leading-5 text-white">
                        {project.plan}
                      </strong>
                    </p>
                    <div className="inline-flex items-center gap-2  rounded bg-white bg-opacity-20 px-2 py-1 text-sm font-semibold text-white">
                      {project.status === "published" ? (
                        <CheckIcon className="h-5 w-5 text-white" />
                      ) : (
                        ""
                      )}
                      <span className="text-base font-bold leading-normal text-white">
                        {project.status === "published" ? (
                          <FormattedMessage id="LangsPublished" />
                        ) : (
                          <FormattedMessage id="LangsUnpublished" />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 bg-white p-4 lg:flex-row">
                <button
                  onClick={() => handleDashboard(project)}
                  className="rounded bg-indigo-600 px-5 py-3 text-base font-semibold text-white hover:bg-indigo-700"
                >
                  <FormattedMessage id="LangsShowDashboard" />
                </button>

                <Menu as="div" className="relative">
                  <Menu.Button className="w-full">
                    <span className="flex w-full items-center justify-center gap-2 rounded border-2 border-indigo-600 bg-white px-5 py-3 font-semibold text-indigo-500 hover:bg-gray-200">
                      <FormattedMessage id="LangsActives" />

                      <ChevronDownIcon className="h-5 w-5 font-bold text-indigo-600" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <button
                        onClick={() => handleEdit(project)}
                        className="text-md flex w-full gap-2 px-3 py-2 leading-6 text-gray-900 hover:bg-gray-100"
                      >
                        <MdOutlineEdit fontSize={20} />
                        <FormattedMessage id="LangsEdit" />
                      </button>
                      {project.status === "published" ? (
                        <button
                          className="text-md flex w-full gap-2 px-3 py-2 leading-6 text-gray-900 hover:bg-gray-100"
                          onClick={() => handlePreview(project)}
                        >
                          <HiExternalLink fontSize={20} />
                          <FormattedMessage id="LangsPreview" />
                        </button>
                      ) : (
                        <button
                          className="text-md flex w-full gap-2 px-3 py-2 leading-6 text-gray-900 hover:bg-gray-100"
                          onClick={() => handlePublish(project)}
                        >
                          <HiExternalLink fontSize={20} />
                          <FormattedMessage id="LangsPublish" />
                        </button>
                      )}
                      <button className="text-md flex w-full gap-2 px-3 py-2 leading-6 text-red-800 hover:bg-gray-100">
                        <BsStars fontSize={20} />
                        <FormattedMessage id="LangsUpgrade" />
                      </button>
                      <button
                        className="text-md flex w-full gap-2 px-3 py-2 leading-6 text-gray-900 hover:bg-gray-100"
                        onClick={() => handleDelete(project)}
                      >
                        <HiTrash fontSize={20} />
                        <FormattedMessage id="LangsDelete" />
                      </button>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          ))}

        <Link to="/wizard/persons">
          <div className="col-span-1 flex h-full w-auto cursor-pointer flex-col items-center justify-center bg-white p-10 font-bold text-indigo-600 hover:drop-shadow-xl">
            <HiPlus className="inline-block h-14 w-14 cursor-pointer font-bold text-indigo-600" />
            <FormattedMessage id="LangsNewWebsite" />
          </div>
        </Link>
      </div>
    </div>
  );
};

const connector = connect(null, { ...projectActions });

export default connector(ProjectPage);
