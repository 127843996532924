import React from "react";
import { useState } from "react";

import { Switch } from "@headlessui/react";

// icon
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { FiInfo } from "react-icons/fi";

import classNames from "classnames";

const categories = [
  {
    id: "a1",
    title: "Essential",
    description:
      "These technologies are strictly necessary to provide you with the services and features available through our website.",
    isOpen: false,
    isEnabled: false,
    isRequired: true,

    labels: [
      {
        id: 2,
        label: "Cloudflaret",
        isOpen: false,
        isEnabled: false,

        labels: [
          {
            id: 2,
            label: "Cloudflaret",
            isOpen: false,
            isEnabled: false,
          },
          {
            id: 3,
            label: "Limited Time Discount",
            isOpen: false,
            isEnabled: false,
          },
          {
            id: 4,
            label: "Usercentrics Consent Management Platform",
            isOpen: false,
            isEnabled: false,
          },
        ],
      },
      {
        id: "b1",
        title: "Functional & Statistics",
        description:
          "These technologies enable us to provide enhanced functionality and personalisation as well as analyse website usage in order to measure and improve its performance.",
      },
      {
        id: 3,
        label: "Limited Time Discount",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 4,
        label: "Usercentrics Consent Management Platform",
        isOpen: false,
        isEnabled: false,
      },
    ],
  },
  {
    id: "b1",
    title: "Functional & Statistics",
    description:
      "These technologies enable us to provide enhanced functionality and personalisation as well as analyse website usage in order to measure and improve its performance.",
    isOpen: false,
    isEnabled: false,

    labels: [
      {
        id: 5,
        label: "Google Analytics",
        sub: "Subservices",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 5,
        label: "Hotjar",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 6,
        label: "YouTube Video",
        isOpen: false,
        isEnabled: false,
      },
    ],
  },
  {
    id: "c1",
    title: "Marketing",
    description:
      "These technologies are usually used to show you advertisements that meet your interests. When you visit another website, your browser’s cookie is recognized and selected ads are displayed to you based on the information stored in this cookie..",
    isOpen: false,
    isEnabled: false,

    labels: [
      {
        id: 7,
        label: "Facebook Pixel",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 8,
        label: "Fullstory",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 9,
        label: "Google Ads Conversion Tracking",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 10,
        label: "Google Ads Remarketing",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 11,
        label: "LinkedIn Insight Tag",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 12,
        label: "Microsoft Conversion Tracking",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 13,
        label: "Pinterest Tags",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 14,
        label: "Twitter Conversion Tracking",
        isOpen: false,
        isEnabled: false,
      },
    ],
  },
  {
    id: "d1",
    title: "Other data processing",
    description:
      "Within our organisation we use other tools for data processing. These are also listed here for informational purposes.",
    isOpen: false,
    isEnabled: false,

    labels: [
      {
        id: 15,
        label: "Affiliate",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 16,
        label: "Beamer",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 17,
        label: "Cognigy",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 18,
        label: "Datadog Real User Monitoring",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 19,
        label: "Google Tag Manager",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 20,
        label: "BUILDLink Cookie",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 21,
        label: "BUILDLink Webanalytics",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 22,
        label: "LaunchDarkly",
        isOpen: false,
        isEnabled: false,
      },
      {
        id: 23,
        label: "Visual Website Optimizer (VWO)",
        isOpen: false,
        isEnabled: false,
      },
    ],
  },
];

const CategoriesPage: React.FC = () => {
  // show section State
  const [categoriesList, setCategoriesList] = useState(categories);

  const handledShow = (index: number) => {
    const newList = [...categoriesList];
    newList[index].isOpen = !newList[index].isOpen;
    setCategoriesList(newList);
  };

  // Active Toggle switch State

  const handledEnable = (idx: number, sidx: number) => {
    const newList = [...categoriesList];
    newList[idx].labels[sidx].isEnabled = !newList[idx].labels[sidx].isEnabled;
    setCategoriesList(newList);
  };

  const handleToastOpen = (index: number) => {
    const newList = [...categoriesList];
    newList[index].isEnabled = !newList[index].isEnabled;

    newList[index].labels = newList[index].labels.map((categories) => {
      categories.isEnabled = newList[index].isEnabled;
      return categories;
    });

    setCategoriesList(newList);
  };

  return (
    <div className="flex flex-col gap-3 overflow-auto px-6">
      {categories.map((category, idx) => (
        <div
          key={category.id}
          className="flex flex-col rounded border border-white bg-blue-950 p-0.5 text-white "
        >
          {/* flex */}
          <div className="flex flex-col  sm:flex-row sm:items-center  sm:justify-between">
            {/* content */}
            <div className="flex-grow border-b border-white p-3 sm:mr-2 sm:border-none">
              {/* content wrapper */}
              <button
                onClick={() => {
                  handledShow(idx);
                }}
                className="text-left text-sm"
              >
                {/* title */}
                <div className="mt-0.5 font-bold ">{category.title}</div>
                <div className="mt-1 text-xs leading-tight">
                  {category.description}
                </div>
              </button>
            </div>

            <div className="flex items-center  justify-between px-3 ">
              <div className="my-3">
                <Switch
                  checked={category.isEnabled}
                  onChange={() => {
                    handleToastOpen(idx);
                  }}
                  className={classNames(
                    category.isEnabled
                      ? "bg-indigo-600"
                      : "border-2 border-gray-500 bg-white",
                    "relative inline-flex h-5 w-10 items-center rounded-full"
                  )}
                >
                  <span
                    className={classNames(
                      category.isEnabled
                        ? "translate-x-5 bg-white"
                        : "translate-x-0.5 bg-gray-500",
                      "inline-block h-4 w-4 transform rounded-full border transition-all duration-300 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
              {/* toggle button */}
              <button
                className="mx-4"
                onClick={() => {
                  handledShow(idx);
                }}
              >
                {category.isOpen ? (
                  <span className="mt-1 inline-block h-5 w-5 md:mt-0">
                    <HiChevronUp className="h-full w-full " />
                  </span>
                ) : (
                  <span className="mt-1 inline-block h-5 w-5 md:mt-0">
                    <HiChevronDown className="h-full w-full " />
                  </span>
                )}
              </button>
            </div>
          </div>
          {/* Activate buildLink Statistics group */}

          <section
            className={classNames(
              category.isOpen ? "opacity-100" : "hidden",
              "duration-600 overflow-hidden transition-all ease-in-out"
            )}
          >
            {category.labels.map((label, sidx) => (
              <div className="flex flex-col" key={sidx}>
                {/* divider */}
                <hr className="border-b-1 border-gray-100" />
                {/* content */}
                <div className="p-3">
                  {/* Activate on and off toggle */}
                  <div className="flex items-center justify-between text-sm">
                    {/* title */}
                    <div className="w-full">
                      <label className="w-full">
                        <div className=" font-normal leading-normal ">
                          {label.label}
                        </div>
                      </label>
                    </div>
                    {/* toggle button */}
                    <button className="flex items-center justify-center">
                      {/* notice icon */}
                      <span className="mr-2 inline-block h-4 w-4">
                        <FiInfo className="h-full w-full " />
                      </span>
                    </button>
                    <Switch
                      checked={label.isEnabled}
                      onChange={() => {
                        handledEnable(idx, sidx);
                      }}
                      className={classNames(
                        label.isEnabled
                          ? "bg-indigo-600"
                          : "border-1 border-gray-500 bg-white",
                        "relative inline-flex h-4 w-8 items-center rounded-full"
                      )}
                    >
                      <span
                        className={classNames(
                          label.isEnabled
                            ? "translate-x-3  bg-white"
                            : "translate-x-0.5   bg-gray-500",
                          "inline-block h-4 w-4 transform rounded-full border transition-all duration-300 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                </div>
                {/* <section
                                    className={classNames(
                                        label.isOpen ? "opacity-100" : "hidden",
                                        "duration-600 overflow-hidden transition-all ease-in-out"
                                    )}
                                >

                                </section> */}
              </div>
            ))}
          </section>
        </div>
      ))}
    </div>
  );
};

export default CategoriesPage;
