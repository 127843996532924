import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

//icon
import { HiCheck, HiMinusSmall, HiOutlineLockClosed } from "react-icons/hi2";
//images
import statistics_promo_cover from "../../../media/svg/statisticPage/buildLink-statistics-teaser.svg";
import { FormattedMessage } from "react-intl";

const StatisticPageUnPublished: React.FC = () => {
  // Published useState
  const [isPublished] = useState<boolean>(true);
  // Active state
  const [isActived] = useState<boolean>(false);

  return (
    <>
      {/* Main card */}
      <div className="rounded bg-white shadow-lg">
        {/* Content section 01 */}
        <div className="border-1 flex flex-col items-start justify-between border-b border-gray-200 pl-10 pr-8 pt-10 sm:flex-row">
          {/* section 01 left content */}
          <div className="mb-6 max-w-[540px] md:mb-0">
            {/* section 01 title */}
            <h3 className="mb-2 text-left text-2xl font-bold leading-normal text-zinc-800 md:mb-3">
              <FormattedMessage id="StatisticUnPublishTrackTraffic" />

            </h3>
            {/* section 01 description */}
            <p className="mb-5 text-left text-base font-normal leading-normal text-zinc-800">
              <FormattedMessage id="StatisticUnPublishTrackTrafficDescription" />

            </p>
            {/* section 01 description more link */}
            <div className="mb-4">
              <p className="text-left text-zinc-800">
                <FormattedMessage id="StatisticUnPublishSteps" />

                {/*eslint-disable-next-line*/}
                <a
                  className="max-w-full cursor-pointer truncate text-left text-base leading-5 text-indigo-600 transition-all duration-300 ease-in-out hover:text-indigo-700"
                  href="#"
                >
                  &nbsp;
                  <FormattedMessage id="LangsMoreInfo" />
                </a>
              </p>
            </div>
            {/* section 01 List */}
            <ul className="mb-10">
              {/* List 01 */}
              <li className="mb-2 flex list-none">
                <span className="mr-2 mt-1 inline-block h-5 w-5">
                  <HiCheck className="h-full w-full text-green-600" />
                </span>
                <span className="w-64">
                  <div className="flex">
                    <p className="text-left text-base font-normal leading-normal text-zinc-800">
                      <FormattedMessage id="LangsVisitsByWeek" />


                    </p>
                    <p className="pl-2 text-left text-base font-normal leading-normal text-green-600">
                      <FormattedMessage id="LangsTryFree" />!
                    </p>
                  </div>
                </span>
              </li>
              {/* List 02 */}
              <li className="mb-2 flex list-none">
                <span className="mr-2 mt-1 inline-block h-5 w-5">
                  <HiCheck className="h-full w-full text-green-600" />
                </span>
                <span className="flex w-64">
                  <p className="text-left text-base font-normal leading-normal text-zinc-800">
                    <FormattedMessage id="LangsVisitorsAndEngagementRate" />

                  </p>
                </span>
              </li>
              {/* List 03 */}
              <li className="mb-2 flex list-none">
                <span className="mr-2 mt-1 inline-block h-5 w-5">
                  <HiCheck className="h-full w-full text-green-600" />
                </span>
                <span className="flex w-64">
                  <p className="text-left text-base font-normal leading-normal text-zinc-800">
                    <FormattedMessage id="LangsSourcesOfTraffic" />

                  </p>
                </span>
              </li>
            </ul>
          </div>
          {/* section 01 image */}
          <div className="pb-5 md:pl-10">
            <img
              className="hidden max-h-[196px] w-auto md:block"
              src={statistics_promo_cover}
              alt=""
            />
          </div>
        </div>
        {/* section 02 list */}
        <ul className="list-none px-5">
          {/* section 02 Publish button group */}
          {/* li onclick change the state to publish */}
          <li className="flex min-h-[88px] items-center justify-between border-b-2 border-solid border-gray-100 py-5 hover:ring-2 hover:ring-indigo-600 hover:ring-offset-2 md:py-4">
            {/* Published onclick Link */}
            <a
              className="w-full max-w-full cursor-pointer transition-all duration-300 ease-in-out"
              href="/cms"
            >
              <div className="flex items-center justify-between">
                {/* Publish content */}
                <div className="mr-5 flex items-center">
                  {isPublished ? (
                    <>
                      {/* check icon */}
                      <span className="mr-5 inline-block h-5 w-5">
                        <HiCheck className="h-full w-full text-gray-400" />
                      </span>
                    </>
                  ) : (
                    <>
                      {/* minus icon */}
                      <span className="mr-5 inline-block h-5 w-5">
                        <HiMinusSmall className="h-full w-full text-gray-400" />
                      </span>
                    </>
                  )}
                  {/* Publish content */}
                  <div className="flex flex-col">
                    <span className="text-left text-base font-normal leading-normal text-zinc-800">
                      <FormattedMessage id="StatisticUnPublishPublishYourWebsite" />

                    </span>
                  </div>
                </div>
                {/* Publish button */}
                {isPublished ? (
                  <>
                    {/* Published Done */}
                    <div className="inline-flex h-8 max-w-full cursor-pointer items-center justify-center px-3 py-1 ">
                      <HiCheck className="h-full w-full text-neutral-500" />
                      <span className="text-center text-base font-normal text-neutral-500">
                        <FormattedMessage id="LangsDone" />

                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="inline-flex h-8 max-w-full cursor-pointer items-center justify-center rounded bg-indigo-600 px-3 py-1 hover:bg-indigo-700 md:block">
                      <span className="truncate text-center text-sm font-bold text-white hover:text-gray-200">
                        <FormattedMessage id="LangsPublish" />

                      </span>
                    </div>
                  </>
                )}
              </div>
            </a>
          </li>
          {/* section 02 Complete group */}
          {!isPublished ? (
            <li className="flex min-h-[88px] items-center justify-between py-5 md:py-4">
              {/* complete content */}
              <div className="mr-5 flex items-center">
                {/* complete icon */}
                <span className="mr-5 inline-block h-5 w-5">
                  <HiOutlineLockClosed className="h-full w-full text-gray-400" />
                </span>
                {/* complete content */}
                <span className="text-left text-base font-normal leading-normal text-neutral-500">
                  <FormattedMessage id="StatisticCompleteSteps" />

                </span>
              </div>
            </li>
          ) : !isActived ? (
            <>
              <li className="flex min-h-[88px] items-center justify-between border-b-2 border-solid border-gray-100 py-5 hover:ring-2 hover:ring-indigo-600 hover:ring-offset-2 md:py-4">
                {/* Published onclick Link */}
                <Link
                  className="w-full max-w-full cursor-pointer transition-all duration-300 ease-in-out"
                  to="/statistic/settings"
                >
                  <div className="flex items-center justify-between">
                    {/* Publish content */}
                    <div className="mr-5 flex items-center">
                      {/* minus icon */}
                      <span className="mr-5 inline-block h-5 w-5">
                        {isActived ? (
                          <HiCheck className="h-full w-full text-neutral-500" />
                        ) : (
                          <HiMinusSmall className="h-full w-full text-gray-400" />
                        )}
                      </span>
                      {/* Publish content */}
                      <div className="flex flex-col">
                        <span
                          className={classNames(
                            isActived ? "text-neutral-500" : "text-zinc-800",
                            "text-left text-base font-normal leading-normal"
                          )}
                        >
                          <FormattedMessage id="StatisticActivateBuildLinkStatistics" />

                        </span>
                      </div>
                    </div>
                    {/* Actived button */}
                    {isActived ? (
                      <>
                        {/* Actived Done */}
                        <div className="inline-flex h-8 max-w-full cursor-pointer items-center justify-center px-3 py-1 ">
                          <HiCheck className="h-full w-full text-neutral-500" />
                          <span className="text-center text-base font-normal text-neutral-500">
                            <FormattedMessage id="LangsDone" />

                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="inline-flex h-8 max-w-full cursor-pointer items-center justify-center rounded bg-indigo-600 px-3 py-1 hover:bg-indigo-700 md:block">
                          <span className="truncate text-center text-sm font-bold text-white hover:text-gray-200">
                            <FormattedMessage id="LangsActivateNew" />

                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </Link>
              </li>
            </>
          ) : null}
        </ul>
      </div>
    </>
  );
};
export default StatisticPageUnPublished;
