import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import { BsChevronLeft } from "react-icons/bs";
import { AiOutlineGlobal } from "react-icons/ai";
import { MdPhoneIphone, MdOutlineDesktopMac } from "react-icons/md";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Splide } from "@splidejs/splide";
//hooks
import { useProject } from "../../../hooks/useProject";
//component
import MyDropdown from "../../MyDropdown";
//context
import { WebBuilderContext } from "../../../pages/Cms/WebBuilderPage";
//type
import type { MyDropdownItem } from "../../MyDropdown/type";
import type { PreviewDrawerHandler, PreviewDrawerProps } from "./type";
import { FormattedMessage } from "react-intl";

const PreviewDrawer: React.ForwardRefRenderFunction<
  PreviewDrawerHandler,
  PreviewDrawerProps
> = ({ mainRef }, innerRef) => {
  const navigate = useNavigate();
  const { getLink: getProjectLink } = useProject();
  const desktopRef = React.useRef<HTMLIFrameElement>(null);
  const mobileRef = React.useRef<HTMLIFrameElement>(null);
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = React.useState<"mobile" | "desktop">("mobile");
  const { handleUnpublish, handlePublish } =
    React.useContext(WebBuilderContext);
  const project = useSelector(({ project }) => project);
  const [dropdowns] = React.useState<MyDropdownItem[]>([
    {
      label: "Unpublish",
      disabled: project?.status !== "published",
      onClick: handleUnpublish,
    },
    { label: "SEO", href: "#" },
  ]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpgrade = () => {
    navigate("/upgrade/package-selection");
  };

  const initContent = React.useCallback(
    (container: HTMLIFrameElement) => {
      if (!mainRef?.current) return;
      let iframeBody = container.contentDocument?.querySelector(
        "body > #root"
      ) as HTMLElement;
      if (!iframeBody) return;
      iframeBody.innerHTML = mainRef.current.innerHTML;
      initSplide(iframeBody);
    },
    //eslint-disable-next-line
    [mainRef, mode]
  );

  const initSplide = (target: HTMLElement) => {
    target.querySelectorAll(".section-carousel").forEach((el: any) => {
      new Splide(el, {
        loop: true,
        heightRatio: 0.5625, //16: 9
        rewind: true,
      }).mount();
    });

    resizeImageDragger(target);

    return target;
  };

  const resizeImageDragger = (target: HTMLElement) => {
    if (!target) return;
    target.querySelectorAll(".image-dragger").forEach((el: any) => {
      //set cursor normal
      el.style.cursor = "normal";
      // recalculate the dragger width height and transform
      const editingWidth = +el.getAttribute("data-width"),
        editingHeight = +el.getAttribute("data-height");
      const draggerWidth = +el.style.width.replace("px", ""),
        draggerHeight = +el.style.height.replace("px", ""),
        draggerTransform = el.style.transform;
      const m = /^translate3d\((.*?)px, (.*?)px, 0px\)$/.exec(draggerTransform);
      if (!m || !m[1] || !m[2]) return;
      const translateX = +m[1],
        translateY = +m[2];

      let parent = el.parentNode as HTMLElement;
      let styledParent = window.getComputedStyle(parent);
      let containerWidth = +styledParent
        .getPropertyValue("width")
        .replace("px", "");
      //check containerWidth is not number
      if (
        typeof containerWidth !== "number" ||
        isNaN(containerWidth) ||
        containerWidth === 0
      )
        return;
      let containerHeight = +styledParent
        .getPropertyValue("height")
        .replace("px", "");
      if (typeof containerHeight !== "number") {
        containerHeight = containerWidth * (editingHeight / editingWidth);
      }
      const multiplier = +containerWidth / editingWidth;
      //change el style width height and transform
      el.style.width = draggerWidth * multiplier + "px";
      el.style.height = draggerHeight * multiplier + "px";
      el.style.transform = `translate3d(${translateX * multiplier}px, ${
        translateY * multiplier
      }px, 0px)`;
    });
  };

  const requestDesktop = React.useCallback(() => {
    if (!desktopRef?.current) return;
    initContent(desktopRef.current);
  }, [desktopRef, initContent]);

  const requestMobile = React.useCallback(() => {
    if (!mobileRef?.current) return;
    initContent(mobileRef.current);
  }, [mobileRef, initContent]);

  React.useImperativeHandle(innerRef, () => ({
    handleOpen,
    handleClose,
  }));

  React.useEffect(() => {
    desktopRef?.current?.addEventListener("load", requestDesktop);
    mobileRef?.current?.addEventListener("load", requestMobile);
    requestDesktop();
    requestMobile();
    return () => {
      //eslint-disable-next-line
      desktopRef?.current?.removeEventListener("load", requestDesktop);
      //eslint-disable-next-line
      mobileRef?.current?.removeEventListener("load", requestMobile);
    };
  }, [desktopRef, mobileRef, requestDesktop, requestMobile, open]);

  return (
    <div
      className={twMerge(
        "flex h-0 w-0 flex-col opacity-0 transition-all duration-200",
        open
          ? "fixed inset-0 z-[990] h-full w-full opacity-100"
          : "left-1/2 top-0 hidden opacity-0"
      )}
    >
      {/*Header START*/}
      <div className="flex h-16 w-full items-center justify-between bg-indigo-950 text-white">
        <button className="flex p-4" onClick={handleClose}>
          <BsChevronLeft fontSize={24} />
          <span>
            <FormattedMessage id="LangsEditWebsite" />
          </span>
        </button>

        <div className="flex items-center justify-end">
          <AiOutlineGlobal fontSize={24} />
          <span className="p-2">{getProjectLink()}</span>
          <button className="bg-red-600 px-2 py-1.5 text-sm font-semibold text-white shadow-sm ring-2 ring-inset ring-red-600 hover:bg-red-500">
            <FormattedMessage id="LangsGetYouOwnDomain" />
          </button>
        </div>

        {/*Header button group START*/}
        <div className="flex h-full">
          <button
            className={twMerge(
              "p-4 text-gray-400 hover:bg-indigo-600 hover:text-white",
              mode === "mobile" && "bg-indigo-600 text-white"
            )}
            onClick={() => setMode("mobile")}
          >
            <MdPhoneIphone fontSize={24} />
          </button>
          <button
            className={twMerge(
              "p-4 text-gray-400 hover:bg-indigo-600 hover:text-white",
              mode === "desktop" && "bg-indigo-600 text-white"
            )}
            onClick={() => setMode("desktop")}
          >
            <MdOutlineDesktopMac fontSize={24} />
          </button>
          <div className="grid h-full grid-cols-5 items-center gap-1">
            <div className="col-span-2 flex h-full items-center justify-center">
              <button
                className="bg-white px-3 py-1.5 text-sm font-bold text-indigo-600 hover:h-full hover:w-full"
                onClick={handlePublish}
              >
                <FormattedMessage id="LangsPublish" />
              </button>
            </div>
            <div className="col-span-2 flex h-full items-center justify-center">
              <button
                className="px-3 py-1.5 text-sm font-bold text-white hover:h-full hover:w-full hover:bg-white hover:text-indigo-900"
                onClick={handleUpgrade}
              >
                <FormattedMessage id="LangsUpgrade" />
              </button>
            </div>
            <div className="col-span-1 flex h-full items-center justify-center">
              <MyDropdown list={dropdowns}>
                <EllipsisVerticalIcon className="h-full w-5" />
              </MyDropdown>
            </div>
          </div>
        </div>
        {/*Header button group END*/}
      </div>
      {/*Header END*/}
      {/*Content START*/}
      <div className="grow bg-gray-300">
        <div
          className={twMerge(
            "h-full w-full bg-white",
            mode !== "desktop" && "hidden"
          )}
        >
          <iframe
            ref={desktopRef}
            title="preview"
            className="h-full w-full"
            src="preview.html"
          ></iframe>
        </div>
        <div
          className={twMerge(
            "flex h-full w-full items-center justify-center",
            mode !== "mobile" && "hidden"
          )}
        >
          <div className="flex h-[692px] w-[346px] items-center justify-center rounded-[38px] bg-white drop-shadow">
            <iframe
              ref={mobileRef}
              title="mobile-preview"
              className="h-[570px] w-[320px] border border-gray-300"
              src="preview.html"
            ></iframe>
          </div>
        </div>
      </div>
      {/*Content END*/}
    </div>
  );
};

export default React.forwardRef(PreviewDrawer);
