import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { Formik, Form, ErrorMessage, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import logo from "../../media/svg/logo/logo.svg";
import Progress from "../../components/Progress";
//images
import name_image from "../../media/svg/wizard/name/name_image.png";
//context
import { WizardContext } from "./index";
import { wizardActions } from "../../stores/actions";

type FormValues = {
  name: string;
};

type Props = ConnectedProps<typeof connector>;

const NamePage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector(({ wizard }) => wizard);
  const { activeStep, setActiveStep } = React.useContext(WizardContext);
  const [initialValues] = React.useState<FormValues>({
    name: formData.name || "",
  });

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

  const handleSubmit = (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    try {
      const { name } = values;
      dispatch(props.editWizard("name", name));
      if (
        formData.purpose &&
        ["personal project", "hobby", "skip"].includes(formData.purpose)
      ) {
        setActiveStep(activeStep + 2);
      } else {
        setActiveStep(activeStep + 1);
      }
    } catch (e: any) {
      setStatus(e.message);
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <div className="tex-black m-0 min-h-screen overflow-auto p-0 leading-6">
        {/* // header */}
        <div className="min-h-screen overflow-auto bg-gray-50 transition">
          <header
            className="fixed left-0 top-0 z-10 m-0 flex h-[88px] w-full items-center justify-center border-b border-solid border-gray-200 bg-gray-50 bg-opacity-[0.9] p-0 leading-6 text-black transition md:w-1/2"
            aria-label="Global"
          >
            <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
          </header>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              values,
              handleChange,
              handleBlur,
            }: FormikProps<FormValues>) => (
              <Form>
                {/* // main content Group */}
                <div className="relative flex min-h-screen w-full flex-col bg-gray-50 pb-24 leading-6 text-black transition md:w-1/2">
                  <div className="relative m-0 mt-[88px] flex flex-1 flex-col p-0 leading-6 text-black">
                    {/* // main content */}
                    <div className="mx-auto my-0 w-full max-w-[660px] px-5 py-0 leading-6 text-black ">
                      {/* Title */}
                      <h1 className="mx-1 mb-8 mt-10 p-0 text-center text-2xl font-bold leading-[1.2] text-black md:mb-16 md:mt-20 md:text-[4.8vh]">
                        <FormattedMessage id="wizardNameTitle" />
                      </h1>
                      {/* // subtitle */}
                      <p className="mx-auto mb-16 mt-0 max-w-[480px] p-0 text-center text-base font-medium leading-normal text-neutral-500">
                        <FormattedMessage id="wizardNameDescription" />
                      </p>
                      {/* // input form */}
                      <div id="name-step" className="leading-6 text-black">
                        <div className="relative mb-4 leading-6 text-black">
                          <input
                            type="text"
                            name="name"
                            required
                            placeholder="For example, 'Marstree's Website'"
                            autoComplete="organization"
                            className="h-20 w-full cursor-text appearance-none border-0 border-b-2 px-5 text-lg font-bold outline-0 transition placeholder:text-lg placeholder:text-neutral-300 hover:bg-zinc-100 focus:bg-zinc-100 sm:text-2xl placeholder:sm:text-2xl"
                            value={values.name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <button
                            type="reset"
                            className="r-[20px] absolute right-2 top-8 h-5 w-5 cursor-pointer rounded-none border-0 text-center text-base leading-5 text-neutral-500 outline-0 hover:animate-bounce hover:text-blue-600"
                          >
                            <XCircleIcon className="cursor-pointer text-center align-middle leading-5 text-neutral-500" />
                          </button>
                          <span className="font-sm text-sm text-red-500">
                            <ErrorMessage name="name" />
                          </span>
                          <div className="absolute bottom-0 left-0 m-0 block h-px w-full rounded-none bg-zinc-800 p-0 leading-6 text-black transition" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* // button group */}
                <div className="fixed bottom-[84px] flex w-full items-center transition md:w-1/2">
                  <Progress className="bg-gray-300/20" value={30} size="sm" />
                  <div className="fixed bottom-0 flex w-full items-center justify-between bg-gray-50 bg-opacity-[0.9] p-5 leading-6 transition md:w-1/2">
                    <button
                      type="button"
                      className="inline-flex  border-2 border-indigo-600 bg-gray-50 px-5 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={handleBack}
                    >
                      <FormattedMessage id="LangsBack" />
                    </button>
                    {/*eslint-disable-next-line*/}
                    <button
                      type="submit"
                      className="inline-flex  bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="LangsContinue" />
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {/* // Right side Content */}
        <div className="fixed left-1/2 right-0 top-0 hidden h-full bg-white leading-6 text-black md:inline">
          <div className="relative flex h-full animate-none flex-col items-center justify-center overflow-hidden bg-white leading-6 text-black transition">
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-start leading-6 text-black pt-[88px]">
              <div className="relative max-w-[45vw] overflow-hidden bg-white shadow transition-transform">
                <img
                  className="block h-full w-full"
                  src={name_image}
                  alt=""
                />
              </div>
              <div className="relative mx-16 mb-0 mt-6 hidden w-[30vw] p-0 leading-6 text-black opacity-100 transition-opacity sm:inline lg:max-w-xl">
                <h2 className="text-4xl font-bold leading-none text-indigo-600">
                  03
                </h2>
                <p className="flex min-h-[40px] items-center text-base font-normal leading-6 text-gray-900">
                  <FormattedMessage id="wizardNameSubTitle" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...wizardActions });

export default connector(NamePage);
