import React from "react";
import { Formik, Form, FormikProps, ErrorMessage, FormikHelpers } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Logo from "../../media/svg/logo/logo.svg";
import { Auth } from "aws-amplify";

import Alert from "../../components/Alert/";
import MyDialog from "../../components/MyDialog/";

import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { FormattedMessage } from "react-intl";

interface FormValues {
  email: string;
}

type MyDialogHandler = React.ElementRef<typeof MyDialog>;

const ForgotPasswordPage: React.FC = () => {
  const dialogRef = React.useRef<MyDialogHandler>(null);
  const navigate = useNavigate();
  const login_at = useSelector(({ auth }) => auth.login_at, shallowEqual);

  const [initialValues] = React.useState<FormValues>({
    email: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const handleSubmit = async (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    const { email } = values;
    try {
      await Auth.forgotPassword(email);
      if (dialogRef?.current) {
        dialogRef.current.setConfig({
          enableClose: false,
          title: <FormattedMessage id="LangsResetPassword" defaultMessage={"Reset Password"} />,
          children:
            <FormattedMessage id="ResetPasswordMessage" defaultMessage={"Please check your email and get a verification code to reset your password"} />,
          buttons: [
            {
              label: <FormattedMessage id="LangsOkay" defaultMessage={"Okay"} />,
              className: "btn-primary",
              onClick: () => {
                navigate(`/auth/reset_password/${email}`);
              },
            },
          ],
        });
        dialogRef.current.handleOpen();
      }
    } catch (error: any) {
      if (error?.message) setStatus(error.message);
      console.warn(error);
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (login_at) {
      navigate("/");
    }
  }, [login_at, navigate]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 sm:px-6 lg:px-8">
      <MyDialog ref={dialogRef} />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={Logo} alt="API Flow" />
      </div>

      <div className="mt-8 min-h-screen sm:flex sm:flex-col sm:items-center sm:justify-center sm:pb-80">
        <div className="bg-white px-6 py-12 shadow sm:w-96 sm:rounded-lg sm:px-12">
          <div className="flex">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
              <FormattedMessage id="LangsForgotYourPassword" />

            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                status,
                values,
                handleBlur,
                handleChange,
                isSubmitting,
              }: FormikProps<FormValues>) => (
                <Form className="space-y-6" autoComplete="off">
                  <Alert status="error" open={!!status} enableClose={false}>
                    {status}
                  </Alert>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      <FormattedMessage id="LangsEmailAddress" />

                    </label>
                    <div className="mt-2">
                      <input
                        name="email"
                        type="email"
                        autoComplete="new-password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="font-sm text-sm text-red-500">
                        <ErrorMessage name="email" />
                      </span>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="LangsSubmit" />

                    </button>
                  </div>
                  <div className="cursor-pointer hover:cursor-pointer">
                    <a
                      href="/auth/login"
                      className="flex items-center text-sm font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      <ArrowLeftIcon className="mr-2 h-5 w-auto text-indigo-600 hover:text-indigo-500" />
                      <FormattedMessage id="LangsBackToLogin" />

                    </a>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="mt-20 flex flex-row justify-center gap-8">
          {/*eslint-disable-next-line*/}
          <a
            href="#"
            className="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            <FormattedMessage id="LangsLegalNotice" />

          </a>
          {/*eslint-disable-next-line*/}
          <a
            href="#"
            className="font-semibold text-gray-600 hover:text-gray-500"
          >
            <FormattedMessage id="LangsPrivacyPolicy" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
