import { API } from "aws-amplify";

export const getCollection = async (params?: object) => {
  return await API.get("buildLinkApi", "/pexels/collections", {
    response: true,
    queryStringParameters: params || {},
  });
};

type GetPhotosProps = {
  query: string;
  page: number;
  per_page: number;
};

export const getPhotos = async (params: GetPhotosProps) => {
  return await API.get("buildLinkApi", "/pexels/photos", {
    response: true,
    queryStringParameters: params || {},
  });
};

type GetTopicsProps = {
  word: string;
};

export const getTopics = async (params: GetTopicsProps) => {
  return await API.get("buildLinkApi", "/pexels/topic", {
    response: true,
    queryStringParameters: params || {},
  });
};
