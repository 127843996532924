import React from "react";

import type { WizardLayoutProps } from "./type";

import AuthInit from "../pages/Auth/AuthInit";

const WizardLayout: React.FC<WizardLayoutProps> = ({ children }) => {
  return <AuthInit>{children}</AuthInit>;
};

export default WizardLayout;
