import React from "react";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { MyDialogContext } from "../../components/MyDialog";
import HelpDocPage from "./HelpDocPage";

const blogs = [
  {
    id: "01",
    title: "First Steps",
    description:
      "I've just created my website, what should I take care of first?",
    href: "#",
  },
  {
    id: "03",
    title: "First Steps",
    description:
      "I've just created my website, what should I take care of first?",
    href: "#",
  },
  {
    id: "04",
    title: "First Steps",
    description:
      "I've just created my website, what should I take care of first?",
    href: "#",
  },
  {
    id: "04",
    title: "First Steps",
    description:
      "I've just created my website, what should I take care of first?",
    href: "#",
  },
  {
    id: "05",
    title: "First Steps",
    description:
      "I've just created my website, what should I take care of first?",
    href: "#",
  },
  {
    id: "06",
    title: "First Steps",
    description:
      "I've just created my website, what should I take care of first?",
    href: "#",
  },
  {
    id: "07",
    title: "First Steps",
    description:
      "I've just created my website, what should I take care of first?",
    href: "#",
  },
  {
    id: "08",
    title: "First Steps",
    description:
      "I've just created my website, what should I take care of first?",
    href: "#",
  },
];

const HelpPage: React.FC = () => {
  const dialogRef = React.useContext(MyDialogContext);
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    // TODO:在这里执行搜索功能的逻辑
    console.log("Perform search");
  };
  const handleClearClick = () => {
    setSearchTerm("");
  };

  const handleBlockClick = () => {
    if (!dialogRef?.setContent) return;
    dialogRef.setContent(<HelpDocPage />);
  };

  return (
    <>
      <div className="flex flex-col bg-white ">
        <div className="relative mb-5 ">
          <input
            type="text"
            placeholder="Search BuildLink Dolphin Help"
            value={searchTerm}
            onChange={handleInputChange}
            className="w-full border-2 border-gray-300  py-2 pl-10  pr-8  focus:border-blue-500"
          />

          <button
            type="button"
            onClick={handleSearchClick}
            className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <MagnifyingGlassIcon className="h-5 w-5 " />
          </button>

          {searchTerm && (
            <button
              type="button"
              onClick={handleClearClick}
              className="absolute right-3 top-1/2 -translate-y-1/2 transform text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <XCircleIcon className="h-5 w-5 " />
            </button>
          )}
        </div>

        <div className="mb-5 grid w-full gap-5 pt-2 text-zinc-800 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
          {blogs.map((blog, idx) => (
            <div
              className="my-card m-0 flex flex-auto cursor-pointer flex-row p-5 text-zinc-800 shadow-[0_0_0_1px_#efefef] transition-shadow ease-in hover:shadow-[0_4px_16px_rgba(0,0,0,.24)]"
              key={idx}
              onClick={handleBlockClick}
            >
              <div className="m-0 cursor-pointer p-0 text-zinc-800">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  {blog.title}
                </h3>
                <p
                  className="mx-0 mb-2 mt-0 cursor-pointer p-0 text-sm  font-normal leading-normal text-zinc-800"
                  data-immersive-translate-effect="1"
                  data-immersive_translate_walked="a9e1a97f-9a99-4a1e-be2a-cbc67ae77fea"
                >
                  {blog.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default HelpPage;
