import React from "react";
import ReactDOMClient from "react-dom/client";
import Editor from "../index";
import { IntlProvider } from "react-intl";
import Langs from "../../../../langs/";

const useEditorContent = () => {
  //target is the .editor-content
  const handleClick = React.useCallback((target: HTMLElement) => {
    const content = target;
    const div = document.createElement("div");
    const id = `editor-holder-${new Date().getTime()}`;
    div.id = id;
    div.setAttribute("data-holder", "editor-content");
    const r = ReactDOMClient.createRoot(div);
    r.render(
      <IntlProvider messages={Langs.en} locale="en" defaultLocale="en">
        <Editor
          root={r}
          holderId={div.id}
          content={content.innerHTML}
          type="editor-content"
        />
      </IntlProvider>
    );
    target.replaceWith(div);
  }, []);

  const handleClickOut = React.useCallback(
    (target: HTMLElement, data: any, prevRoot: ReactDOMClient.Root) => {
      const holder = target?.parentNode as HTMLElement;
      if (!holder) return;
      const holderType = holder.getAttribute("data-holder");
      if (holderType !== "editor-content") return;
      const div = document.createElement("div");
      div.className = "editor-content";
      div.innerHTML = data.getInnerHTML();
      holder.replaceWith(div);
      prevRoot.unmount();
      div.addEventListener("click", (e: any) => handleClick(e.currentTarget));
    },
    [handleClick]
  );

  return { handleClick, handleClickOut };
};

export default useEditorContent;
