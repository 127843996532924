import React from "react";
import classNames from "classnames";
import {
  BiHorizontalLeft,
  BiHorizontalRight,
  BiHorizontalCenter,
} from "react-icons/bi";
import { FaLink } from "react-icons/fa";
import {
  HiOutlineDocumentText,
  HiOutlineLink,
  //  HiOutlineCalendar,
  HiArrowLeft,
} from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
// context
import { EditorContext } from "./index";
//components
import Tooltip from "../../Tooltip";
import FormUrl from "./forms/FormUrl";
import FormCall from "./forms/FormCall";
import FormPage from "./forms/FormPage";

import type { ButtonToolbarProps } from "./type";
import { FormattedMessage } from "react-intl";

const ButtonToolbar: React.FC<ButtonToolbarProps> = ({
  type,
  link,
  pageId,
}) => {
  const { buttonAlign, handleButtonAlign } = React.useContext(EditorContext);
  const [openLinkBox, setOpenLinkBox] = React.useState<boolean>(false);
  const [typeState, setTypeState] = React.useState<string>("");

  const handleChooseType = (t: string) => {
    setTypeState(t);
  };

  React.useEffect(() => {
    setTypeState(type);
  }, [type]);

  return (
    <div className="editor-toolbar absolute left-0 top-0 z-50 flex -translate-y-[150%] flex-row text-black drop-shadow-xl">
      <Tooltip content={<FormattedMessage id="LangsAlignLeft" />}>
        <button
          type="button"

          onClick={() => handleButtonAlign("left")}
          className={classNames(
            buttonAlign === "left" &&
            "bg-indigo-600 text-white hover:bg-indigo-700",
            "flex h-12 w-12 items-center justify-center bg-gray-100 p-1 text-black hover:bg-gray-200"
          )}
        >
          <BiHorizontalLeft fontSize={24} />
        </button>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsAlignCenter" />}>
        <button
          type="button"

          onClick={() => handleButtonAlign("center")}
          className={classNames(
            buttonAlign === "center" &&
            "bg-indigo-600 text-white hover:bg-indigo-700",
            "flex h-12 w-12 items-center justify-center bg-gray-100 p-1 text-black hover:bg-gray-200"
          )}
        >
          <BiHorizontalCenter fontSize={24} />
        </button>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsAlignRight" />}>
        <button
          type="button"

          onClick={() => handleButtonAlign("right")}
          className={classNames(
            buttonAlign === "right" &&
            "bg-indigo-600 text-white hover:bg-indigo-700",
            "flex h-12 w-12 items-center justify-center bg-gray-100 p-1 text-black hover:bg-gray-200"
          )}
        >
          <BiHorizontalRight fontSize={24} />
        </button>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsLink" />}>
        <button
          type="button"

          onClick={() => setOpenLinkBox(!openLinkBox)}
          className={classNames(
            openLinkBox && "bg-indigo-600 text-white hover:bg-indigo-700",
            "flex h-12 w-12 items-center justify-center bg-gray-100 p-1 text-black hover:bg-gray-200"
          )}
        >
          <FaLink />
        </button>
      </Tooltip>
      <div className="relative">
        <div
          className={classNames(
            openLinkBox && "!flex",
            "editor-link-dialog absolute -left-40 top-0 z-50 hidden w-80 -translate-y-[calc(100%+1rem)] flex-col bg-gray-100 drop-shadow-xl "
          )}
        >
          <div
            className={classNames(
              typeState === "" ? "justify-center" : "justify-start",
              "flex border-b border-gray-300 px-2 py-4 font-medium"
            )}
          >
            {!link && (
              <button
                className={classNames(typeState === "" && "hidden", "pl-2")}
                onClick={() => handleChooseType("")}
              >
                <HiArrowLeft fontSize={22} />
              </button>
            )}
            <div className="flex grow justify-center">
              {typeState === "" && <><FormattedMessage id="LangsSetUpYourLink" /></>}
              {typeState === "page" && <><FormattedMessage id="LangsLinkToPage" /></>}
              {typeState === "url" && <>
                <FormattedMessage id="LangsLinkToURLOrEmail" />
              </>}
              {typeState === "booking" && <>
                <FormattedMessage id="LangsLinkToBookingService" />
              </>}
              {typeState === "call" && <>
                <FormattedMessage id="LangsLinkToCall" />
              </>}
            </div>
          </div>
          <div
            className={classNames(
              typeState !== "" && "hidden",
              "grid grid-cols-2 gap-4 p-4"
            )}
          >
            <>
              <div
                className="col-span-1 flex cursor-pointer flex-col items-center justify-center p-3 text-center hover:bg-gray-200"
                onClick={() => handleChooseType("page")}
              >
                <HiOutlineDocumentText fontSize={22} className="m-3" />
                <FormattedMessage id="LangsPage" />

              </div>
              <div
                className="col-span-1 flex cursor-pointer flex-col items-center justify-center p-3 text-center hover:bg-gray-200"
                onClick={() => handleChooseType("url")}
              >
                <HiOutlineLink fontSize={22} className="m-3" />
                <FormattedMessage id="LangsURLOrEmail" />

              </div>
              {/*<div
                className="flex flex-col items-center justify-center p-3 text-center cursor-pointer col-span-1 hover:bg-gray-200"
                onClick={() => handleChooseType("booking")}
              >
                <HiOutlineCalendar fontSize={22} className="m-3" />
                Booking Service
              </div>*/}
              <div
                className="col-span-1 flex cursor-pointer flex-col items-center justify-center p-3 text-center hover:bg-gray-200"
                onClick={() => handleChooseType("call")}
              >
                <FiPhoneCall fontSize={22} className="m-3" />
                <FormattedMessage id="LangsCall" />

              </div>
            </>
          </div>
          <div
            className={classNames(
              typeState !== "url" && "hidden",
              "flex flex-col"
            )}
          >
            <FormUrl link={link} />
          </div>
          <div
            className={classNames(
              typeState !== "call" && "hidden",
              "flex flex-col"
            )}
          >
            <FormCall link={link} />
          </div>
          <div
            className={classNames(
              typeState !== "page" && "hidden",
              "flex flex-col"
            )}
          >
            <FormPage link={link} pageId={pageId || ""} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonToolbar;
