import { API } from "aws-amplify";

type GetSuggestionProps = {
  domain: string;
};

export const getSuggestion: any = async (params: GetSuggestionProps) => {
  return await API.get("buildLinkApi", "/domain/suggestion", {
    response: true,
    queryStringParameters: params || {},
  });
};

type GetAvailableProps = {
  domain: string;
};

export const getAvailable: any = async (params: GetAvailableProps) => {
  return await API.get("buildLinkApi", "/domain/check_available", {
    response: true,
    queryStringParameters: params || {},
  });
};
