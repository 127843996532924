import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { BsChevronLeft } from "react-icons/bs";
//type
import type { PexelsLibraryHandler } from "./type";
//context
import { MediaDialogContext } from "./index";
//models
import * as PexelsModel from "../../../../models/Pexels";
//hooks
import useIntersectionObserver from "../../../../hooks/useInterectionObserver";
import { FormattedMessage } from "react-intl";

const queries = [
  { name: "Services", query: "Services" },
  { name: "Photography", query: "Photography" },
  { name: "Health & Wellness", query: "Health & Wellness" },
  { name: "Restaurant", query: "Restaurant" },
  { name: "Art & Design", query: "Art & Design" },
  { name: "Sports", query: "Sports" },
  { name: "Portfolio & CV", query: "Portfolio & CV" },
  { name: "Home & Garden", query: "Home & Garden" },
  { name: "Music", query: "Music" },
  { name: "Education & Community", query: "Education & Community" },
];

export const PexelsLibraryTitle: React.FC = () => {
  const { setLibraryOpen } = React.useContext(MediaDialogContext);
  return (
    <>
      <button
        className="items-center justify-center p-2 pl-0"
        onClick={() => setLibraryOpen("media")}
      >
        <BsChevronLeft />
      </button>
      <FormattedMessage id="LangsFreePromotionalPhotos" />
    </>
  );
};

const PexelsLibrary: React.ForwardRefRenderFunction<
  PexelsLibraryHandler,
  {}
> = (props, innerRef) => {
  const bottomRef = React.useRef<HTMLDivElement>(null);
  const [selectedQuery, setSelectedQuery] = React.useState<string>("Services");
  const [images, setImages] = React.useState<any>([]);
  const [selectedImages, setSelectedImages] = React.useState<any>([]);
  const [pexelsResult, setPexelsResult] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // observe scroll to bottom
  const isBottomVisible = useIntersectionObserver(
    bottomRef,
    {
      threshold: 0.5,
      rootMargin: "40px",
    },
    false
  );

  //select image
  const handleSelectImage = (image: any) => {
    if (selectedImages.includes(image)) {
      setSelectedImages(selectedImages.filter((i: any) => i !== image));
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  };

  //request photo from pexels api
  const requestPhotos = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await PexelsModel.getPhotos({
        query: selectedQuery,
        page: pexelsResult?.nextPage ? pexelsResult.nextPage : 1,
        per_page: 20,
      });
      const { data } = resp;
      setPexelsResult({
        ...data,
        nextPage: +data.page + 1,
      });
      const newImages = data.photos;
      if (images.length === 0 || !pexelsResult?.next_page) {
        setImages(newImages);
      } else {
        setImages([...images, ...newImages]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [images, pexelsResult, selectedQuery]);

  React.useImperativeHandle(innerRef, () => ({
    getSelected: () => {
      return selectedImages;
    },
  }));

  React.useEffect(() => {
    if (!isBottomVisible) return;
    requestPhotos();
  }, [requestPhotos, isBottomVisible, selectedQuery]);

  return (
    <>
      <h3 className="text-base font-bold">
        <FormattedMessage id="EdutorMediaFilterImage" />
      </h3>
      <p className="text-sm">
        <FormattedMessage id="EdutorMediaFilterImageDesc" />
      </p>
      <div className="mt-4 flex flex-wrap gap-2">
        {/*category button start*/}
        {queries.map((query: any, queryIdx: number) => (
          <button
            key={queryIdx}
            className={classNames(
              selectedQuery === query.query &&
                "bg-indigo-500 text-white hover:bg-indigo-600",
              "border-2 border-indigo-500 px-2 py-1 text-sm font-bold text-indigo-500 hover:bg-gray-100"
            )}
            onClick={() => {
              setPexelsResult({});
              setImages([]);
              setSelectedQuery(query.query);
            }}
          >
            {query.name}
          </button>
        ))}
        {/*category button end*/}
      </div>
      <div className="mt-4 grid max-h-[486px] grid-cols-4 gap-4 overflow-y-auto overflow-x-hidden">
        {images &&
          images.map((image: any) => (
            <div
              key={image.id}
              onClick={() => handleSelectImage(image)}
              className={classNames(
                "image-thumbnail relative col-span-1 m-1 pt-[calc(100%-0.25rem)]",
                selectedImages.includes(image) && "selected"
              )}
            >
              <input
                type="checkbox"
                value={image.src.small}
                className=" absolute left-3 top-3 z-10 bg-transparent checked:bg-indigo-500 checked:bg-opacity-100"
                checked={selectedImages.includes(image)}
                onChange={() => handleSelectImage(image)}
              />
              <img
                alt={image.alt}
                src={image.src.tiny}
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
          ))}
        {isLoading && (
          <>
            {_.map([0, 1, 2, 3, 4, 5, 6, 7], (i) => (
              <div
                key={i}
                className={classNames(
                  "image-thumbnail relative col-span-1 m-1 animate-pulse bg-gray-300 pt-[calc(100%-0.25rem)]"
                )}
              />
            ))}
          </>
        )}
        <div ref={bottomRef} className="col-span-4 h-1"></div>
      </div>
    </>
  );
};

export default React.forwardRef(PexelsLibrary);
