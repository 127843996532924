import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

import {
  ArrowUpOnSquareIcon,
  BuildingStorefrontIcon,
  CalendarDaysIcon,
  ChatBubbleLeftIcon,
  CheckIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";

import Progress from "../../components/Progress";
import logo from "../../media/svg/logo/logo.svg";

//image
import goal_image from "../../media/svg/wizard/goal/goal_image.png";

// hobby_types images
import user_panel_6 from "../../media/svg/wizard/goal/user-panel-6.svg";
import user_panel_6_white from "../../media/svg/wizard/goal/user-panel-6-white.svg";
import user_01 from "../../media/svg/wizard/goal/user-01.svg";
import user_01_white from "../../media/svg/wizard/goal/user-01-white.svg";
import user_11 from "../../media/svg/wizard/goal/user-11.svg";
import user_11_white from "../../media/svg/wizard/goal/user-11-white.svg";
import phone_profile_2 from "../../media/svg/wizard/goal/phone-profile-2.svg";
import phone_profile_2_white from "../../media/svg/wizard/goal/phone-profile-2-white.svg";
import phone_in_hand from "../../media/svg/wizard/goal/phone-in-hand.svg";
import phone_in_hand_white from "../../media/svg/wizard/goal/phone-in-hand-white.svg";
import shopping_cart from "../../media/svg/wizard/goal/shopping-cart.svg";
import shopping_cart_white from "../../media/svg/wizard/goal/shopping-cart-white.svg";

// business_types images
import building_03 from "../../media/svg/wizard/goal/building-03.svg";
import building_03_white from "../../media/svg/wizard/goal/building-03-white.svg";
import email_notification from "../../media/svg/wizard/goal/email-notification.svg";
import email_notification_whtie from "../../media/svg/wizard/goal/email-notification-white.svg";

//Context
import { WizardContext } from "./index";
import { wizardActions } from "../../stores/actions";

const hobby_types = [
  {
    id: 1,
    image: user_panel_6,
    image_white: user_panel_6_white,
    icon: MapPinIcon,
    title: "PromoteMyContent",
  },
  {
    id: 2,
    image: user_01,
    image_white: user_01_white,
    icon: ChatBubbleLeftIcon,
    title: "AnnounceAnEventOrProject",
  },
  {
    id: 3,
    image: user_11,
    image_white: user_11_white,
    icon: ArrowUpOnSquareIcon,
    title: "ShareMyPortfolio",
  },
  {
    id: 4,
    image: phone_profile_2,
    image_white: phone_profile_2_white,
    icon: CheckIcon,
    title: "GetHired",
  },
  {
    id: 5,
    image: phone_in_hand,
    image_white: phone_in_hand_white,
    icon: CalendarDaysIcon,
    title: "GetOnlineBookings",
  },
  {
    id: 6,
    image: shopping_cart,
    image_white: shopping_cart_white,
    icon: BuildingStorefrontIcon,
    title: "SellOnline",
  },
];

const business_types = [
  {
    id: 1,
    image: building_03,
    image_white: building_03_white,
    icon: MapPinIcon,
    title: "PromoteAPhysicalBusiness",
  },
  {
    id: 2,
    image: user_01,
    image_white: user_01_white,
    icon: ChatBubbleLeftIcon,
    title: "AnnounceAnEventOrProject",
  },
  {
    id: 3,
    image: user_11,
    image_white: user_11_white,
    icon: ArrowUpOnSquareIcon,
    title: "ShowcaseMyWork",
  },
  {
    id: 4,
    image: email_notification,
    image_white: email_notification_whtie,
    icon: CheckIcon,
    title: "GetCustomersToContactMe",
  },
  {
    id: 5,
    image: phone_in_hand,
    image_white: phone_in_hand_white,
    icon: CalendarDaysIcon,
    title: "GetOnlineBookings",
  },
  {
    id: 6,
    image: shopping_cart,
    image_white: shopping_cart_white,
    icon: BuildingStorefrontIcon,
    title: "SellOnline",
  },
];

type Props = ConnectedProps<typeof connector>;

const GoalPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector(({ wizard }) => wizard);
  const { activeStep, setActiveStep } = React.useContext(WizardContext);

  // businessTypes state
  const [businessTypes] = React.useState(
    formData.purpose !== undefined &&
      !["personal project", "hobby", "skip"].includes(formData.purpose)
      ? business_types
      : hobby_types
  );

  const handlePagesClick = (businessIdx: number) => {
    const title: string = businessTypes[businessIdx].title;
    let goal = formData.goal;
    if (!goal.includes(title)) {
      if (goal.length < 3) goal.push(title);
    } else {
      goal = goal.filter((item: string) => item !== title);
    }
    dispatch(props.editWizard("goal", goal));
  };

  const handleBack = () => {
    if (
      formData.purpose !== undefined &&
      !["personal project", "hobby", "skip"].includes(formData.purpose)
    ) {
      setActiveStep(activeStep - 1);
    } else {
      setActiveStep(activeStep - 2);
    }
  };

  const handleSkip = () => {
    dispatch(props.editWizard("goal", ["skip"]));
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    const goal = businessTypes
      .filter((businessType) => formData.goal.includes(businessType.title))
      .map((businessType) => businessType.title);
    dispatch(props.editWizard("goal", goal));
    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <div className="tex-black m-0 min-h-screen overflow-auto p-0 leading-6">
        {/* // header */}
        <div className="min-h-screen overflow-auto bg-gray-50 transition">
          <header
            className="fixed left-0 top-0 z-10 m-0 flex h-[88px] w-full items-center justify-center border-b border-solid border-gray-200 bg-gray-50 bg-opacity-[0.9] p-0 leading-6 text-black transition md:w-1/2"
            aria-label="Global"
          >
            <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
          </header>

          {/* // main content Group */}
          <div className="relative flex min-h-screen w-full flex-col bg-gray-50 pb-24 leading-6 text-black transition md:w-1/2">
            <div className="relative m-0 mt-[88px] flex flex-1 flex-col p-0 leading-6 text-black">
              {/* // main content */}
              <div className="mx-auto my-0 w-full max-w-[660px] px-5 py-0 leading-6 text-black ">
                {/* Title */}
                <h1 className="mx-1 mb-8 mt-10 p-0 text-center text-2xl font-bold leading-[1.2] text-black md:mb-16 md:mt-20 md:text-[4.8vh]">
                  <FormattedMessage id="wizardGoalTitle" />
                </h1>
                {/* // subtitle */}
                <p className="mx-auto mb-16 mt-0 max-w-[480px] p-0 text-center text-base font-medium leading-normal text-neutral-500">
                  <FormattedMessage id="wizardGoalDescription" />
                </p>

                {/* //businessTypes Multiple choice */}
                <div className="grid grid-cols-1 justify-start gap-6 leading-6 text-black sm:grid-cols-3">
                  {businessTypes.map((businessType, businessIdx) => (
                    <div
                      key={businessType.id}
                      className={classNames(
                        formData.goal.includes(businessType.title)
                          ? "box-selected bg-indigo-900"
                          : "bg-white",
                        formData.goal.length >= 3 &&
                          !formData.goal.includes(businessType.title)
                          ? "opacity-50"
                          : "opacity-100",
                        "wizard goal box box-hover"
                      )}
                      onClick={() => handlePagesClick(businessIdx)}
                    >
                      <div className="">
                        {formData.goal.includes(businessType.title) ? (
                          <img
                            src={businessType.image_white}
                            alt=""
                            className="wizard goal image"
                          />
                        ) : (
                          <img
                            src={businessType.image}
                            alt=""
                            className="wizard goal image"
                          />
                        )}
                      </div>
                      <div className="">
                        <businessType.icon
                          className={classNames(
                            formData.goal.includes(businessType.title)
                              ? "text-white"
                              : "text-indigo-600",
                            "wizard goal icon"
                          )}
                        />
                      </div>
                      <div className="min-2-0 flex flex-grow items-end leading-6">
                        <p
                          className={classNames(
                            formData.goal.includes(businessType.title)
                              ? "font-bold text-white"
                              : "text-zinc-800",
                            "cursor-pointer pr-3 text-base leading-6"
                          )}
                        >
                          <FormattedMessage id={businessType.title} />
                        </p>
                        <CheckCircleIcon
                          className={classNames(
                            formData.goal.includes(businessType.title)
                              ? "wizard goal checkIcon"
                              : "hidden"
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* // button group */}
          <div className="fixed bottom-[84px] flex w-full items-center transition md:w-1/2">
            <Progress className="bg-gray-300/20" value={50} size="sm" />
            <div className="fixed bottom-0 flex w-full items-center justify-between bg-gray-50 bg-opacity-[0.9] p-5 leading-6 transition md:w-1/2">
              <button
                onClick={handleBack}
                type="button"
                className="inline-flex border-2 border-indigo-600 bg-gray-50 px-5 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FormattedMessage id="LangsBack" />
              </button>
              <div
                onClick={handleSkip}
                className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500"
              >
                <FormattedMessage id="LangsSkipThisStep" />
              </div>
              <button
                type="button"
                className="inline-flex bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={formData.goal.length <= 0}
                onClick={handleNext}
              >
                <FormattedMessage id="LangsContinue" />
              </button>
            </div>
          </div>
        </div>

        {/* // Right side Content */}
        <div className="fixed left-1/2 right-0 top-0 hidden h-full bg-white leading-6 text-black md:inline">
          <div className="relative flex h-full animate-none flex-col items-center justify-center overflow-hidden bg-white leading-6 text-black transition">
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-start pt-[88px] leading-6 text-black">
              <div className="relative max-w-[45vw] overflow-hidden  bg-white shadow transition-transform">
                <img className="block h-full w-full" src={goal_image} alt="" />
              </div>
              <div className="relative mx-16 mb-0 mt-6 hidden w-[30vw] p-0 leading-6 text-black opacity-100 transition-opacity sm:inline lg:max-w-xl">
                <h2 className="text-4xl font-bold leading-none text-indigo-600">
                  05
                </h2>
                <p className="flex min-h-[40px] items-center text-base font-normal leading-6 text-gray-900">
                  <FormattedMessage id="wizardGoalSubTitle" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...wizardActions });

export default connector(GoalPage);
