import React, { Fragment } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Tab } from "@headlessui/react";

import classNames from "classnames";

import { HiXMark } from "react-icons/hi2";

import type { MyCookiesDrawerHandler, MyCookiesDrawerProps } from "./type";
import CategoriesPage from "../../../pages/Setting/subPage/CategoriesPage";
import ServicesPage from "../../../pages/Setting/subPage/ServicesPage";
import { FormattedMessage } from "react-intl";

const tabs = [
  { name: "LangsCategories", content: <CategoriesPage /> },
  { name: "LangsServices", content: <ServicesPage /> },
];

const MyCookiesDrawer: React.ForwardRefRenderFunction<
  MyCookiesDrawerHandler,
  MyCookiesDrawerProps
> = ({ ...props }, innerRef) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useImperativeHandle(innerRef, () => ({
    handleOpen,
    handleClose,
  }));

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden text-white">
            <div className="absolute inset-0 overflow-hidden bg-gray-500 bg-opacity-40 transition duration-500 ease-in-out">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl bg-blue-900">
                    <div className="bg-whtie flex h-full flex-col shadow-xl  ">
                      <div className="bg-whtie flex h-full flex-col overflow-auto ">
                        <div className="bg-blue-950 px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="fonts-bold text-lg font-bold leading-6 ">
                              <div>
                                <FormattedMessage id="MyCookiesTitle" />
                              </div>
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className=""
                                onClick={handleClose}
                              >
                                <HiXMark className="h-6 w-6 text-white hover:rounded-md hover:text-gray-200 hover:ring-2 hover:ring-indigo-500" />
                              </button>
                            </div>
                          </div>
                          <div className="py-1 text-sm font-bold">
                            <span className="w-full">
                              <FormattedMessage id="MyCookiesDescription" />
                            </span>
                          </div>

                          <div className="pt-3 text-sm">
                            <div>
                              {/*eslint-disable-next-line*/}
                              <a href="#" className="mr-3 pt-1">
                                <FormattedMessage id="LangsPrivacyPolicy" />
                              </a>
                              {/*eslint-disable-next-line*/}
                              <a href="#" className="mr-3 pt-1">
                                <FormattedMessage id="LangsLegalNotice" />
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="relative mb-20 flex  w-full flex-col ">
                          <Tab.Group>
                            <Tab.List className=" mb-4 flex  w-full border-b border-white bg-blue-950 px-6 pt-1">
                              {tabs.map((tab, i) => (
                                <Tab as={Fragment} key={i}>
                                  {({ selected }) => (
                                    <button
                                      className={classNames(
                                        selected ? " border-b-2 font-bold" : "",
                                        "flex-1 cursor-pointer items-center  whitespace-nowrap py-2 text-sm"
                                      )}
                                    >
                                      <FormattedMessage id={tab.name} />
                                    </button>
                                  )}
                                </Tab>
                              ))}
                            </Tab.List>
                            <Tab.Panels>
                              {tabs.map((tab, i) => (
                                <Tab.Panel key={i}>{tab.content}</Tab.Panel>
                              ))}
                            </Tab.Panels>
                          </Tab.Group>
                        </div>
                      </div>

                      <div className="absolute bottom-0 flex w-full justify-center border-t border-white bg-blue-950 px-6 pb-3 ">
                        <div className="flex w-full gap-3 py-3">
                          <button className="flex-1 bg-white p-2 text-sm font-bold text-gray-800 transition-colors duration-300 hover:bg-gray-200">
                            <FormattedMessage id="LangsSaveSettings" />
                          </button>
                          <button className="flex-1 bg-white p-2 text-sm font-bold text-gray-800 transition-colors duration-300 hover:bg-gray-200">
                            <FormattedMessage id="LangsDeny" />
                          </button>
                          <button className="flex-1 bg-green-600 p-2 text-sm font-bold text-white transition-colors duration-300 hover:bg-green-900">
                            <FormattedMessage id="LangsAcceptAll" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default React.forwardRef(MyCookiesDrawer);
