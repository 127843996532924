import { PROJECT_LOADED, PROJECT_CHANGED, PROJECT_LOGOUT } from "./actions";

import type { Reducer } from "redux";
import type { StoreAction, ProjectStateType } from "./type";

const initProjectState = {
  id: undefined,
  slug: undefined,
  plan: undefined,
  status: undefined,
};

export const reducer: Reducer<ProjectStateType, StoreAction> = (
  state = initProjectState,
  action
) => {
  switch (action.type) {
    case PROJECT_LOADED: {
      const { project } = action.payload;
      return {
        ...state,
        ...project,
      };
    }

    case PROJECT_CHANGED: {
      const { project } = action.payload;
      return {
        ...state,
        ...project,
      };
    }

    case PROJECT_LOGOUT: {
      return { ...initProjectState };
    }

    default: {
      return state;
    }
  }
};
