import React from "react";
import Splide from "@splidejs/splide";
import "@splidejs/splide/css";

import useEditorTitle from "./useEditorTitle";
import useEditorContent from "./useEditorContent";
import useEditorButton from "./useEditorButton";
import useEditorImage from "./useEditorImage";
import useEditorGrid from "./useEditorGrid";

export type useEventListenerProps = {
  splide?: Splide | null;
};
export type AddEventOption = {
  isSlide?: boolean;
};

const useEventListener = ({ splide: sp = null }: useEventListenerProps) => {
  const [splide, setSplide] = React.useState<Splide | null>(sp);
  const { handleClick: handleClickEditorTitle } = useEditorTitle();
  const { handleClick: handleClickEditorContent } = useEditorContent();
  const { handleClick: handleClickEditorButton } = useEditorButton();
  const { handleClick: handleClickEditorImage } = useEditorImage();
  const { handleClick: handleClickEditorGrid } = useEditorGrid();

  const coverClick = (e: MouseEvent) => {
    const cover = e.currentTarget as HTMLElement;
    //hidden cover
    cover.style.display = "none";
    //trigger editor-image click
    const container = cover.parentNode;
    if (!container) return;
    const editorImage = container.querySelector(".editor-image") as HTMLElement;
    if (!editorImage) return;
    editorImage.click();
  };

  const addEvent = React.useCallback(
    (sectionNode: HTMLElement, options?: AddEventOption) => {
      //for editor-title
      sectionNode.querySelectorAll(".editor-title").forEach((el) => {
        el.addEventListener("click", (e: any) => {
          handleClickEditorTitle(e.currentTarget);
        });
      });

      //for editor-content
      sectionNode.querySelectorAll(".editor-content").forEach((el) => {
        el.addEventListener("click", (e: any) => {
          handleClickEditorContent(e.currentTarget);
        });
      });

      //for editor-button and editor-link
      sectionNode
        .querySelectorAll(".editor-button, .editor-link")
        .forEach((el) => {
          el.addEventListener("click", (e: any) => {
            handleClickEditorButton(e.currentTarget);
          });
        });

      //for editor-image
      sectionNode.querySelectorAll(".editor-image").forEach((el) => {
        el.addEventListener("click", (e: any) => {
          if (!e.target.classList.contains("editor-image")) return;
          handleClickEditorImage(e.currentTarget);
        });
      });

      // for editor-grids
      if (options?.isSlide === true) {
        //for slide cover click
        sectionNode
          .querySelectorAll(".splide__slide .cover")
          .forEach((cover: any) => {
            cover.addEventListener("click", coverClick);
          });

        sectionNode.addEventListener("mouseenter", (e: any) => {
          handleClickEditorGrid(e.currentTarget, splide);
        });
      } else {
        //for carousel
        let sp: null | Splide = null;
        if (sectionNode.classList.contains("section-carousel")) {
          sectionNode
            .querySelectorAll(".splide__slide .cover")
            .forEach((cover: any) => {
              cover.addEventListener("click", coverClick);
            });
          if (!splide) {
            sp = new Splide(sectionNode, {
              drag: false,
              heightRatio: 0.5625, //16: 9
            }).mount();
            setSplide(sp);
          } else {
            splide.refresh();
          }
        }

        sectionNode
          .querySelectorAll(".editor-grids-item, .editor-grid-item")
          .forEach((el) => {
            el.addEventListener("mouseenter", (e: any) => {
              handleClickEditorGrid(e.currentTarget, sp);
            });
          });
      }
    },
    [
      handleClickEditorTitle,
      handleClickEditorContent,
      handleClickEditorButton,
      handleClickEditorImage,
      handleClickEditorGrid,
      splide,
    ]
  );

  const removeEvent = React.useCallback(
    (sectionNode: HTMLElement) => {
      sectionNode.querySelectorAll(".editor-title").forEach((el) => {
        el.removeEventListener("click", (e: any) => {
          handleClickEditorTitle(e.currentTarget);
        });
      });
      sectionNode.querySelectorAll(".editor-content").forEach((el) => {
        el.removeEventListener("click", (e: any) => {
          handleClickEditorContent(e.currentTarget);
        });
      });
      sectionNode
        .querySelectorAll(".editor-button, .editor-link")
        .forEach((el) => {
          el.removeEventListener("click", (e: any) => {
            handleClickEditorButton(e.currentTarget);
          });
        });
      sectionNode.querySelectorAll(".editor-image").forEach((el) => {
        el.removeEventListener("click", (e: any) => {
          handleClickEditorImage(e.currentTarget);
        });
      });

      sectionNode
        .querySelectorAll(".editor-grids > *.editor-grid-item")
        .forEach((el) => {
          el.removeEventListener("mouseenter", (e: any) => {
            handleClickEditorGrid(e.currentTarget, splide);
          });
        });
    },
    [
      handleClickEditorImage,
      handleClickEditorButton,
      handleClickEditorContent,
      handleClickEditorTitle,
      handleClickEditorGrid,
      splide,
    ]
  );
  return { addEvent, removeEvent };
};

export default useEventListener;
