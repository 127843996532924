import React from "react";

import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";

import Logo1 from "../../media/img/logo1.svg";
import Logo2 from "../../media/img/logo2.svg";
import { FormattedMessage } from "react-intl";

const logos = [
  {
    id: "01",
    image: Logo1,
    title: "logo 1",
    href: "#",
  },
  {
    id: "02",
    image: Logo2,
    title: "logo 1",
    href: "#",
  },
];

const LogosPage: React.FC = () => {
  return (
    <div className="mx-auto max-w-7xl pt-24 sm:px-6 lg:px-8">
      <h1 className="mb-16 font-sans text-3xl font-bold  text-gray-900 md:text-4xl">
        <FormattedMessage id="LangsLogos" />

      </h1>
      <div className="flex  flex-col md:flex-row md:items-center md:justify-between">
        <p className="mb-3">
          <FormattedMessage id="LogosChooseOrCreate" />

        </p>
        {/* TODO: Add new logo Download delete */}
        <a
          href="/cms/logos/new"
          className="flex cursor-pointer items-center justify-center truncate  bg-blue-600  px-3 py-2 text-sm font-bold text-white shadow-sm hover:bg-[#0538d3] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <span>
            <FormattedMessage id="LangsNewLogo" />
          </span>
        </a>
      </div>
      <div>
        <div className="mb-10 grid gap-5 md:grid-cols-2 ">
          {logos.map((logo, idx) => (
            <div key={idx}>
              <a
                href={logo.href}
                className="flex cursor-pointer items-center justify-end gap-4 p-4 "
              >
                <TrashIcon className="h-5 w-5 text-gray-600 hover:text-red-500 " />

                <span className="hover:border-transparent hover:text-red-500 focus:border-transparent focus:text-red-500">
                  <FormattedMessage id="LangsDelete" />

                </span>
              </a>
              <a
                className="my-card relative flex h-56 flex-auto cursor-pointer flex-col  items-center justify-center self-end overflow-hidden rounded-none bg-white p-4 font-bold text-blue-600 shadow-[0_0_0_2px_#efefef] transition-shadow ease-in hover:shadow-[0_4px_16px_rgba(0,0,0,.24)] "
                href="/download"
              >
                <img src={logo.image} alt="" />
                <button
                  type="button"
                  className="absolute bottom-8 right-8 mt-8 flex  cursor-pointer items-center justify-center truncate  whitespace-nowrap border-2 border-blue-600  px-3 py-2 text-center  text-sm font-bold text-blue-600 shadow-sm hover:bg-[rgba(5,56,211,.08)]  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <span>
                    <FormattedMessage id="LangsDownload" />
                  </span>
                </button>
              </a>
            </div>
          ))}
          <a
            className="my-card relative mt-5 flex h-56 flex-auto cursor-pointer  flex-col items-center justify-center self-end overflow-hidden rounded-none bg-white font-bold text-blue-600 shadow-[0_0_0_2px_#efefef] transition-shadow ease-in hover:shadow-[0_4px_16px_rgba(0,0,0,.24)] "
            href="/upload"
          >
            <PlusIcon className="h-12 w-12 text-blue-600" />
            <FormattedMessage id="LangsNewLogo" />

          </a>
        </div>
      </div>
    </div>
  );
};
export default LogosPage;
