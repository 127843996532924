import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import classNames from "classnames";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { SidemenuContext } from "./index";
import { WebBuilderContext } from "../../pages/Cms/WebBuilderPage";
import { FormattedMessage } from "react-intl";

const CmsSidememu: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setMenuOpen } = React.useContext(SidemenuContext);
  const { openDesignDrawer, openPageDrawer } =
    React.useContext(WebBuilderContext);

  return (
    <nav className="flex flex-1 flex-col">
      <ul className="flex flex-1 flex-col ">
        <li>
          <button
            className={classNames(
              "text-gray-700 hover:bg-gray-50 hover:text-black",
              "group flex w-full flex-auto  gap-x-3 p-2 text-sm font-semibold leading-6"
            )}
            onClick={() => setMenuOpen("home")}
          >
            <AiOutlineArrowLeft fontSize={20} />
            <p className="flex grow items-center justify-between ">
              <FormattedMessage id="LangsBackToMainMenu" />

            </p>
          </button>
        </li>
        <li>
          <div className="mt-8 text-xs font-semibold uppercase leading-6 text-gray-700">
            <FormattedMessage id="LangsWebBuilder" />

          </div>
          <ul className="-ml-2  mt-2">
            <li>
              <button
                className={classNames(
                  location.pathname === "/cms" ? "bg-gray-50" : "",
                  "group flex w-full flex-auto gap-x-3 p-2 text-sm  font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-black"
                )}
                onClick={() => navigate("/cms")}
              >
                <p className="flex grow items-center justify-between ">
                  <FormattedMessage id="LangsEditWebsites" />

                </p>
              </button>
            </li>
            <li>
              <button
                className={classNames(
                  "group flex w-full flex-auto gap-x-3 p-2 text-sm  font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-black"
                )}
                onClick={() => openDesignDrawer()}
              >
                <p className="flex grow items-center justify-between ">
                  <FormattedMessage id="LangsDesign" />

                </p>
              </button>
            </li>
            <li>
              <button
                className={classNames(
                  "group flex w-full flex-auto gap-x-3 p-2 text-sm  font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-black"
                )}
                onClick={() => openPageDrawer()}
              >
                <p className="flex grow items-center justify-between ">
                  <FormattedMessage id="LangsPages" defaultMessage="Pages" />
                </p>
              </button>
            </li>
            <li>
              <Link
                className={classNames(
                  "group flex w-full flex-auto gap-x-3 p-2 text-sm  font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-black"
                )}
                to={"/cms/domains"}
              >
                <p className="flex grow items-center justify-between ">
                  <FormattedMessage id="LangsDomains" />

                </p>
              </Link>
            </li>
            <li>
              <button
                className={classNames(
                  "group flex w-full flex-auto gap-x-3 p-2 text-sm  font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-black"
                )}
              >
                <p className="flex grow items-center justify-between ">
                  <FormattedMessage id="LangsSearchEngines" />

                </p>
              </button>
            </li>
            <li>
              <Link
                className={classNames(
                  "group flex w-full flex-auto gap-x-3 p-2 text-sm  font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-black"
                )}
                to={"/cms/setting/general"}
              >
                <p className="flex grow items-center justify-between ">
                  <FormattedMessage id="LangsSettings" />

                </p>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default CmsSidememu;
