import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  // BuildingStorefrontIcon,
  ChevronRightIcon,
  // EnvelopeIcon,
  HomeIcon,
  IdentificationIcon,
  PresentationChartBarIcon,
  SparklesIcon,
  MegaphoneIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import Topmenu from "../Topmenu";

import { SidemenuContext } from "./index";
import { FormattedMessage } from "react-intl";

type MenuItem = {
  name: string;
  href: string;
  icon: any;
  hasSubmenu?: boolean;
  menu: string;
};

const homeList = [
  {
    name: "LangsDashboard",
    href: "/",
    icon: HomeIcon,
    menu: "home",
  },
  {
    name: "LangsWebsiteBuilder",
    href: "/cms",
    icon: IdentificationIcon,
    hasSubmenu: true,
    menu: "cms",
  },
  // {
  //   name: "Store",
  //   href: "/cms/store",
  //   icon: BuildingStorefrontIcon,
  //   hasSubmenu: true,
  //   menu: "cms",
  // },
  {
    name: "LangsStatistics",
    href: "/statistic",
    icon: PresentationChartBarIcon,
    hasSubmenu: true,
    menu: "statistic",
  },
  {
    name: "LangsLogos",
    href: "/logos",
    icon: SparklesIcon,
    menu: "home",
  },
  {
    name: "LangsMarketing",
    href: "/marketing",
    icon: MegaphoneIcon,
    hasSubmenu: true,
    menu: "marketing",
  },
  {
    name: "LangsContacts",
    href: "/contacts",
    icon: UserIcon,
    hasSubmenu: true,
    menu: "contacts",
  },
  // Leo: cancel the Email menu and do it separately
  // {
  //   name: "Email",
  //   href: "/email",
  //   icon: EnvelopeIcon,
  //   hasSubmenu: true,
  //   menu: "email",
  // },
];

const HomeMenu: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setMenuOpen, menuOpen } = React.useContext(SidemenuContext);
  const [navigation] = React.useState<MenuItem[]>(homeList);

  const handleClick = (item: MenuItem) => {
    setMenuOpen(item.menu);
    navigate(item.href);
  };

  return (
    <nav className="flex flex-1 flex-col">
      <ul className="flex flex-1 flex-col ">
        <li>
          <ul className="-mx-2 space-y-1">
            {menuOpen === "home" &&
              navigation.map((item) => (
                <li key={item.name}>
                  <button
                    onClick={() => handleClick(item)}
                    className={classNames(
                      location.pathname === item.href
                        ? "bg-gray-50"
                        : "text-gray-700 hover:bg-gray-50 hover:text-black",
                      "group flex w-full flex-auto  gap-x-3 p-2 text-sm font-semibold leading-6"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        location.pathname === item.href
                          ? "text-black"
                          : "text-black group-hover:text-black",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    <p className="flex grow items-center justify-between ">
                      <FormattedMessage id={item.name} />
                      {item.hasSubmenu && (
                        <ChevronRightIcon
                          className="cursor-pointe -mr-1 h-4 w-4 whitespace-nowrap text-left font-bold leading-5 text-black "
                          aria-hidden="true"
                        />
                      )}
                    </p>
                  </button>
                </li>
              ))}
          </ul>
        </li>
      </ul>
      <div className="mb-4 block w-full border-t border-gray-300 lg:hidden" />
      <div className="block lg:hidden">
        <Topmenu />
      </div>
    </nav>
  );
};

export default HomeMenu;
