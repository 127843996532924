import React, { Fragment } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { AiOutlinePlus } from "react-icons/ai";
import {
  BsChevronDown,
  BsThreeDotsVertical,
  BsArrowRight,
  BsEye,
  BsEyeSlash,
  BsTrash,
  BsDot,
} from "react-icons/bs";
import { BiMove, BiCopyAlt, BiCopy } from "react-icons/bi";
import type { Page, PageRowProps } from "./type";
import { PageDrawerContext } from "./index";
import { SortableList } from "./SortableList";
import usePage from "./hooks/usePage";
import { FormattedMessage } from "react-intl";

const PageRow: React.FC<PageRowProps> = ({
  itemRef,
  page,
  itemProps,
  isActive,
  isDragged,
  handleProps,
  index,
}) => {
  const { pageList: orignalPageList, setPageList: setOriginalPageList } =
    React.useContext(PageDrawerContext);
  const { editPage, getPageLevel } = usePage();
  const [pageList, setPageList] = React.useState<Page[]>(page.children || []);
  const [pageName, setPageName] = React.useState<string>(page.name);
  const [isHidden, setIsHidden] = React.useState<boolean>(page.isHidden);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const {
    handleIsHidden,
    handleDuplicate,
    handleDeletePage,
    showPageAdd,
    handleEditName,
  } = React.useContext(PageDrawerContext);

  const handleKeyUp = (e: any) => {
    if (e?.keyCode === 13) {
      setIsEdit(false);
      handleEditName(page.id, e.target.value);
    }
  };

  const handleClickAddPage = () => {
    showPageAdd(page.id);
  };

  const handleClickHidden = () => {
    setIsHidden(!isHidden);
    handleIsHidden(page.id);
  };

  const handleClickDuplicate = () => {
    handleDuplicate(page.id);
  };

  const handleClickDelete = () => {
    handleDeletePage(page.id);
  };

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <div
            ref={itemRef}
            className={classNames(
              isActive ? "!bg-blue-100" : "",
              isDragged ? "!bg-gray-100" : "",
              open ? "z-[999]" : "",
              "flex items-center justify-between border border-gray-100 bg-white px-4 py-3.5 drop-shadow"
            )}
            {...itemProps}
          >
            <div className="flex gap-4">
              {getPageLevel({ id: page.id, list: orignalPageList }) <= 1 ? (
                <button onClick={() => setIsOpen(!isOpen)}>
                  <BsChevronDown
                    className={classNames(
                      "transform duration-100 ease-linear",
                      !isOpen ? "-rotate-90" : ""
                    )}
                    fontSize={24}
                  />
                </button>
              ) : (
                <BsDot fontSize={24} />
              )}
              {isHidden && <BsEyeSlash fontSize={24} />}

              {isEdit ? (
                <input
                  type="text"
                  value={pageName}
                  onChange={(e) => setPageName(e.target.value)}
                  onKeyUp={handleKeyUp}
                  className="border-0 p-0 ring-0 focus:ring-0"
                  autoFocus={true}
                />
              ) : (
                <span
                  className="cursor-pointer"
                  onClick={() => setIsEdit(true)}
                >
                  {pageName}
                </span>
              )}
            </div>
            <div className="flex gap-4">
              <button className="cursor-move" {...handleProps}>
                <BiMove fontSize={24} />
              </button>
              <Menu.Button className=" flex items-center ">
                <BsThreeDotsVertical fontSize={24} />
              </Menu.Button>
            </div>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100 z-50"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100 z-50"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={classNames(
                open ? "z-[1000]" : "",
                "absolute right-0 mt-1 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
              )}
            >
              <Menu.Item>
                <Link
                  to={`/cms/?pageId=${page.slug}`}
                  className="flex w-full gap-4 px-3 py-2 text-sm leading-6 text-gray-900 hover:bg-gray-100"
                >
                  <BsArrowRight fontSize={24} />
                  <span>
                    <FormattedMessage id="LangsGoTo" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <button
                  className="flex w-full gap-4 px-3 py-2 text-sm leading-6 text-gray-900 hover:bg-gray-100"
                  onClick={handleClickHidden}
                >
                  {!isHidden ? (
                    <BsEyeSlash fontSize={24} />
                  ) : (
                    <BsEye fontSize={24} />
                  )}
                  <span>
                    {isHidden ? "Show" : "Hide"}{" "}
                    <FormattedMessage id="LangsPageLink" />{" "}
                  </span>
                </button>
              </Menu.Item>
              <Menu.Item>
                <button className="flex w-full gap-4 px-3 py-2 text-sm leading-6 text-gray-900 hover:bg-gray-100">
                  <BiCopyAlt fontSize={24} />
                  <span>
                    <FormattedMessage id="LangsCopyLink" />
                  </span>
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  className="flex w-full gap-4 px-3 py-2 text-sm leading-6 text-gray-900 hover:bg-gray-100"
                  onClick={handleClickDuplicate}
                >
                  <BiCopy fontSize={24} />
                  <span>
                    <FormattedMessage id="LangsDuplicate" />
                  </span>
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  className="flex w-full gap-4 px-3 py-2 text-sm leading-6 text-gray-900 hover:bg-gray-100"
                  onClick={handleClickDelete}
                >
                  <BsTrash fontSize={24} />
                  <span>
                    <FormattedMessage id="LangsDelete" />
                  </span>
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
          {isOpen && (
            <div className="flex flex-col gap-2 p-4 pr-0">
              <SortableList
                items={pageList}
                getItemId={(item: any) => item.id}
                renderItem={({
                  item,
                  isActive,
                  isDragged,
                  ref,
                  props,
                  handleProps,
                  index,
                }) => {
                  return (
                    <PageRow
                      key={item.id}
                      itemRef={ref}
                      page={item}
                      isActive={isActive}
                      isDragged={isDragged}
                      itemProps={props}
                      handleProps={handleProps}
                      index={index}
                    />
                  );
                }}
                onSort={(oldIndex: number, newIndex: number) => {
                  const newPageList = pageList.slice();
                  newPageList.splice(
                    newIndex,
                    0,
                    newPageList.splice(oldIndex, 1)[0]
                  );

                  setPageList(newPageList);
                  let newPage = { ...page, children: newPageList };
                  const newOriginalPage = editPage({
                    list: orignalPageList,
                    page: newPage,
                    id: page.id,
                  });
                  setOriginalPageList([...newOriginalPage]);
                }}
              />
              {/* Add Page Button START*/}
              <button
                className="flex items-center justify-between border border-gray-100 bg-white px-4 py-3.5 drop-shadow hover:bg-gray-100"
                onClick={handleClickAddPage}
              >
                <div className="flex gap-4">
                  <AiOutlinePlus fontSize={24} />
                  <span className="font-bold">
                    <FormattedMessage id="LangsAddNewSubpage" />
                  </span>
                </div>
              </button>
              {/* Add Page Button END*/}
            </div>
          )}
        </>
      )}
    </Menu>
  );
};

export default PageRow;
