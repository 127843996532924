import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import classNames from "classnames";
import { Tab } from "@headlessui/react";

import CookieSettingsPage from "./subPage/CookieSettingsPage";
import EmailsSettingPage from "./subPage/EmailsSettingPage";
import ProfilesPage from "./subPage/ProfilesPage";
import { FormattedMessage } from "react-intl";

const tabs = [
  { name: "LangsCookieSettings", content: <CookieSettingsPage /> },
  { name: "LangsProfile", content: <ProfilesPage /> },
  { name: "LangsEmail", content: <EmailsSettingPage /> },
];

const fcupper = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const AccountPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector(({ auth }) => auth);
  const [defaultIndex, setDefaultIndex] = React.useState(
    location.pathname === "/profile/setting"
      ? 0
      : location.pathname === "/profile"
        ? 1
        : location.pathname === "/profile/email"
          ? 2
          : 0
  );

  const handleChangeTab = (index: number) => {
    setDefaultIndex(index);
  };

  React.useEffect(() => {
    setDefaultIndex(
      location.pathname === "/profile/setting"
        ? 0
        : location.pathname === "/profile"
          ? 1
          : location.pathname === "/profile/email"
            ? 2
            : 0
    );
  }, [location.pathname]);

  React.useEffect(() => {
    if (defaultIndex === 0 && location.pathname !== "/profile/setting") {
      navigate("/profile/setting");
    } else if (defaultIndex === 1 && location.pathname !== "/profile") {
      navigate("/profile");
    } else if (defaultIndex === 2 && location.pathname !== "/profile/email") {
      navigate("/profile/email");
    }
    //eslint-disable-next-line
  }, [defaultIndex]);

  return (
    <div className="mx-auto flex max-w-7xl flex-col items-start gap-6 py-24 leading-6 text-black sm:px-6 lg:px-8">
      {/* TODO: Add back 返回上一頁面*/}
      <button className="truncat m-0 flex h-8 max-w-full cursor-pointer items-center rounded-none bg-none px-3 py-1 text-center text-sm font-bold leading-5 text-blue-600">
        <ArrowLeftIcon className="mr-2 inline-block h-5 w-5 flex-shrink-0 cursor-pointer  whitespace-nowrap text-center text-sm font-bold leading-5 text-blue-600" />
        <span>
          <FormattedMessage id="LangsBack" />
        </span>
      </button>
      <div className="mx-0 mb-10 mt-0 flex flex-col items-center p-0 text-zinc-800 md:flex-row">
        {/* TODO: 獲取 account name */}
        <div className="">
          <h1 className="text-center text-3xl font-bold text-zinc-800 md:text-left md:text-4xl">
            {user?.email && fcupper(user?.email.split("@")[0])}
          </h1>
          <a
            href="/auth/logout"
            className="max-w-full cursor-pointer truncate bg-none text-left text-base leading-5 text-blue-600 decoration-white md:text-2xl md:leading-8"
          >
            <FormattedMessage id="LangsLogOut" />
          </a>
        </div>
      </div>

      {/* TODO: Add tabs */}

      <div className="relative mb-10 flex w-full flex-col text-zinc-800">
        <Tab.Group selectedIndex={defaultIndex} onChange={handleChangeTab}>
          <Tab.List className="mb-10 flex w-full">
            {tabs.map((tab, i) => (
              <Tab as={Fragment} key={i}>
                {({ selected }) => (
                  <button
                    className={classNames(
                      selected
                        ? "border-indigo-600 font-bold text-indigo-600"
                        : "text-zinc-800",
                      "cursor-pointer items-center  whitespace-nowrap border-b-2 p-4  outline-0 ring-0 hover:text-blue-600 focus:ring-0"
                    )}
                  >
                    <FormattedMessage id={tab.name} />
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {tabs.map((tab, i) => (
              <Tab.Panel key={i}>{tab.content}</Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
export default AccountPage;
