import React from "react";
import { useState } from "react";

import classNames from "classnames";

// icon
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { ShieldCheckIcon } from "@heroicons/react/20/solid";

import type { MyToastHandler } from "../../../../components/MyToast/type";

const service = {
  id: 1,
  description:
    "This is a service providing increased security and performance for websites. Cloudflare offers for example a content delivery network („CDN“) to improve the loading times of the website.  The use of a CDN enables the user to make content available for retrieval more quickly with the help of regionally or internationally distributed servers.",
};

const processingCompany = {
  companyName: "Cloudflare Inc.",
  address:
    "101 Townsend St., San Francisco, CA 94107, United States of America",
};

const protectionOfficer = {
  description:
    "Below you can find the email address of the data protection officer of the processing company.",
  email: ["privacyquestions@cloudflare.com", "legal@cloudflare.com"],
};

const purposes = {
  description:
    "This list represents the purposes of the data collection and processing.",
  tabs: ["Optimization", "Website security", "Reducing bandwidth usage"],
};

const technologiesUsed = [
  {
    description:
      "This list represents all technologies this service uses to collect data. Typical technologies are Cookies and Pixels that are placed in the browser.",
    tabs: "Cookies",
  },
];

const collected = {
  description:
    "This list represents all (personal) data that is collected by or through the use of this service.",
  tabs: [
    "IP address",
    "System configuration information",
    "Name of website",
    "Date and time of request",
    "Name and URL of the accessed file",
    "Amount of data transferred",
    "Status Information",
    "Device operating system",
    "Referrer URL",
    "Requesting provider",
    "Device type",
    "Time of the server request",
  ],
};
const legalBasis = [
  "Art. 6 para. 1 s. 1 lit. a GDPR",
  "§ 25 para 2 no. 2 TTDSG",
];

const locationProcessing = {
  description:
    "This is the primary location where the collected data is being processed. If the data is also processed in other countries, you are informed separately.",
  location: "United States of America,European Union",
};

const retentionPeriod = {
  description:
    "The retention period is the time span the collected data is saved for the processing purposes. The data needs to be deleted as soon as it is no longer needed for the stated processing purposes.",
  period:
    "Data will be deleted as soon as they are no longer needed for the processing purposes.",
};

const thirdCountries = {
  description:
    "This service may forward the collected data to a different country. Please note that this service might transfer the data to a country without the required data protection standards. If the data is transferred to the USA, there is a risk that your data can be processed by US authorities, for control and surveillance measures, possibly without legal remedies. Below you can find a list of countries to which the data is being transferred. For more information regarding safeguards please refer to the website provider’s privacy policy or contact the website provider directly.",
  countries:
    "United States of America | Singapore | Australia | United Arab Emirates",
};

const dataRecipients = {
  description:
    "In the following the recipients of the data collected are listed.",
  recipients: ["Cloudflare Group"],
};

const moreprivacy = [
  {
    description: "Click here to read the privacy policy of the data processor",
    link: "https://www.cloudflare.com/privacypolicy/",
  },
  {
    description: "Click here to read the cookie policy of the data processor",
    link: "https://www.cloudflare.com/cookie-policy/",
  },
];

const storedInformation = [
  {
    id: 1,
    title: "Type:",
    value: "cookie",
  },
  {
    id: 2,
    title: "Duration:",
    value: "1 month",
  },
  {
    id: 3,
    title: "Domain:",
    value: "BUILD.com",
  },
];

const historys = [
  {
    id: 1,
    Decision: "yes",
    Date: "05.08.2023, 16:36",
  },
  {
    id: 2,
    Decision: "yes",
    Date: "05.08.2023, 18:21",
  },
  {
    id: 3,
    Decision: "yes",
    Date: "05.08.2023, 18:21",
  },
];

const Cloudflare: React.FC = () => {
  const [isShow, setShow] = useState<boolean>(false);
  const handledShow = () => {
    setShow(!isShow);
  };

  // Active Toggle switch State
  // eslint-disable-next-line
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const toastRef = React.useRef<MyToastHandler>(null);

  // eslint-disable-next-line
  function handleToastOpen() {
    if (!toastRef.current) return;
    toastRef.current.handleToastOpen();
  }

  return (
    <div className="cookies-content text-xs">
      <div className="pb-4">
        <h6>Description of Service</h6>
        <p>{service.description}</p>
      </div>

      <div className="pb-4">
        <h6 className="text-sm opacity-70">Processing Company</h6>
        <p>
          {processingCompany.companyName}
          <br />
          {processingCompany.address}
        </p>
      </div>

      <div className="pb-4">
        <h6 className="text-sm opacity-70">
          Data Protection Officer of Processing Company
        </h6>
        <p className="pb-2">{protectionOfficer.description}</p>
        <div>
          {protectionOfficer.email.map((email, index) => (
            <React.Fragment key={index}>
              <a key={index} href="{{email}}" className="">
                {email}
              </a>
              {index !== protectionOfficer.email.length - 1 && ", "}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="pb-4">
        <h6 className="text-sm opacity-70">Data Purposes</h6>
        <p className="pb-2">{purposes.description}</p>
        <ul className="flex flex-wrap justify-start gap-2">
          {purposes.tabs.map((tab, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {tab}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Technologies Used</h6>
        <p className="pb-2">{technologiesUsed[0].description}</p>
        <ul className="flex flex-wrap justify-start gap-2">
          {technologiesUsed.map((used, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {used.tabs}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Data Collected</h6>
        <p className="pb-2">{collected.description}</p>
        <ul className="flex flex-wrap justify-start gap-2">
          {collected.tabs.map((tab, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {tab}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Legal Basis</h6>
        <p className="pb-2">
          In the following the required legal basis for the processing of data
          is listed.
        </p>
        <ul className="flex flex-wrap justify-start gap-2">
          {legalBasis.map((legalBasis, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {legalBasis}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Location of Processing</h6>
        <p className="pb-2">{locationProcessing.description}</p>
        <ul className="flex list-disc flex-col justify-start px-4">
          <li className="">
            <div>{locationProcessing.location}</div>
          </li>
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Retention Period</h6>
        <p className="pb-2">{retentionPeriod.description}</p>
        <ul className="flex list-disc flex-col justify-start px-4">
          <li className="">
            <div>{retentionPeriod.period}</div>
          </li>
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Transfer to Third Countries</h6>
        <p className="pb-2">{thirdCountries.description}</p>
        <ul className="flex flex-wrap justify-start gap-2">
          <li className=" rounded border border-white  p-2">
            {thirdCountries.countries}
          </li>
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Data Recipients</h6>
        <p className="pb-2">{dataRecipients.description}</p>
        <ul className="flex flex-wrap justify-start gap-2">
          {dataRecipients.recipients.map((recipient, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {recipient}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        {moreprivacy.map((privacy, index) => (
          <React.Fragment key={index}>
            <div className="mb-4">
              <h6 className="pb-1 opacity-70">{privacy.description}</h6>
              <a href={privacy.link}>{privacy.link}</a>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="pb-4">
        <div className="flex flex-col text-xs">
          {/* content */}
          <div className="flex-grow  sm:mr-2 ">
            {/* content wrapper */}
            <button
              onClick={() => {
                handledShow();
              }}
              className="text-left "
            >
              {/* title */}
              <div className="flex items-center justify-start pb-1 pt-2 ">
                <h6 className="opacity-70">Storage Information</h6>
                {/* toggle button */}
                <span
                  className="mx-2 flex items-center justify-start"
                  onClick={() => {
                    handledShow();
                  }}
                >
                  {isShow ? (
                    <span className="mt-1 inline-block h-4 w-4 md:mt-0">
                      <HiChevronUp className="h-full w-full " />
                    </span>
                  ) : (
                    <span className="mt-1 inline-block h-4 w-4 md:mt-0">
                      <HiChevronDown className="h-full w-full " />
                    </span>
                  )}
                </span>
              </div>
              <div className="mb-2 mt-1 leading-tight">
                <p>
                  This service uses different means of storing information on a
                  user’s device as listed below.
                </p>
              </div>
            </button>
          </div>

          <div className="flex items-center  justify-between px-3 "></div>

          <section
            className={classNames(
              isShow ? "opacity-100" : "hidden",
              "duration-600 overflow-hidden transition-all ease-in-out"
            )}
          >
            <div className="flex flex-col">
              <div className="border px-3 py-2">
                <table className="min-w-full ">
                  <thead className="">
                    <tr>
                      <th className="mb-1 font-bold">trs</th>
                      <th className="mb-2">referrer url</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {storedInformation.map((item) => (
                      <tr key={item.id} className="pb-2 ">
                        <td className="w-32 whitespace-nowrap pb-2  ">
                          {item.title}
                        </td>
                        <td
                          className="
                                                whitespace-nowrap "
                        >
                          {item.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="mb-4">
        <h6>History</h6>

        <div className="mt-2 rounded border">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="px-3 py-2">
                <th scope="col" className="px-3 py-2 text-left font-normal">
                  Decision
                </th>
                <th scope="col" className="text-left font-normal">
                  Date
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {historys.map((item) => (
                <tr key={item.id} className="">
                  <td className="flex w-1/2 whitespace-nowrap px-3 py-2 text-left">
                    <ShieldCheckIcon className="mr-3 h-4 w-3" />
                    {item.Decision}
                  </td>
                  <td className="w-1/2 whitespace-nowrap  ">{item.Date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Cloudflare;
