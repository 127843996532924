import React, { useState } from "react";
import classNames from "classnames";
//icon
import { HiCheck, HiMinusSmall } from "react-icons/hi2";
//images
import google_analytics_dashboard from "../../media/svg/statisticPage/google-analytics-dashboard.png";
import { FormattedMessage } from "react-intl";

const GoogleAnalyticsPage: React.FC = () => {
  // Published useState
  const [isPublished] = useState<boolean>(false);
  return (
    <>
      <div className="h-full w-full px-3 sm:px-6">
        {/* background */}
        <div className="mx-auto mb-auto mt-24 flex h-auto w-full max-w-[996px] flex-1 flex-col">
          {/* Main Title */}
          <h1 className="mb-16 w-full justify-start whitespace-nowrap text-left text-3xl font-bold text-zinc-800 md:text-4xl">
            <FormattedMessage id="LangsGoogleAnalytics" />

          </h1>
          {/* Main card */}
          <div className="rounded bg-white shadow-lg">
            {/* Content section 01 */}
            <div className="border-1 flex flex-col items-center justify-between border-b border-gray-200 pb-10 pl-10 pr-8 pt-16 sm:flex-row">
              {/* section 01 left content */}
              <div className="mb-6 max-w-[540px] md:mb-0">
                {/* section 01 title */}
                <h3 className="mb-2 text-left text-2xl font-bold leading-normal text-zinc-800 md:mb-3">
                  <FormattedMessage id="GoogleAnalyticsData" />

                </h3>
                {/* section 01 description */}
                <p className="mb-5 text-left text-base font-normal leading-normal text-zinc-800">
                  <FormattedMessage id="GoogleAnalyticsDescription" />

                </p>
                {/* section 01 description more link */}
                <div className="mb-4">
                  {/*eslint-disable-next-line*/}
                  <a
                    className="max-w-full cursor-pointer truncate text-left text-base leading-5 text-indigo-600 transition-all duration-300 ease-in-out hover:text-indigo-700"
                    href="#"
                  >
                    <FormattedMessage id="LangsMoreInformation" />

                  </a>
                </div>
              </div>
              {/* section 01 image */}
              <div className="pb-5 md:pl-10">
                <img
                  className="hidden max-h-[196px] w-auto md:block"
                  src={google_analytics_dashboard}
                  alt=""
                />
              </div>
            </div>
            {/* section 02 list */}
            <ul className="list-none px-5">
              {/* section 02 Publish button group */}
              {/* li onclick change the state to publish */}
              <li className="flex min-h-[88px] items-center justify-between border-b-2 border-solid border-gray-100 py-5 hover:ring-2 hover:ring-indigo-600 hover:ring-offset-2 md:py-4">
                {/* Published onclick Link */}
                <a
                  className="w-full max-w-full cursor-pointer transition-all duration-300 ease-in-out"
                  href="/cms"
                >
                  <div className="flex items-center justify-between">
                    {/* Publish content */}
                    <div className="mr-5 flex items-center">
                      {/* minus icon */}
                      <span className="mr-5 inline-block h-5 w-5">
                        {isPublished ? (
                          <HiCheck className="h-full w-full text-neutral-500" />
                        ) : (
                          <HiMinusSmall className="h-full w-full text-gray-400" />
                        )}
                      </span>
                      {/* Publish content */}
                      <div className="flex flex-col">
                        <span
                          className={classNames(
                            isPublished ? "text-neutral-500" : "text-zinc-800",
                            "text-left text-base font-normal leading-normal"
                          )}
                        >
                          <FormattedMessage id="GoogleAnalyticsTrafficeData" />

                        </span>
                      </div>
                    </div>
                    {/* Publish button */}
                    {isPublished ? (
                      <>
                        {/* Published Done */}
                        <div className="inline-flex h-8 max-w-full cursor-pointer items-center justify-center px-3 py-1 ">
                          <HiCheck className="h-full w-full text-neutral-500" />
                          <span className="text-center text-base font-normal text-neutral-500">
                            <FormattedMessage id="LangsDone" />

                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="inline-flex h-8 max-w-full cursor-pointer items-center justify-center rounded bg-indigo-600 px-3 py-1 hover:bg-indigo-700 md:block">
                          <span className="truncate text-center text-sm font-bold text-white hover:text-gray-200">
                            <FormattedMessage id="LangsPublish" />

                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </a>
              </li>
              {/* section 02 upgrade group */}
              <li className="flex min-h-[88px] items-center justify-between py-5 md:py-4">
                {/* upgrade content */}
                <div className="mr-5 flex items-center">
                  {/* upgrade icon */}
                  <span className="mr-5 inline-block h-5 w-5">
                    <HiMinusSmall className="h-full w-full text-gray-400" />
                  </span>
                  {/* upgrade content */}
                  <span className="text-left text-base font-normal leading-normal text-zinc-800">
                    <FormattedMessage id="GoogleAnalyticsUpgrade" />

                  </span>
                </div>
                {/* upgrade button */}
                <div className="inline-flex h-8 max-w-full cursor-pointer items-center justify-center rounded bg-indigo-600 px-3 py-1 hover:bg-indigo-700 md:block">
                  <span className="truncate text-center text-sm font-bold text-white hover:text-gray-200">
                    <FormattedMessage id="LangsUpgrade" />
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default GoogleAnalyticsPage;
