// layout
import MainLayout from "../layouts/MainLayout";
//pages
import AccountPage from "../pages/Setting/AccountPage";


const routes = [
  {
    path: "/profile/setting",
    element: <MainLayout selected="setting" children={<AccountPage />} />,
  },
  {
    path: "/profile",
    element: <MainLayout selected="setting" children={<AccountPage />} />,
  },
  {
    path: "/profile/email",
    element: <MainLayout selected="setting" children={<AccountPage />} />,
  },
];

export default routes;
