import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import Progress from "../../components/Progress";
//image
import logo from "../../media/svg/logo/logo.svg";
import personal from "../../media/svg/wizard/persons/personal.png";
import teams from "../../media/svg/wizard/persons/teams.png";
import persons_image from "../../media/svg/wizard/persons/persons_image.png";
//content
import { WizardContext } from "./index";
import { wizardActions } from "../../stores/actions";

const personModel = [
  {
    id: "single",
    // title: "A single individual",
    title: "personModelTitle1",
    // description: "For myself, or for another person",
    description: "personModelDesc1",
    image: personal,
  },
  {
    id: "organization",
    // title: "A group of people",
    title: "personModelTitle2",
    // description:"For a team, organization, or business with more than one person",
    description: "personModelDesc2",
    image: teams,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = ConnectedProps<typeof connector>;
const PersonsPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector(({ wizard }) => wizard);
  const { activeStep, setActiveStep } = React.useContext(WizardContext);
  const [selectedPerson, setSelectedPerson] = React.useState<any>(
    personModel.find((person) => person.id === formData.size) || personModel[0]
  );

  const handleSkip = () => {
    dispatch(props.editWizard("size", "skip"));
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    if (!selectedPerson || !selectedPerson.id) return;
    dispatch(props.editWizard("size", selectedPerson.id));
    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <div className="tex-black m-0 min-h-screen overflow-auto p-0 leading-6">
        {/* // header */}
        <div className="min-h-screen overflow-auto bg-gray-50 transition">
          <header
            className="fixed left-0 top-0 z-10 m-0 flex h-[88px] w-full items-center justify-center border-b border-solid border-gray-200 bg-gray-50 bg-opacity-[0.9] p-0 leading-6 text-black transition md:w-1/2"
            aria-label="Global"
          >
            <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
          </header>

          {/* // main content Group */}
          <div className="relative flex min-h-screen w-full flex-col bg-gray-50 pb-24 leading-6 text-black transition md:w-1/2">
            <div className="relative m-0 mt-[88px] flex flex-1 flex-col p-0 leading-6 text-black">
              {/* // main content */}
              <div className="mx-auto my-0 w-full max-w-[660px] px-4 py-0 leading-6 text-black ">
                {/* Title */}
                <h1 className="mx-1 mb-8 mt-10 p-0 text-center text-2xl font-bold leading-[1.2] text-black md:mb-16 md:mt-20 md:text-[4.8vh]">
                  <FormattedMessage id="personsTitle" />
                </h1>
                {/* // input form */}
                <RadioGroup value={selectedPerson} onChange={setSelectedPerson}>
                  <div className="mt-4 grid grid-cols-1 justify-items-center gap-y-6 sm:grid-cols-2 sm:gap-x-6">
                    {personModel.map((person) => (
                      <RadioGroup.Option
                        key={person.id}
                        value={person}
                        className={({ checked }) =>
                          classNames(
                            checked ? "box-selected" : "",
                            "wizard persons box box-hover !bg-white"
                          )
                        }
                      >
                        {({ checked }) => (
                          <>
                            <span className="flex flex-1">
                              <span className="flex w-full flex-col">
                                <RadioGroup.Label
                                  as="span"
                                  className="block text-lg font-bold text-indigo-600"
                                >
                                  <FormattedMessage id={person.title} />
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                  as="span"
                                  className="mt-1 flex items-center text-sm text-gray-500"
                                >
                                  <FormattedMessage id={person.description} />
                                </RadioGroup.Description>
                                <RadioGroup.Description
                                  as="span"
                                  className="mx-auto mb-2 mt-auto hidden h-auto w-auto max-w-full cursor-pointer place-self-center sm:inline-block"
                                >
                                  <img
                                    className="h-auto w-auto"
                                    src={person.image}
                                    alt=""
                                  />
                                </RadioGroup.Description>
                              </span>
                              {checked ? (
                                <CheckCircleIcon
                                  className="wizard persons checkIcon"
                                  aria-hidden="true"
                                />
                              ) : null}
                            </span>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>

          {/* // button group */}
          <div className="fixed bottom-[84px] flex w-full items-center transition md:w-1/2">
            <Progress className="bg-gray-300/20" value={0} size="sm" />
            <div className="fixed bottom-0 flex w-full items-center justify-between bg-gray-50 bg-opacity-[0.9] p-5 leading-6 transition md:w-1/2">
              {/*eslint-disable-next-line*/}
              <div
                className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500 "
                onClick={handleSkip}
              >
                <FormattedMessage id="LangsSkipThisStep" />
              </div>
              <button
                type="button"
                className="inline-flex bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={!selectedPerson}
                onClick={handleNext}
              >
                <FormattedMessage id="LangsContinue" />
              </button>
            </div>
          </div>
        </div>

        {/* // Right side Content */}
        <div className="fixed left-1/2 right-0 top-0 hidden h-full bg-gray-50 leading-6 text-black md:inline">
          <div className="relative flex h-full animate-none flex-col items-center justify-center overflow-hidden bg-white leading-6 text-black transition">
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-start pt-[88px] leading-6 text-black">
              <div className="relative max-w-[45vw] overflow-hidden   bg-white shadow transition-transform">
                <img
                  className="block h-full w-full"
                  src={persons_image}
                  alt=""
                />
              </div>
              <div className="relative mx-16 mb-0 mt-6 hidden w-[30vw] p-0 leading-6 text-black opacity-100 transition-opacity sm:inline lg:max-w-xl">
                <h2 className="text-4xl font-bold leading-none text-indigo-600">
                  01
                </h2>
                <p className="flex min-h-[40px] items-center text-base font-normal leading-6 text-gray-900">
                  <FormattedMessage id="personsDescription" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...wizardActions });

export default connector(PersonsPage);
