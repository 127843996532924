import classNames from "classnames";
import { Disclosure } from "@headlessui/react";
// Mycomponents
import Tooltip from "../../components/Tooltip";
// BuildLink Logo
import logo from "../../media/svg/logo/logo.svg";
// icon
import { HiCheck, HiXMark } from "react-icons/hi2";
import { FiInfo } from "react-icons/fi";
import { BiListCheck } from "react-icons/bi";
import { AiFillCheckCircle } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { PiShieldCheckBold } from "react-icons/pi";
import { RiUserHeartLine } from "react-icons/ri";
// images
import visa from "../../media/img/payment_methods/visa.png";
import mastercard from "../../media/img/payment_methods/mastercard.png";
import paypal from "../../media/img/payment_methods/paypal.png";
import sofort from "../../media/img/payment_methods/sofort.png";
import american_express from "../../media/img/payment_methods/american_express.png";
import sepa from "../../media/img/payment_methods/sepa.png";
import { FormattedMessage } from "react-intl";

// tiers data
const tiers = [
  {
    name: "Unlimited",
    // name: "tiersUnlimitedName",
    id: "tier-unlimited",
    href: "/checkout/unlimited/billing-cycle/",
    priceMonthly: "us$39",
    // description: "Your toolbox for success",
    description: "tiersUnlimitedDescription",
    // feature_title: "Everything",
    feature_title: "tiersUnlimitedfeature_title",
    // features: [
    //   "Unlimited storage",
    //   "Professional design analysis",
    //   "Premium support within an hour",
    // ],
    features: [
      "tiersUnlimitedfeature_01",
      "Professional_design_analysis",
      "tiersUnlimitedfeature_03",
    ],
    mostPopular: false,
    // hosting
    https_security: true,
    free_domain: true,
    storage: (
      <FormattedMessage id="tiersUnlimitedName" defaultMessage={"Unlimited"} />
    ),
    bandwidth: (
      <FormattedMessage id="tiersUnlimitedName" defaultMessage={"Unlimited"} />
    ),
    connect_domain: true,
    customer_support: (
      <FormattedMessage
        id="tiersCustomer_support01"
        defaultMessage={"within 1 hour"}
      />
    ),
    connect_email: true,
    forward_email: "20",
    // website
    contact_forms: true,
    mobile_optimized: true,
    image_library: true,
    number_of_website_pages: (
      <FormattedMessage id="tiersUnlimitedName" defaultMessage={"Unlimited"} />
    ),
    advanced_seo: true,
    fast_indexing_on_google: true,
    ad_free: true,
    statistics: true,
    professional_design_analysis: true,
  },
  {
    name: "Grow",
    // name: "tiersGrowName",
    id: "tier-grow",
    href: "/checkout/grow/billing-cycle/",
    priceMonthly: "us$15",
    // description: "For your business website",
    // feature_title: "All START features,",
    // features: [
    //   "Search Engine Optimization (SEO)",
    //   "Visitor statistics",
    //   "Personalized support within 4 hours",
    // ],
    description: "tiersGrowDescription",
    feature_title: "tiersGrowfeature_title",
    features: [
      "tiersGrowfeature_01",
      "tiersGrowfeature_02",
      "tiersGrowfeature_03",
    ],
    mostPopular: true,
    // hosting
    https_security: true,
    free_domain: true,
    storage: "15 GB",
    bandwidth: "20 GB",
    connect_domain: true,
    customer_support: (
      <FormattedMessage
        id="tiersCustomer_support04"
        defaultMessage={"within 4 hour"}
      />
    ),
    connect_email: true,
    forward_email: "5",
    // website
    contact_forms: true,
    mobile_optimized: true,
    image_library: true,
    number_of_website_pages: "50",
    advanced_seo: true,
    fast_indexing_on_google: true,
    ad_free: true,
    statistics: true,
    professional_design_analysis: false,
  },
  {
    name: "Start",
    // name: "tiersStartName",
    id: "tier-start",
    href: "/checkout/start/billing-cycle/",
    priceMonthly: "us$9",
    // description: "For your personal website",
    // feature_title: "All PLAY features,",
    // features: [{ name: "Domain included", tooltip: true }, "Ad-free"],
    description: "tiersStartDescription",
    feature_title: "tiersStartfeature_title",
    features: [{ name: "tiersStartfeature_name", tooltip: true }, "Ad_free"],
    mostPopular: false,
    // hosting
    https_security: true,
    free_domain: true,
    storage: "5 GB",
    bandwidth: "10 GB",
    connect_domain: true,
    customer_support: (
      <FormattedMessage
        id="tiersCustomer_support012"
        defaultMessage="within 1-2 workdays"
      />
    ),
    connect_email: true,
    forward_email: "1",
    // website
    contact_forms: true,
    mobile_optimized: true,
    image_library: true,
    number_of_website_pages: "10",
    advanced_seo: true,
    fast_indexing_on_google: true,
    ad_free: true,
    statistics: true,
    professional_design_analysis: false,
  },
  {
    name: "Play",
    // name: "tiersPlayName",
    id: "tier-play",
    href: "#",
    priceMonthly: "us$0",
    // description: "Try for free",
    // feature_title: "Includes:",
    // features: ["All base features", ".buildlink.com subdomain"],
    description: "tiersPlayDescription",
    feature_title: "tiersPlayfeature_title",
    features: ["tiersPlayfeature_01", "tiersPlayfeature_02"],
    mostPopular: false,
    // hosting
    https_security: true,
    free_domain: ".buildlink.com subdomain",
    storage: "500 MB",
    bandwidth: "2 GB",
    connect_domain: false,
    customer_support: false,
    connect_email: false,
    forward_email: false,
    // website
    contact_forms: true,
    mobile_optimized: true,
    image_library: true,
    number_of_website_pages: "5",
    advanced_seo: false,
    fast_indexing_on_google: false,
    ad_free: false,
    statistics: false,
    professional_design_analysis: false,
  },
];

const premium_plans = [
  {
    // name: "Ad-free",
    // id: "p_plan_ad-free",
    // tooltip_content:
    //   "Your website is for your content, not ours. No BuildLink logos or links will be shown to your visitors.",
    name: "Ad_free",
    id: "p_plan_ad-free",
    tooltip_content: "premium_Ad_freeTooltip",
  },
  {
    // name: "Connect your own domain",
    // id: "p_plan_connect-your-own-domain",
    // tooltip_content:
    //   "Already have a domain with another provider? You can easily connect it to your BuildLink site without having to switch providers.",
    name: "ConnectYourOwnDomain",
    id: "p_plan_connect-your-own-domain",
    tooltip_content: "premium_domainTooltip",
  },
  {
    // name: "Connect your email address",
    // id: "p_plan_connect-your-own-domain",
    // tooltip_content:
    //   "Use uour custom domain to receive email with email forwarding or Google Workspace.",
    name: "premium_emailName",
    id: "p_plan_connect-your-own-domain",
    tooltip_content: "premium_emailTooltip",
  },
  {
    // name: "Contact forms",
    // id: "p_plan_contact-forms",
    // tooltip_content: "Help visitors get in touch and submit a form.",
    name: "LangsContactForms",
    id: "p_plan_contact-forms",
    tooltip_content: "premium_formsTooltip",
  },
  {
    // name: "Image library",
    // id: "p_plan_image-library",
    // tooltip_content:
    //   "Choose from our huge selection of free, high-quality photos for every industry.",
    name: "LangsImageLibrary",
    id: "p_plan_image-library",
    tooltip_content: "premium_ImageTooltip",
  },
  {
    // name: "Mobile-optimized",
    // id: "p_plan_mobile-optimized",
    // tooltip_content:
    //   "Your website automatically adjusts to the size of the screen, so it looks great on all devices.",
    name: "Mobile_optimized",
    id: "p_plan_mobile-optimized",
    tooltip_content: "premium_MobileTooltip",
  },
];

const tables = [
  {
    // name: "Hosting",
    name: "LangsHosting",
    children: [
      {
        // name: "HTTPS security",
        // id: "https_security",
        // tooltip_content:
        //   "Your website and customer data are protected by gold-standard HTTPS encryption.",
        name: "tables_Hosting_child_name_HTTPS",
        id: "https_security",
        tooltip_content: "tables_Hosting_child_tooltip_HTTPS",
      },
      {
        // name: "Free domain",
        // id: "free_domain",
        // tooltip_content:
        //   "When you purchase a BuildLink plan, we offer a 20 $ discount for the first contractual term, which corresponds to the cost of a domain. This offer is valid for the following domains: de, .com, .net, .org, .info, .at, .ch, .es, .it, .fr, .nl. A domain registered with another provider can be used with your new plan for the first contract period at no addittional cost. When renewing, the selected plan will be renewed at full price or without this 20 $ discount. The duration and billing terms of the domain depend on the duration and billing terms of the purchased BuildLink plan.",
        name: "tables_Hosting_child_name_domain",
        id: "free_domain",
        tooltip_content: "tables_Hosting_child_tooltip_domain",
      },
      {
        // name: "Storage",
        // id: "storage",
        // tooltip_content: "Upload more content and pictures.",
        name: "LangsStorage",
        id: "storage",
        tooltip_content: "tables_Hosting_child_tooltip_Storage",
      },
      {
        // name: "Bandwidth",
        // id: "bandwidth",
        // tooltip_content:
        //   "More bandwidth means a smoother experience for visitors, especially if you have a lot of videos and images on your site.",
        name: "LangsBandwidth",
        id: "bandwidth",
        tooltip_content: "tables_Hosting_child_tooltip_Bandwidth",
      },
      {
        // name: "Connect your own domain",
        // id: "connect_domain",
        // tooltip_content:
        //   "Already have a domain with another provider? You can easily connect it to your BuildLink site without having to switch providers.",
        name: "ConnectYourOwnDomain",
        id: "connect_domain",
        tooltip_content: "premium_domainTooltip",
      },
      {
        // name: "Customer support",
        // id: "customer_support",
        // tooltip_content:
        //   "Support Working Hours: Mon-Fri. 10:00 - 18:00 (GMT+8); Hong Kong-wide bank holidays excluded.",
        name: "LangsCustomerSupport",
        id: "customer_support",
        tooltip_content: "tables_Hosting_child_tooltip_support",
      },
      {
        // name: "Connect uour email address",
        // id: "connect_email",
        // tooltip_content: "Use a custom email address that matches your domain.",
        name: "Connect_uour_email_address",
        id: "connect_email",
        tooltip_content: "tables_Hosting_child_tooltip_email",
      },
      {
        // name: "Forwarding email addresses",
        // id: "forward_email",
        // tooltip_content:
        //   "Forward incoming emails to an external email account.",
        name: "tables_Hosting_child_name_Forwarding",
        id: "forward_email",
        tooltip_content: "tables_Hosting_child_tooltip_Forwarding",
      },
    ],
  },
  {
    // name: "Website",
    name: "LangsWebsite",
    children: [
      {
        // name: "Contact forms",
        // id: "contact_forms",
        // tooltip_content: "Help visitors get in touch and submit a form.",
        name: "LangsContactForms",
        id: "contact_forms",
        tooltip_content: "premium_formsTooltip",
      },
      {
        // name: "Mobile-optimized",
        // id: "mobile_optimized",
        // tooltip_content:
        //   "Your website automatically adjusts to the size of the screen, so it looks great on all devices.",
        name: "Mobile_optimized",
        id: "mobile_optimized",
        tooltip_content: "premium_MobileTooltip",
      },
      {
        // name: "Image library",
        // id: "image_library",
        // tooltip_content:
        //   "Choose from our huge selection of free, high-quality photos for every  industry.",
        name: "LangsImageLibrary",
        id: "image_library",
        tooltip_content: "tables_Website_child_tooltip_Image",
      },
      {
        // name: "Number of website pages",
        // id: "number_of_website_pages",
        // tooltip_content: "Get more website pages and build a larger site.",
        name: "tables_Website_child_name_pages",
        id: "number_of_website_pages",
        tooltip_content: "tables_Website_child_tooltip_pages",
      },
      {
        // name: "Advanced SEO",
        // id: "advanced_seo",
        // tooltip_content:
        //   "Smart technoiogy fills in your SEO settings for you. Plus you get customized instructions to fully optimize your site.",
        name: "tables_Website_child_name_SEO",
        id: "advanced_seo",
        tooltip_content: "tables_Website_child_tooltip_SEO",
      },
      {
        // name: "Fast indexing on Google",
        // id: "fast_indexing_on_google",
        // tooltip_content: "Get your website indexed faster on Google.",
        name: "tables_Website_child_name_Google",
        id: "fast_indexing_on_google",
        tooltip_content: "tables_Website_child_tooltip_Google",
      },
      {
        // name: "Ad-free",
        // id: "ad_free",
        // tooltip_content:
        //   "Your website is for your content, not ours. No BuildLink logos or links will be shown to your visitors.",
        name: "Ad_free",
        id: "ad_free",
        tooltip_content: "premium_Ad_freeTooltip",
      },
      {
        // name: "Statistics",
        // id: "statistics",
        // tooltip_content: "Analyze your website traffic and track your success.",
        name: "LangsStatistics",
        id: "statistics",
        tooltip_content: "tables_Website_child_tooltip_statistics",
      },
      {
        // name: "Professional design analysis",
        // id: "professional_design_analysis",
        // tooltip_content:
        //   "Get a personal design analysis with suggestions from one of our design experts.",
        name: "Professional_design_analysis",
        id: "professional_design_analysis",
        tooltip_content: "tables_Website_child_tooltip_Professional",
      },
    ],
  },
];

const faqs = [
  {
    id: 1,
    // question:
    //   "I already have a registered domain, can I connect it to my new BuildLink website?",
    // answer:
    //   "Sure! With our premium plans, it's easy to transfer your existing domain to BuildLink, or keeep your domain where it is and simply connect it to your BuildLink website. When you sign up, be sure to add your registered domain and confirm that your are the owner.",
    question: "faqs_question01",
    answer: "faqs_answer01",
  },
  {
    id: 2,
    // question:
    //   "Can I Start with a free play website then upgrade my plan later?",
    // answer:
    //   "Yes, you can upgrade at any time. Just click on **Upgrade** in the top right-hand corner of your website.",
    question: "faqs_question02",
    answer: "faqs_answer02",
  },
  {
    id: 3,
    // question: "CAN I CANCEL MY CONTRACT IF I CHANGE MY MIND?",
    // answer:
    //   "Of course! If you decide to cancel, you just need to let us know within the first 14 days. So there's no risk to you.",
    question: "faqs_question03",
    answer: "faqs_answer03",
  },
  {
    id: 4,
    // question: "When Do I need to pay",
    // answer:
    //   "We offer our BuildLink premium plans with a minimum contract term of 1, 12 or 24 months. You pay for the full agreed term at the time of purchase and then at the renewal of every term.",
    question: "faqs_question04",
    answer: "faqs_answer04",
  },
  {
    id: 5,
    // question: "Can I sell products from my website?",
    // answer:
    //   "Yes! Check out our online shop plans. They have everything you need to get started-just snap a product photo, fill in the details, and you're ready to start taking orders. You can choose the plan that's right for you, depending on the ecommerce features you nedd.",
    question: "faqs_question05",
    answer: "faqs_answer05",
  },
];

export const PackageSelectionPage: React.FC = () => {
  return (
    <div className="flex flex-1 flex-col">
      {/* // header section */}
      <header className="sticky top-0 z-10 bg-white shadow">
        <nav
          className="mx-auto flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex flex-1 ">
            <span className="sr-only">
              {/* space */}
              <FormattedMessage id="LangsSpace" />
            </span>
          </div>
          {/* company logo */}
          <div className="flex flex-1 justify-center">
            {/*eslint-disable-next-line*/}
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">
                {/* Your Company */}
                <FormattedMessage id="LangsYourCompany" />
              </span>
              <img className="h-8 w-auto sm:h-10" src={logo} alt="bulidLink" />
            </a>
          </div>
          {/* Xmark icon */}
          <div className="flex flex-1 justify-end">
            <a href="/" className="h-6 w-6 cursor-pointer">
              <HiXMark className="h-full w-full text-slate-900 hover:rounded-md hover:text-slate-800 hover:ring-2 hover:ring-indigo-500" />
            </a>
          </div>
        </nav>
      </header>

      {/* package price content */}
      <div className="pt-24 sm:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-7xl text-center">
            <h2 className="mt-2 text-4xl font-bold text-gray-900 sm:text-5xl">
              <FormattedMessage
                id="PackagePlanTitle"
                defaultMessage={
                  "Choose the BuildLink plan that's right for you"
                }
              />
            </h2>
            <section className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-24 lg:mx-0 lg:max-w-none lg:grid-cols-4">
              {/* map tiers content */}
              {tiers.map((tier, tierIdx) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "mt-6 rounded-t-none lg:z-10 lg:mt-0 lg:rounded-b-none lg:shadow-2xl"
                      : "lg:mt-8",
                    tierIdx === 0 ? "lg:rounded-r-none" : "",
                    tierIdx === 2 ? "lg:rounded-b-none lg:rounded-t-none" : "",
                    tierIdx === tiers.length - 1 ? "lg:rounded-l-none" : "",
                    "relative flex flex-col justify-between rounded-3xl bg-white px-6 py-8 ring-1 ring-gray-200"
                  )}
                >
                  {/* Bestseller title */}
                  {tier.mostPopular ? (
                    <p className="absolute -top-7 left-0 w-full rounded-t-3xl bg-slate-900 px-2.5 py-1 text-xs font-semibold uppercase leading-5 text-white">
                      {/* Most Popular */}
                      <FormattedMessage
                        id="LangsMostPopular"
                        defaultMessage={"Most Popular"}
                      />
                    </p>
                  ) : null}
                  <div className="">
                    <div className="flex items-center justify-between gap-x-4">
                      {/* main Title */}
                      <h3
                        id={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? "text-indigo-600"
                            : "text-gray-900",
                          "w-full items-center text-lg font-semibold uppercase leading-8"
                        )}
                      >
                        {tier.name}
                        {/* <FormattedMessage id={tier.name} defaultMessage={"array:tiers.name"} /> */}
                      </h3>
                    </div>
                    {/* description */}
                    <p className="mt-2 text-sm leading-6 text-gray-600">
                      {/* {tier.description}<br /> */}
                      <FormattedMessage
                        id={tier.description}
                        defaultMessage={"array:tiers.description"}
                      />
                    </p>
                    {/* price */}
                    <p className="mt-6 flex flex-col items-baseline gap-x-1">
                      <span className="place-self-center text-4xl font-bold text-gray-900">
                        {tier.priceMonthly}
                      </span>
                      <span className="place-self-center text-sm font-semibold leading-6 text-gray-600">
                        {/* per month */}
                        <FormattedMessage
                          id="LangsPerMonth"
                          defaultMessage={"per month"}
                        />
                      </span>
                    </p>
                    {/* get started button */}
                    {/* eslint-disable-next-line*/}
                    {tier.id === "tier-play" ? (
                      <div className="mt-8">
                        <p className="text-center text-sm font-normal text-gray-600">
                          <FormattedMessage
                            id="PackageCustomDomain"
                            defaultMessage={"Continue without a custom domain"}
                          />
                        </p>
                      </div>
                    ) : (
                      <a
                        href={tier.href}
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                            : "text-indigo-600 ring-2 ring-inset ring-indigo-600 hover:ring-indigo-500",
                          "mx-12 mt-8 block px-3 py-2 text-center text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        )}
                      >
                        {/* Get started */}
                        <FormattedMessage
                          id="LangsGetStarted"
                          defaultMessage={"Get started"}
                        />
                      </a>
                    )}
                    <div className="mt-8 w-full">
                      <p className="flex flex-1 text-sm font-bold leading-6 text-gray-900">
                        <span className="">
                          <FormattedMessage
                            id={tier.feature_title}
                            defaultMessage={"array:tiers.feature_title"}
                          />
                        </span>
                        {/* <span className="">
                          &nbsp;
                          plus
                          <FormattedMessage id="LangsPlus" defaultMessage={"plus"} />
                          :</span> */}
                      </p>
                    </div>
                    {/* features section */}
                    <ul className="mx-1 mt-2 space-y-2 text-sm leading-6 text-gray-600">
                      {tier.features.map((feature, idx) => (
                        <li key={idx} className="flex flex-1">
                          <div>
                            <HiCheck
                              className="h-6 w-5 flex-none text-gray-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-2 text-left text-gray-800">
                            {typeof feature === "string" ? (
                              <FormattedMessage id={feature} />
                            ) : (
                              <div className="flex flex-1 items-center">
                                <span
                                  className=""
                                  title={
                                    feature.tooltip ? "Tooltip text" : undefined
                                  }
                                >
                                  <FormattedMessage
                                    id={feature.name}
                                    defaultMessage={"array:tiers.feature.name"}
                                  />
                                </span>

                                <Tooltip
                                  placement="top"
                                  content={
                                    <div className="flex flex-1 p-1">
                                      <p className="whitespace-normal text-sm text-white">
                                        <FormattedMessage
                                          id="PackagePlanDesc"
                                          defaultMessage={
                                            "When you purchase a BuildLink plan, we offer a discount for the first contractual term, which corresponds to the cost of a domain. This offer is valid for the following domains: .de, .com, .net, .org, .info, .at, .ch, .es, .it, .fr, and .nl. You can use a domain that's registered with another provider for the first contract period at no additional cost. Upon renewal, the cost  of the domain will be charged separately, based on the duration and billing terms of your BuildLink plan."
                                          }
                                        />
                                      </p>
                                    </div>
                                  }
                                >
                                  <FiInfo className="ml-1 h-4 w-4 cursor-help text-gray-600" />
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </section>
          </div>
        </div>

        {/* Premium plans tooltip content */}
        <section className="mx-auto mb-8 mt-16 w-[95vw] px-5 lg:max-w-screen-xl lg:px-0 lg:pt-16">
          <p className="text-base font-bold leading-normal text-zinc-800">
            <FormattedMessage
              id="PackagePremiumPlan"
              defaultMessage={"Included in all premium plans"}
            />
            :
          </p>
          {/* all tooltip */}
          <div className="flex w-full flex-wrap justify-between leading-6 text-zinc-800">
            {/* list */}
            <ul className="flex flex-col md:flex-row">
              {/* list item */}
              {premium_plans.map((plan, idx) => (
                <Tooltip
                  placement="top"
                  key={idx}
                  content={
                    <div className="flex flex-1 p-1">
                      <p className="whitespace-normal text-sm text-white">
                        <FormattedMessage
                          id={plan.tooltip_content}
                          defaultMessage={"array:premium_plans.tooltip_content"}
                        />
                      </p>
                    </div>
                  }
                >
                  <li className="mb-3 inline-block cursor-help text-left leading-6 text-zinc-800 md:m-0 md:pb-3 md:pr-5">
                    <FormattedMessage
                      id={plan.name}
                      defaultMessage={"array:premium_plans.name"}
                    />
                  </li>
                </Tooltip>
              ))}
            </ul>
            {/* compare all features button */}
            <div className="flex h-12 cursor-pointer items-center leading-6 text-zinc-800">
              <BiListCheck className="mr-1 h-8 w-8 text-gray-900" />

              <FormattedMessage
                id="PackageCompareFeatures"
                defaultMessage={"Compare all features"}
              />
            </div>
          </div>
        </section>
        {/* features compare section */}
        <section className="bg-white pb-6 leading-6 text-zinc-800">
          <h2 className="mb-10 px-2 pt-6 text-center text-3xl font-normal leading-3 md:text-4xl lg:pt-16">
            <FormattedMessage
              id="PackageFindFeatures"
              defaultMessage={"Find the features you need"}
            />
          </h2>

          {/* Hosting compare table*/}
          {tables.map((table, idx) => (
            <div
              className="text-zinc-800 md:mx-auto md:mb-6 md:max-w-screen-xl md:table-fixed md:pt-5 md:text-sm md:leading-normal"
              key={idx}
            >
              <table
                role="table"
                className="w-full border-collapse border-spacing-2 text-sm leading-5"
              >
                <thead className="">
                  <tr role="row" className="whitespace-nowrap uppercase">
                    <th
                      role="columnheader"
                      className="hidden py-4 pr-4 md:table-cell"
                    ></th>
                    {/* header map */}
                    {tiers.map((tier, tIdx) => (
                      <th
                        key={idx + "-" + tier.id}
                        role="columnheader"
                        className="hidden py-4 pr-4 md:table-cell"
                      >
                        {/*eslint-disable-next-line*/}
                        <a
                          href={tier.href}
                          className="cursor-pointer text-center text-sm font-medium uppercase leading-5 hover:underline"
                        >
                          {tier.name}
                          {/* <FormattedMessage id={tier.name} defaultMessage={"array:tiers.name"} /> */}
                        </a>
                      </th>
                    ))}
                  </tr>
                </thead>

                <thead className="">
                  <tr role="row" className="whitespace-nowrap">
                    <th
                      role="columnheader"
                      className="relative m-auto block h-auto w-auto text-clip bg-zinc-100 p-4 text-left text-base font-extrabold leading-normal text-zinc-800 md:p-4"
                    >
                      <FormattedMessage
                        id={table.name}
                        defaultMessage={"array:table.name"}
                      />
                    </th>
                    {tiers.map((tier, tIdx) => (
                      <th
                        key={tier.id}
                        role="columnheader"
                        className="hidden bg-zinc-100 py-4 pl-2 pr-4 text-left text-base font-bold uppercase leading-normal text-zinc-800 md:table-cell md:text-center md:text-sm md:font-medium"
                      >
                        {tier.priceMonthly}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="">
                  {table?.children?.map((child, cIdx) => (
                    <tr
                      role="row"
                      className="flex w-screen flex-1 flex-col gap-2 px-5 py-4 md:table-row md:border-t md:border-gray-300"
                      key={idx + "-" + cIdx}
                    >
                      <td
                        role="cell"
                        className="whitespace-normal pl-2 md:table-cell md:w-3/12 md:whitespace-nowrap md:border-x md:border-b md:border-t-0 md:border-zinc-100 md:px-3 md:py-4 md:align-middle"
                      >
                        <div>
                          <Tooltip
                            placement="top"
                            content={
                              <div className="flex flex-1 p-1">
                                <p className="whitespace-normal text-sm text-white">
                                  <FormattedMessage
                                    id={child.tooltip_content}
                                    defaultMessage={
                                      "array:child.tooltip_content"
                                    }
                                  />
                                </p>
                              </div>
                            }
                          >
                            <p className="text-base font-medium leading-6 text-zinc-800 hover:cursor-help hover:text-indigo-600">
                              <span>
                                <FormattedMessage
                                  id={child.name}
                                  defaultMessage={"array:child.name"}
                                />
                              </span>
                            </p>
                          </Tooltip>
                        </div>
                      </td>

                      {tiers.map((tier: any, tIdx) => (
                        <td
                          key={tIdx}
                          role="cell"
                          className="pb-2 pl-2 sm:w-2/12 md:border-b md:border-l-0 md:border-r md:border-t-0 md:border-zinc-100 md:py-4 md:pl-2 md:pr-4 md:text-center"
                        >
                          <div className="text-sm font-normal leading-normal text-neutral-500 md:hidden">
                            {tier.name}
                            {/* <FormattedMessage id={tier.name} defaultMessage={"array:tiers.name"} /> */}
                          </div>
                          {/* find tier value by child name */}
                          {tier && child?.id && tier[child.id] === true ? (
                            <span className="relative top-1 inline-block h-6 w-6">
                              <div>
                                <AiFillCheckCircle className="h-full w-full text-green-600" />
                              </div>
                            </span>
                          ) : tier && child?.id && tier[child.id] === false ? (
                            <span className="relative top-2 inline-block h-6 w-6">
                              <div>
                                <GrFormClose fontSize={16} />
                              </div>
                            </span>
                          ) : (
                            <>{tier && child?.id && tier[child.id]}</>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </section>
        {/* Pricing FAQ */}
        <section className="w-full bg-white">
          <div className="mx-auto w-[90vw] py-10 lg:w-[76vw] lg:max-w-[1200px]">
            <div className="mb-3 text-base font-bold leading-6 text-slate-900">
              {/* Pricing FAQ */}
              <FormattedMessage
                id="LangsPricingFAQ"
                defaultMessage={"Pricing FAQ"}
              />
            </div>
            <h2 className="mb-8 text-2xl font-extrabold leading-6 text-slate-900 lg:mb-16 lg:text-4xl">
              {/* Questions before starting? */}
              <FormattedMessage
                id="PackageQuestions"
                defaultMessage={"Questions before starting?"}
              />
            </h2>
            <div className="mb-10 text-sm leading-6 text-slate-900 lg:text-base">
              <FormattedMessage
                id="PackagePerfectPlan"
                defaultMessage={
                  "Compare and find the perfect plan for your website. Take a look at what we offer and start creating."
                }
              />
            </div>
            {faqs.map((faq, idx) => (
              <Disclosure as="div" key={faq.id} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full justify-between border-b border-zinc-200 py-6 font-semibold leading-6 tracking-wide text-slate-900 hover:cursor-pointer hover:text-indigo-600">
                        <h3 className="flex flex-wrap text-left text-lg font-extrabold leading-6 tracking-wide lg:text-2xl">
                          <FormattedMessage
                            id={faq.question}
                            defaultMessage={"array:faqs.question"}
                          />
                        </h3>
                        <span className="ml-2 inline-block font-semibold">
                          {open ? (
                            <HiChevronDown
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <HiChevronUp
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel
                      as="dd"
                      className="overflow-y-hidden leading-6 text-slate-900 transition-all duration-75 ease-in-out"
                    >
                      <p className="my-6">
                        <FormattedMessage
                          id={faq.answer}
                          defaultMessage={"array:faqs.answer"}
                        />
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </div>
        </section>
        <section className="w-full bg-slate-50">
          <div className="mx-auto flex w-[90vw] max-w-7xl flex-grow flex-col justify-between pt-20 md:flex-row">
            <div className="mb-5 flex w-full sm:w-1/3">
              <PiShieldCheckBold className="relative top-1 mr-3 h-9 w-9 self-baseline text-green-600" />
              <div className="flex flex-col">
                <h3 className="mb-5 text-lg font-bold uppercase leading-5 tracking-wider text-zinc-800">
                  {/* Ssl Secure payments */}
                  <FormattedMessage
                    id="LangsSSLSecurePayments"
                    defaultMessage={"SSL Secure payments"}
                  />
                </h3>
                <p className="mb-3 text-base font-normal leading-normal text-zinc-800">
                  <FormattedMessage
                    id="PackageSSLSecurity"
                    defaultMessage={
                      "With SSL security you can rest easy knowing that the payment process is secure."
                    }
                  />
                </p>
              </div>
            </div>
            <div className="mb-5 flex w-full sm:w-1/3 sm:pl-4">
              <RiUserHeartLine className="relative top-1 mr-3 h-8 w-8 self-baseline text-red-600" />
              <div className="flex flex-col">
                <h3 className="mb-5 text-lg font-bold uppercase leading-5 tracking-wider text-zinc-800">
                  <FormattedMessage
                    id="Package25Million"
                    defaultMessage={"25 Million websites created"}
                  />
                </h3>
                <p className="mb-3 text-base font-normal leading-normal text-zinc-800">
                  <FormattedMessage
                    id="PackageJoinBuildLink"
                    defaultMessage={
                      "Join the millions of websites already created with BuildLink."
                    }
                  />
                </p>
              </div>
            </div>
            <div className="mb-5 flex w-full flex-col pl-8 sm:w-1/3">
              <h3 className="mb-5 text-lg font-bold uppercase leading-5 tracking-wider text-zinc-800">
                <FormattedMessage
                  id="LangsPaymentMethods"
                  defaultMessage={"Payment methods"}
                />
              </h3>
              <div className="flex flex-wrap items-center justify-start leading-6">
                <img
                  src={visa}
                  alt="visa"
                  className="mr-2 mt-1 w-12 saturate-0"
                />
                <img
                  src={mastercard}
                  alt="Marstercard"
                  className="mr-2 mt-1 w-12 saturate-0"
                />
                <img
                  src={paypal}
                  alt="Paypal"
                  className="mr-2 mt-1 w-12 saturate-0"
                />
                <img
                  src={sofort}
                  alt="Sofort"
                  className="mr-2 mt-1 w-12 saturate-0"
                />
                <img
                  src={american_express}
                  alt="American Express"
                  className="mr-2 mt-1 w-12 saturate-0"
                />
                <img
                  src={sepa}
                  alt="SEPA"
                  className="mr-2 mt-1 w-12 saturate-0"
                />
              </div>
            </div>
          </div>
        </section>
        <footer className="mt-auto block w-full bg-slate-50 pb-10">
          <div className="mx-auto block w-[90vw]">
            <div className="mx-auto max-w-7xl border-t border-t-gray-300 pt-10 text-gray-500">
              <span className="relative block pb-3 text-base font-black uppercase text-gray-600">
                <FormattedMessage
                  id="LangsLegalInformation"
                  defaultMessage={"Legal information"}
                />
              </span>
              <div className="my-3 block max-w-7xl text-sm text-gray-500">
                &nbsp;&nbsp;
                <FormattedMessage
                  id="LangsPricesIncludeTax"
                  defaultMessage={"Prices include tax"}
                />
              </div>
              *&nbsp;
              <span className="text-sm text-gray-500">
                <FormattedMessage
                  id="PackagefeeBasedBuildLinkPlan"
                  defaultMessage={
                    "In purchasing a fee-based BuildLink plan we grant a reduction in price equal in value to the cost of a domain, for the first contractual team.This offer applies exclusively to domains with the following domain endings: .com, .net, .org, .info, .de, .at, .ch, .es, .it, .fr, .nl, .eu, .be, .store, .shop, .uk, .co.uk, .biz, .website, .online, .me. The cost of the package upon renewal of the contract is 247.92 $ for the Business package and 199.92 $ for the Basic package based on an initial contractual term of one year. The domain and the corresponding BuildLink package may be terminated at any time in accordance with the BuildLink"
                  }
                />
                {/*eslint-disable-next-line*/}
                <a href="#" className="cursor-pointer no-underline">
                  &nbsp;
                  <span className="underline">
                    {/* Terms of Service. */}
                    <FormattedMessage
                      id="LangsTermsOfService"
                      defaultMessage={"Terms of Service"}
                    />
                  </span>
                  &nbsp;
                </a>

                <FormattedMessage
                  id="PackageTermBillingConditions"
                  defaultMessage={
                    "The term and billing conditions of the domain depend on the term and billing conditions of the fee-based BuildLink package purchased."
                  }
                />
              </span>
              <div className="my-8 block pt-5 text-base text-gray-500 ">
                {/*eslint-disable-next-line*/}
                <a href="#" className="mr-4 hover:text-gray-900">
                  {/* Terms of use */}
                  <FormattedMessage
                    id="LangsTermsOfUse"
                    defaultMessage={"Terms of use"}
                  />
                </a>
                {/*eslint-disable-next-line*/}
                <a href="#" className="mr-4 hover:text-gray-900">
                  {/* Contact */}
                  <FormattedMessage
                    id="LangsContact"
                    defaultMessage={"Contact"}
                  />
                </a>
                {/*eslint-disable-next-line*/}
                <a href="#" className="mr-4 hover:text-gray-900">
                  {/* Refund Policy and form */}
                  <FormattedMessage
                    id="LangsRefundPolicyAndForm"
                    defaultMessage={"Refund Policy and form"}
                  />
                </a>
                {/*eslint-disable-next-line*/}
                <a href="#" className="mr-4 hover:text-gray-900">
                  {/* About */}
                  <FormattedMessage id="LangsAbout" defaultMessage={"About"} />
                </a>
                {/*eslint-disable-next-line*/}
                <a href="#" className="mr-4 hover:text-gray-900">
                  {/* Privacy Policy */}
                  <FormattedMessage
                    id="LangsPrivacyPolicy"
                    defaultMessage={"Privacy Policy"}
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
export default PackageSelectionPage;
