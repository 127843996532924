import React from "react";
import { Formik, Form, ErrorMessage, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import {
  connect,
  ConnectedProps,
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import { Auth } from "aws-amplify";

//redux
import { authActions } from "../../stores/actions";
// types
import type { LoginPageProps } from "../type";

import Logo from "../../media/svg/logo/logo.svg";
import Alert from "../../components/Alert";
import { FormattedMessage } from "react-intl";

type Props = ConnectedProps<typeof connector> & LoginPageProps;

interface FormValues {
  email: string;
  password: string;
}

const LoginPage: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const login_at = useSelector(({ auth }) => auth.login_at, shallowEqual);
  const { login } = props;

  const [initialValues] = React.useState<FormValues>({
    email: "",
    password: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const handleSubmit = async (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    try {
      const resp = await Auth.signIn(values.email, values.password);
      const { signInUserSession } = resp;
      if (signInUserSession?.accessToken?.jwtToken) {
        dispatch(login(signInUserSession.accessToken.jwtToken));
      }
      if (location.pathname === "/") {
        window.location.reload();
      } else {
        navigate("/");
      }
    } catch (e: any) {
      setStatus(e.message);
      setSubmitting(false);
      console.log(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (login_at) {
      navigate("/");
    }
  }, [login_at, navigate]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={Logo} alt="Build Link" />
      </div>

      <div className="mt-8 min-h-screen sm:flex sm:flex-col sm:items-center sm:justify-center sm:pb-80">
        <div className="bg-white px-6 py-12 shadow sm:w-96 sm:rounded-lg sm:px-12">
          <div className="flex flex-col">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
              <FormattedMessage id="LangsWelcomeBack" />

            </h2>
            <p className="mt-2 text-sm text-gray-600">
              <FormattedMessage id="AuthLogin" />

            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              status,
              values,
              handleBlur,
              handleChange,
              isSubmitting,
            }: FormikProps<FormValues>) => (
              <Form className="space-y-6" autoComplete="off">
                <Alert status="error" open={!!status} enableClose={false}>
                  {status}
                </Alert>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <FormattedMessage id="LangsEmailAddresses" />

                  </label>
                  <div className="mt-2">
                    <input
                      name="email"
                      type="email"
                      autoComplete="new-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="font-sm text-sm text-red-500">
                      <ErrorMessage name="email" />
                    </span>
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      <FormattedMessage id="LangsPassword" />

                    </label>
                  </div>
                  <div className="mt-2">
                    <input
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="font-sm text-sm text-red-500">
                      <ErrorMessage name="password" />
                    </span>
                  </div>
                  <div className="text-sm">
                    <a
                      href="/auth/forgot_password"
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      <FormattedMessage id="LangsForgotYourPassword" />

                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="LangsSignIn" />
                  </button>
                </div>
                <div className="w-full border-t border-gray-300" />
                <p className="mt-10 text-center text-sm text-gray-500">
                  <FormattedMessage id="LangsNewHere" />

                </p>
                <a
                  href="/auth/register"
                  className="flex w-full justify-center rounded-md border-2 border-indigo-600 bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <FormattedMessage id="LangsSignUp" />

                </a>
              </Form>
            )}
          </Formik>
        </div>
        <div className="mt-20 flex flex-row justify-center gap-8">
          {/*eslint-disable-next-line*/}
          <a
            href="#"
            className="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            <FormattedMessage id="LangsLegalNotice" />

          </a>
          {/*eslint-disable-next-line*/}
          <a
            href="#"
            className="font-semibold text-gray-600 hover:text-gray-500"
          >
            <FormattedMessage id="LangsPrivacyPolicy" />

          </a>
        </div>
      </div>
    </div>
  );
};

const connector = connect(null, { ...authActions });

export default connector(LoginPage);
