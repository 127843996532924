import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import { SidemenuContext } from "./index";
import { FormattedMessage } from "react-intl";

const SettingMenu: React.FC = () => {
  const location = useLocation();
  const { setMenuOpen } = React.useContext(SidemenuContext);

  return (
    <nav className="flex flex-1 flex-col">
      <ul className="flex flex-1 flex-col ">
        <li>
          <button
            className={classNames(
              "text-gray-700 hover:bg-gray-50 hover:text-black",
              "group flex w-full flex-auto items-center gap-x-3 p-2 text-base font-bold leading-6"
            )}
            onClick={() => setMenuOpen("home")}
          >
            <AiOutlineArrowLeft fontSize={20} />
            <p className="flex items-center justify-between">
              <FormattedMessage id="LangsBackToMainMenu" />
            </p>
          </button>
        </li>
        <div className="mt-8 text-sm font-semibold uppercase leading-6 text-gray-700">
          <FormattedMessage id="LangsAccount" />

        </div>
        <ul className="-ml-2 mt-5 flex flex-col gap-3">
          <li>
            <Link to="/profile/setting">
              <div
                className={classNames(
                  location.pathname === "/profile/setting" ? "bg-gray-50" : "",
                  "group flex w-full flex-auto gap-x-3 p-2 text-base  font-bold leading-6 text-gray-700 hover:bg-gray-50 hover:text-black"
                )}
              >
                <p className="flex items-center justify-between ">
                  <FormattedMessage id="LangsCookieSettings" />

                </p>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/profile">
              <div
                className={classNames(
                  "group flex w-full flex-auto gap-x-3 p-2 text-base font-bold leading-6 text-gray-700 hover:bg-gray-50 hover:text-black"
                )}
              >
                <p className="flex items-center justify-between">
                  <FormattedMessage id="LangsProfile" />
                </p>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/profile/email">
              <div
                className={classNames(
                  "group flex w-full flex-auto gap-x-3 p-2 text-base font-bold leading-6 text-gray-700 hover:bg-gray-50 hover:text-black"
                )}
              >
                <p className="flex items-center justify-between">
                  <FormattedMessage id="LangsEmails" />
                </p>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/auth/logout">
              <div
                className={classNames(
                  "group flex w-full flex-auto gap-x-3 p-2 text-base font-bold leading-6 text-gray-700 hover:bg-gray-50 hover:text-black"
                )}
              >
                <p className="flex items-center justify-between">

                  <FormattedMessage id="LangsLogOut" /></p>
              </div>
            </Link>
          </li>
        </ul>
      </ul>
    </nav>
  );
};
export default SettingMenu;
