import React from "react";

type SplashScreenContextType = {
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
};

type SplashScreenProviderProps = {
  children: React.ReactNode;
};

type LayoutSplashScreenProps = {
  visible?: boolean;
};

export const SplashScreenContext = React.createContext<SplashScreenContextType>(
  {
    count: 0,
    setCount: () => {},
  }
);

export const SplashScreenProvider: React.FC<SplashScreenProviderProps> = ({
  children,
}) => {
  const [count, setCount] = React.useState<number>(0);

  React.useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");

    // Show Splash Screen
    if (splashScreen && count > 0) {
      splashScreen.style.display = "block";

      return () => {
        splashScreen.style.display = "none";
      };
    }

    //Hide Splash Screen
    let timeout: ReturnType<typeof setTimeout>;
    if (splashScreen && count === 0) {
      timeout = setTimeout(() => {
        splashScreen.style.display = "none";
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [count]);

  return (
    <SplashScreenContext.Provider value={{ count, setCount }}>
      {children}
    </SplashScreenContext.Provider>
  );
};

export const LayoutSplashScreen: React.FC<LayoutSplashScreenProps> = ({
  visible = true,
}) => {
  // Everything are ready - remove splashscreen
  const { setCount } = React.useContext(SplashScreenContext);

  React.useEffect(() => {
    if (!visible) {
      return;
    }

    setCount((prev: number) => {
      return prev + 1;
    });

    return () => {
      setCount((prev: number) => {
        return prev - 1;
      });
    };
  }, [setCount, visible]);

  return null;
};
