import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
//components
import SettingGeneralPage from "./subPage/SettingGeneralPage";
import SettingDeletePage from "./subPage/SettingDeletePage";
import { FormattedMessage } from "react-intl";

const tabs = [
  { name: "LangsGeneral", content: <SettingGeneralPage /> },
  { name: "LangsDeleteWebsite", content: <SettingDeletePage /> },
];

const SettingPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [defaultIndex, setDefaultIndex] = React.useState<number>(
    location.pathname === "/cms/setting/general"
      ? 0
      : location.pathname === "/cms/setting/delete"
        ? 1
        : 0
  );

  const handleChangeTab = (index: number) => {
    setDefaultIndex(index);
    if (index === 0) {
      navigate("/cms/setting/general");
    } else if (index === 1) {
      navigate("/cms/setting/delete");
    }
  };

  return (
    <div className="mx-auto flex max-w-7xl flex-col items-start gap-6 px-6 py-24 leading-6 text-black lg:px-8">
      {/* TODO: 獲取 account name */}
      <h1 className="text-3xl font-bold text-zinc-800 md:text-left md:text-4xl">
        <FormattedMessage id="LangsSettings" />
      </h1>
      <p>
        <FormattedMessage id="SettingPageDescription" />
      </p>

      {/* TODO: Add tabs */}

      <div className="relative mb-10 flex w-full flex-col text-zinc-800">
        <Tab.Group selectedIndex={defaultIndex} onChange={handleChangeTab}>
          <Tab.List className="mb-10 flex w-full">
            {tabs.map((tab, i) => (
              <Tab as={Fragment} key={i}>
                {({ selected }) => (
                  <button
                    className={twMerge(
                      "cursor-pointer items-center  whitespace-nowrap border-b-2 p-4  outline-0 ring-0 hover:text-blue-600 focus:ring-0",
                      selected
                        ? "border-indigo-600 font-bold text-indigo-600"
                        : "text-zinc-800"
                    )}
                  >
                    <FormattedMessage id={tab.name} />
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {tabs.map((tab, i) => (
              <Tab.Panel key={i}>{tab.content}</Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default SettingPage;
