import React from "react";
import ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { store } from "../../../../stores";
import Langs from "../../../../langs/";

import GridToolbar from "../GridToolbar";
import { Splide } from "@splidejs/splide";

const useEditorGrid = () => {
  const handleClick = React.useCallback(
    (target: HTMLElement, splide: Splide | null) => {
      if (!target) return;
      const holder = target.querySelector("[data-holder='editor-grid']");
      if (holder) return;
      const div = document.createElement("div");
      const id = `editor-holder-${new Date().getTime()}`;
      div.id = id;
      div.setAttribute("data-holder", "editor-grid");
      div.setAttribute("style", "position: absolute; top:10%; left:0;");

      const root = ReactDOMClient.createRoot(div);
      root.render(
        <Provider store={store}>
          <IntlProvider messages={Langs.en} locale="en" defaultLocale="en">
            <GridToolbar root={root} splide={splide} />
          </IntlProvider>
        </Provider>
      );
      target.appendChild(div);
      target.style.position = "relative";
    },
    []
  );

  const handleClickOut = React.useCallback(
    (target: HTMLElement, prevRoot: ReactDOMClient.Root) => {
      if (!target) return;
      const holder = target.querySelector("[data-holder='editor-grid']");
      if (!holder) return;
      prevRoot.unmount();
      holder.remove();
    },
    []
  );

  return { handleClick, handleClickOut };
};

export default useEditorGrid;
