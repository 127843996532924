import React from "react";
import {
  EyeIcon,
  TrashIcon,
  PlusIcon,
  SwatchIcon,
  DocumentDuplicateIcon,
  ClipboardDocumentIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";

import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { WebBuilderContext } from "../../../pages/Cms/WebBuilderPage";
//components
import Tooltip from "../../Tooltip";
import useEventListener from "../Editor/hooks/useEventListener";

//hooks
import useIntersectionObserver from "../../../hooks/useInterectionObserver";
//store
import { builderActions } from "../../../stores/actions";
//type
import type { BuilderRowProps } from "./type";
import { FormattedMessage } from "react-intl";

type Props = ConnectedProps<typeof connector>;

const BuilderRow: React.FC<Props & BuilderRowProps> = ({
  root,
  index,
  ...props
}) => {
  const dispatch = useDispatch();
  const toolbarRef = React.useRef<HTMLDivElement>(null);
  const addButtonRef = React.useRef<HTMLDivElement>(null);
  const isBottomVisible = useIntersectionObserver(
    addButtonRef,
    {
      threshold: 0.5,
      rootMargin: "10px",
    },
    false
  );

  const { openBlockDrawer, addSection, removeSection } =
    React.useContext(WebBuilderContext);
  const [copyDone, setCopyDone] = React.useState<boolean>(false);
  const clipboard = useSelector(({ builder }) => builder.clipboard);
  const { addEvent, removeEvent } = useEventListener({});

  //get sectionNode
  const getSectionNode = React.useCallback(() => {
    if (!toolbarRef.current) return;
    const sectionNode = toolbarRef.current?.parentNode?.parentNode;
    if (!sectionNode) return;
    return sectionNode;
  }, []);

  //section has className
  const sectionHasClass = React.useCallback(
    (className: string) => {
      const sectionNode = getSectionNode();
      if (!sectionNode) return false;
      if (!(sectionNode instanceof HTMLElement)) return false;
      return sectionNode.classList.contains(className);
    },
    [getSectionNode]
  );

  //get section type
  const getSectionType = React.useCallback(() => {
    if (sectionHasClass("section-navbar")) {
      return "section-navbar";
    } else if (sectionHasClass("section-footer")) {
      return "section-footer";
    } else if (sectionHasClass("section-hero")) {
      return "section-hero";
    } else if (sectionHasClass("section-feature")) {
      return "section-feature";
    } else if (sectionHasClass("section-cta")) {
      return "section-cta";
    } else if (sectionHasClass("section-grid")) {
      return "section-grid";
    } else if (sectionHasClass("section-carousel")) {
      return "section-carousel";
    } else {
      return null;
    }
  }, [sectionHasClass]);

  // click add block button
  const handleAdd = React.useCallback(() => {
    //open block drawer
    const sectionType = getSectionType();
    if (!sectionType) return;
    openBlockDrawer(index, "add", "");
  }, [openBlockDrawer, index, getSectionType]);

  // click choose button
  const handleChoose = React.useCallback(() => {
    const sectionType = getSectionType();
    if (!sectionType) return;
    openBlockDrawer(index, "edit", sectionType);
  }, [openBlockDrawer, index, getSectionType]);

  // click remove button
  const handleRemove = React.useCallback(() => {
    removeSection(index);
    root.unmount();
  }, [root, removeSection, index]);

  //click deplicate button
  const handleDeplicate = React.useCallback(() => {
    const sectionNode = getSectionNode();
    if (!sectionNode || index === -1) return;
    const newNode = sectionNode.cloneNode(true) as HTMLElement;
    newNode.querySelector(".builder-row-tool")?.remove();
    addSection(index, newNode);
  }, [addSection, index, getSectionNode]);

  //click copy button
  const handleCopy = React.useCallback(() => {
    const sectionNode = getSectionNode();
    if (!sectionNode) return;
    const newNode = sectionNode.cloneNode(true) as HTMLElement;
    newNode.querySelector(".builder-row-tool")?.remove();
    //copy newNode to clipboard
    dispatch(props.filledClipboard(newNode));
    //change tooltip
    setCopyDone(true);
    setTimeout(() => {
      setCopyDone(false);
    }, 2000);
  }, [getSectionNode, dispatch, props]);

  //click paste button
  const handlePaste = React.useCallback(() => {
    if (index === -1) return;
    addSection(index, clipboard);
    dispatch(props.clearClipboard());
  }, [addSection, clipboard, dispatch, props, index]);

  React.useEffect(() => {
    // check sectionNode has footer class remove addButton
    const sectionNode = getSectionNode();
    if (!sectionNode) return;
    if (!(sectionNode instanceof HTMLElement)) return;
    if (sectionNode?.classList.contains("section-footer")) {
      addButtonRef?.current?.remove();
    }

    // check sectionNode has navbar or footer class remove the delete button in toolbar
    if (
      sectionNode?.classList.contains("section-footer") ||
      sectionNode?.classList.contains("section-navbar")
    ) {
      toolbarRef?.current?.querySelector(".btn-remove")?.remove();
      toolbarRef?.current?.querySelector(".btn-deplicate")?.remove();
      toolbarRef?.current?.querySelector(".btn-copy")?.remove();
    }
  }, [getSectionNode, toolbarRef]);

  // set the toolbar follow the window scroll
  React.useEffect(() => {
    if (!addButtonRef.current || !toolbarRef.current) return;
    const sectionNode = getSectionNode();
    if (!sectionNode) return;
    if (!(sectionNode instanceof HTMLElement)) return;
    if (
      sectionNode?.classList.contains("section-footer") ||
      sectionNode?.classList.contains("section-navbar")
    )
      return;
    // if (isBottomVisible) {
    //   toolbarRef.current.style.position = "fixed";
    //   toolbarRef.current.style.top = "80px";
    //   toolbarRef.current.style.left = "calc(10% - 2.5rem + 16rem)";
    // } else {
    toolbarRef.current.style.position = "";
    toolbarRef.current.style.top = "";
    toolbarRef.current.style.left = "";
    // }
  }, [isBottomVisible, getSectionNode, toolbarRef]);

  //set event listener for editor
  React.useEffect(() => {
    const sectionNode = getSectionNode();
    if (!sectionNode) return;
    if (!(sectionNode instanceof HTMLElement)) return;

    //add event to section node
    addEvent(sectionNode);

    return () => {
      removeEvent(sectionNode);
    };
    //eslint-disable-next-line
  }, [getSectionNode, addEvent, removeEvent]);

  return (
    <>
      {/*toolbar START*/}
      <div
        ref={toolbarRef}
        className="toolbar absolute -left-10 top-3 z-50 flex flex-col gap-2"
        style={{ display: "none" }}
      >
        <div>
          <Tooltip
            content={<FormattedMessage id="LangsShowElement" />}
            placement="left"
          >
            <button
              type="button"
              className="h-8 w-8 bg-gray-100 p-1 drop-shadow-2xl hover:bg-gray-200"
            >
              <EyeIcon />
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            content={<FormattedMessage id="LangsBlockDesign" />}
            placement="left"
          >
            <button
              type="button"
              onClick={handleChoose}
              className="h-8 w-8 bg-gray-100 p-1 drop-shadow-2xl hover:bg-gray-200"
            >
              <SwatchIcon />
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            content={
              copyDone ? (
                <FormattedMessage id="LangsCopied" />
              ) : (
                <FormattedMessage id="LangsCopy" />
              )
            }
            placement="left"
            className={classnames(copyDone && `!bg-green-500 !text-white`)}
          >
            <button
              type="button"
              onClick={handleCopy}
              className={classnames(
                copyDone && "!bg-green-500 !text-white",
                "btn-copy h-8 w-8 bg-gray-100 p-1 drop-shadow-2xl hover:bg-gray-200"
              )}
            >
              {copyDone ? <CheckIcon /> : <ClipboardDocumentIcon />}
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            content={<FormattedMessage id="LangsDelete" />}
            placement="left"
          >
            <button
              type="button"
              onClick={handleRemove}
              className="btn-remove h-8 w-8 bg-gray-100 p-1 drop-shadow-2xl hover:bg-gray-200"
            >
              <TrashIcon />
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            content={<FormattedMessage id="LangsDuplicate" />}
            placement="left"
          >
            <button
              type="button"
              onClick={handleDeplicate}
              className="btn-deplicate h-8 w-8 bg-gray-100 p-1 drop-shadow-2xl hover:bg-gray-200"
            >
              <DocumentDuplicateIcon />
            </button>
          </Tooltip>
        </div>
      </div>
      {/*toolbar END*/}

      <div
        ref={addButtonRef}
        className="add-block absolute -bottom-6 left-1/2 z-50 mx-auto flex -translate-x-1/2 flex-row items-center justify-center gap-4"
        style={{ display: "none" }}
      >
        {/*add Block Button START*/}
        <button
          onClick={handleAdd}
          className="flex flex-row items-center justify-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PlusIcon className="w-6" />
          <FormattedMessage id="LangsAddBlock" />
        </button>
        {/*add Block Button END*/}
        {/*Paste Block Button START*/}

        {clipboard && clipboard.querySelector && (
          <button
            onClick={handlePaste}
            className="flex flex-row items-center justify-center rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm ring-2 ring-inset ring-indigo-600 hover:bg-indigo-50"
          >
            <ClipboardDocumentCheckIcon className="w-6" />
            <FormattedMessage id="LangsPaste" />
          </button>
        )}

        {/*Paste Block Button END*/}
      </div>
    </>
  );
};

const connector = connect(null, { ...builderActions });

export default connector(BuilderRow);
