import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { TrashIcon, AcademicCapIcon } from "@heroicons/react/24/outline";
import { Formik, Form, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import classNames from "classnames";

import { authActions } from "../../../stores/actions";

type FormValues = {
  email: string;
};

type Props = ConnectedProps<typeof connector>;

const EmailsSettingPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const [initialValues, setInitialValues] = React.useState<FormValues>({
    email: "",
  });

  const [email, setEmail] = useState([
    ...(user.email ? [{ address: user.email, status: "Contact address" }] : []),
    ...(user["custom:email1"]
      ? [{ address: user["custom:email1"], status: "" }]
      : []),
    ...(user["custom:email2"]
      ? [{ address: user["custom:email2"], status: "" }]
      : []),
  ]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const handleSubmit = async (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);

    if (
      user.email === values.email ||
      user["custom:email1"] === values.email ||
      user["custom:email2"] === values.email
    ) {
      setStatus(
        <FormattedMessage
          id="EmailsSettingExisting"
          defaultMessage="Email is already in use!"
        />
      );
      setSubmitting(false);
      return;
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        ...user.attributes,
        ...(email.length === 1 ? { "custom:email1": values.email } : {}),
        ...(email.length === 2 ? { "custom:email2": values.email } : {}),
      });
      const { attributes } = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });

      dispatch(props.filledUser(attributes));
      const { email: currentEmail, ...rest } = attributes;
      setInitialValues({ ...rest, email: "" });
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteEmail = async (target: string) => {
    let deleteKey = "";
    if (target === user["custom:email1"]) {
      deleteKey = "custom:email1";
    }
    if (target === user["custom:email2"]) {
      deleteKey = "custom:email2";
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        ...user.attributes,
        ...(deleteKey === "custom:email1" ? { "custom:email1": "" } : {}),
        ...(deleteKey === "custom:email2" ? { "custom:email2": "" } : {}),
      });
      const { attributes } = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      dispatch(props.filledUser(attributes));
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    setEmail([
      ...(user.email
        ? [{ address: user.email, status: "Contact address" }]
        : []),
      ...(user["custom:email1"]
        ? [{ address: user["custom:email1"], status: "" }]
        : []),
      ...(user["custom:email2"]
        ? [{ address: user["custom:email2"], status: "" }]
        : []),
    ]);
  }, [user]);

  return (
    <div>
      <p className="mb-3  text-base font-normal leading-normal text-zinc-800">
        <FormattedMessage id="EmailsSettingDescriptionNo1" />
      </p>
      <p className="mb-3  text-base font-normal leading-normal text-zinc-800">
        <FormattedMessage id="EmailsSettingDescriptionNo2" />
      </p>
      <p className="mb-2 text-sm font-normal leading-normal text-yellow-700">
        <FormattedMessage id="EmailsSettingDescriptionNo3" />
        <a
          href="/help"
          className="cursor-pointer text-sm leading-5 text-blue-700"
        >
          <FormattedMessage id="LangsHere" />
        </a>
        .
      </p>

      <div>
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="m-0 border-collapse whitespace-nowrap py-4 pl-0 pr-4 text-left text-base font-bold leading-normal text-zinc-800">
                <h3 className=" mb-5  border-collapse whitespace-nowrap text-left text-lg font-bold">
                  <FormattedMessage id="LangsYourEmailAddresses" />
                </h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* TODO: email status set 驗證設定 */}
            {email.map((item, index) => (
              <tr
                key={index}
                className=" table-row  border-collapse border-t border-solid border-gray-300 py-4  text-zinc-800"
              >
                <th className="col-start-1 col-end-2  border-collapse text-zinc-800 md:py-4 md:pl-0 md:pr-4">
                  <div className="flex ">
                    <div
                      className={classNames(
                        item.status === "Contact address"
                          ? "bg-blue-600 "
                          : "bg-gray-300",
                        "mr-4 flex h-10 w-10 border-collapse items-center justify-center rounded-full text-white"
                      )}
                    >
                      <AcademicCapIcon className="h-5 w-5" />
                    </div>
                    <div>
                      <p className="mb-1  border-collapse text-sm font-bold leading-normal text-zinc-800">
                        {item.address}
                      </p>
                      <p className=" border-collapse text-left text-sm font-normal leading-normal text-zinc-800 md:order-3 ">
                        {item.status}
                      </p>
                    </div>
                  </div>
                </th>
                <th className="w-full border-collapse text-zinc-800 md:w-auto md:py-4 md:pl-0 md:pr-4 md:text-right">
                  <div className="flex items-center justify-end">
                    <button
                      className={classNames(
                        item.status === "Contact address"
                          ? "pointer-events-none  cursor-default border-indigo-300 text-indigo-300 "
                          : item.status === "Secondary address"
                          ? "border-blue-600 text-blue-600 hover:border-blue-500 hover:bg-blue-100 hover:text-blue-600"
                          : " border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700",
                        "relative m-0 inline-flex h-8 min-w-0 max-w-full border-collapse cursor-pointer select-none appearance-none items-center justify-center gap-2  overflow-hidden border-2 border-solid px-3 py-1 text-center text-sm font-bold "
                      )}
                    >
                      {item.status === "unverified"
                        ? "Resend"
                        : "Set as primary"}
                    </button>
                    <button
                      onClick={() => handleDeleteEmail(item.address)}
                      className={classNames(
                        item.status === "Contact address"
                          ? "focus:None hover:None pointer-events-none cursor-default opacity-40 "
                          : "hover:text-red-500 focus:text-red-500",
                        "flex h-12 w-12  cursor-pointer items-center  justify-center text-sm text-neutral-500  "
                      )}
                    >
                      <TrashIcon className="h-5 w-5 " />
                    </button>
                  </div>
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          status,
          values,
          handleBlur,
          handleChange,
          isSubmitting,
        }: FormikProps<FormValues>) => (
          <Form className="space-y-6" autoComplete="off">
            <div className="flex flex-col gap-5 py-5 text-zinc-800 md:flex-row md:justify-between">
              <div className="flex-grow ">
                <div className=" border-2 border-solid border-zinc-200 bg-white text-zinc-800 hover:border-gray-300 focus:border-2 focus:border-solid focus:border-blue-600">
                  <input
                    className="m-0 block w-full flex-1 cursor-text  appearance-none border-0 p-3 text-base "
                    name="email"
                    placeholder="your-email@mail.com"
                    type="email"
                    id="email"
                    aria-invalid="false"
                    value={values.email || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <button
                className={classNames(
                  values.email
                    ? "cursor-pointer bg-blue-600 hover:bg-blue-700"
                    : "pointer-events-none cursor-default bg-indigo-300",
                  "inline-flex select-none  appearance-none items-center justify-center overflow-hidden  rounded-none border-2 border-solid border-transparent  px-5 py-3 text-center text-base font-bold  text-white"
                )}
                disabled={!values.email || isSubmitting}
                type="submit"
              >
                <span>
                  <FormattedMessage id="LangsAddNewEmail" />
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const connector = connect(null, { ...authActions });

export default connector(EmailsSettingPage);
