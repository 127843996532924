import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import classNames from "classnames";
import {
  ArrowRightIcon,
  CheckCircleIcon,
  EllipsisHorizontalIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { HeartIcon } from "@heroicons/react/24/outline";
import Progress from "../../components/Progress";

//logo
import logo from "../../media/svg/logo/logo.svg";

import { WizardContext } from "./index";
import { wizardActions } from "../../stores/actions";
import { PageStateType } from "../../stores/type";

//image
import subpage_image from "../../media/svg/wizard/subpage/subpage_image.png";

const subpages = [
  {
    id: "services",
    title: "Services",
    clicked: false,
  },
  {
    id: "classes",
    title: "Classes",
    clicked: false,
  },
  {
    id: "about",
    title: "About Us",
    clicked: false,
  },
  {
    id: "contact",
    title: "Contact",
    clicked: false,
  },
  {
    id: "booking",
    title: "Booking",
    clicked: false,
  },
  {
    id: "events",
    title: "Events",
    clicked: false,
  },
  {
    id: "gallery",
    title: "Gallery",
    clicked: false,
  },
  {
    id: "prices",
    title: "Prices",
    clicked: false,
  },
  {
    id: "custom",
    title: "",
    clicked: false,
  },
];

type Props = ConnectedProps<typeof connector>;

const SubpagesPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector(({ wizard }) => wizard);
  const { activeStep, setActiveStep } = React.useContext(WizardContext);
  const [pages] = React.useState(subpages);

  const handlePagesClick = (pageIdx: number) => {
    let formPages = formData.pages || [
      { id: "home", title: "Home", clicked: false },
    ];
    const newPage = pages[pageIdx];
    if (!formPages.some((fp: any) => fp.id === newPage.id)) {
      if (formPages.length < 3) formPages.push(newPage);
    } else {
      formPages = formPages.filter((fp: any) => fp.id !== newPage.id);
    }

    dispatch(props.editWizard("pages", formPages));
  };

  const handleChangeCustomTitle = (e: any) => {
    const value = e.target.value;
    let customPage = formData.pages.find(
      (x: PageStateType) => x.id === "custom"
    );
    if (!customPage) return;
    customPage.title = value;
    let formPages = formData.pages.filter(
      (x: PageStateType) => x.id !== "custom"
    );
    formPages.push(customPage);
    dispatch(props.editWizard("pages", formPages));
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <div className="tex-black m-0 min-h-screen overflow-auto p-0 leading-6">
        {/* // header */}
        <div className="min-h-screen overflow-auto bg-gray-50 transition">
          <header
            className="bg-gary-50 fixed left-0 top-0 z-10 m-0 flex h-[88px] w-full items-center justify-center border-b border-solid border-gray-200 bg-opacity-[0.9] p-0 leading-6 text-black transition md:w-1/2"
            aria-label="Global"
          >
            <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
          </header>

          {/* // main content Group */}
          <div className="bg-gary-50 relative flex min-h-screen w-full flex-col pb-24 leading-6 text-black transition md:w-1/2">
            <div className="relative m-0 mt-[88px] flex flex-1 flex-col p-0 leading-6 text-black">
              {/* // main content */}
              <div className="mx-auto my-0 w-full max-w-[660px] px-5 py-0 leading-6 text-black ">
                {/* Title */}
                <h1 className="mx-1 mb-8 mt-10 p-0 text-center text-2xl font-bold leading-[1.2] text-black md:mb-16 md:mt-20 md:text-[4.8vh]">
                  <FormattedMessage id="WizardSubpagesTitle" />
                </h1>
                {/* // subtitle */}
                <p className="mx-auto mb-16 mt-0 max-w-[480px] p-0 text-center text-base font-medium leading-normal text-neutral-500">
                  <FormattedMessage id="WizardSubpagesDescription" />
                </p>
                {/* // Recommended form */}
                {/* //Title 1 */}
                <div className="fint-bold mx-0 my-5 flex items-center p-0 text-lg leading-none text-zinc-800">
                  <HeartIcon className="my-0 ml-0 mr-2 h-5 w-5 p-0 align-middle text-lg font-bold leading-4 text-zinc-800" />
                  <h5 className="m-0 p-0 text-sm font-bold leading-4 text-zinc-800">
                    <FormattedMessage id="LangsRecommended" />
                  </h5>
                </div>
                {/* //Recommended Multiple choice */}
                <div className="grid grid-cols-1 justify-start gap-2 leading-6 text-black sm:grid-cols-3">
                  {pages.slice(0, 3).map((page, pageIdx) => (
                    <div
                      key={page.id}
                      className={classNames(
                        formData.pages.some((fp: any) => fp.id === page.id)
                          ? "box-selected bg-indigo-900"
                          : "bg-white",
                        formData.pages.length >= 3 &&
                        !formData.pages.some(
                          (fp: any) => fp.id === page.id
                        ) &&
                        "opacity-50",
                        "wizard subpages box box-hover"
                      )}
                      onClick={() => handlePagesClick(pageIdx)}
                    >
                      <div className="min-2-0 flex flex-grow items-center leading-6">
                        <p
                          className={classNames(
                            formData.pages.some((fp: any) => fp.id === page.id)
                              ? "font-bold text-white"
                              : "text-zinc-800",
                            formData.pages.length >= 3 &&
                            !formData.pages.some(
                              (fp: any) => fp.id === page.id
                            ) &&
                            "opacity-50",
                            "cursor-pointer truncate pr-3 text-base leading-6"
                          )}
                        >
                          {page.title}
                        </p>
                        <CheckCircleIcon
                          className={classNames(
                            formData.pages.some((fp: any) => fp.id === page.id)
                              ? "wizard subpages icon"
                              : "hidden"
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {/* // You might also like form */}
                {/* // Title 2 */}
                <div className="fint-bold mx-0 my-5 flex items-center p-0 text-lg leading-none text-zinc-800">
                  <EllipsisHorizontalIcon className="my-0 ml-0 mr-2 h-5 w-5 p-0 align-middle text-lg font-bold leading-4 text-zinc-800" />
                  <h5 className="m-0 p-0 text-sm font-bold leading-4 text-zinc-800">
                    <FormattedMessage id="YouMightAlsoLike" />
                  </h5>
                </div>
                {/* //You might also like Multiple choice */}
                <div className="grid grid-cols-1 justify-start gap-2 leading-6 text-black sm:grid-cols-3">
                  {pages.slice(3, 8).map((morePage, morePageIdx) => (
                    <div
                      key={morePage.id}
                      className={classNames(
                        formData.pages.some((fp: any) => fp.id === morePage.id)
                          ? "box-selected bg-indigo-900"
                          : "bg-white",
                        formData.pages.length >= 3 &&
                        !formData.pages.some(
                          (fp: any) => fp.id === morePage.id
                        ) &&
                        "opacity-50",
                        "wizard subpages box box-hover"
                      )}
                      onClick={() => handlePagesClick(3 + morePageIdx)}
                    >
                      <div className="min-2-0 flex flex-grow items-center leading-6">
                        <p
                          className={classNames(
                            formData.pages.some(
                              (fp: any) => fp.id === morePage.id
                            )
                              ? "font-bold text-white"
                              : "text-zinc-800",
                            formData.pages.length >= 3 &&
                            !formData.pages.some(
                              (fp: any) => fp.id === morePage.id
                            ) &&
                            "opacity-50",
                            "cursor-pointer truncate pr-3 text-base leading-6"
                          )}
                        >
                          {morePage.title}
                        </p>
                        <CheckCircleIcon
                          className={classNames(
                            formData.pages.some(
                              (fp: any) => fp.id === morePage.id
                            )
                              ? "wizard subpages icon"
                              : "hidden"
                          )}
                        />
                      </div>
                    </div>
                  ))}
                  {/* // Custom page input form */}
                  <div
                    onClick={() => handlePagesClick(8)}
                    className={classNames(
                      formData.pages.some((fp: any) => fp.id === "custom")
                        ? "wizard subpages box-selected"
                        : "null",
                      "wizard subpages box-hover mx-2 mb-5 flex h-20 w-auto select-none  items-center justify-center rounded-md border border-solid bg-white leading-6 text-black transition duration-300 ease-out hover:cursor-pointer hover:shadow-md hover:ease-in"
                    )}
                  >
                    {formData.pages.some((fp: any) => fp.id === "custom") ? (
                      <div className="relative flex">
                        <input
                          type="text"
                          className={classNames(
                            formData.pages.some((fp: any) => fp.id === "custom")
                              ? "bg-indigo-900 font-bold text-white"
                              : "bg-white",
                            "h-20 w-full rounded-md border border-solid border-gray-200 text-start placeholder:text-base"
                          )}
                          autoFocus
                          placeholder="Custom page"
                          value={
                            formData.pages.find((fp: any) => fp.id === "custom")
                              ?.title || ""
                          }
                          onChange={handleChangeCustomTitle}
                        />
                        <ArrowRightIcon
                          onClick={() => handlePagesClick(8)}
                          className={classNames(
                            formData.pages.some((fp: any) => fp.id === "custom")
                              ? "hidden"
                              : "null",
                            "absolute right-4 top-4 h-5 w-5 text-indigo-600"
                          )}
                        />
                        <CheckCircleIcon
                          className={classNames(
                            formData.pages.some((fp: any) => fp.id === "custom")
                              ? "wizard subpages icon"
                              : "hidden"
                          )}
                        />
                      </div>
                    ) : (
                      <div onClick={() => handlePagesClick(8)}>
                        <PlusIcon className="h-8 w-8 stroke-2 text-4xl leading-6 text-indigo-600" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* // button group */}
          <div className="fixed bottom-[84px] flex w-full items-center transition md:w-1/2">
            <Progress className="bg-gray-300/20" value={90} size="sm" />
            <div className="fixed bottom-0 flex w-full items-center justify-between bg-gray-50 bg-opacity-[0.9] p-5 leading-6 transition md:w-1/2">
              <button
                type="button"
                className="inline-flex border-2 border-indigo-600 bg-gray-50 px-5 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleBack}
              >
                <FormattedMessage id="LangsBack" />
              </button>
              <div
                onClick={handleSkip}
                className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500"
              >
                <FormattedMessage id="LangsSkipThisStep" />
              </div>
              <button
                onClick={handleNext}
                type="button"
                className="inline-flex bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={formData.pages.length === 0}
              >
                <FormattedMessage id="LangsContinue" />
              </button>
            </div>
          </div>
        </div>

        {/* // Right side Content */}
        <div className="bg-whtie fixed left-1/2 right-0 top-0 hidden h-full leading-6 text-black md:inline">
          <div className="relative flex h-full animate-none flex-col items-center justify-center overflow-hidden bg-white leading-6 text-black transition">
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-start pt-[88px] leading-6 text-black">
              <div className="relative max-w-[45vw] overflow-hidden bg-white shadow transition-transform">
                <img
                  className="block h-full w-full"
                  src={subpage_image}
                  alt=""
                />
              </div>
              <div className="relative mx-16 mb-0 mt-6 hidden w-[30vw] p-0 leading-6 text-black opacity-100 transition-opacity sm:inline lg:max-w-xl">
                <h2 className="text-4xl font-bold leading-none text-indigo-600">
                  09
                </h2>
                <p className="flex min-h-[40px] items-center text-base font-normal leading-6 text-gray-900">
                  <FormattedMessage id="WizardSubpagesSubTitle" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...wizardActions });

export default connector(SubpagesPage);
