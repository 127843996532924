import React, { Fragment } from "react";
import moment from "moment";
import { twMerge } from "tailwind-merge";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowRightIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  CreditCardIcon,
  MinusSmallIcon,
  IdentificationIcon,
  BuildingStorefrontIcon,
  SparklesIcon,
  MegaphoneIcon,
  UserIcon,
  EnvelopeIcon,
  LinkIcon,
  CalendarIcon,
  GlobeAltIcon,
  MapPinIcon,
  NewspaperIcon,
  ChevronRightIcon,
  ArrowTopRightOnSquareIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
//components
import Progress from "../components/Progress";
import MyLineChart from "../components/MyLineChart";

import LogoImg from "../media/img/logo4ebd.png";
// GoalsTools img
import GoalsToolsCreateLogoImg from "../media/img/goalsTools/Create a Free Logo.svg";
import GoalsToolsSmartAppsImg from "../media/img/goalsTools/See Smart Apps.svg";
import GoalsToolsAddButtonImg from "../media/img/goalsTools/Add Button.svg";
import GoalsToolsAddWhatsAppImg from "../media/img/goalsTools/Add WhatsApp.svg";
import GoalsToolsAddContactImg from "../media/img/goalsTools/Add Contact Form.svg";
import GoalsToolsReadMoreImg from "../media/img/goalsTools/Read more.svg";
import GoalsToolsSetSEOImg from "../media/img/goalsTools/Set SEO Keywords.svg";
import GoalsToolsEditPageImg from "../media/img/goalsTools/Edit About Page.svg";
import GoalsToolsTryNewsBlockImg from "../media/img/goalsTools/Try News Block.svg";
import unlockcustomImg from "../media/img/viseits11.svg";
//hook
import { useProject } from "../hooks/useProject";

const Actions = [
  {
    id: "01",
    icon: ArrowTopRightOnSquareIcon,
    title: "LangsPreviewWebsite",
    href: "/cms/preview/:projectId",
  },
  {
    id: "02",
    icon: RocketLaunchIcon,
    title: "LangsUpgrade",
    href: "/cms/upgrade/:projectId",
  },
];

const GoalsTools = [
  {
    id: "01",
    title: "GoalsToolsCreateLogoTitle",
    description: "GoalsToolsCreateLogoDescListality",
    titleDefaultMessage: "Go professional with our free Logo Creator",
    descriptionDefaultMessage:
      "A logo reflects your listality and makes a memorable first impression. Create a free logo and add it to your website, in just a few steps",
    href: "/logos",
    hyperlinktext: "GoalsToolsCreateLogoLinktext",
    image: GoalsToolsCreateLogoImg,
    show: ["AnnounceAnEventOrProject"],
  },
  {
    id: "02",
    title: "GoalsToolsSmartAppsTitle",
    description: "GoalsToolsSmartAppsDesc",
    titleDefaultMessage: "Showcase your products like a pro",
    descriptionDefaultMessage:
      "With Smart Apps, you can do even more with your website. Add rich content, videos, playlists, links to other services, and more.",
    href: "/cms",
    hyperlinktext: "GoalsToolsSmartAppsLinktext",
    image: GoalsToolsSmartAppsImg,
    show: ["SellOnline"],
  },

  {
    id: "03",
    title: "GoalsToolsContactFormTitle",
    description: "GoalsToolsContactFormDesc",
    titleDefaultMessage: "Help customers and clients get in touch ASAP",
    descriptionDefaultMessage:
      "Add a Contact form so website visitors know exactly how to send a business inquiry or ask for more information.",
    href: "/cms",
    hyperlinktext: "GoalsToolsContactFormLinktext",
    image: GoalsToolsAddContactImg,
    show: ["GetHired", "GetCustomersToContactMe"],
  },
  {
    id: "04",
    title: "GoalsToolsAddButtonTitle",
    description: "GoalsToolsAddButtonDesc",
    titleDefaultMessage: "Tell customers you're open for work",
    descriptionDefaultMessage:
      "Add a 'Hire Me' button on your homepage linked to your Contact page. That way they'll know you're open to hearing from new prospects.",
    href: "/cms",
    hyperlinktext: "GoalsToolsAddButtonLinktext",
    image: GoalsToolsAddButtonImg,
    show: ["GetHired", "GetCustomersToContactMe"],
  },
  {
    id: "05",
    title: "GoalsToolsSetSEOTitle",
    description: "GoalsToolsSetSEODesc",
    titleDefaultMessage: "Drive more traffic to your website",
    descriptionDefaultMessage:
      "Make sure you're targeting likely customers by adding SEO keywords to your website. Think about which keywords your customers would type into Google to find a business like yours.",
    href: "/cms",
    hyperlinktext: "GoalsToolsSetSEOLinktext",
    image: GoalsToolsSetSEOImg,
    show: ["GetHired", "GetCustomersToContactMe"],
  },
  {
    id: "06",
    title: "GoalsToolsAddWhatsAppTitle",
    description: "GoalsToolsAddWhatsAppDesc",
    titleDefaultMessage: "Add your WhatsApp number so you're easy to contact",
    descriptionDefaultMessage:
      "Respond to inquiries on-the-go by adding your WhatsApp number to your website. That way, customers can chat with you directly by clicking on this icon.",
    href: "/cms",
    hyperlinktext: "GoalsToolsAddWhatsAppLinktext",
    image: GoalsToolsAddWhatsAppImg,
    show: ["GetHired", "GetCustomersToContactMe"],
  },
  {
    id: "07",
    title: "GoalsToolsReadMoreTitle",
    description: "GoalsToolsReadMoreDesc",
    titleDefaultMessage: "Enhance your pictures with the Image Editor",
    descriptionDefaultMessage:
      "Beautiful photos are key to making your website look professional. Click on any image, then on the pen icon to open the Image Editor. Add filters, crop, adjust, and more.",
    href: "/cms",
    hyperlinktext: "GoalsToolsReadMoreLinktext",
    image: GoalsToolsReadMoreImg,
    show: [
      "ShareMyPortfolio",
      "PromoteMyContent",
      "PromoteAPhysicalBusiness",
      "ShowcaseMyWork",
      "SellOnline",
    ],
  },
  {
    id: "08",
    title: "GoalsToolsSmartAppsTitle",
    description: "GoalsToolsSmartAppsDescInstagram",
    titleDefaultMessage: "Showcase your products like a pro",
    descriptionDefaultMessage:
      "With Smart Apps, you can do even more with your website. Add rich content, videos, playlists, links to other services, and more.",
    href: "/cms",
    hyperlinktext: "GoalsToolsSmartAppsLinktext",
    image: GoalsToolsSmartAppsImg,
    show: [
      "ShareMyPortfolio",
      "PromoteMyContent",
      "PromoteAPhysicalBusiness",
      "ShowcaseMyWork",
    ],
  },
  {
    id: "09",
    title: "GoalsToolsAddBookingButtonTitle",
    description: "GoalsToolsAddBookingButtonDesc",
    titleDefaultMessage: "Add a booking button that's easy to spot",
    descriptionDefaultMessage:
      "Make sure your 'Book Now' button is one the first thing customers see on your homepage. Then they can quickly book an appointment directly via your website.",
    href: "/cms",
    hyperlinktext: "GoalsToolsAddButtonLinktext",
    image: GoalsToolsAddButtonImg,
    show: ["GetOnlineBookings"],
  },
  {
    id: "10",
    title: "GoalsToolsSmartAppsTitleCalendly",
    description: "GoalsToolsSmartAppsDescCalendly",
    titleDefaultMessage: "Use apps to make booking appointments a snap",
    descriptionDefaultMessage:
      "With Smart Apps like Calendly and Google Calendar, you can embed a calendar right on your website and offer direct scheduling.",
    href: "/cms",
    hyperlinktext: "GoalsToolsSmartAppsLinktext",
    image: GoalsToolsSmartAppsImg,
    show: ["GetOnlineBookings"],
  },
  {
    id: "11",
    title: "GoalsToolsNewsBlockTitle",
    description: "GoalsToolsNewsBlockDesc",
    titleDefaultMessage: "Share your latest news",
    descriptionDefaultMessage:
      "Do you have a big announcement, a new event, or a project to share? With a News Block, you can add an eye-catching banner to the top of your homepage.",
    href: "/cms",
    hyperlinktext: "GoalsToolsNewsBlockLinktext",
    image: GoalsToolsTryNewsBlockImg,
    show: ["AnnounceAnEventOrProject"],
  },
  {
    id: "12",
    title: "GoalsToolsEditPageTitle",
    description: "GoalsToolsEditPageDesc",
    titleDefaultMessage: "Share what sets you apart",
    descriptionDefaultMessage:
      "When visitors connect with your story, they're more likely to want to work with you. Try adding more information to your About page—how you got started, what sets you apart from your competitors, and what keeps you motivated.",
    href: "/cms",
    hyperlinktext: "GoalsToolsEditPageLinktext",
    image: GoalsToolsEditPageImg,
    show: ["AnnounceAnEventOrProject"],
  },
  {
    id: "13",
    title: "GoalsToolsCreateLogoTitle",
    description: "GoalsToolsCreateLogoDescPersonality",
    titleDefaultMessage: "Go professional with our free Logo Creator",
    descriptionDefaultMessage:
      "A logo reflects your personality and makes a memorable first impression. Create a free logo and add it to your website, in just a few steps.",
    href: "/cms",
    hyperlinktext: "GoalsToolsCreateLogoLinktext",
    image: GoalsToolsCreateLogoImg,
    show: ["AnnounceAnEventOrProject"],
  },
];

const tools = [
  {
    // go to Cms page edit web site
    id: "01",
    title: "LangsWebsite",
    description: "MoreToolsWebsiteDesc",
    href: "/cms",
    icon: IdentificationIcon,
  },
  {
    id: "02",
    title: "LangsDomain",
    description: "MoreToolsDomainDesc",
    href: "/cms/upgrade",
    icon: LinkIcon,
  },
  {
    id: "03",
    title: "LangsBookings",
    description: "MoreToolsBookingsDesc",
    href: "#",
    icon: CalendarIcon,
  },
  {
    id: "04",
    title: "LangsOnlineStore",
    description: "MoreToolsOnlineStoreDesc",
    href: "#",
    icon: BuildingStorefrontIcon,
  },
  {
    id: "05",
    title: "LangsSEO",
    description: "MoreToolsSEODesc",
    href: "#",
    icon: GlobeAltIcon,
  },
  {
    id: "06",
    title: "LangsBusinessEmail",
    description: "MoreToolsBusinessEmailDesc",
    href: "#",
    icon: EnvelopeIcon,
  },
  {
    id: "07",
    title: "LangsContacts",
    description: "MoreToolsContactsDesc",
    href: "/contacts",
    icon: UserIcon,
  },
  {
    id: "08",
    title: "LangsBusinessListings",
    description: "MoreToolsBusinessListingsDesc",
    href: "#",
    icon: MapPinIcon,
  },
  {
    id: "09",
    title: "LangsLegalTextGenerator",
    description: "MoreToolsLegalTextGeneratorDesc",
    href: "/cms",
    icon: NewspaperIcon,
  },
  {
    id: "10",
    title: "LangsFacebookInstagram",
    description: "MoreToolsFacebookInstagramDesc",
    href: "/marketing",
    icon: MegaphoneIcon,
  },
  {
    id: "11",
    title: "LangsLogoCreator",
    description: "MoreToolsLogoCreatorDesc",
    href: "/logos",
    icon: SparklesIcon,
  },
];

const visits = {
  value: 100,
  percentage: "0%",
};

const fcupper = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const progress = { progress: 75 };

const hour = +moment().format("HH");

const DashboardPage: React.FC = () => {
  const { user } = useSelector(({ auth }) => auth);
  const project = useSelector(({ project }) => project);
  const { getLink: getProjectList } = useProject();

  const [selectedGoal, setSelectedGoal] = React.useState(
    project?.info?.goal[0]
  );
  const [setList, setSetList] = React.useState([
    {
      id: "01",
      slug: "website_created",
      title: "LangsWebsiteCreated",
      status: "done",
    },
    ...(!project?.info?.goal?.includes("SellOnline")
      ? [
          {
            id: "02",
            slug: "website_edited",
            title: "LangsEditYourWebsite",
            status: "done",
            button: {
              label: (
                <FormattedMessage
                  id="LangsEditWebsite"
                  defaultMessage={"Edit Websites"}
                />
              ),
              className:
                "relative inline-flex h-8 min-w-0 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2 overflow-hidden rounded-none border-2 border-solid border-indigo-600 bg-indigo-600 px-3 py-1 text-left text-sm font-bold text-white hover:border-indigo-800 hover:bg-indigo-800",
            },
          },

          {
            id: "03",
            slug: "publish_website",
            title: "LangsPublishYourWebsite",
            status: "done",
            button: {
              label: (
                <FormattedMessage
                  id="LangsPublishWebsite"
                  defaultMessage={"Publish Websites"}
                />
              ),
              className:
                "relative inline-flex h-8 min-w-0 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2 overflow-hidden rounded-none border-2 border-solid border-indigo-600 bg-indigo-600 px-3 py-1 text-left text-sm font-bold text-white hover:border-indigo-800 hover:bg-indigo-800",
              href: "/cms/publish",
            },
          },
        ]
      : [
          {
            id: "06",
            slug: "store_created",
            title: "LangsAddYourFirstProduct",
            status: "done",
            button: {
              label: (
                <FormattedMessage
                  id="LangsAddProduct"
                  defaultMessage={"Add Products"}
                />
              ),
              className:
                "relative inline-flex h-8 min-w-0 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2 overflow-hidden rounded-none border-2 border-solid border-indigo-600 bg-indigo-600 px-3 py-1 text-left text-sm font-bold text-white hover:border-indigo-800 hover:bg-indigo-800",
            },
          },
          {
            id: "07",
            slug: "Business Profile",
            title: "LangsCompleteYourBusinessProfile",
            status: "none",
            button: {
              label: (
                <>
                  <span>
                    <FormattedMessage
                      id="LangsGoToStore"
                      defaultMessage={"Go to Store"}
                    />
                  </span>{" "}
                  <ArrowRightIcon
                    className="white -mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </>
              ),
              className:
                "relative inline-flex h-8 min-w-0 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2 overflow-hidden rounded-none border-2 border-solid border-indigo-600 bg-indigo-600 px-3 py-1 text-left text-sm font-bold text-white hover:border-indigo-800 hover:bg-indigo-800",
              href: "/",
            },
          },

          {
            id: "08",
            slug: "publish_Store",
            title: "LangsPublishYourStore",
            status: "none",
            button: {
              label: (
                <FormattedMessage
                  id="LangsPublishStore"
                  defaultMessage={"Publish Store"}
                />
              ),
              className:
                "relative inline-flex h-8 min-w-0 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2 overflow-hidden rounded-none border-2 border-solid border-indigo-600 bg-indigo-600 px-3 py-1 text-left text-sm font-bold text-white hover:border-indigo-800 hover:bg-indigo-800",
              href: "/cms/publish",
            },
          },
        ]),
    {
      id: "04",
      slug: "custom_domain",
      title: "LangsAddCustomDomain",
      status: "none",
      button: {
        label: (
          <>
            <span>
              <FormattedMessage
                id="LangsBrowseDomain"
                defaultMessage={"Browse Domains"}
              />
            </span>{" "}
            <ArrowRightIcon
              className="white -mr-1 h-5 w-5"
              aria-hidden="true"
            />
          </>
        ),
        className:
          "relative inline-flex h-8 min-w-0 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2 overflow-hidden rounded-none border-2 border-solid border-indigo-600 bg-indigo-600 px-3 py-1 text-left text-sm font-bold text-white hover:border-indigo-800 hover:bg-indigo-800",
        href: "/",
      },
    },
    {
      id: "05",
      slug: "seo",
      title: "LangsGetFoundOnGoogle",
      status: "none",
      button: {
        label: (
          <FormattedMessage id="LangsSetUpSEO" defaultMessage={"Set up SEO"} />
        ),
        className:
          "hidden cursor-pointer whitespace-nowrap text-left font-bold leading-5 text-indigo-600 hover:underline md:inline",
        href: "/",
      },
    },
  ]);

  const [stepGoals] = React.useState([
    ...(project?.info?.goal?.includes("PromoteMyContent")
      ? [
          {
            id: "01",
            href: "#",
            title: "PromoteMyContent",
          },
        ]
      : []),
    ...(project?.info?.goal?.includes("AnnounceAnEventOrProject")
      ? [
          {
            id: "02",
            href: "#",
            title: "AnnounceAnEventOrProject",
          },
        ]
      : []),
    ...(project?.info?.goal?.includes("ShareMyPortfolio")
      ? [
          {
            id: "03",
            href: "#",
            title: "ShareMyPortfolio",
          },
        ]
      : []),
    ...(project?.info?.goal?.includes("GetHired")
      ? [
          {
            id: "04",
            href: "#",
            title: "GetHired",
          },
        ]
      : []),
    ...(project?.info?.goal?.includes("GetOnlineBookings")
      ? [
          {
            id: "05",
            href: "#",
            title: "GetOnlineBookings",
          },
        ]
      : []),
    ...(project?.info?.goal?.includes("SellOnline")
      ? [
          {
            id: "06",
            href: "#",
            title: "SellOnline",
          },
        ]
      : []),
    ...(project?.info?.goal?.includes("PromoteAPhysicalBusiness")
      ? [
          {
            id: "07",
            href: "#",
            title: "PromoteAPhysicalBusiness",
          },
        ]
      : []),
    ...(project?.info?.goal?.includes("ShowcaseMyWork")
      ? [
          {
            id: "08",
            href: "#",
            title: "ShowcaseMyWork",
          },
        ]
      : []),
    ...(project?.info?.goal?.includes("GetCustomersToContactMe")
      ? [
          {
            id: "09",
            href: "#",
            title: "GetCustomersToContactMe",
          },
        ]
      : []),
  ]);

  //check Project status and reset setList
  const checkProject = React.useCallback(() => {
    let newSetList = [...setList];
    newSetList = newSetList.map((item) => {
      if (item.slug === "publish_website") {
        item.status = project.status === "published" ? "done" : "none";
      }
      return item;
    });
    setSetList(newSetList);
    // eslint-disable-next-line
  }, [project]);

  React.useEffect(() => {
    checkProject();
  }, [checkProject]);

  return (
    <div>
      {project.status === "published" ? (
        <div className="top-img flex h-32 w-full items-center justify-center overflow-hidden bg-gray-100">
          <img
            className="h-auto w-full object-cover opacity-90"
            src={project.thumbnail}
            alt=""
          />
        </div>
      ) : null}

      <div className="px-4 py-10 sm:px-6 lg:px-8">
        {/* //header start */}
        <header className="mb-10">
          <div className="mx-auto flex max-w-7xl flex-col gap-7 sm:px-6 md:grid md:grid-cols-2  lg:px-8">
            <div className="flex space-x-5">
              {/* TODO:獲取顯示用戶LOGO start */}
              <div className="flex-shrink-0">
                <div className="relative">
                  <div className="h-16 w-16 overflow-hidden rounded-full border-2 bg-white p-1 ">
                    <img className="" src={LogoImg} alt="user logo" />
                  </div>
                  <span
                    className="absolute inset-0 rounded-full shadow-inner"
                    aria-hidden="true"
                  />
                </div>
                {/* TODO:獲取顯示用戶LOGO end */}
              </div>
              {/* TODO:獲取顯示用戶名 start*/}
              <div className="pt-1.5">
                <h1 className="text-2xl font-bold text-gray-900">
                  <FormattedMessage
                    id="LangsDashboard"
                    defaultMessage={"Dashboard"}
                  />
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  {hour > 6 && hour < 12 ? (
                    <FormattedMessage
                      id="LangsGoodMorning"
                      defaultMessage={"Good Morning"}
                    />
                  ) : hour > 12 && hour < 18 ? (
                    <FormattedMessage
                      id="LangsGoodAfternoon"
                      defaultMessage={"Good Afternoon"}
                    />
                  ) : hour > 18 && hour < 24 ? (
                    <FormattedMessage
                      id="LangsGoodEvening"
                      defaultMessage={"Good Evening"}
                    />
                  ) : (
                    <FormattedMessage
                      id="LangsGoodNight"
                      defaultMessage={"Good Night"}
                    />
                  )}
                  {/*eslint-disable-next-line*/}
                  <Link
                    to="/profile/setting"
                    className="ml-1 text-base text-gray-900"
                  >
                    {/* TODO:bug 重新登錄時email報錯登錄不上 */}
                    {user?.email && fcupper(user?.email.split("@")[0])}
                  </Link>
                </p>
              </div>
              {/* TODO:獲取顯示用戶名 end*/}
            </div>
            {/* TODO :Edit more actions website button start  */}
            <div className="mt-6 flex flex-col gap-4 md:flex-row-reverse ">
              <a
                href="/cms"
                className="flex h-12 cursor-pointer items-center justify-center justify-items-center whitespace-nowrap border-2  border-blue-600 bg-blue-600  px-5  text-sm font-bold text-white shadow-sm hover:border-[#0538d3] hover:bg-[#0538d3] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <span>
                  <FormattedMessage
                    id="LangsEditWebsite"
                    defaultMessage={"Edit website"}
                  />
                </span>
              </a>

              <Menu as="div" className="mymenu relative text-right">
                <Menu.Button className="flex h-12 w-full cursor-pointer select-none  appearance-none items-center justify-center  gap-x-1.5  whitespace-nowrap rounded-none border-2 border-solid border-blue-600 bg-white px-5 py-0 text-center text-base font-bold text-blue-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 md:w-auto ">
                  <FormattedMessage
                    id="LangsMoreActions"
                    defaultMessage={"More Actions"}
                  />

                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-blue-600"
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {Actions.map((action) => (
                      <a
                        key={action.id}
                        href={action.href}
                        className={twMerge(
                          "text-md flex gap-2 px-3 py-2 leading-6 hover:bg-gray-100",
                          action.title === "Upgrade"
                            ? "text-pink-600"
                            : " text-black"
                        )}
                      >
                        <action.icon
                          className={twMerge(
                            "h-5 w-5",
                            action.title === "Upgrade"
                              ? "text-pink-600"
                              : " text-black"
                          )}
                        />
                        <span>
                          <FormattedMessage
                            id={action.title}
                            defaultMessage={"Array:Actions.title"}
                          />
                        </span>
                      </a>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            {/* //Edit more actions website button end  */}
          </div>
        </header>
        {/* //header end */}

        {/* //main content start */}
        <main className="my_main_area">
          <div className="mx-auto flex max-w-7xl flex-col gap-7 sm:px-6 lg:px-8">
            {/* //website Stats start */}
            {/* Leo: change the grid */}
            <div className="flex flex-col gap-7">
              <div className=" flex flex-col gap-7 xl:grid xl:grid-cols-3">
                {/* TODO:website info */}
                <div className="xl:col-span-2">
                  <div className="h-full bg-white">
                    <dl className="flex flex-wrap p-5">
                      <div className="flex-auto">
                        <dt className="text-sm font-semibold leading-6 text-gray-900">
                          <FormattedMessage
                            id="LangsWebsiteInfo"
                            defaultMessage={"Website info"}
                          />
                        </dt>
                      </div>
                      <div className="relative flex items-center bg-gray-50 p-1 ">
                        <div
                          className={twMerge(
                            "mr-2 h-2 min-h-0 w-2 min-w-0 rounded-full  shadow-md shadow-black/20",
                            project.status === "published"
                              ? "bg-green-600"
                              : "bg-red-600"
                          )}
                        />
                        <dd className="text-center text-xs  font-bold uppercase text-zinc-800">
                          {project.status === "published" ? (
                            <FormattedMessage
                              id="LangsPublished"
                              defaultMessage={"Published"}
                            />
                          ) : (
                            <FormattedMessage
                              id="LangsUnpublished"
                              defaultMessage={"Unpublished"}
                            />
                          )}
                        </dd>
                      </div>

                      <div className="mb-2 mt-5 flex w-full flex-none gap-x-4 border-gray-900/5">
                        <CalendarDaysIcon
                          className="h-6 w-5 text-gray-400"
                          aria-hidden="true"
                        />

                        <dt className="text-base  font-bold leading-normal text-zinc-800">
                          <FormattedMessage
                            id="LangsDomain"
                            defaultMessage={"Domain"}
                          />
                          :
                        </dt>
                        <dd>
                          {getProjectList()} is still available
                          {/*eslint-disable-next-line*/}
                          <a
                            href="#"
                            className="max-w-full cursor-pointer truncate text-base leading-5 text-blue-600  hover:underline md:ml-2"
                          >
                            {project.status === "published" ? (
                              <FormattedMessage
                                id="LangsClaimItNow"
                                defaultMessage={"Claim it now"}
                              />
                            ) : (
                              <FormattedMessage
                                id="LangsGetADomain"
                                defaultMessage={"Get a domain"}
                              />
                            )}
                          </a>
                        </dd>
                      </div>
                      <div className="flex w-full flex-none gap-x-4">
                        <CreditCardIcon
                          className="h-6 w-5 text-gray-400"
                          aria-hidden="true"
                        />

                        <dt className="text-base  font-bold leading-normal text-zinc-800">
                          <FormattedMessage
                            id="LangsPlan"
                            defaultMessage={"Plan"}
                          />
                          :
                        </dt>
                        <dd>
                          {/* TODO:獲取plan */}
                          {project.plan}
                          {/*eslint-disable-next-line*/}
                          <a
                            href="#"
                            className={twMerge(
                              "max-w-full cursor-pointer truncate text-base leading-5  hover:text-pink-800 hover:underline md:ml-2",
                              project.status === "published"
                                ? "text-pink-600"
                                : "text-blue-600"
                            )}
                          >
                            <FormattedMessage
                              id="LangsChooseAPlan"
                              defaultMessage={"Choose a plan"}
                            />
                          </a>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                {/* //website Plans start */}
                <div className="hidden xl:col-span-1 xl:block">
                  <div className="rounded bg-pink-600 p-5">
                    <h2 className="mb-6 font-bold text-white">
                      <FormattedMessage
                        id="WebsitePlansMore"
                        defaultMessage={
                          "Unlock more pages. More features. More support."
                        }
                      />
                    </h2>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border-2 border-white px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:bg-white hover:bg-opacity-10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <FormattedMessage
                        id="LangsSeePlans"
                        defaultMessage={"See plans"}
                      />
                      <ArrowRightIcon
                        className="white -mr-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className=" flex flex-col-reverse gap-7  xl:grid xl:grid-cols-3">
                {/* //website Plans end */}

                {/* //TODO: Set website start */}
                <div className="xl:col-span-2">
                  <div className="bg-white">
                    <div className="flex flex-row  items-center justify-between gap-2 border-b border-solid border-neutral-200 p-5 text-zinc-800">
                      <p className="break-all text-lg  font-bold text-zinc-800">
                        <FormattedMessage
                          id="LangsSetUpYourWebsite"
                          defaultMessage={"Set up your website"}
                        />
                      </p>
                      <div className="flex items-center gap-3">
                        <h3 className="text-lg   font-bold text-zinc-800">
                          {
                            setList.filter((item) => item.status === "done")
                              .length
                          }
                          /{setList.length}
                        </h3>
                        <Progress
                          className="h-2 w-24 bg-gray-300"
                          value={progress.progress}
                          size="sm"
                        />
                      </div>
                    </div>
                    <div>
                      <ul className="divide-y divide-gray-100">
                        {setList.map((item) => (
                          <li
                            key={item.id}
                            className="border-none bg-gray-50 px-6"
                          >
                            {item.status === "done" ? (
                              <div className="relative flex items-center  justify-between border-b-2 border-solid border-neutral-200 py-4 text-left text-zinc-800">
                                <div className="flex flex-shrink-0 items-center gap-2">
                                  <CheckCircleIcon
                                    className="h-8 w-8 text-left text-zinc-800 "
                                    aria-hidden="true"
                                  />
                                  <p className="text-left text-base  font-normal leading-normal text-zinc-800">
                                    <FormattedMessage
                                      id={item.title}
                                      defaultMessage={"setList:setList.title"}
                                    />
                                  </p>
                                </div>
                                <div className="flex flex-shrink-0 items-center">
                                  <span className="hidden  text-left  leading-6  text-neutral-500 md:inline">
                                    <FormattedMessage
                                      id="LangsDone"
                                      defaultMessage={"Done"}
                                    />
                                  </span>
                                  <CheckIcon
                                    className="h-5 w-5 text-neutral-500"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="relative flex items-center  justify-between border-b-2 border-solid border-neutral-200 py-4 text-left text-zinc-800">
                                <div className="flex flex-shrink-0 items-center gap-2 bg-white">
                                  <MinusSmallIcon
                                    className="h-8 w-8 text-left text-zinc-800"
                                    aria-hidden="true"
                                  />
                                  <p className="text-left text-base  font-bold leading-normal text-zinc-800">
                                    <FormattedMessage
                                      id={item.title}
                                      defaultMessage={"setList:setList.title"}
                                    />
                                  </p>
                                </div>
                                <Link
                                  className={item?.button?.className}
                                  to={item?.button?.href || "/"}
                                >
                                  {item?.button?.label}
                                </Link>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Set website end */}
                {/* TODO:website visits start  */}
                <div className="rounded xl:col-span-1">
                  {project.status === "published" ? (
                    <>
                      <div className="bg-white p-5 text-zinc-800">
                        <h2 className="mb-5 font-bold">
                          <FormattedMessage
                            id="LangsLastWeek"
                            defaultMessage={"Last week"}
                          />
                        </h2>
                        <div className="flex flex-col items-start border-t border-[#efefef] pt-5">
                          {/* TODO:獲取顯示訪問數據*/}
                          <div className="mb-5 flex w-full items-center justify-between text-2xl font-bold leading-normal text-zinc-800">
                            <p className="mb-1 text-base  font-normal leading-normal text-zinc-800">
                              <FormattedMessage
                                id="LangsWebsiteVisits"
                                defaultMessage={"Website visits"}
                              />
                            </p>
                            <div className="flex items-center">
                              <span className="text-2xl  font-bold leading-normal text-zinc-800">
                                {visits.value}
                              </span>
                              <span className="pl-2  text-sm font-bold leading-normal text-green-600">
                                {visits.percentage}
                              </span>
                            </div>
                          </div>

                          <div className="mb-4 flex w-full justify-center">
                            <MyLineChart />
                          </div>
                          <button
                            type="button"
                            className="rounded-mdpx-3 mb-5 inline-flex items-center justify-center bg-pink-600 px-3 py-1 text-sm font-bold text-white shadow-sm hover:bg-pink-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <FormattedMessage
                              id="LangsSeeMoreStatistics"
                              defaultMessage={"See More Statistics"}
                            />

                            <ArrowRightIcon
                              className="white -mr-1 h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>

                          {/*eslint-disable-next-line*/}
                          <a
                            className="cursor-pointer whitespace-nowrap  text-sm font-bold leading-normal text-blue-700 hover:border-b hover:border-b-blue-700 "
                            href="#"
                          >
                            <FormattedMessage
                              id="WebsiteWhatAreStatistics"
                              defaultMessage={"What are Statistics?"}
                            />
                          </a>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="cursor-pointer rounded bg-white p-5 ">
                      {/*eslint-disable-next-line */}
                      <a className="junstify-start flex items-center gap-5">
                        <img src={unlockcustomImg} alt="" />
                        <p className=" text-sm font-normal leading-normal">
                          <FormattedMessage
                            id="PublishWebsite"
                            defaultMessage={"Publish your website to unlock"}
                          />
                          <strong>
                            <FormattedMessage
                              id="LangsCustomBranding"
                              defaultMessage={"Custom branding"}
                            />
                          </strong>
                          <FormattedMessage
                            id="ForYourDashboard"
                            defaultMessage={"for your Dashboard"}
                          />
                        </p>
                        <ChevronRightIcon className="h-5 w-5  whitespace-nowrap text-left font-bold leading-5 text-black " />
                      </a>
                    </div>
                  )}
                </div>
                {/* website visits end */}
              </div>
            </div>

            {/* //website Stats end */}

            <div className="flex items-center justify-items-center gap-5 text-zinc-800">
              <div className="h-px flex-grow bg-gray-700" />
              <span className="w-max  flex-shrink-0 text-center  text-base  font-normal leading-normal text-zinc-800">
                <FormattedMessage
                  id="DashboardDividingLine"
                  defaultMessage={"There's more you can do"}
                />
              </span>
              <div className="h-px flex-grow bg-gray-700" />
            </div>

            {/* //website Next steps start */}

            <div className="= bg-white">
              <div className="border-b border-gray-200 bg-white p-5 sm:px-6">
                <div className="flex justify-between ">
                  <div>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      {project.status === "published" ? (
                        <FormattedMessage
                          id="DashboardGoalOnlineBookings"
                          defaultMessage={"Next steps to get online bookings"}
                        />
                      ) : (
                        <FormattedMessage
                          id="DashboardGoalStepsSuccess"
                          defaultMessage={"Next steps to success"}
                        />
                      )}
                    </h3>
                    <p className=" mt-1   text-sm leading-5 text-gray-800">
                      {project.status === "published" ? (
                        <FormattedMessage
                          id="DashboardGoalRecommendations"
                          defaultMessage={
                            "Get recommendations based on your business goal"
                          }
                        />
                      ) : (
                        <FormattedMessage
                          id="DashboardGoalCheckOut"
                          defaultMessage={
                            "Check out these features to get even more out of your website."
                          }
                        />
                      )}
                    </p>
                  </div>
                  {project.status === "published" ? (
                    <Menu as="div" className="relative">
                      <Menu.Button className="relative flex h-12 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2  overflow-hidden border-2 border-solid border-blue-600 px-5 text-center text-base text-black hover:bg-blue-500 hover:bg-opacity-10">
                        <span>
                          <FormattedMessage
                            id="LangsMyGoals"
                            defaultMessage={"My goals"}
                          />
                        </span>
                        <ChevronDownIcon className="h-5 w-5 text-black" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2.5 min-w-min origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          {stepGoals.map((goal) => (
                            <button
                              key={goal.id}
                              className={twMerge(
                                "text-md flex w-full gap-2 whitespace-nowrap p-4 leading-6 text-gray-900 hover:bg-gray-100",
                                selectedGoal === goal.title && "bg-gray-100"
                              )}
                              onClick={() => setSelectedGoal(goal.title)}
                            >
                              <FormattedMessage
                                id={goal.title}
                                defaultMessage={"stepGoals:stepGoals.title"}
                              />
                            </button>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : null}
                </div>
              </div>
              <div className="my_card_area flex flex-col gap-5 p-5">
                {GoalsTools.filter((item) =>
                  item.show.includes(selectedGoal)
                ).map((toolsItem) => (
                  <div
                    className="my-card flex cursor-pointer flex-row p-5 text-zinc-800 shadow-[0_0_0_2px_#efefef] transition-shadow ease-in hover:shadow-[0_4px_16px_rgba(0,0,0,.24)]"
                    key={toolsItem.id}
                    onClick={() => (window.location.href = toolsItem.href)}
                  >
                    <div className="mr-7 flex flex-none items-center justify-center">
                      <img src={toolsItem.image} className="w-20" alt="" />
                    </div>
                    <div
                      data-immersive-translate-effect="1"
                      data-immersive_translate_walked="a9e1a97f-9a99-4a1e-be2a-cbc67ae77fea"
                      className="flex-auto  cursor-pointer text-zinc-800 "
                    >
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        <FormattedMessage
                          id={toolsItem.title}
                          defaultMessage={"stepGoals:stepGoals.title"}
                        />
                      </h3>
                      <p className="mx-0 mb-2 mt-0 cursor-pointer text-sm   font-normal leading-normal text-zinc-800">
                        <FormattedMessage
                          id={toolsItem.description}
                          defaultMessage={"stepGoals:stepGoals.description"}
                        />
                      </p>

                      <a
                        href={toolsItem.href}
                        className=" max-w-full cursor-pointer truncate bg-none  text-sm font-bold leading-5 text-blue-600 hover:underline"
                      >
                        <FormattedMessage
                          id={toolsItem.hyperlinktext}
                          defaultMessage={"stepGoals:stepGoals.hyperlinktext"}
                        />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* //website Next steps end */}

            {/* //website Tools start */}

            <div className="border-b border-gray-200 bg-white">
              <div className="border-b border-gray-200 bg-white p-5 sm:px-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  <FormattedMessage
                    id="DashboardMoreToolsTtitle"
                    defaultMessage={"Explore more tools to grow your business"}
                  />
                </h3>
              </div>

              <div className="my_card_area_grid flex flex-col gap-5 ">
                <div className="flex flex-col gap-5 p-5 md:grid md:grid-cols-2   xl:grid-cols-3 ">
                  {tools.map((tool) => (
                    <div
                      className="my-card flex  flex-auto cursor-pointer flex-row p-5   text-zinc-800 shadow-[0_0_0_2px_#efefef] transition-shadow ease-in hover:shadow-[0_4px_16px_rgba(0,0,0,.24)]"
                      key={tool.id}
                      onClick={() => (window.location.href = tool.href)}
                    >
                      <div className="mr-7 flex justify-center">
                        <tool.icon
                          className="-mr-1 h-5 w-5 text-gray-900"
                          aria-hidden="false"
                        />
                      </div>
                      <div className="cursor-pointer  text-zinc-800 ">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                          <FormattedMessage
                            id={tool.title}
                            defaultMessage={"tools:tools.title"}
                          />
                        </h3>
                        <p
                          className="mx-0 mb-2 mt-0 cursor-pointer text-sm   font-normal leading-normal text-zinc-800"
                          data-immersive-translate-effect="1"
                          data-immersive_translate_walked="a9e1a97f-9a99-4a1e-be2a-cbc67ae77fea"
                        >
                          <FormattedMessage
                            id={tool.description}
                            defaultMessage={"tools:tools.description"}
                          />
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* //website Tools end */}
          </div>
        </main>
        {/* //main content end */}
      </div>
    </div>
  );
};

export default DashboardPage;
