import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HiXMark } from "react-icons/hi2";

import type { MyNewsDrawerHandler, MyNewsDrawerProps } from "./type";
import { FormattedMessage } from "react-intl";

const firstResults = [
  {
    id: 1,
    news: true,
    date: "February 01, 2023",
    title:
      "New BuildLink ToS: Implementation of the Fair Consumer Contracts Act",
    description:
      "Here you can find all information about the upcoming changes to the BuildLink TOS.",
    image: "",
    buttonText: "Read More",
    buttonLink: "#",
  },
  {
    id: 2,
    news: true,
    date: "June 15, 2022",
    title: "Help us make BuildLink better",
    description:
      "Help shape the future of BuildLink! Become part of our Research Community to share your opinions and test new features.",
    image: "",
    buttonText: "Sign up now",
    buttonLink: "#",
  },
  {
    id: 3,
    news: true,
    date: "March 31, 2022",
    title: "Add Smart Apps to Your Website",
    description:
      "From calendars to surveys to social media, now hyou can plug your favorite platforms right into your website, with no code.",
    image:
      "https://images.unsplash.com/photo-1441015401724-70d16b783f5c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80",
    buttonText: "Read More",
    buttonLink: "#",
  },
  {
    id: 4,
    news: false,
    date: "February 01, 2023",
    title:
      "New BuildLink ToS: Implementation of the Fair Consumer Contracts Act",
    description:
      "Here you can find all information about the upcoming changes to the BuildLink TOS.",
    image: "",
    buttonText: "Read More",
    buttonLink: "#",
  },
];

const secondResults = [
  {
    id: 1,
    news: false,
    date: "December 09, 2021",
    title: "Meet Maxim: Community supporter and beekeeper in Hamburg",
    image:
      "https://images.unsplash.com/photo-1448062885262-aa6670248b0e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2669&q=80",
    description:
      "Thora, Maxim, and Amanda have something in common: They all have a main job as well as a self-employed project on the side. The three projects could't be more different. And yet they all stem from their own passion and come with similar challenges. Today, we're introducing you to Maxim, the second side hustler in [...]",
    buttonText: "Read More",
    buttonLink: "#",
  },
  {
    id: 2,
    news: false,
    date: "December 06, 2021",
    title: "GDPR: What You Should Know and How BuildLink Can Help",
    image:
      "https://images.unsplash.com/photo-1635752498868-0fd55cef5e06?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80",
    description:
      "What is the GDPR and how does it affect your website or online store? This guide helps explain what you need to know.",
    buttonText: "Read More",
    buttonLink: "#",
  },
  {
    id: 3,
    news: true,
    date: "December 01, 2021",
    title: "Website Statistics: How to Check Your Traffic",
    image:
      "https://images.unsplash.com/photo-1516383274235-5f42d6c6426d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2674&q=80",
    description:
      "Who are your website visitors and where do they come from? Find out how to quickly view your traffic, referrals, and other important numbers.",
    buttonText: "Read More",
    buttonLink: "#",
  },
  {
    id: 4,
    news: false,
    date: "December 01, 2021",
    title: "Meet Thora: Project manager and bracelet designer at Tampen Kiel",
    image:
      "https://images.unsplash.com/photo-1534349735944-2b3a6f7a268f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80",
    description:
      "Thora, Maxim, and Amanda have something in common: They all have a day job and a self-employed side business. Their three projects couldn’t be more different, yet they all stem from similar passions—and come with the same challenges. Our three-part series of side hustlers starts with Thora, a full-time product owner at our own headquarters […]",
    buttonText: "Read More",
    buttonLink: "#",
  },
];

const MyNewsDrawer: React.ForwardRefRenderFunction<
  MyNewsDrawerHandler,
  MyNewsDrawerProps
> = ({ ...props }, innerRef) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useImperativeHandle(innerRef, () => ({
    handleOpen,
    handleClose,
  }));

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden bg-gray-500 bg-opacity-40 transition duration-500 ease-in-out">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md bg-gray-100">
                    <div className="bg-whtie flex h-full flex-col shadow-xl">
                      <div className="bg-slate-900 px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="fonts-bold text-lg leading-6 text-white">
                            <FormattedMessage id="NewsDrawerTitle" />
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className=""
                              onClick={handleClose}
                            >
                              <HiXMark className="h-6 w-6 text-white hover:rounded-md hover:text-gray-200 hover:ring-2 hover:ring-indigo-500" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* background */}
                      <div className="h-full min-h-full scroll-pt-[64px] overflow-y-scroll p-4 pb-20">
                        {/* first results */}
                        <div id="firstResults">
                          {/* firstResults card */}
                          {firstResults.map((firstResult, index) => (
                            <div
                              key={index}
                              className="z-100 relative mx-auto mb-4 max-w-[800px] rounded-sm bg-white p-4 shadow-md"
                            >
                              <div className="flex flex-1 flex-col">
                                <div className="mb-2 flex w-full flex-row items-center">
                                  {firstResult.news ? (
                                    <div className="mr-2 max-w-full rounded-xl bg-slate-900 px-2 py-1.5 text-xs uppercase text-white">
                                      <FormattedMessage id="LangsNews" />!
                                    </div>
                                  ) : null}
                                  <span className="text-sm font-light text-gray-900">
                                    {firstResult.date}
                                  </span>
                                </div>
                                <h3 className="mb-3 block text-xl font-bold leading-7 text-slate-900 hover:underline">
                                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                  <a href="#" className="">
                                    {firstResult.title}
                                  </a>
                                </h3>
                                <p className="mb-1 text-base leading-6 text-gray-900">
                                  {firstResult.description}
                                </p>
                                <div className="h-auto w-full overflow-hidden shadow">
                                  <img
                                    src={firstResult.image}
                                    alt=""
                                    className="object-cover transition duration-300 ease-in hover:scale-110"
                                  />
                                </div>
                                <a
                                  href={firstResult.buttonLink}
                                  className="my-6 place-self-center text-base font-bold text-blue-800 hover:underline"
                                >
                                  {firstResult.buttonText}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                        {/* second results */}
                        <div id="secondResults">
                          {/* secondResults card */}
                          {secondResults.map((secondResult, index) => (
                            <div
                              key={index}
                              className="z-100 relative mx-auto mb-4 max-w-[800px] rounded-sm bg-white p-4 shadow-md"
                            >
                              <div className="flex flex-1 flex-col">
                                <div className="mb-2 flex w-full flex-row items-center">
                                  {secondResult.news ? (
                                    <div className="mr-2 max-w-full rounded-xl bg-slate-900 px-2 py-1.5 text-xs uppercase text-white">
                                      <FormattedMessage id="LangsNews" />!
                                    </div>
                                  ) : null}
                                  <span className="text-sm font-light text-gray-900">
                                    {secondResult.date}
                                  </span>
                                </div>
                                <h3 className="mb-3 block text-xl font-bold leading-7 text-slate-900 hover:underline">
                                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                  <a href="#" className="">
                                    {secondResult.title}
                                  </a>
                                </h3>
                                <div className="mb-4 h-auto w-full overflow-hidden shadow">
                                  <img
                                    src={secondResult.image}
                                    alt=""
                                    className="object-cover transition duration-300 ease-in hover:scale-110"
                                  />
                                </div>
                                <p className="mb-1 text-base leading-6 text-gray-900">
                                  {secondResult.description}
                                </p>
                                <a
                                  href={secondResult.buttonLink}
                                  className="my-6 place-self-center text-base font-bold text-blue-800 hover:underline"
                                >
                                  {secondResult.buttonText}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default React.forwardRef(MyNewsDrawer);
