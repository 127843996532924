import React from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { SidemenuProps } from "./type";

//submenu
import HomeMenu from "./HomeMenu";
import CmsSidememu from "./CmsSidemenu";
import StatisticMemu from "./StatisticMenu";
import ContactMenu from "./ContactMenu";
import MarketingMenu from "./MarketingMenu";
import EmailMenu from "./EmailMenu";
import SettingMenu from "./SettingMenu";

import Logo from "../../media/svg/logo/logo.svg";

type SidemenuContextType = {
  menuOpen: string;
  setMenuOpen: React.Dispatch<React.SetStateAction<string>>;
};

export const SidemenuContext = React.createContext<SidemenuContextType>({
  menuOpen: "home",
  setMenuOpen: () => { },
});

const Sidemenu: React.FC<SidemenuProps> = ({ selected = "home" }) => {
  const [menuOpen, setMenuOpen] = React.useState(selected);

  React.useEffect(() => {
    setMenuOpen(selected);
  }, [selected]);

  return (
    <SidemenuContext.Provider value={{ menuOpen, setMenuOpen }}>
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
        <div className="mt-2 flex h-16 shrink-0 items-center">
          <Link to="/">
            <img className="h-8 w-auto" src={Logo} alt="Buildlink" />
          </Link>
        </div>
        <Transition
          show={menuOpen === "home" || menuOpen === "project"}
          enter="transition ease-in-out transform duration-150"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out transform duration-150"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          {(menuOpen === "home" || menuOpen === "project") && <HomeMenu />}
        </Transition>
        <Transition
          show={menuOpen === "cms"}
          enter="transition ease-in-out transform duration-150"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out transform duration-150"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          {menuOpen === "cms" && <CmsSidememu />}
        </Transition>
        <Transition
          show={menuOpen === "statistic"}
          enter="transition ease-in-out transform duration-150"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out transform duration-150"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          {menuOpen === "statistic" && <StatisticMemu />}
        </Transition>
        <Transition
          show={menuOpen === "contacts"}
          enter="transition ease-in-out transform duration-150"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out transform duration-150"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          {menuOpen === "contacts" && <ContactMenu />}
        </Transition>
        <Transition
          show={menuOpen === "marketing"}
          enter="transition ease-in-out transform duration-150"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out transform duration-150"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          {menuOpen === "marketing" && <MarketingMenu />}
        </Transition>
        <Transition
          show={menuOpen === "email"}
          enter="transition ease-in-out transform duration-150"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out transform duration-150"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          {menuOpen === "email" && <EmailMenu />}
        </Transition>
        <Transition
          show={menuOpen === "setting"}
          enter="transition ease-in-out transform duration-150"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out transform duration-150"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          {menuOpen === "setting" && <SettingMenu />}
        </Transition>
      </div>

      {/*OLD*/}
    </SidemenuContext.Provider>
  );
};

export default Sidemenu;
