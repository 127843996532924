import React from "react";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import {
  InformationCircleIcon,
  XMarkIcon,
  CheckIcon,
  ArrowSmallLeftIcon,
} from "@heroicons/react/20/solid";

import { WizardContext } from "./index";
import Tooltip from "../../components/Tooltip";

import logo from "../../media/svg/logo/logo.svg";
//model
import * as ProjectModel from "../../models/Project";
import * as DomainModel from "../../models/Domain";
//store action
import { projectActions, wizardActions } from "../../stores/actions";

type Props = ConnectedProps<typeof connector>;

const UpgradePage: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const project = useSelector(({ project }) => project);
  const formData = useSelector(({ wizard }) => wizard);
  const { activeStep, setActiveStep } = React.useContext(WizardContext);
  const [domain, setDomain] = React.useState(
    (project?.info?.name || "") + ".com"
  );
  const [domainAvailable, setDomainAvailable] = React.useState(false);
  const [availableDomains, setAvailableDomains] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const addProject = React.useCallback(
    async (callback: () => void) => {
      try {
        const resp = await ProjectModel.add({
          userId: user?.sub,
          info: formData,
          plan: "free",
          status: "unpublish",
        });
        const { data } = resp;
        dispatch(props.filledProject(data.Items[0]));
        dispatch(props.resetWizard());
        if (callback) callback();
      } catch (error) {
        console.log(error);
      }
    },
    [formData, user?.sub, dispatch, props]
  );

  const requestDomainAvailable = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await DomainModel.getAvailable({
        domain,
      });
      const { data } = resp;
      setDomainAvailable(data?.Availability === "AVAILABLE");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    //eslint-disable-next-line
  }, [domain]);

  const requestSuggestion = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await DomainModel.getSuggestion({
        domain,
      });
      const { data } = resp;
      setAvailableDomains(data?.SuggestionsList || []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    //eslint-disable-next-line
  }, [domain]);

  const handleSearch = () => {
    requestDomainAvailable();
    requestSuggestion();
  };

  const handleKeyUp = (e: any) => {
    if (e.keyCode !== 13) return;
    handleSearch();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
  };

  const handleUpgrade = () => {
    addProject(() => {
      navigate("/upgrade/package-selection");
    });
  };

  const handleCreateFreeWebsite = () => {
    addProject(() => {
      navigate("/cms");
    });
  };

  React.useEffect(() => {
    requestDomainAvailable();
    requestSuggestion();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex flex-1 flex-col">
        {/* // header section */}
        <header className="sticky top-0 z-10 bg-gray-50 shadow">
          <nav
            className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex flex-1">
              <div className="flex flex-1 justify-start">
                {/*eslint-disable-next-line*/}
                <button
                  className="flex flex-row items-center gap-1 text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600"
                  onClick={handleBack}
                >
                  <ArrowSmallLeftIcon className="h-7 w-auto text-gray-900 hover:text-indigo-600" />
                  <FormattedMessage id="LangsBack" defaultMessage="Back" />
                </button>
              </div>
            </div>
            {/*eslint-disable-next-line*/}
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">
                <FormattedMessage
                  id="LangsYourCompany"
                  defaultMessage="Your Company"
                />
              </span>
              <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
            </a>
            <div className="flex flex-1 justify-end">
              <Tooltip
                placement="bottom-start"
                content={
                  <div className="w-80 whitespace-normal break-normal p-2">
                    <h3 className="text-lg font-medium text-white">
                      <FormattedMessage id="LangsBuildLinkWizard" />
                    </h3>
                    <p className="text-sm font-normal text-white opacity-80">
                      <ul className="flex flex-col">
                        <li className="mt-1 flex">
                          1.
                          <FormattedMessage
                            id="UpgradeList1"
                            defaultMessage={
                              "Choose a domain that fits your brand name best"
                            }
                          />
                        </li>
                        <li className="flex">
                          2.
                          <FormattedMessage
                            id="UpgradeList2"
                            defaultMessage={"Select a premium plan, or"}
                          />
                        </li>
                        <li className="flex">
                          3.
                          <FormattedMessage
                            id="UpgradeList3"
                            defaultMessage={
                              "Start for free with a Buildlink domain"
                            }
                          />
                        </li>
                        <li className="flex">
                          4.
                          <FormattedMessage
                            id="UpgradeList4"
                            defaultMessage={"Build and edit your website"}
                          />
                        </li>
                      </ul>
                    </p>
                  </div>
                }
              >
                <QuestionMarkCircleIcon
                  strokeWidth={2}
                  className="h-5 w-5 cursor-pointer text-slate-500"
                />
              </Tooltip>
            </div>
          </nav>
        </header>

        {/* // Title section */}
        <div className="m-auto flex min-h-full w-full max-w-7xl flex-1 flex-col items-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="max-w-2xl">
            <h1 className="text-center text-2xl font-extrabold tracking-wider text-zinc-800 sm:text-4xl">
              <FormattedMessage
                id="UpgradeGoPremiumTitle"
                defaultMessage="We offer domains with all our premium plans"
              />
            </h1>
            <h3 className="mt-4 text-center text-base font-normal text-zinc-800">
              <FormattedMessage
                id="UpgradeGoPremiumDesc"
                defaultMessage="Go premium. Choose a domain and select a paid plan. Your domain will be free within the first term. After renewal it will cost only $20 per year."
              />
            </h3>
          </div>

          {/* // Buy Domain form */}
          <div className="mt-12 w-full rounded-md bg-white px-4 py-8 leading-6 sm:p-8">
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-12 items-center gap-6">
                <div className="col-span-full sm:col-span-10">
                  <div className="relative flex rounded-md shadow-sm">
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 py-2.5 text-gray-500 sm:text-sm">
                      http://
                    </span>
                    <input
                      type="text"
                      name="company-website"
                      id="company-website"
                      className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-lg font-extrabold text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-base placeholder:font-normal placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="www.example.com"
                      value={domain}
                      onKeyUp={handleKeyUp}
                      onChange={handleChange}
                    />

                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <XMarkIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-full sm:col-span-2">
                  <button
                    type="button"
                    className=" w-full  rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleSearch}
                  >
                    <FormattedMessage
                      id="LangsSearch"
                      defaultMessage="Search"
                    />
                  </button>
                </div>
              </div>
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-gray-700"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex-1 md:flex">
                  <q className="text-sm text-gray-900">
                    <span className="font-extrabold">
                      <FormattedMessage
                        id="UpgradeShortUnique"
                        defaultMessage="Short, unique,"
                      />
                    </span>
                    <FormattedMessage id="LangsAnd" defaultMessage={"and"} />{" "}
                    <span className="font-extrabold">
                      <FormattedMessage
                        id="LangsEasyToRemember"
                        defaultMessage={"easy to remember"}
                      />
                    </span>
                    –
                    <FormattedMessage
                      id="UpgradeGreatDomain"
                      defaultMessage={
                        "that what makes a great domain and it helps visitors find your site faster."
                      }
                    />
                  </q>
                </div>
              </div>
            </div>
            <div className="mt-8 w-full border-t border-gray-300" />
            <ul className="divide-y divide-gray-200">
              {isLoading ? (
                <li className="px-4 py-8 sm:px-0">
                  <div className="md:flex md:justify-between">
                    <div className="flex flex-col">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-orange-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 flex gap-2">
                          <p className="text-base font-normal leading-normal text-gray-900">
                            Loading ...
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 flex gap-2 text-sm md:ml-6">
                      {/*eslint-disable-next-line*/}
                      <div className="rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-green-600/20">
                        Please Waiting
                      </div>
                    </p>
                  </div>
                </li>
              ) : (
                <li className="px-4 py-8 sm:px-0">
                  <div className="md:flex md:justify-between">
                    <div className="flex flex-col">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          {domainAvailable ? (
                            <CheckIcon
                              className="h-5 w-5 text-green-600"
                              aria-hidden="true"
                            />
                          ) : (
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-orange-600"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                        <div className="ml-3 flex gap-2">
                          <p className="text-base font-normal leading-normal text-gray-900">
                            {domain}
                          </p>
                          {domainAvailable ? (
                            <div className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              <FormattedMessage
                                id="LangsAvailable"
                                defaultMessage={"available"}
                              />
                            </div>
                          ) : (
                            <div className="rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                              <FormattedMessage
                                id="LangsNotAvailable"
                                defaultMessage={"Not available"}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {!domainAvailable && (
                        <p className="mt-1 text-sm font-normal leading-normal text-yellow-700">
                          <FormattedMessage
                            id="UpgradeDomainAlready"
                            defaultMessage={
                              "This domain is already claimed-is it yours?"
                            }
                          />
                        </p>
                      )}
                    </div>
                    <p className="mt-3 flex gap-2 text-sm md:ml-6">
                      {/*eslint-disable-next-line*/}
                      <button
                        onClick={handleUpgrade}
                        className="whitespace-nowrap font-extrabold text-gray-900 hover:border-b-2 hover:text-blue-600"
                      >
                        {!domainAvailable ? (
                          <FormattedMessage
                            id="LangsTransferDomain"
                            defaultMessage={"Yes, I want to transfer it"}
                          />
                        ) : (
                          <FormattedMessage
                            id="LangsRegisterDomain"
                            defaultMessage={"Get it now"}
                          />
                        )}
                        <span aria-hidden="true"> &rarr;</span>
                      </button>
                    </p>
                  </div>
                </li>
              )}
              <div className="w-full border-t border-gray-200" />
            </ul>
            <ul className="divide-y divide-gray-200">
              {isLoading ? (
                <>
                  {[0, 1, 2, 3, 4].map((key) => (
                    <li key={key} className="px-4 py-8 sm:px-0">
                      <div className="md:flex md:justify-between">
                        <div className="flex flex-col">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-orange-600"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3 flex gap-2">
                              <p className="text-base font-normal leading-normal text-gray-900">
                                Loading ...
                              </p>
                            </div>
                          </div>
                        </div>
                        <p className="mt-3 flex gap-2 text-sm md:ml-6">
                          {/*eslint-disable-next-line*/}
                          <div className="rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-green-600/20">
                            Please Waiting
                          </div>
                        </p>
                      </div>
                    </li>
                  ))}
                </>
              ) : (
                <>
                  {availableDomains.map(
                    (availableDomain: any, index: number) =>
                      index < 5 && (
                        <li key={index} className="px-4 py-8 sm:px-0">
                          {/* content */}
                          <div className="flex">
                            <div className="flex-shrink-0">
                              {availableDomain?.Availability === "AVAILABLE" ? (
                                <CheckIcon
                                  className="h-5 w-5 text-green-600"
                                  aria-hidden="true"
                                />
                              ) : (
                                <ExclamationCircleIcon
                                  className="h-5 w-5 text-orange-600"
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                              <div className="flex gap-2">
                                <p className="text-base font-normal leading-normal text-gray-900">
                                  {availableDomain?.DomainName}
                                </p>
                                {availableDomain?.Availability ===
                                "AVAILABLE" ? (
                                  <div className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                    <FormattedMessage
                                      id="LangsAvailable"
                                      defaultMessage={"available"}
                                    />
                                  </div>
                                ) : (
                                  <div className="rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                                    <FormattedMessage
                                      id="LangsNotAvailable"
                                      defaultMessage={"Not available"}
                                    />
                                  </div>
                                )}
                              </div>
                              <p className="mt-3 flex gap-2 text-sm md:ml-6 md:mt-0">
                                {/*eslint-disable-next-line*/}
                                <button
                                  className="whitespace-nowrap font-extrabold text-gray-900 hover:border-b-2 hover:text-blue-600"
                                  onClick={handleUpgrade}
                                >
                                  {availableDomain?.Availability !==
                                  "AVAILABLE" ? (
                                    <FormattedMessage
                                      id="LangsTransferDomain"
                                      defaultMessage={
                                        "Yes, I want to transfer it"
                                      }
                                    />
                                  ) : (
                                    <FormattedMessage
                                      id="LangsRegisterDomain"
                                      defaultMessage={"Get it now"}
                                    />
                                  )}
                                  <span aria-hidden="true"> &rarr;</span>
                                </button>
                              </p>
                            </div>
                          </div>
                        </li>
                      )
                  )}
                </>
              )}
              <div className="w-full border-t border-gray-200" />
            </ul>
            <div className="">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg font-black leading-6 text-gray-900">
                  <FormattedMessage id="UpgradeNoWorries" />
                </h3>
                <div className="mt-2 max-w-3xl text-sm text-gray-900">
                  <p>
                    <FormattedMessage id="LangsDoYouWant" />{" "}
                    <span className="font-extrabold">
                      <FormattedMessage id="UpgradeSEOAssistant" />
                    </span>
                    <FormattedMessage id="UpgradeOtherPremium" />
                  </p>
                </div>
                <div className="mt-5">
                  <button
                    type="button"
                    className="inline-flex w-full place-content-center items-center rounded-md border-2 border-indigo-500 bg-white px-3 py-2 text-sm font-semibold text-indigo-500 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 sm:w-auto"
                  >
                    <FormattedMessage id="UpgradeChoosePremium" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <p className="m-5 text-base font-normal leading-normal text-gray-900">
            <FormattedMessage id="LangsOr" defaultMessage={"or"} />
          </p>
          {/* // free website section */}
          <div className="w-full rounded-md bg-white px-4 py-8 leading-6 sm:p-8">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-2xl font-extrabold leading-normal text-gray-900">
                <FormattedMessage
                  id="UpgradeContinueForFree"
                  defaultMessage={
                    "Continue for free to explore our product first"
                  }
                />
              </h3>
              <div className="mt-2 max-w-xl">
                <p className="text-sm text-gray-900">
                  <FormattedMessage
                    id="UpgradeCreateWebsite"
                    defaultMessage={"Create your website with"}
                  />
                  <span className="font-extrabold">
                    https://buildlink.com/{project.domain}
                  </span>
                  <FormattedMessage
                    id="UpgradeDomainToGetStarted"
                    defaultMessage="as your domain to get started and to explore our product. But don’t wait too long: you need to book a domain to make sure no one else can use it."
                  />
                </p>
              </div>
              <div className="mt-3 text-base leading-6">
                <button
                  type="button"
                  className="inline-flex w-full place-content-center items-center rounded-md border-2 border-indigo-500 bg-white px-3 py-2 text-sm font-semibold text-indigo-500 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 sm:hidden sm:w-auto"
                >
                  <FormattedMessage
                    id="LangsStartFree"
                    defaultMessage="Start Free"
                  />
                </button>
                <button
                  onClick={handleCreateFreeWebsite}
                  className="hidden font-semibold text-indigo-600 hover:text-indigo-500 sm:inline-flex"
                >
                  <FormattedMessage
                    id="UpgradeCreateFreeWebsite"
                    defaultMessage="Create your free website and start editing"
                  />

                  <span aria-hidden="true"> &rarr;</span>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-20 w-full border-t border-gray-300" />

          {/* // Legal information section */}
          <div className="mt-7">
            <h3 className="text-lg font-bold text-gray-900">
              <FormattedMessage id="LangsLegalInformation" />
            </h3>

            <p className="mt-6 text-sm font-normal leading-normal text-gray-500">
              <FormattedMessage id="LangsPricesIncludeTax" />
            </p>
            <p className="mt-2 text-sm font-normal leading-normal text-gray-500">
              <FormattedMessage id="UpgradeBuildLinkDolphin" />
            </p>

            <div className="mt-2 w-full border-t border-gray-300" />
            <div className="mt-6 flex flex-col gap-3 sm:flex-row sm:gap-6">
              {/*eslint-disable-next-line*/}
              <a
                href="#"
                className="font-base text-gray-500 hover:text-indigo-500"
              >
                <FormattedMessage id="LangsTermsOfUse" />
              </a>
              {/*eslint-disable-next-line*/}
              <a
                href="#"
                className="font-base text-gray-500 hover:text-indigo-500"
              >
                <FormattedMessage id="LangsContact" />
              </a>
              {/*eslint-disable-next-line*/}
              <a
                href="#"
                className="font-base text-gray-500 hover:text-indigo-500"
              >
                <FormattedMessage id="LangsRefundPolicyAndForm" />
              </a>
              {/*eslint-disable-next-line*/}
              <a
                href="#"
                className="font-base text-gray-500 hover:text-indigo-500"
              >
                <FormattedMessage id="LangsAbout" />
              </a>
              {/*eslint-disable-next-line*/}
              <a
                href="#"
                className="font-base text-gray-500 hover:text-indigo-500"
              >
                <FormattedMessage id="LangsPrivacyPolicy" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...wizardActions, ...projectActions });

export default connector(UpgradePage);
