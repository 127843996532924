import { WIZARD_EDIT, WIZARD_RESET } from "./actions";

import type { Reducer } from "redux";
import type { StoreAction, WizardStateType } from "./type";

const initWizardState = {
  size: undefined,
  purpose: undefined,
  name: undefined,
  business_category: undefined,
  goal: [],
  topic: undefined,
  style: undefined,
  pages: [],
  color: undefined,
};

export const reducer: Reducer<WizardStateType, StoreAction> = (
  state = initWizardState,
  action
) => {
  switch (action.type) {
    case WIZARD_EDIT: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }

    case WIZARD_RESET: {
      return {
        ...initWizardState,
      };
    }

    default: {
      return state;
    }
  }
};
