import React from "react";
import { HiOutlineDuplicate, HiOutlineTrash } from "react-icons/hi";
import { AiOutlineLeft, AiOutlineRight, AiOutlinePlus } from "react-icons/ai";

import useEventListener from "./hooks/useEventListener";
import useEditorGrid from "./hooks/useEditorGrid";

import type { GridToolbarProps } from "./type";
import Tooltip from "../../Tooltip";
import MediaDialog from "./media/index";
import { MediaDialogHandler } from "./media/type";
import { FormattedMessage } from "react-intl";

const GridToolbar: React.FC<GridToolbarProps> = ({ root, splide }) => {
  const { addEvent } = useEventListener({ splide });
  const { handleClickOut } = useEditorGrid();

  const ref = React.useRef<HTMLDivElement>(null);
  const dialogRef = React.useRef<MediaDialogHandler>(null);

  const [enableDelete, setEnableDelete] = React.useState(true);
  const [enableLeft, setEnableLeft] = React.useState(true);
  const [enableRight, setEnableRight] = React.useState(true);
  const [adding, setAdding] = React.useState(false);

  const initGridEditor = (grid: HTMLElement) => {
    if (!grid) return;
    addEvent(grid, { isSlide: true });
  };

  const handleDuplicate = () => {
    if (!ref.current) return;
    const holder = ref.current.parentNode as HTMLElement;
    const figure = holder.parentNode as HTMLElement;
    const container = figure.parentNode as HTMLElement;
    const newFigure = figure.cloneNode(true) as HTMLElement;
    newFigure.querySelector("[data-holder]")?.remove();
    container.appendChild(newFigure);
    //if splide section-carousel
    if (splide) {
      const originStyle = splide.root.getAttribute("style");
      newFigure.classList.add("splide__slide");
      splide.add(newFigure);
      splide.refresh();
      if (originStyle) splide.root.setAttribute("style", originStyle);
    }
    initGridEditor(newFigure);
  };

  const handleDelete = () => {
    if (!ref.current) return;
    const holder = ref.current.parentNode as HTMLElement;
    const figure = holder.parentNode as HTMLElement;
    figure.remove();
    //if splide section-carousel
    if (splide) {
      const originStyle = splide.root.getAttribute("style");
      splide.refresh();
      if (originStyle) splide.root.setAttribute("style", originStyle);
    }
  };

  const handleMoveLeft = () => {
    if (!ref.current) return;
    const holder = ref.current.parentNode as HTMLElement;
    const figure = holder.parentNode as HTMLElement;
    const container = figure.parentNode as HTMLElement;
    if (!container) return;
    const prevFigure = figure.previousSibling as HTMLElement;
    if (!prevFigure) return;
    container.insertBefore(figure, prevFigure);

    //if splide section-carousel
    if (splide) {
      const originStyle = splide.root.getAttribute("style");
      splide.refresh();
      if (originStyle) splide.root.setAttribute("style", originStyle);
    }
  };

  const handleMoveRight = () => {
    if (!ref.current) return;
    const holder = ref.current.parentNode as HTMLElement;
    const figure = holder.parentNode as HTMLElement;
    const container = figure.parentNode as HTMLElement;
    if (!container) return;
    const nextFigure = figure.nextSibling as HTMLElement;
    if (!nextFigure) return;
    container.insertBefore(nextFigure, figure);

    //if splide section-carousel
    if (splide) {
      const originStyle = splide.root.getAttribute("style");
      splide.refresh();
      if (originStyle) splide.root.setAttribute("style", originStyle);
    }
  };

  const handleStartAdd = () => {
    setAdding(true);
    dialogRef.current?.handleOpen();
  };

  const handleAddItem = (imageUrl: string) => {
    if (!ref.current) return;
    const holder = ref.current.parentNode as HTMLElement;
    const figure = holder.parentNode as HTMLElement;
    const container = figure.parentNode as HTMLElement;
    if (!container) return;
    const templateFigure = container.querySelector(".template") as HTMLElement;
    if (!templateFigure) return;
    const newFigure = templateFigure.cloneNode(true) as HTMLElement;
    newFigure.classList.remove("template");
    newFigure.classList.remove("!hidden");
    newFigure.classList.add("editor-grids-item");
    container.appendChild(newFigure);
    //trigger .editor-image click
    const editorImage = newFigure.querySelector(
      ".editor-image img"
    ) as HTMLImageElement;
    if (editorImage) editorImage.src = imageUrl;

    //if in splide section-carousel
    if (splide) {
      const originStyle = splide.root.getAttribute("style");
      newFigure.classList.add("splide__slide");
      splide.add(newFigure);
      splide.refresh();
      if (originStyle) splide.root.setAttribute("style", originStyle);
    }
    initGridEditor(newFigure);
  };

  const handleOut = React.useCallback(
    (event: MouseEvent) => {
      if (adding) return;
      handleClickOut(event.target as HTMLElement, root);
    },
    [handleClickOut, root, adding]
  );

  React.useEffect(() => {
    if (!ref.current) return;
    const holder = ref.current.parentElement as HTMLElement;
    const figure = holder.parentElement as HTMLElement;
    if (!figure) return;
    const container = figure.parentElement as HTMLElement;
    figure.addEventListener("mouseleave", handleOut);

    // set enable delete
    if (container) {
      const i = container.querySelectorAll(
        ".editor-grids-item, .editor-grid-item"
      ) as NodeList;
      if (i.length <= 2) {
        setEnableDelete(false);
      } else {
        setEnableDelete(true);
      }
    }
    // set enable left and right
    const allItem = container.querySelectorAll(
      ".editor-grids-item, .editor-grid-item"
    ) as NodeList;
    const index = Array.from(allItem).indexOf(figure);
    setEnableLeft(!(index === 0));
    setEnableRight(!(index === allItem.length - 1));

    return () => {
      figure.removeEventListener("mouseleave", handleOut);
    };
  }, [ref, handleOut]);

  return (
    <div
      ref={ref}
      className="editor-toolbar absolute left-0 top-0 z-50 flex -translate-y-[150%] flex-row text-black drop-shadow-xl"
    >
      <MediaDialog
        ref={dialogRef}
        onInsert={handleAddItem}
        onClose={() => setAdding(false)}
      />
      <Tooltip
        content={<FormattedMessage id="LangsDuplicate" />}
        placement="top"
      >
        <button
          type="button"
          className="flex h-12 w-12 items-center justify-center bg-gray-500 p-1 text-white hover:bg-gray-400"
          onClick={handleDuplicate}
        >
          <HiOutlineDuplicate fontSize={24} />
        </button>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsDelete" />} placement="top">
        <button
          type="button"
          className="flex h-12 w-12 items-center justify-center bg-gray-500 p-1 text-white hover:bg-gray-400 disabled:bg-gray-200"
          onClick={handleDelete}
          disabled={!enableDelete}
        >
          <HiOutlineTrash fontSize={24} />
        </button>
      </Tooltip>
      <Tooltip
        content={<FormattedMessage id="LangsMoveLeft" />}
        placement="top"
      >
        <button
          type="button"
          className="flex h-12 w-12 items-center justify-center bg-gray-500 p-1 text-white hover:bg-gray-400 disabled:bg-gray-200"
          onClick={handleMoveLeft}
          disabled={!enableLeft}
        >
          <AiOutlineLeft fontSize={24} />
        </button>
      </Tooltip>
      <Tooltip
        content={<FormattedMessage id="LangsMoveRight" />}
        placement="top"
      >
        <button
          type="button"
          className="flex h-12 w-12 items-center justify-center bg-gray-500 p-1 text-white hover:bg-gray-400 disabled:bg-gray-200"
          onClick={handleMoveRight}
          disabled={!enableRight}
        >
          <AiOutlineRight fontSize={24} />
        </button>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsAddItem" />} placement="top">
        <button
          type="button"
          className="ml-4 flex h-12 w-12 items-center justify-center bg-gray-500 p-1 text-white hover:bg-gray-400"
          onClick={handleStartAdd}
        >
          <AiOutlinePlus fontSize={24} />
        </button>
      </Tooltip>
    </div>
  );
};

export default GridToolbar;
