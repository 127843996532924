// layouts

// pages
import PackageSelectionPage from "../pages/Upgrade/PackageSelectionPage";

const UpgradeRoutes = [
  {
    path: "/upgrade/package-selection/",
    element: <PackageSelectionPage />,
  },
];

export default UpgradeRoutes;
