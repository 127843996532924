import React from "react";
import { useSelector, connect, ConnectedProps, useDispatch } from "react-redux";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import Progress from "../../components/Progress";
//image
import logo from "../../media/svg/logo/logo.svg";
//context
import { WizardContext } from "./index";
//store
import { wizardActions } from "../../stores/actions";
//model
import * as PexelsModel from "../../models/Pexels";
import { FormattedMessage } from "react-intl";
//image
import topic_image from "../../media/svg/wizard/topic/topic-image.png";

type Props = ConnectedProps<typeof connector>;

const TopicPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector(({ wizard }) => wizard);
  const { activeStep, setActiveStep } = React.useContext(WizardContext);
  const [query, setQuery] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [topics, setTopics] = React.useState([]);
  const [selectedTopic, setSelectedTopic] = React.useState<any>(formData.topic);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSearch = (e: any) => {
    if (e.keyCode !== 13) return;
    setQuery(e.target.value);
    requestPexels();
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    dispatch(props.editWizard("topic", "skip"));
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    if (!selectedTopic?.word) return;
    dispatch(props.editWizard("topic", selectedTopic.word));
    setActiveStep(activeStep + 1);
  };

  const _getTopics = React.useCallback(() => {
    let categories = formData.business_category || "";
    if (categories === "skip" || categories === "") {
      categories = "life style";
    }
    if (query) categories = query;
    return categories;
    //eslint-disable-next-line
  }, [formData.business_category, query]);

  const requestPexels = React.useCallback(async () => {
    setIsLoading(true);

    const promise = PexelsModel.getTopics({
      word: _getTopics(),
    });

    try {
      const resp = await promise;
      const { data } = resp;
      setTopics(data);
      setSelectedTopic(data.find((item: any) => item.word === formData.topic));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }

    return promise;
  }, [_getTopics, formData.topic]);

  React.useEffect(() => {
    requestPexels();
  }, [requestPexels]);

  return (
    <>
      <div className="tex-black m-0 min-h-screen overflow-auto p-0 leading-6">
        {/* // header */}
        <div className="min-h-screen overflow-auto bg-gray-50 transition">
          <header
            className="fixed left-0 top-0 z-10 m-0 flex h-[88px] w-full items-center justify-center border-b border-solid border-gray-200 bg-gray-50 bg-opacity-[0.9] p-0 leading-6 text-black transition md:w-1/2"
            aria-label="Global"
          >
            <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
          </header>

          {/* // main content Group */}
          <div className="relative flex min-h-screen w-full flex-col bg-gray-50 pb-24 leading-6 text-black transition md:w-1/2">
            <div className="relative m-0 mt-[88px] flex flex-1 flex-col p-0 leading-6 text-black">
              {/* // main content */}
              <div className="mx-auto my-0 w-full max-w-[660px] px-2 py-0 leading-6 text-black ">
                {/* Title */}
                <h1 className="mx-1 mb-8 mt-10 p-0 text-center text-2xl font-bold leading-[1.2] text-black md:mb-16 md:mt-20 md:text-[4.8vh]">
                  <FormattedMessage id="wizardTopicTitle" />
                </h1>
                {/* // search form */}
                <div className="relative mb-4 leading-6 text-black">
                  <input
                    type="text"
                    placeholder="Search for a Topic"
                    autoComplete="organization"
                    className="h-20 w-full cursor-text appearance-none border-0 border-b-2 px-5 text-lg font-bold outline-0 transition placeholder:text-lg placeholder:text-neutral-300 hover:bg-zinc-100 focus:bg-zinc-100 sm:text-2xl placeholder:sm:text-2xl"
                    onKeyUp={handleSearch}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                  />

                  <div className="absolute bottom-0 left-0 m-0 block h-px w-full rounded-none bg-zinc-800 p-0 leading-6 text-black transition" />
                </div>
                {/* // input form */}
                {isLoading && (
                  <div className="mt-4 grid grid-cols-1 gap-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    {[0, 1, 2, 3, 4, 5].map((i) => (
                      <div
                        key={i}
                        className="relative flex cursor-pointer rounded-none border bg-gray-300 pt-[100%] shadow-sm focus:outline-none "
                      ></div>
                    ))}
                  </div>
                )}
                {!isLoading && (
                  <RadioGroup value={selectedTopic} onChange={setSelectedTopic}>
                    <div className="mt-4 grid grid-cols-2 gap-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                      {topics &&
                        topics.map((topic: any) => (
                          <RadioGroup.Option
                            key={topic.word}
                            value={topic}
                            className={({ checked }) =>
                              classNames(
                                checked ? "box-selected" : "",
                                "wizard topic box box-hover"
                              )
                            }
                          >
                            {({ checked }) => (
                              <>
                                {topic.photo?.src?.tiny ? (
                                  <img
                                    src={topic?.photo?.src?.tiny}
                                    alt={topic.word}
                                    className="absolute inset-0 h-full w-full rounded-none object-cover"
                                  />
                                ) : (
                                  <div className="absolute inset-0 h-full w-full rounded-none bg-gray-300"></div>
                                )}

                                <span className="absolute bottom-0 left-0 z-10 w-full ">
                                  <RadioGroup.Label
                                    as="span"
                                    className="wizard topic text-stroke block  p-4 text-2xl font-bold text-white shadow"
                                  >
                                    {topic.word}
                                  </RadioGroup.Label>
                                </span>
                                {checked ? (
                                  <CheckCircleIcon
                                    className="wizard topic checkIcon"
                                    aria-hidden="true"
                                  />
                                ) : null}
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      {(!topics || topics.length === 0) && !isLoading && (
                        <div className="relative flex cursor-pointer rounded-none border border-gray-300 bg-white pt-[100%] shadow-sm focus:outline-none">
                          <div className="absolute inset-0 flex h-full w-full flex-col items-center justify-center">
                            <h3 className="font-bold text-indigo-600">
                              <FormattedMessage id="TopicNoMatches" />
                            </h3>
                            <p className="text-sm text-gray-300">
                              <FormattedMessage id="TopicStickWith" />"{query}"
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </RadioGroup>
                )}
              </div>
            </div>
          </div>

          <div className="fixed bottom-[84px] flex w-full items-center transition md:w-1/2">
            <Progress className="bg-gray-300/20" value={60} size="sm" />
            <div className="fixed bottom-0 flex w-full items-center justify-between bg-gray-50 bg-opacity-[0.9] p-5 leading-6 transition md:w-1/2">
              <button
                type="button"
                className="inline-flex border-2 border-indigo-600 bg-gray-50 px-5 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleBack}
              >
                <FormattedMessage id="LangsBack" />
              </button>
              <div
                className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500 "
                onClick={handleSkip}
              >
                <FormattedMessage id="LangsSkipThisStep" />
              </div>
              <button
                type="button"
                className="inline-flex  bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleNext}
              >
                <FormattedMessage id="LangsContinue" />
              </button>
            </div>
          </div>
        </div>

        {/* // Right side Content */}
        <div className="fixed left-1/2 right-0 top-0 hidden h-full bg-white leading-6 text-black md:inline">
          <div className="relative flex h-full animate-none flex-col items-center justify-center overflow-hidden bg-white leading-6 text-black transition">
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center pt-[88px] justify-start  leading-6 text-black">
              <div className="relative max-w-[45vw] overflow-hidden bg-white shadow transition-transform">
                <img className="block h-full w-full" src={topic_image} alt="" />
              </div>
              <div className="relative mx-16 mb-0 mt-6 hidden w-[30vw] p-0 leading-6 text-black opacity-100 transition-opacity sm:inline lg:max-w-xl">
                <h2 className="text-4xl font-bold leading-none text-indigo-600">
                  06
                </h2>
                <p className="flex min-h-[40px] items-center text-base font-normal leading-6 text-gray-900">
                  <FormattedMessage id="wizardTopicSubTitle" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...wizardActions });

export default connector(TopicPage);
