import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { Listbox, Transition } from "@headlessui/react";
//my Components
import Tooltip from "../../components/Tooltip";
import { useCountries } from "../../hooks/useCountries";
//pages
import NotFoundPage from "../NotFoundPage";
//logos
import logo_c from "../../media/svg/logo/logo_circle.svg";
//icons
import {
  HiArrowSmallRight,
  HiCheck,
  HiChevronDown,
  HiChevronLeft,
  HiOutlineExclamationCircle,
  HiOutlineLockClosed,
} from "react-icons/hi2";
import { AiFillCaretDown, AiFillHeart } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { FiInfo } from "react-icons/fi";

const tiers = [
  {
    name: "Unlimited",
    id: "tier-unlimited",
    options: [
      {
        id: "ONE_MONTH",
        name: "Monthly",
        price: 49,
        unit_quantity: 1,
        domain_price: 2,
        unit: "every month",
        popular_choice: false,
        save_money: false,
      },
      {
        id: "ONE_YEAR",
        name: "1 Year",
        price: 39,
        unit_quantity: 12,
        domain_price: 1.66,
        unit: "months",
        popular_choice: true,
        save_money: false,
      },
      {
        id: "TWO_YEARS",
        name: "2 Years",
        price: 35,
        unit_quantity: 24,
        domain_price: 1.66,
        unit: "months",
        popular_choice: false,
        save_money: true,
      },
    ],
  },
  {
    name: "Grow",
    id: "tier-grow",
    options: [
      {
        id: "ONE_MONTH",
        name: "Monthly",
        price: 19,
        unit_quantity: 1,
        domain_price: 2,
        unit: "every month",
        popular_choice: false,
        save_money: false,
      },
      {
        id: "ONE_YEAR",
        name: "1 Year",
        price: 15,
        unit_quantity: 12,
        domain_price: 1.66,
        unit: "months",
        popular_choice: true,
        save_money: false,
      },
      {
        id: "TWO_YEARS",
        name: "2 Years",
        price: 13,
        domain_price: 1.66,
        unit_quantity: 24,
        unit: "months",
        popular_choice: false,
        save_money: true,
      },
    ],
  },
  {
    name: "Start",
    id: "tier-start",
    options: [
      {
        id: "ONE_MONTH",
        name: "Monthly",
        price: 12,
        unit_quantity: 1,
        domain_price: 2,
        unit: "every month",
        popular_choice: false,
        save_money: false,
      },
      {
        id: "ONE_YEAR",
        name: "1 Year",
        price: 9,
        unit_quantity: 12,
        domain_price: 1.66,
        unit: "months",
        popular_choice: true,
        save_money: false,
      },
      {
        id: "TWO_YEARS",
        name: "2 Years",
        price: 8,
        unit_quantity: 24,
        domain_price: 1.66,
        unit: "months",
        popular_choice: false,
        save_money: true,
      },
    ],
  },
];

// type CountSaveMoneyProps = {
//   price: number;
//   unit_quantity: number;
//   domain_price: number;
// };

export const CheckoutPageTwo: React.FC = () => {
  // website link ID
  const { tierId } = useParams();
  const [tier] = React.useState(tiers.find((t) => t.id === `tier-${tierId}`));

  // Apply promo code state
  const [isShowPromoCode, setIsShowPromoCode] = React.useState(false);
  const handleShowPromoCode = () => {
    setIsShowPromoCode(true);
  };
  const handleHidePromoCode = () => {
    setIsShowPromoCode(false);
  };
  // summary state
  const [isShowSummary, setIsShowSummary] = React.useState(true);
  const handleShowSummary = () => {
    setIsShowSummary(isShowSummary);
  };

  const countTotal = ({
    price,
    unit_quantity,
  }: {
    price: number;
    unit_quantity: number;
  }) => {
    return price * unit_quantity;
  };

  // const countSaveMoney = React.useCallback(
  //   ({ price, unit_quantity }: CountSaveMoneyProps): number => {
  //     if (!tier) return 0;
  //     const optionOneMonth = tier.options.find((t) => t.id === "ONE_MONTH");
  //     if (!optionOneMonth) return 0;
  //     const currentTotal = +price * unit_quantity;
  //     const oneMonthTotal =
  //       (+optionOneMonth.price + +optionOneMonth.domain_price) * unit_quantity;
  //     return +oneMonthTotal - +currentTotal;
  //   },
  //   [tier]
  // );

  const [selectedOption] = React.useState(
    tiers.find((t) => t.id === `tier-${tierId}`)?.options[0]
  );

  const getUnit = React.useCallback(() => {
    return selectedOption?.id === "ONE_MONTH"
      ? "month"
      : selectedOption?.id === "ONE_YEAR"
      ? "year"
      : selectedOption?.id === "TWO_YEARS"
      ? "2 years"
      : "";
  }, [selectedOption]);

  const getRound = (n: number) => {
    return Math.round(n * 100) / 100;
  };

  const [isShowCompany, setIsShowCompany] = useState(true);
  const handleShowCompany = () => {
    setIsShowCompany(true);
  };
  const handleNotShowCompany = () => {
    setIsShowCompany(false);
  };

  const [isError] = useState(false);

  // use Countries state
  const countries = useCountries();
  const [country, setCountry] = React.useState<string>(
    countries[0]?.name || ""
  );
  const selectedCountry = countries.find((c) => c.name === country);
  const countryName = selectedCountry?.name || "";

  return (
    <>
      {!tier ? (
        <div className="h-screen">
          <NotFoundPage />
        </div>
      ) : (
        <div className="flex flex-1 flex-col">
          {/* checkout seciton */}
          <nav className="mx-auto w-full max-w-7xl px-4 pb-5 pt-10 text-base sm:p-4">
            <Link
              to={`/checkout/${tierId}/billing-cycle/`}
              className="hidden cursor-pointer items-center leading-6 text-neutral-500 md:flex"
            >
              <HiChevronLeft className="h-5 w-5 text-slate-900" />
              <FormattedMessage id="BackToChooseABillingOption" />
            </Link>
          </nav>
          <div className="mx-auto mt-2 flex max-w-7xl flex-col-reverse gap-x-6 px-4 pb-16 md:grid md:grid-cols-12">
            {/* order summary icon show in mobile */}
            <aside className="block leading-5 text-zinc-800 md:hidden">
              <div className="mb-4 flex items-center">
                <span className="inline-block h-5 w-5">
                  <HiOutlineLockClosed className="h-full w-full" />
                </span>
                <span className="pl-2 text-xs">
                  <FormattedMessage id="LangsSSLSecureTransfer" />
                </span>
              </div>
              <div className="mb-4 flex items-center">
                <span className="inline-block h-5 w-5">
                  <AiFillHeart className="h-full w-full text-red-600" />
                </span>
                <span className="pl-2 text-xs">
                  <FormattedMessage id="CheckOutMadeInMacao" />
                </span>
              </div>
              <div className="mb-4 flex items-center">
                <span className="inline-block h-5 w-5">
                  <img src={logo_c} alt="buildlink" className="h-full w-full" />
                </span>
                <span className="pl-2 text-xs">
                  <FormattedMessage id="CheckOutTrusted" />
                </span>
              </div>
            </aside>
            <header className="col-span-full mb-6 hidden md:mb-4 md:block">
              <h1 className="mb-4 text-3xl font-bold text-zinc-800 md:text-4xl">
                <FormattedMessage id="CheckOutComplete" />
              </h1>
              <p className="text-base font-normal leading-6">
                <FormattedMessage id="CheckOutStep2Of3" />
              </p>
            </header>
            <main className="col-span-7">
              <form>
                <div className="mb-5 flex flex-col items-stretch bg-white p-5 leading-5 text-zinc-800">
                  <h2 className="mb-5 text-lg font-bold">
                    <FormattedMessage id="CheckOutProvideYourBillingInformation" />
                  </h2>
                  {/* switch Business or Personal */}
                  <fieldset className="mb-5 flex items-center gap-6">
                    <label className="flex cursor-default items-baseline gap-3">
                      <input
                        name="kind"
                        type="radio"
                        value="BUSINESS"
                        onChange={handleShowCompany}
                        className="h-4 w-4 flex-shrink-0 border-2 border-black"
                      />
                      <span className="flex cursor-default flex-col gap-1">
                        <span className="text-base font-normal leading-normal">
                          Business
                        </span>
                      </span>
                    </label>
                    <label className="flex cursor-default items-baseline gap-3">
                      <input
                        name="kind"
                        type="radio"
                        value="CONSUMER"
                        onChange={handleNotShowCompany}
                        className="h-4 w-4 flex-shrink-0 border-2 border-black"
                      />
                      <span className="flex cursor-default flex-col gap-1">
                        <span className="cursor-default text-base font-normal leading-normal">
                          Personal
                        </span>
                      </span>
                    </label>
                    <Tooltip
                      placement="top"
                      className="max-w-xs"
                      content={
                        <div className="flex flex-col space-y-4 p-1">
                          <p className="whitespace-normal text-sm text-white">
                            Business: You're an entrepreneur or self-employed.
                            You sell products or services or advertise business
                            activities. if you use your site for business and
                            personal purposes, choose this option.
                          </p>
                          <p className="whitespace-normal text-sm text-white">
                            Personal: You're a consumer. You don't sell products
                            or services or advertise business activities. You
                            use your site for personal purposes.
                          </p>
                        </div>
                      }
                    >
                      <FiInfo className="pointer-events-auto ml-1 inline-block h-5 w-5 cursor-help text-gray-600" />
                    </Tooltip>
                  </fieldset>
                  {/* company input */}
                  {isShowCompany ? (
                    <div className="mb-5">
                      <label className="mb-2 inline-block cursor-default text-base font-normal leading-normal">
                        Company / Organization
                        <span className="ml-2 text-neutral-500">
                          (optional)
                        </span>
                      </label>
                      <div className="transiton-all relative flex items-center gap-5 border-2 border-zinc-200 bg-white duration-200 ease-in hover:border-gray-300 focus:border-2 focus:border-indigo-600">
                        <input
                          name="companyName"
                          maxLength={100}
                          type="text"
                          id="companyName"
                          className="block h-10 w-full flex-1 cursor-text appearance-none border-0 bg-transparent p-3 text-base"
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* first and last name input */}
                  <div className="flex flex-col gap-0 md:flex-row md:gap-5">
                    {/* first name input */}
                    <div className="mb-5 flex-shrink flex-grow basis-1/2">
                      <div className="">
                        <label className="mb-2 inline-block cursor-default text-base font-normal leading-normal">
                          First Name
                        </label>
                        <div
                          className={classNames(
                            isError ? "border-red-500" : "border-zinc-200",
                            "relative mb-2 flex items-center gap-5 border-2 border-red-500 bg-white text-zinc-800 transition-all duration-200 ease-in hover:border-gray-300 focus:border-2 focus:border-indigo-600"
                          )}
                        >
                          <input
                            name="firstName"
                            maxLength={50}
                            type="text"
                            id="firstName"
                            className="block h-10 w-full flex-1 cursor-text appearance-none border-0 bg-transparent py-3 pl-3 text-base"
                          />
                          <span
                            className={classNames(
                              isError
                                ? "mr-3 inline-block h-5 w-5 text-red-500"
                                : "hidden"
                            )}
                          >
                            <HiOutlineExclamationCircle className="h-full w-full" />
                          </span>
                        </div>
                        <span
                          className={classNames(
                            isError
                              ? "mt-2 block text-sm font-bold leading-normal text-red-500"
                              : "hidden"
                          )}
                        >
                          Required
                        </span>
                      </div>
                    </div>
                    {/* last name input */}
                    <div className="mb-5 flex-shrink flex-grow basis-1/2">
                      <div className="">
                        <label className="mb-2 inline-block cursor-default text-base font-normal leading-normal">
                          Last Name
                        </label>
                        <div
                          className={classNames(
                            isError ? "border-red-500" : "border-zinc-200",
                            "relative mb-2 flex items-center gap-5 border-2 border-red-500 bg-white text-zinc-800 transition-all duration-200 ease-in hover:border-gray-300 focus:border-2 focus:border-indigo-600"
                          )}
                        >
                          <input
                            name="lastName"
                            maxLength={50}
                            type="text"
                            id="lastName"
                            className="block h-10 w-full flex-1 cursor-text appearance-none border-0 bg-transparent py-3 pl-3 text-base"
                          />
                          <span
                            className={classNames(
                              isError
                                ? "mr-3 inline-block h-5 w-5 text-red-500"
                                : "hidden"
                            )}
                          >
                            <HiOutlineExclamationCircle className="h-full w-full" />
                          </span>
                        </div>
                        <span
                          className={classNames(
                            isError
                              ? "mt-2 block text-sm font-bold leading-normal text-red-500"
                              : "hidden"
                          )}
                        >
                          Required
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* street name and house number input */}
                  <div className="mb-5 leading-5 text-zinc-800">
                    <label className="mb-2 inline-block cursor-default text-base font-normal leading-normal">
                      Street name and house number
                    </label>
                    <div
                      className={classNames(
                        isError ? "border-red-500" : "border-zinc-200",
                        "relative mb-2 flex items-center gap-5 border-2 bg-white leading-5 text-zinc-800 transition-all duration-200 ease-in hover:border-gray-300 focus:border-2 focus:border-indigo-600"
                      )}
                    >
                      <input
                        name="addressLine1"
                        maxLength={200}
                        type="text"
                        id="addressLine1"
                        className="block h-10 w-full flex-1 cursor-text appearance-none border-0 bg-transparent py-3 pl-3 text-base text-zinc-800"
                      />
                      <span
                        className={classNames(
                          isError
                            ? "mr-3 inline-block h-5 w-5 text-red-500"
                            : "hidden"
                        )}
                      >
                        <HiOutlineExclamationCircle className="h-full w-full" />
                      </span>
                    </div>
                    <span
                      className={classNames(
                        isError
                          ? "mt-2 block text-sm font-bold leading-normal text-red-500"
                          : "hidden"
                      )}
                    >
                      Required
                    </span>
                  </div>
                  {/* apartment, building, or floor input */}
                  <div className="mb-5 leading-5 text-zinc-800">
                    <label className="mb-2 inline-block cursor-default text-base font-normal leading-normal">
                      Apartment, building, or floor
                      <span className="ml-2 cursor-default text-neutral-500">
                        (optional)
                      </span>
                    </label>
                    <div className="relative flex items-center gap-5 border-2 border-zinc-200 bg-white text-zinc-800 transition-all duration-200 ease-in hover:border-gray-300 focus:border-2 focus:border-indigo-600">
                      <input
                        name="addressLine2"
                        maxLength={200}
                        type="text"
                        id="addressLine2"
                        className="block h-10 w-full flex-1 cursor-text appearance-none border-0 bg-transparent py-3 pl-3 text-base text-zinc-800"
                      />
                    </div>
                  </div>
                  {/* postal code and city input group */}
                  <div className="flex flex-col leading-5 text-zinc-800 md:flex-row md:gap-5">
                    {/* postal code input */}
                    <div className="mb-5 flex-shrink flex-grow basis-1/3 text-zinc-800">
                      <div className="leading-5">
                        <label className="mb-2 inline-block cursor-default text-base font-normal leading-normal">
                          Postal Code
                        </label>
                        <div
                          className={classNames(
                            isError ? "border-red-500" : "border-zinc-200",
                            "relative mb-2 flex items-center gap-5 border-2 bg-white leading-5 hover:border-gray-300 focus:border-2 focus:border-indigo-600"
                          )}
                        >
                          <input
                            name="postalCode"
                            maxLength={10}
                            type="text"
                            id="postalCode"
                            className="block h-10 w-full flex-1 cursor-text appearance-none border-0 bg-transparent py-3 pl-3 text-base"
                          />
                          <span
                            className={classNames(
                              isError
                                ? "mr-3 inline-block h-5 w-5 text-red-500"
                                : "hidden"
                            )}
                          >
                            <HiOutlineExclamationCircle className="h-full w-full" />
                          </span>
                        </div>
                        <span
                          className={classNames(
                            isError
                              ? "mt-2 block text-sm font-bold leading-normal text-red-500"
                              : "hidden"
                          )}
                        >
                          Required
                        </span>
                      </div>
                    </div>
                    {/* city input */}
                    <div className="mb-5 flex-shrink flex-grow basis-2/3 leading-5 text-zinc-800">
                      <div className="">
                        <label className="mb-2 inline-block cursor-default text-base font-normal leading-normal text-zinc-800">
                          City
                        </label>
                        <div
                          className={classNames(
                            isError ? "border-red-500" : "border-zinc-200",
                            "relative mb-2 flex items-center gap-5 border-2  bg-white hover:border-gray-300 focus:border-2 focus:border-indigo-600"
                          )}
                        >
                          <input
                            name="city"
                            maxLength={100}
                            type="text"
                            id="city"
                            className="block h-10 w-full flex-1 cursor-text appearance-none border-0 bg-transparent py-3 pl-3 text-base"
                          />
                          <span
                            className={classNames(
                              isError
                                ? "mr-3 inline-block h-5 w-5 text-red-500"
                                : "hidden"
                            )}
                          >
                            <HiOutlineExclamationCircle className="h-full w-full" />
                          </span>
                        </div>
                        <span
                          className={classNames(
                            isError
                              ? "mt-2 block text-sm font-bold leading-normal text-red-500"
                              : "hidden"
                          )}
                        >
                          Required
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* country input */}
                  <div className="relative mb-5 inline-flex flex-col leading-5 text-zinc-800">
                    <Listbox value={country} onChange={setCountry}>
                      <Listbox.Label className="mb-2 cursor-default text-base font-normal leading-normal">
                        Country
                      </Listbox.Label>
                      <Listbox.Button className="relative flex h-12 w-full items-center overflow-hidden border-2 border-zinc-200 bg-white text-zinc-800 hover:border-gray-300 focus:border-2 focus:border-indigo-600">
                        <span className="ml-4 block">{countryName}</span>
                        <span className="pointer-events-none absolute bottom-3 right-2.5 inline-block h-5 w-5 text-zinc-800">
                          <AiFillCaretDown className="h-full w-full" />
                        </span>
                      </Listbox.Button>
                      <Transition
                        className="absolute z-10 w-full"
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transfrom scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Listbox.Options className="mt-20 h-96 origin-top-left overflow-auto overscroll-contain rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-all duration-200 ease-in focus:outline-none ">
                          {countries.map((country, idx) => (
                            <Listbox.Option
                              key={idx}
                              value={country.name}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-pointer whitespace-nowrap  px-9 py-2"
                                )
                              }
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal','block truncate"
                                    )}
                                  >
                                    {country.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-whtite"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                      )}
                                    >
                                      <HiCheck className="h-5 w-5" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </Listbox>
                  </div>
                  {/* Email input */}
                  <div className="leading-5 text-zinc-800">
                    <label className="mb-2 inline-block cursor-default text-base font-normal leading-normal">
                      Email
                    </label>
                    <div
                      className={classNames(
                        isError ? "border-red-500" : "border-zinc-200",
                        "relative mb-2 flex items-center gap-5 border-2 bg-white leading-5 text-zinc-800 hover:border-gray-300 focus:border-2 focus:border-indigo-600"
                      )}
                    >
                      <input
                        name="email"
                        type="email"
                        maxLength={200}
                        id="email"
                        className="block flex-1 cursor-text appearance-none border-0 bg-transparent py-3 pl-3 text-base"
                      />
                      <span
                        className={classNames(
                          isError
                            ? "mr-3 inline-block h-5 w-5 text-red-500"
                            : "hidden"
                        )}
                      >
                        <HiOutlineExclamationCircle className="h-full w-full" />
                      </span>
                    </div>
                    <span
                      className={classNames(
                        isError
                          ? "mt-2 block text-sm font-bold leading-normal text-red-500"
                          : "hidden"
                      )}
                    >
                      Required
                    </span>
                  </div>
                </div>
              </form>
              <div className="fixed inset-x-0 bottom-0 z-10 bg-white leading-5 text-zinc-800 md:static md:mb-10 md:bg-inherit">
                <div className="mx-auto flex flex-col items-start px-4 py-5 md:p-0">
                  <Link
                    to={`/checkout/${tierId}/confirm/`}
                    className="relative mb-4 inline-flex h-12 w-full max-w-full cursor-pointer items-center justify-center gap-2 overflow-hidden border-2 border-indigo-600 bg-indigo-600 px-5 text-base font-bold text-white hover:bg-indigo-700 md:mb-4 md:w-auto"
                  >
                    <span className="">
                      <FormattedMessage id="LangsContinueWithPayment" />
                    </span>
                    <span className="inline-block h-5 w-5 flex-shrink-0 font-bold text-white">
                      <HiArrowSmallRight className="h-full w-full" />
                    </span>
                  </Link>
                </div>
              </div>
            </main>
            {/* step show in mobile */}
            <header className="col-span-full mb-6 block md:mb-4 md:hidden">
              <p className="text-base font-normal leading-6 ">
                <FormattedMessage id="CheckOutStep2Of3" />
              </p>
            </header>
            {/* order summary */}
            <aside className="col-span-5 col-end-13 mb-5 leading-5 text-zinc-800 lg:col-span-4 lg:col-end-13">
              <div className="md:sticky ">
                <div className="flex flex-col rounded border border-gray-300 bg-white pb-3 md:mb-4">
                  <div className="">
                    <button
                      onClick={handleShowSummary}
                      className="flex w-full justify-between p-5 text-xl leading-none hover:cursor-pointer"
                    >
                      {isShowSummary ? (
                        <h2 className="text-lg font-bold leading-5">
                          <FormattedMessage id="CheckOutOrderSummary" />
                        </h2>
                      ) : (
                        <h2 className="text-lg font-bold leading-5">
                          <FormattedMessage id="LangsStartMonthly" />
                        </h2>
                      )}
                      <span
                        className={classNames(
                          isShowSummary ? "rotate-180" : "",
                          "ml-2 inline-block h-5 w-5 cursor-pointer text-xl text-black transition-all duration-200 ease-in-out"
                        )}
                      >
                        <HiChevronDown className="h-full w-full" />
                      </span>
                    </button>
                  </div>
                  {/* order content */}
                  <div className="px-5 md:h-auto">
                    <ul
                      className={classNames(
                        isShowSummary ? "opacity-100" : "opacity-0",
                        "transiton-all duration-200 ease-in-out"
                      )}
                    >
                      {/* tier price */}
                      <li className="mb-3 text-left">
                        <div className="flex justify-between gap-4">
                          <p className="flex text-base font-bold leading-normal">
                            {tier.name}
                            <span className="whitespace-nowrap font-normal leading-6">
                              &nbsp;(
                              {selectedOption?.name})
                            </span>
                          </p>
                          <p className="text-base font-bold leading-normal">
                            ${selectedOption?.price}
                          </p>
                        </div>
                        <p className="mb-3 text-base font-normal leading-normal text-neutral-500">
                          ${selectedOption?.price} x{" "}
                          {selectedOption?.unit_quantity} months
                        </p>
                      </li>
                      {/* domain price */}
                      <li className="mb-3 text-left">
                        <div className="flex justify-between gap-4">
                          <p className="text-base font-bold leading-normal">
                            Domain
                          </p>
                          <p className="text-base font-bold leading-normal">
                            $0
                          </p>
                        </div>
                        <p className="mb-3 text-base font-normal leading-normal text-neutral-500">
                          Free for the first contract term
                        </p>
                      </li>
                    </ul>

                    {/* prmom code show button */}
                    <div
                      className={classNames(
                        isShowSummary ? "opacity-100" : "opacity-0",
                        "transiton-all duration-200 ease-in-out"
                      )}
                    >
                      <button
                        onClick={handleShowPromoCode}
                        className={classNames(
                          isShowPromoCode
                            ? "hidden"
                            : "flex h-12 max-w-full cursor-pointer items-center bg-none px-5 py-3 text-base font-bold leading-5 text-indigo-600"
                        )}
                      >
                        <span className="inline-flex flex-row">
                          <span className="">Apply promo code</span>
                        </span>
                      </button>
                      {/* input prmom code */}
                      {isShowPromoCode && (
                        <div className="mb-3 leading-5 text-zinc-800">
                          <div className="mb-5 mt-3">
                            <label className="mb-2 inline-block cursor-default text-base font-normal leading-normal">
                              Promo code
                            </label>
                            <div className="relative flex items-center gap-5 border-2 border-zinc-200 bg-white text-zinc-800 hover:border-gray-300 focus:border-2 focus:border-indigo-600">
                              <input
                                id="discountCode"
                                type="text"
                                name="discountCode"
                                data-testid="discount-input"
                                placeholder="Enter code"
                                className="block h-10 w-full flex-1 cursor-text appearance-none border-0 bg-transparent p-3 text-base text-zinc-800"
                              />
                            </div>
                          </div>
                          {/* cancel promo code button */}
                          <button
                            onClick={handleHidePromoCode}
                            className="relative inline-flex h-8 max-w-full cursor-pointer items-center justify-center gap-2 overflow-hidden border-2 border-indigo-600 bg-transparent px-3 py-1 hover:bg-gray-200"
                          >
                            <span className="text-sm font-bold leading-4 text-indigo-600">
                              Cancel
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* total */}
                  <div className="px-5 leading-5 text-zinc-800">
                    <hr className="mb-4 border-2 border-t-0 border-gray-300 md:mb-4" />
                    <div className="mb-3">
                      <div className="flex justify-between gap-4">
                        <h3 className="text-base font-bold leading-normal">
                          Total
                        </h3>
                        <p className="text-base font-bold leading-normal ">
                          $
                          {countTotal({
                            price: selectedOption?.price || 0,
                            unit_quantity: selectedOption?.unit_quantity || 0,
                          })}
                        </p>
                      </div>
                    </div>
                    <p
                      className={classNames(
                        isShowSummary ? "block" : "hidden",
                        "mb-2 text-sm font-normal leading-normal transition-all duration-200 ease-in-out"
                      )}
                    >
                      Includes $0 VAT (0% on $
                      {countTotal({
                        price: selectedOption?.price || 0,
                        unit_quantity: selectedOption?.unit_quantity || 0,
                      })}
                      )
                    </p>
                  </div>
                </div>
                {/* Legal information */}
                <div className="block leading-5 text-zinc-800 md:mb-5 md:block">
                  <p className="mb-2 text-sm font-normal leading-normal text-neutral-500">
                    We'll bill you on the same date every month for this paid
                    plan. Total price for the next billing cycle is $14/month
                    consisting of:
                  </p>
                  <ul className="mb-4 list-disc pl-5 text-sm font-normal leading-normal text-neutral-500">
                    <li>
                      Plan: ${selectedOption?.price} x{" "}
                      {selectedOption?.unit_quantity} month = $
                      {countTotal({
                        price: selectedOption?.price || 0,
                        unit_quantity: selectedOption?.unit_quantity || 0,
                      })}
                      /{getUnit()}
                    </li>
                    <li>
                      Domain: ${selectedOption?.domain_price} x{" "}
                      {selectedOption?.unit_quantity} month
                      {selectedOption?.unit_quantity &&
                      selectedOption.unit_quantity > 1
                        ? "s"
                        : ""}{" "}
                      = $
                      {getRound(
                        countTotal({
                          price: selectedOption?.domain_price || 0,
                          unit_quantity: selectedOption?.unit_quantity || 0,
                        })
                      )}
                      / {getUnit()}
                    </li>
                    <li>
                      VAT: $0 (0% on $
                      {getRound(
                        countTotal({
                          price: selectedOption?.price || 0,
                          unit_quantity: selectedOption?.unit_quantity || 0,
                        }) +
                          getRound(
                            countTotal({
                              price: selectedOption?.domain_price || 0,
                              unit_quantity: selectedOption?.unit_quantity || 0,
                            })
                          )
                      )}
                      )
                    </li>
                  </ul>
                  <p className="mb-2 text-sm font-normal leading-normal text-neutral-500">
                    For more information, see "Legal Information" at the end of
                    the page.
                  </p>
                </div>
                {/* order summary icon */}
                <aside className="hidden leading-5 text-zinc-800 md:block">
                  <div className="mb-4 flex items-center">
                    <span className="inline-block h-5 w-5">
                      <HiOutlineLockClosed className="h-full w-full" />
                    </span>
                    <span className="pl-2 text-xs">SSL Secure Transfer</span>
                  </div>
                  <div className="mb-4 flex items-center">
                    <span className="inline-block h-5 w-5">
                      <AiFillHeart className="h-full w-full text-red-600" />
                    </span>
                    <span className="pl-2 text-xs">
                      Made in Macao since 2023
                    </span>
                  </div>
                  <div className="mb-4 flex items-center">
                    <span className="inline-block h-5 w-5">
                      <img
                        src={logo_c}
                        alt="buildlink"
                        className="h-full w-full"
                      />
                    </span>
                    <span className="pl-2 text-xs">
                      Trusted by millions of people and businesses.
                    </span>
                  </div>
                </aside>
              </div>
            </aside>
            {/* order summary icon hidden in desktop */}
            <aside></aside>
          </div>
        </div>
      )}
    </>
  );
};
export default CheckoutPageTwo;
