import React from "react";

// icon
import { InformationCircleIcon, TrashIcon, AcademicCapIcon } from "@heroicons/react/24/outline";

import { } from "@heroicons/react/20/solid";

import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const domains = [
    {
        domain: "qJFZiexample.com",
        type: "BUILD",
        status: "Primary Domain",
        link: "linked"
    },
    {
        domain: "dskfhsqJFZiexample.com",
        type: "BUILD",
        status: "Secondary Domain",
        link: "Unlinked"
    },

]

const DomainsPage: React.FC = () => {
    return (
        <div className="mx-auto flex max-w-7xl flex-col items-start py-24 leading-6 inc-800  sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold mb-5">
                <FormattedMessage id="LangsDomains" />
            </h1>
            <div className="mb-10" >
                <FormattedMessage id="DomainsExistingDomains" />

            </div>

            {/* TODO: Upgrade now! */}
            <div className="relative mx-0 mb-5 flex flex-col justify-between border-y-2 border-l-8 border-r-2 border-pink-600 bg-white p-4 md:flex-row ">
                <div className="mb-4 flex items-center md:mb-0">
                    <div className="mr-5 hidden md:block">
                        <InformationCircleIcon className="h-5 w-5" />{" "}
                    </div>
                    <div className="">
                        <p className="text-base font-normal leading-normal">
                            <FormattedMessage id="DomainsGetMore" />

                            <strong >
                                <FormattedMessage id="LangsUpgradeNow" />
                            </strong>
                        </p>
                    </div>
                </div>
                <div className="flex w-full flex-shrink-0 flex-wrap-reverse items-center  justify-end md:ml-5 md:w-auto">
                    <a
                        href="/cms/upgrade"
                        className="relative inline-flex h-12 w-full max-w-full cursor-pointer select-none appearance-none items-center  justify-center gap-2 overflow-hidden bg-blue-600 px-5 text-sm font-bold text-white transition-colors duration-300 hover:bg-blue-700 md:w-auto"
                    >
                        <span className="cursor-pointer truncate text-sm font-bold text-white">

                            <FormattedMessage id="LangsUpgradeNow" />

                        </span>
                    </a>
                </div>
            </div>


            <div className="w-full">
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            <th className="py-4 pr-4 font-bold leading-normal text-left whitespace-nowrap border-collapse  ">
                                <FormattedMessage
                                    id="LangsURL" />

                            </th>
                            <th className="py-4 pr-4 font-bold leading-normal text-left whitespace-nowrap border-collapse  ">
                                <FormattedMessage id="LangsType" />

                            </th>
                            <th className="py-4 pr-4 font-bold leading-normal text-left whitespace-nowrap border-collapse  ">
                                <FormattedMessage id="LangsStatus" />

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* TODO: domains status set 驗證設定 */}
                        {domains.map((item, index) => (
                            <tr key={index} className=" py-4  border-collapse table-row border-t border-solid border-gray-300   ">
                                <th className="col-start-1 col-end-2  border-collapse   md:py-4 md:pr-4 md:pl-0">
                                    <div className="flex ">
                                        <div className={classNames(
                                            item.status === "Primary Domain" ? "bg-blue-600 " : "bg-gray-300", "flex justify-center items-center mr-4 w-10 h-10 text-white border-collapse rounded-full")}>
                                            <AcademicCapIcon className="w-5 h-5" />
                                        </div>
                                        <div >
                                            <p className="mb-1  text-sm font-bold leading-normal border-collapse  ">{item.domain}</p>
                                            <p className=" text-sm font-normal leading-normal border-collapse md:order-3   text-left ">{item.status}</p>
                                        </div>
                                    </div>

                                </th>

                                <th className="text-left text-sm font-normal w-full border-collapse md:w-auto md:whitespace-nowrap md:py-4 md:pr-4 ">
                                    {item.type}

                                </th>
                                <th className="text-left text-sm font-normal w-full h-full border-collapse md:w-auto md:whitespace-nowrap md:py-4 md:pr-4 ">
                                    <div className="flex justify-start items-center">
                                        <div
                                            className={classNames(
                                                item.link === "linked"
                                                    ? "bg-green-600"
                                                    : "bg-red-600",
                                                "mr-2 h-2 min-h-0 w-2 min-w-0 rounded-full  shadow-md shadow-black/20"
                                            )}
                                        />
                                        {item.link}
                                    </div>
                                </th>
                                <th className="w-full border-collapse md:w-auto md:py-4 md:pr-4 md:pl-0 md:text-center">
                                    <div className="flex justify-center items-center">
                                        <button className={classNames(item.status === "Primary Domain" ? "opacity-40 focus:None hover:None cursor-default pointer-events-none " : "focus:text-red-500 hover:text-red-500", "flex justify-center items-center  w-12 h-12  text-sm cursor-pointer text-neutral-500  ")}>
                                            <TrashIcon className="w-5 h-5 " />
                                        </button>
                                    </div>
                                </th>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>


        </div>
    );
};

export default DomainsPage