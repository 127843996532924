import React from "react";
import { useState } from "react";

import classNames from "classnames";

// icon
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { ShieldCheckIcon } from "@heroicons/react/20/solid";

import type { MyToastHandler } from "../../../../components/MyToast/type";

const service = {
  id: 1,
  description:
    "This technology measures the success of the partner program. The information enables the attribution of advertising successes for billing and scaling the performance of the corresponding advertising partners.",
};

const processingCompany = {
  companyName: "Impact Tech, Inc.",
  address:
    "a Delaware corporation (file no. 4426140) with a principal place of business at: 223 De La Guerra Street Santa Barbara, CA 93101, USA",
};

const storedInformation = [
  {
    id: 1,
    title: "Type:",
    value: "cookie",
  },
  {
    id: 2,
    title: "Duration:",
    value: "1 month",
  },
  {
    id: 3,
    title: "Domain:",
    value: "BUILD.com",
  },
];

const historys = [
  {
    id: 1,
    Decision: "yes",
    Date: "05.08.2023, 16:36",
  },
  {
    id: 2,
    Decision: "yes",
    Date: "05.08.2023, 18:21",
  },
  {
    id: 3,
    Decision: "yes",
    Date: "05.08.2023, 18:21",
  },
];

const Affiliate: React.FC = () => {
  const [isShow, setShow] = useState<boolean>(false);
  const handledShow = () => {
    setShow(!isShow);
  };

  // Active Toggle switch State
  // eslint-disable-next-line
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const toastRef = React.useRef<MyToastHandler>(null);

  // eslint-disable-next-line
  function handleToastOpen() {
    if (!toastRef.current) return;
    toastRef.current.handleToastOpen();
  }

  return (
    <div className="cookies-content text-xs">
      <div className="pb-4">
        <h6>Description of Service</h6>
        <p>{service.description}</p>
      </div>

      <div className="pb-4">
        <h6 className="text-sm opacity-70">Processing Company</h6>
        <p>
          {processingCompany.companyName}
          <br />
          {processingCompany.address}
        </p>
      </div>

      <div className="pb-4">
        <h6 className="text-sm opacity-70">Technologies Used</h6>
        <p className="pb-2">
          This list represents all technologies this service uses to collect
          data. Typical technologies are Cookies and Pixels that are placed in
          the browser.
        </p>
        <ul className="flex justify-start">
          <li className="mb-2 mr-2 rounded border border-white p-2">
            {" "}
            Server to Server tracking
          </li>
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="text-sm opacity-70">Retention Period</h6>
        <p className="pb-2">
          The retention period is the time span the collected data is saved for
          the processing purposes. The data needs to be deleted as soon as it is
          no longer needed for the stated processing purposes.
        </p>
        <ul className="flex list-disc justify-start px-4">
          <li className="">
            <div>
              Impact retains Personal Data only as long as is reasonably
              necessary for the performance of its Services, and Personal Data
              of our Clients’ visitors is automatically deleted or anonymized
              after ninety days. When Impact is requested to delete Personal
              Data prior to such automatic deletion, we follow a process to make
              sure that genuine requests are honoured. This involves verifying
              that the requestor is the individual that is the data subject, or,
              if the request comes from a Client about its visitors, we delete
              Personal Data as instructed by our Client. Since we do not collect
              personally identifiable information of our Client’s visitors, if
              the information comes directly from a Client’s visitor we may not
              be able to verify that we have any such data or to match a request
              with a particular Client. When deleting data, the Personal Data is
              completely removed from our servers or retained only in anonymized
              form. If we have collected Personal Data about you from more than
              one Client, we will delete all instances of the Personal Data that
              we have at the time of the request (to the extent that we are able
              to verify and match such data). However, if you subsequently
              authorize or visit the same Client or another Client’s website or
              application, we will have processed your Personal Data in relation
              to such authorization or visit.
            </div>
          </li>
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Transfer to Third Countries</h6>
        <p>
          This service may forward the collected data to a different country.
          Please note that this service might transfer the data to a country
          without the required data protection standards. If the data is
          transferred to the USA, there is a risk that your data can be
          processed by US authorities, for control and surveillance measures,
          possibly without legal remedies. Below you can find a list of
          countries to which the data is being transferred. For more information
          regarding safeguards please refer to the website provider’s privacy
          policy or contact the website provider directly.
        </p>
        <ul className="flex justify-start">
          <li className="mb-2 mr-2 mt-2 rounded border border-white  p-2">
            USA
          </li>
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Data Recipients</h6>
        <p>In the following the recipients of the data collected are listed.</p>
        <ul className="flex justify-start">
          <li className="mb-2 mr-2 mt-2 rounded border border-white p-2">
            Impact Tech, Inc
          </li>
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">
          Click here to read the cookie policy of the data processor
        </h6>
        <a href="https://impact.com/privacy-policy/">
          {" "}
          https://impact.com/privacy-policy/
        </a>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Storage Information</h6>
        <p className="pb-2">
          Below you can see the longest potential duration for storage on a
          device, as set when using the cookie method of storage and if there
          are any other methods used.
        </p>
        <ul className="flex list-disc flex-col justify-start px-4">
          <li className="">
            <div>Maximum age of cookie storage: Session</div>
          </li>
          <li className="">
            <div>Non-cookie storage: no</div>
          </li>
        </ul>
      </div>

      <div className="pb-4">
        <div className="flex flex-col text-xs">
          {/* content */}
          <div className="flex-grow  sm:mr-2 ">
            {/* content wrapper */}
            <button
              onClick={() => {
                handledShow();
              }}
              className="text-left "
            >
              {/* title */}
              <div className="flex items-center justify-start pb-1 pt-2 ">
                <h6 className="opacity-70">Storage Information</h6>
                {/* toggle button */}
                <span
                  className="mx-2 flex items-center justify-start"
                  onClick={() => {
                    handledShow();
                  }}
                >
                  {isShow ? (
                    <span className="mt-1 inline-block h-4 w-4 md:mt-0">
                      <HiChevronUp className="h-full w-full " />
                    </span>
                  ) : (
                    <span className="mt-1 inline-block h-4 w-4 md:mt-0">
                      <HiChevronDown className="h-full w-full " />
                    </span>
                  )}
                </span>
              </div>
              <div className="mb-2 mt-1 leading-tight">
                <p>
                  This service uses different means of storing information on a
                  user’s device as listed below.
                </p>
              </div>
            </button>
          </div>

          <div className="flex items-center  justify-between px-3 "></div>

          <section
            className={classNames(
              isShow ? "opacity-100" : "hidden",
              "duration-600 overflow-hidden transition-all ease-in-out"
            )}
          >
            <div className="flex flex-col">
              <div className="border px-3 py-2">
                <table className="min-w-full ">
                  <thead className="">
                    <tr>
                      <th className="mb-1 font-bold">trs</th>
                      <th className="mb-2">referrer url</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {storedInformation.map((item) => (
                      <tr key={item.id} className="pb-2 ">
                        <td className="w-32 whitespace-nowrap pb-2  ">
                          {item.title}
                        </td>
                        <td
                          className="
                                                whitespace-nowrap "
                        >
                          {item.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="mb-4">
        <h6>History</h6>

        <div className="mt-2 rounded border">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="px-3 py-2">
                <th scope="col" className="px-3 py-2 text-left font-normal">
                  Decision
                </th>
                <th scope="col" className="text-left font-normal">
                  Date
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {historys.map((item) => (
                <tr key={item.id} className="">
                  <td className="flex w-1/2 whitespace-nowrap px-3 py-2 text-left">
                    <ShieldCheckIcon className="mr-3 h-4 w-3" />
                    {item.Decision}
                  </td>
                  <td className="w-1/2 whitespace-nowrap  ">{item.Date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Affiliate;
