import React from "react";

const MxRecordsPage: React.FC = () => {
  return (
    <div>
      <h1>Mx Records</h1>
    </div>
  );
};
export default MxRecordsPage;
