import React from "react";
import { FormattedMessage } from "react-intl";
import { Combobox } from "@headlessui/react";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import logo from "../../media/svg/logo/logo.svg";
import { WizardContext } from "./index";
import Progress from "../../components/Progress";

import industries from "../../models/_mock/industries";
//images
import category_image from "../../media/svg/wizard/category/categroy_image.png";
import { wizardActions } from "../../stores/actions";

let bussinessTypes: any[] = industries;

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = ConnectedProps<typeof connector>;

const CategoryPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector(({ wizard }) => wizard);
  const { activeStep, setActiveStep } = React.useContext(WizardContext);
  const [query, setQuery] = React.useState("");
  const [selectedType, setSelectedType] = React.useState<any>({
    name: formData.business_category || "",
  });

  const filteredBussinessTypes =
    query === ""
      ? bussinessTypes
      : bussinessTypes.filter((type: any) => {
          return type.name.toLowerCase().includes(query.toLowerCase());
        });

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    dispatch(props.editWizard("business_category", "skip"));
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    if (!selectedType || !selectedType.name) return;
    dispatch(props.editWizard("business_category", selectedType.name));
    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <div className="tex-black m-0 min-h-screen overflow-auto p-0 leading-6">
        {/* // header */}
        <div className="min-h-screen overflow-auto bg-gray-50 transition">
          <header
            className="fixed left-0 top-0 z-10 m-0 flex h-[88px] w-full items-center justify-center border-b border-solid border-gray-200 bg-gray-50 bg-opacity-[0.9] p-0 leading-6 text-black transition md:w-1/2"
            aria-label="Global"
          >
            <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
          </header>

          {/* // main content Group */}
          <div className="relative flex min-h-screen w-full flex-col bg-gray-50 pb-24 leading-6 text-black transition md:w-1/2">
            <div className="relative m-0 mt-[88px] flex flex-1 flex-col p-0 leading-6 text-black">
              {/* // main content */}
              <div className="mx-auto my-0 w-full max-w-[660px] px-5 py-0 leading-6 text-black ">
                {/* Title */}
                <h1 className="mx-1 mb-8 mt-10 p-0 text-center text-2xl font-bold leading-[1.2] text-black md:mb-16 md:mt-20 md:text-[4.8vh]">
                  <FormattedMessage id="WizardCategoryTitle" />
                </h1>
                {/* TODO: setup the Search combobox */}
                {/* // input form */}
                <Combobox
                  as="div"
                  value={selectedType}
                  onChange={setSelectedType}
                >
                  <div className="relative mb-4">
                    <Combobox.Input
                      type="text"
                      autoComplete="list"
                      placeholder="Search for a category"
                      className="h-20 w-full cursor-text appearance-none border-0 border-b-2 px-5 text-lg font-bold outline-0 transition placeholder:text-lg placeholder:text-neutral-300 hover:bg-zinc-100 focus:bg-zinc-100 sm:text-2xl placeholder:sm:text-2xl"
                      onChange={(event) => setQuery(event.target.value)}
                      displayValue={(type: any) => (type && type.name) || ""}
                    />
                    {/* show search results when typing */}
                    {filteredBussinessTypes.length > 0 ? (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-50 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-lg">
                        {filteredBussinessTypes.map((type: any) => (
                          <Combobox.Option
                            key={type.name}
                            value={type}
                            className={({ active }) =>
                              classNames(
                                "relative cursor-pointer select-none py-2 pl-3 pr-9",
                                active
                                  ? "bg-indigo-100 text-indigo-600"
                                  : "text-zinc-900"
                              )
                            }
                          >
                            {({ selected }) => (
                              <span
                                className={classNames(
                                  "block truncate",
                                  selected.toString() && "font-semibold"
                                )}
                              >
                                {type.name}
                              </span>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    ) : (
                      <>
                        {/*  show no results message when no results found      */}
                        <div className="absolute z-10 mt-1 w-full rounded-md bg-white px-3 py-1 pb-4 text-base  font-bold shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-lg">
                          <span className="block px-3 py-2 text-center font-bold text-zinc-900">
                            <FormattedMessage id="WizardUnfortunately" />
                          </span>
                          <div
                            className="block cursor-pointer px-3 py-2 text-indigo-600"
                            onClick={handleSkip}
                          >
                            <FormattedMessage id="LangsSkip" />?
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Combobox>
              </div>
            </div>
          </div>

          {/* // button group */}
          <div className="fixed bottom-[84px] flex w-full items-center transition md:w-1/2">
            <Progress className="bg-gray-300/20" value={40} size="sm" />
            <div className="fixed bottom-0 flex w-full items-center justify-between bg-gray-50 bg-opacity-[0.9] p-5 leading-6 transition md:w-1/2">
              <button
                type="button"
                className="inline-flex border-2 border-indigo-600 bg-gray-50 px-5 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleBack}
              >
                <FormattedMessage id="LangsBack" />
              </button>
              {/*eslint-disable-next-line*/}
              <div
                className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500 "
                onClick={handleSkip}
              >
                <FormattedMessage id="LangsSkipThisStep" />
              </div>
              <button
                onClick={handleNext}
                type="button"
                className="inline-flex bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FormattedMessage id="LangsContinue" />
              </button>
            </div>
          </div>
        </div>

        {/* // Right side Content */}
        <div className="fixed left-1/2 right-0 top-0 hidden h-full bg-white leading-6 text-black md:inline">
          <div className="relative flex h-full animate-none flex-col items-center justify-center overflow-hidden bg-white leading-6 text-black transition">
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-start pt-[88px] leading-6 text-black">
              <div className="relative max-w-[45vw] overflow-hidden  bg-white shadow transition-transform">
                <img
                  className="block h-full w-full"
                  src={category_image}
                  alt=""
                />
              </div>
              <div className="relative mx-16 mb-0 mt-6 hidden w-[30vw] p-0 leading-6 text-black opacity-100 transition-opacity sm:inline lg:max-w-xl">
                <h2 className="text-4xl font-bold leading-none text-indigo-600">
                  04
                </h2>
                <p className="flex min-h-[40px] items-center text-base font-normal leading-6 text-gray-900">
                  <FormattedMessage id="WizardCategoryDescription" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...wizardActions });

export default connector(CategoryPage);
