import React from "react";
import { AiOutlineHeart } from "react-icons/ai";
//context
import { PageDrawerContext } from "../index";
//image
import AboutMe from "../../../../media/svg/pagedraw/aboutme.svg";
import Appointment from "../../../../media/svg/pagedraw/appointment.svg";
import Contact from "../../../../media/svg/pagedraw/contact.svg";
import Event from "../../../../media/svg/pagedraw/events.svg";
import Gallery from "../../../../media/svg/pagedraw/gallery.svg";
import Job from "../../../../media/svg/pagedraw/jobs.svg";
import News from "../../../../media/svg/pagedraw/news.svg";
import Team from "../../../../media/svg/pagedraw/team.svg";
import Courses from "../../../../media/svg/pagedraw/course.svg";
import { FormattedMessage } from "react-intl";

const pageTypeList = [
  {
    id: "appointment",
    name: "Appointments",
    thumbnail: Appointment,
    description: "Let customers book with you 24/7",
  },
  {
    id: "news",
    name: "News",
    thumbnail: News,
    description: "Share the latest updates from your business",
  },
  {
    id: "aboutme",
    name: "About Me",
    thumbnail: AboutMe,
    description: "Tell visitors your unique story",
  },
  {
    id: "event",
    name: "Events",
    thumbnail: Event,
    description: "Keep your visitors up-to-date with what’s on",
  },
  {
    id: "contact",
    name: "Contacts",
    thumbnail: Contact,
    description: "Make it easy for visitors to get in touch",
  },
  {
    id: "gallery",
    name: "Gallery",
    thumbnail: Gallery,
    description: "Show off your images in a beautiful layout",
  },
  {
    id: "team",
    name: "Team",
    thumbnail: Team,
    description: "Introduce your people to the world",
  },
  {
    id: "classes",
    name: "Classes",
    thumbnail: Courses,
    description: "Advertise the training you offer",
  },
  {
    id: "job",
    name: "Jobs",
    thumbnail: Job,
    description: "Post about vacancies at your company",
  },
];

const PageAdd: React.FC = () => {
  const [name, setName] = React.useState<string>("");
  const { setActiveStep, handleAddPage } = React.useContext(PageDrawerContext);

  const handleNewPage = (pageName: string) => {
    handleAddPage(pageName);
    setActiveStep("home");
  };

  return (
    <>
      <div className="flex flex-col border-b-2 border-gray-300 px-4 pb-10 pt-8">
        <label className="mb-2">
          <FormattedMessage id="LangsBlankPage" />
        </label>
        <div className="flex gap-4">
          <input
            className="grow border border-gray-300"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <button
            className="bg-white px-6 py-2.5 text-sm font-bold text-indigo-600 shadow-sm ring-2 ring-inset ring-indigo-600 hover:bg-indigo-50"
            onClick={() => handleNewPage(name)}
          >
            <FormattedMessage id="LangsAdd" />

          </button>
        </div>
      </div>
      <div className="flex flex-col px-4 py-8">
        <div className="flex items-center gap-2 py-4">
          <AiOutlineHeart fontSize={20} />
          <h1 className="font-bold">
            <FormattedMessage id="LangsRecommendedForYou" />
          </h1>
        </div>

        <div className="flex flex-col gap-8">
          {pageTypeList.map((pt: any, index: number) => (
            <div
              key={index}
              className="flex flex-1 items-center justify-between gap-3"
            >
              <div className="flex h-full w-[80px] flex-1 items-end">
                <img
                  src={pt.thumbnail}
                  alt=""
                  className="w-[76px] max-w-none"
                />
              </div>
              <div className="flex grow flex-col gap-2">
                <h4>{pt.name}</h4>
                <p className="text-sm text-gray-500">{pt.description}</p>
              </div>
              <button
                className="bg-white px-4 py-2 text-sm font-bold text-indigo-600 shadow-sm ring-2 ring-inset ring-indigo-600 hover:bg-indigo-50"
                onClick={() => handleNewPage(pt.name)}
              >
                <FormattedMessage id="LangsAdd" />

              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PageAdd;
