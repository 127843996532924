import type { StoreAction, AuthStateType, ProjectStateType } from "./type";
import * as ProjectModel from "../models/Project";
// actionType
//
//auth
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOADED = "USER_LOADED";
export const USER_LOGOUT = "USER_LOGOUT";
//builder
export const BUILDER_CLIPBOARD_LOADED = "BUILDER_CLIPBOARD_LOADED";
export const BUILDER_CLIPBOARD_UNLOADED = "BUILDER_CLIPBOARD_UNLOADED";
//project
export const PROJECT_LOADED = "PROJECT_LOADED";
export const PROJECT_CHANGED = "PROJECT_CHANGED";
export const PROJECT_LOGOUT = "PROJECT_LOGOUT";
//wizard
export const WIZARD_EDIT = "WIZARD_ADD";
export const WIZARD_RESET = "WIZARD_RESET";

//actions
//
//auth actions

export const authActions = {
  login: (login_at: string): StoreAction => ({
    type: USER_LOGIN,
    payload: { login_at },
  }),

  logout: (): StoreAction => ({
    type: USER_LOGOUT,
  }),

  filledUser: (user: AuthStateType): StoreAction => ({
    type: USER_LOADED,
    payload: { user },
  }),
};

// builder actions
export const builderActions = {
  filledClipboard: (section: HTMLElement): StoreAction => ({
    type: BUILDER_CLIPBOARD_LOADED,
    payload: { section },
  }),
  clearClipboard: (): StoreAction => ({
    type: BUILDER_CLIPBOARD_UNLOADED,
  }),
};

// project actions
export const projectActions = {
  filledProject: (project: ProjectStateType): StoreAction => ({
    type: PROJECT_LOADED,
    payload: { project },
  }),

  changeProject: (project: ProjectStateType) => {
    return (dispatch: any) => {
      return ProjectModel.edit(project).then(() => {
        dispatch({
          type: PROJECT_CHANGED,
          payload: { project },
        });
      });
    };
  },

  logout: (): StoreAction => ({
    type: PROJECT_LOGOUT,
  }),
};

// wizard actions
export const wizardActions = {
  editWizard: (key: string, value: any): StoreAction => ({
    type: WIZARD_EDIT,
    payload: { key, value },
  }),

  resetWizard: (): StoreAction => ({
    type: WIZARD_RESET,
  }),
};
