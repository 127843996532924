import React from "react";
import type { AlertProps } from "./type";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { FormattedMessage } from "react-intl";

const Alert: React.FC<AlertProps> = ({
  status,
  children,
  open = true,
  enableClose = true,
}) => {
  const [show, setShow] = React.useState<boolean>(open);

  const handleClose = () => {
    setShow(false);
  };

  React.useEffect(() => {
    setShow(open);
  }, [open]);

  return (
    <div
      className={`alert alert-${status} rounded-md p-4 ${!show && "hidden"}`}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 icon" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium content">{children}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={`button inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${!enableClose && "hidden"
                }`}
              onClick={handleClose}
            >
              <span className="sr-only">
                <FormattedMessage id="LangsDismiss" />
              </span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
