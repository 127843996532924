import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { authActions, projectActions } from "../../stores/actions";

type Props = ConnectedProps<typeof connector>;

const LogoutPage: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signOut = React.useCallback(async () => {
    try {
      await Auth.signOut();
      dispatch(props.projectLogout());
      dispatch(props.logout());
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, navigate, props]);

  React.useEffect(() => {
    signOut();
  }, [signOut]);

  return <></>;
};

const connector = connect(null, {
  logout: authActions.logout,
  projectLogout: projectActions.logout,
});

export default connector(LogoutPage);
