import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

//load redux file;
import * as auth from "./authRedux";
import * as builder from "./builderRedux";
import * as project from "./projectRedux";
import * as wizard from "./wizardRedux";

const rootReducer = combineReducers({
  auth: persistReducer(
    {
      key: "webbuiler-auth",
      storage: storage,
    },
    auth.reducer
  ),
  builder: persistReducer(
    {
      key: "webbuiler-builder",
      storage: storage,
    },
    builder.reducer
  ),
  project: persistReducer(
    {
      key: "webbuiler-project",
      storage: storage,
    },
    project.reducer
  ),
  wizard: persistReducer(
    {
      key: "webbuiler-wizard",
      storage: storage,
    },
    wizard.reducer
  ),
});

export default rootReducer;
