import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { projectActions } from "../../stores/actions";
import type { ProjectInitProps } from "../type";
//models
import * as ProjectModel from "../../models/Project";

type Props = ConnectedProps<typeof connector> & ProjectInitProps;

const ProjectInit: React.FC<Props> = ({ children, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const project = useSelector(({ project }) => project);
  const user = useSelector(({ auth }) => auth.user);
  //eslint-disable-next-line
  const [inProject, setInProject] = React.useState<any>();

  const requestProject = React.useCallback(
    async (id: string | undefined) => {
      if (!user?.sub) return;
      try {
        const resp = await ProjectModel.get({
          userId: user?.sub,
          "status[0]": "published",
          "status[1]": "unpublish",
          ...(id ? { id } : {}),
        });
        const { data } = resp;
        const { Items: projects } = data;
        if (!projects || projects.length === 0) {
          navigate("/wizard/persons");
        } else if (projects && projects.length === 1) {
          dispatch(props.filledProject(projects[0]));
          setInProject(projects[0]);
        } else {
          //check projectId is included in projects
          const projectIds = projects.map((p: any) => p.id);
          if (!project || !projectIds.includes(project?.id)) {
            navigate("/projects");
          } else {
            setInProject(projects[0]);
          }
        }
      } catch (error) {
        console.log(error);
        navigate("/wizard/persons");
      }
    },
    //eslint-disable-next-line
    [dispatch, navigate, user]
  );

  React.useEffect(() => {
    if (!project.id) {
      requestProject(undefined);
    } else if (!inProject?.id) {
      requestProject(project.id);
    }
  }, [requestProject, project, inProject]);

  return <Fragment>{children}</Fragment>;
};

const connector = connect(null, { ...projectActions });

export default connector(ProjectInit);
