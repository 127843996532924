import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import { IoMdColorFilter } from "react-icons/io";
import { Popover } from "@headlessui/react";
import Colorful from "@uiw/react-color-colorful";
import { prominent } from "color.js";
//components
import Tooltip from "../../../Tooltip";
import MediaDialog from "../../Editor/media/";
//hook
import { useColor } from "../../../../hooks/useColor";
import type { HexColor } from "../../../../hooks/useColor";
//context
import { WebBuilderContext } from "../../../../pages/Cms/WebBuilderPage";
//type
import { MediaDialogHandler } from "../../Editor/media/type";
import { FormattedMessage } from "react-intl";

const waitingGenerators = [
  { position: "secondary", theory: "mochromatic" },
  { position: "primary", theory: "random" },
  { position: "tertiary", theory: "triadic" },
  { position: "tiny", theory: "complementary" },
  { position: "tertiary", theory: "analogous" },
  { position: "primary", theory: "random" },
  { position: "secondary", theory: "split-complementary" },
  { position: "tertiary", theory: "triadic" },
  { position: "primary", theory: "triadic" },
  { position: "secondary", theory: "random" },
  { position: "tiny", theory: "triadic" },
  { position: "tiny", theory: "random" },
];

const Color: React.FC = () => {
  const {
    getNewColors,
    getRandomInRange,
    hexToHsl,
    hslToHex,
    getBrightness,
    hexToRgb,
  } = useColor();
  const dialogRef = React.useRef<MediaDialogHandler>(null);
  const { design, setDesign } = React.useContext(WebBuilderContext);
  const [waitingColors, setWaitingColors] = React.useState<any>([]);
  const [showMore, setShowMore] = React.useState(false);
  const [saturation, setSaturation] = React.useState(
    hexToHsl(design.colors.primary).s || 50
  );

  const handleClickMedia = () => {
    if (!dialogRef.current) return;
    dialogRef.current?.handleOpen();
  };

  const handleInsertImage = React.useCallback(
    async (imageUrl: any) => {
      if (!imageUrl) return;

      let colors = (await prominent(imageUrl, {
        amount: 30,
        group: 38,
        format: "hex",
        sample: 5,
      })) as HexColor[];

      // skip black color
      colors = colors.filter((color: any) => {
        const rgb = hexToRgb(color);
        const brightness = getBrightness(color);
        const HSL = hexToHsl(color);
        return (
          brightness > 30 &&
          HSL.l < 90 &&
          !(rgb.r === rgb.g && rgb.r === rgb.b && rgb.g === rgb.b)
        );
      });

      setDesign({
        ...design,
        colors: {
          ...design.colors,
          image: imageUrl,
          primary: colors[0],
          secondary: colors[Math.floor(colors.length / 3)],
          tertiary: colors[Math.floor((colors.length * 2) / 3)],
          tiny: colors[colors.length - 1],
        },
      });
    },
    [setDesign, design, getBrightness, hexToHsl, hexToRgb]
  );

  const handleRemoveImage = React.useCallback(() => {
    setDesign({
      ...design,
      colors: {
        ...design.colors,
        image: undefined,
      },
    });
  }, [setDesign, design]);

  const handleRandomColor = () => {
    const newSecondary = getNewColors({ colors: [design.colors.primary] });
    const newOthers = getNewColors({
      colors: [design.colors.primary, newSecondary[0]],
      isWCAG2: true,
    });
    setDesign({
      ...design,
      colors: {
        primary: design.colors.primary,
        secondary: newSecondary[0],
        tertiary: newOthers[0],
        tiny: newOthers[1],
        white: "#FFFFFF",
        black: "#000000",
      },
    });
    handleRandomWaitingColor();
  };

  const handleRandomWaitingColor = React.useCallback(() => {
    const colors = waitingGenerators.map(({ position, theory }): any => {
      if (position === "primary") {
        const newSecondary = getNewColors({
          colors: [design.colors.primary],
          isWCAG2: true,
          theory,
        });
        const newOthers = getNewColors({
          colors: [design.colors.primary, newSecondary[0]],
          theory,
          isWCAG2: true,
        });
        return {
          primary: design.colors.primary,
          secondary: newSecondary[0],
          tertiary: newOthers[0],
          tiny: newOthers[1],
          white: "#FFFFFF",
          black: "#000000",
          fixed: "primary",
        };
      } else if (position === "secondary") {
        const newPrimary = getNewColors({
          colors: [design.colors.primary],
          isWCAG2: true,
          theory,
        });
        const newOthers = getNewColors({
          colors: [newPrimary[0], design.colors.primary],
          theory,
          isWCAG2: true,
        });
        return {
          primary: newPrimary[0],
          secondary: design.colors.primary,
          tertiary: newOthers[0],
          tiny: newOthers[1],
          white: "#FFFFFF",
          black: "#000000",
          fixed: "secondary",
        };
      } else if (position === "tertiary") {
        const newPrimary = getNewColors({
          colors: [design.colors.primary],
          isWCAG2: true,
          theory,
          changeHSL: {
            h: 0,
            s: theory === "complementary" ? getRandomInRange(20, 80) : 0,
            l: 0,
          },
        });
        const newSecondary = getNewColors({
          colors: [...newPrimary],
          isWCAG2: true,
          theory,
          changeHSL: {
            h: 0,
            s: theory === "complementary" ? getRandomInRange(20, 80) : 0,
            l: 0,
          },
        });
        const newOthers = getNewColors({
          colors: [newPrimary[0], newSecondary[0]],
          theory,
          isWCAG2: true,
          changeHSL: {
            h: 0,
            s: 0,
            l: theory === "complementary" ? getRandomInRange(-20, 20) : 0,
          },
        });
        return {
          primary: newPrimary[0],
          secondary: newSecondary[0],
          tertiary: design.colors.primary,
          tiny: newOthers[1],
          white: "#FFFFFF",
          black: "#000000",
          fixed: "tertiary",
        };
      } else if (position === "tiny") {
        const newPrimary = getNewColors({
          colors: [design.colors.primary],
          isWCAG2: true,
          theory,
          changeHSL: {
            h: 0,
            s: theory === "complementary" ? getRandomInRange(20, 80) : 0,
            l: 0,
          },
        });
        const newSecondary = getNewColors({
          colors: [...newPrimary],
          isWCAG2: true,
          theory,
          changeHSL: {
            h: 0,
            s: theory === "complementary" ? getRandomInRange(20, 80) : 0,
            l: 0,
          },
        });
        const newOthers = getNewColors({
          colors: [newPrimary[0], newSecondary[0]],
          theory,
          isWCAG2: true,
          changeHSL: {
            h: 0,
            s: 0,
            l: theory === "complementary" ? getRandomInRange(-10, 20) : 0,
          },
        });
        return {
          primary: newPrimary[0],
          secondary: newSecondary[0],
          tertiary: newOthers[0],
          tiny: design.colors.primary,
          white: "#FFFFFF",
          black: "#000000",
          fixed: "tiny",
        };
      }
      return null;
    });
    setWaitingColors(colors);
    //eslint-disable-next-line
  }, [design.colors.primary, design.colors.secondary]);

  const handleSaturationChange = (e: any) => {
    const s = e.target.value;
    setSaturation(e.target.value);
    const newWaitingColors = waitingColors.map(
      ({ primary, secondary, tertiary, tiny, fixed }: any) => {
        const primaryHSL = hexToHsl(primary);
        const secondaryHSL = hexToHsl(secondary);
        const tertiaryHSL = hexToHsl(tertiary);
        const tinyHSL = hexToHsl(tiny);

        const newPrimaryHSL = {
          ...primaryHSL,
          s: fixed !== "primary" ? s : primaryHSL.s,
        };
        const newSecondaryHSL = {
          ...secondaryHSL,
          s: fixed !== "secondary" ? s : secondaryHSL.s,
        };
        const newTertiaryHSL = {
          ...tertiaryHSL,
          s: fixed !== "tertiary" ? s : tertiaryHSL.s,
        };
        const newTinyHSL = {
          ...tinyHSL,
          s: fixed !== "tiny" ? s : tiny.s,
        };

        return {
          primary: hslToHex(newPrimaryHSL.h, newPrimaryHSL.s, newPrimaryHSL.l),
          secondary: hslToHex(
            newSecondaryHSL.h,
            newSecondaryHSL.s,
            newSecondaryHSL.l
          ),
          tertiary: hslToHex(
            newTertiaryHSL.h,
            newTertiaryHSL.s,
            newTertiaryHSL.l
          ),
          tiny: hslToHex(newTinyHSL.h, newTinyHSL.s, newTinyHSL.l),
          white: "#FFFFFF",
          black: "#000000",
          fixed,
        };
      }
    );

    setWaitingColors(newWaitingColors);
  };

  const handleChangePrimaryInput = (e: any) => {
    setDesign({
      ...design,
      colors: {
        ...design.colors,
        primary: e.target.value,
      },
    });
  };

  const handleChangePrimary = (color: any) => {
    setDesign({
      ...design,
      colors: {
        ...design.colors,
        primary: color.hex,
      },
    });
  };

  const handleChangeSecondaryInput = (e: any) => {
    setDesign({
      ...design,
      colors: {
        ...design.colors,
        secondary: e.target.value,
      },
    });
  };

  const handleChangeSecondary = (color: any) => {
    setDesign({
      ...design,
      colors: {
        ...design.colors,
        secondary: color.hex,
      },
    });
  };

  React.useEffect(() => {
    handleRandomWaitingColor();
  }, [handleRandomWaitingColor]);

  return (
    <>
      <div className="flex flex-col gap-4 px-3">
        <div className="flex justify-between">
          <h1 className="text-2xl font-bold">
            <FormattedMessage id="LangsColorPalette" /></h1>
          <Tooltip
            content={
              <>
                <FormattedMessage id="FormColorTitle1" />
                <br />
                <FormattedMessage id="FormColorTitle2" />

              </>
            }
            placement="right"
          >
            <button>
              <BsInfoCircle fontSize={18} />
            </button>
          </Tooltip>
        </div>
        {/*Color Palette START*/}
        <div className="relative">
          {/*photo show START*/}
          {design?.colors?.image && (
            <div className="absolute inset-0 z-50 h-[calc(100%-50px)] w-full">
              <div className="relative h-full w-full overflow-hidden rounded-t-lg">
                <button
                  type="button"
                  className="absolute right-2 top-2 rounded-full bg-white bg-opacity-40 p-2 hover:bg-opacity-60"
                  onClick={handleRemoveImage}
                >
                  <GrFormClose fontSize={24} />
                </button>
                <img
                  src={design.colors.image}
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
          )}
          {/*photo show END*/}
          {/*control panal START*/}
          <div className="absolute inset-0 grid h-56 w-full grid-cols-4">
            <div className="col-span-1 flex items-center justify-center">
              <Popover className="relative">
                <Popover.Button className="rounded-full bg-white bg-opacity-40 p-2 hover:bg-opacity-60">
                  <IoMdColorFilter fontSize={24} />
                </Popover.Button>

                <Popover.Panel className="z-999 absolute bg-white">
                  <div className="m-4 flex items-center justify-between gap-4 border-2 border-gray-300 p-2">
                    <span
                      className="ml-2 h-4 w-4"
                      style={{ backgroundColor: design.colors.primary }}
                    ></span>
                    <input
                      className="h-4 border-0 text-sm ring-0 focus:ring-0"
                      value={design.colors.primary}
                      onChange={handleChangePrimaryInput}
                    />
                  </div>
                  <Colorful
                    style={{ borderRadius: 0 }}
                    className="!w-full !rounded-none"
                    color={design.colors.primary}
                    onChange={handleChangePrimary}
                    disableAlpha={true}
                  />
                </Popover.Panel>
              </Popover>
            </div>
            <div className="col-span-1 flex flex-col items-center justify-center gap-2">
              <Popover className="relative">
                <Popover.Button className="rounded-full bg-white bg-opacity-40 p-2 hover:bg-opacity-60">
                  <IoMdColorFilter fontSize={24} />
                </Popover.Button>

                <Popover.Panel className="z-999 absolute bg-white">
                  <div className="m-4 flex items-center justify-between gap-4 border-2 border-gray-300 p-2">
                    <span
                      className="ml-2 h-4 w-4"
                      style={{ backgroundColor: design.colors.secondary }}
                    ></span>
                    <input
                      className="h-4 border-0 text-sm ring-0 focus:ring-0"
                      value={design.colors.secondary}
                      onChange={handleChangeSecondaryInput}
                    />
                  </div>
                  <Colorful
                    style={{ borderRadius: 0 }}
                    className="!w-full !rounded-none"
                    color={design.colors.secondary}
                    onChange={handleChangeSecondary}
                    disableAlpha={true}
                  />
                </Popover.Panel>
              </Popover>
              <button
                type="button"
                className="rounded-full bg-white bg-opacity-40 p-2 hover:bg-opacity-60"
                onClick={handleRandomColor}
              >
                <BiRefresh fontSize={24} />
              </button>
            </div>
            <div className="col-span-2"></div>
          </div>
          {/*control panal END*/}
          {/*color show START*/}
          <div className="grid h-56 w-full grid-cols-8 overflow-hidden rounded-lg">
            <div
              className="col-span-2 flex items-center justify-center"
              style={{ backgroundColor: design.colors.primary }}
            ></div>
            <div
              className="col-span-2 flex flex-col items-center justify-center gap-2"
              style={{ backgroundColor: design.colors.secondary }}
            ></div>
            <div
              className="col-span-1"
              style={{ backgroundColor: design.colors.tertiary }}
            ></div>
            <div
              className="col-span-1"
              style={{ backgroundColor: design.colors.tiny }}
            ></div>
            <div
              className="col-span-1"
              style={{ backgroundColor: design.colors.white }}
            ></div>
            <div
              className="col-span-1"
              style={{ backgroundColor: design.colors.black }}
            ></div>
          </div>
          {/*color show END*/}
        </div>
        {/*Color Palette END*/}

        {/*Image upload START*/}
        <button
          className="w-full border-2 border-indigo-600 bg-white p-2 font-bold text-indigo-600"
          onClick={handleClickMedia}
        >
          <FormattedMessage id="FormColorCreatePalette" />

        </button>
        {/*Image upload END*/}

        {/*Saturation START*/}
        <div className="mt-2">
          <div className="flex justify-between">
            <h3 className="text-lg font-bold">
              <FormattedMessage id="LangsSaturation" />

            </h3>
            <Tooltip
              content={
                <>
                  <FormattedMessage id="FormColorChooseStrong" />
                  <br />
                  <FormattedMessage id="FormColorSlide" />
                </>
              }
              placement="right"
            >
              <button>
                <BsInfoCircle fontSize={18} />
              </button>
            </Tooltip>
          </div>
          <input
            type="range"
            className="w-full"
            min={10}
            max={100}
            value={saturation}
            onChange={handleSaturationChange}
          />
          <div className="flex justify-between">
            <span>
              <FormattedMessage id="LangsMuted" />
            </span>
            <span>
              <FormattedMessage id="LangsMellow" />
            </span>
            <span>
              <FormattedMessage id="LangsBright" />
            </span>
            <span>
              <FormattedMessage id="LangsColorful" />
            </span>
          </div>
        </div>
        {/*Saturation END*/}
        <div className="mt-2 border-t-2 border-gray-300 py-4">
          <h3 className="text-lg font-bold">
            <FormattedMessage id="LangsSimilarPalettes" />

          </h3>
        </div>

        {waitingColors.map(
          ({ primary, secondary, tertiary, tiny }: any, i: number) => {
            if (!showMore && i > 5) return null;

            return (
              <div
                key={i}
                className="style-box w-full"
                onClick={() =>
                  setDesign({
                    ...design,
                    colors: {
                      primary,
                      secondary,
                      tertiary,
                      tiny,
                      white: "#FFFFFF",
                      black: "#000000",
                    },
                  })
                }
              >
                <div className="grid h-10 w-full grid-cols-8 overflow-hidden rounded-sm drop-shadow-lg">
                  <div
                    className="col-span-2"
                    style={{ backgroundColor: primary }}
                  ></div>
                  <div
                    className="col-span-2"
                    style={{ backgroundColor: secondary }}
                  ></div>
                  <div
                    className="col-span-1"
                    style={{ backgroundColor: tertiary }}
                  ></div>
                  <div
                    className="col-span-1"
                    style={{ backgroundColor: tiny }}
                  ></div>
                  <div
                    className="col-span-1"
                    style={{ backgroundColor: "#ffffff" }}
                  ></div>
                  <div
                    className="col-span-1"
                    style={{ backgroundColor: "#000000" }}
                  ></div>
                </div>
              </div>
            );
          }
        )}
        <div>
          <button
            className="font-bold text-indigo-600"
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            {showMore ? (<FormattedMessage id="LangsShowLess" />) : (<FormattedMessage id="LangsShowMore" />)}
          </button>
        </div>
      </div>
      <MediaDialog ref={dialogRef} onInsert={handleInsertImage} />
    </>
  );
};
export default Color;
