import React from "react";
import ReactDOMClient from "react-dom/client";
import { IntlProvider } from "react-intl";
import Langs from "../../../../langs/";
import Editor from "../index";

const useEditorTitle = () => {
  //target is the .editor-title
  const handleClick = React.useCallback((target: HTMLElement) => {
    let i = 0;
    let content = target.childNodes[i] as HTMLElement;
    while (content.nodeType === 3) {
      //if content is text node just skip
      i++;
      content = target.childNodes[i] as HTMLElement;
    }
    const div = document.createElement("div");
    const id = `editor-holder-${new Date().getTime()}`;
    div.id = id;
    div.setAttribute("data-holder", "editor-title");
    div.setAttribute("style", target.getAttribute("style") || "");
    const r = ReactDOMClient.createRoot(div);
    r.render(
      <IntlProvider messages={Langs.en} locale="en" defaultLocale="en">
        <Editor
          root={r}
          holderId={div.id}
          content={content.outerHTML}
          type="editor-title"
        />
      </IntlProvider>
    );
    target.replaceWith(div);
  }, []);

  const handleClickOut = React.useCallback(
    (target: HTMLElement, data: any, prevRoot: ReactDOMClient.Root) => {
      const holder = target?.parentNode as HTMLElement;
      if (!holder) return;
      const holderType = holder.getAttribute("data-holder");
      if (holderType !== "editor-title") return;
      const div = document.createElement("div");
      div.className = "editor-title";
      div.setAttribute("style", holder.getAttribute("style") || "");
      data?.childNodes.forEach((c: any) => {
        div.appendChild(c);
      });
      holder.replaceWith(div);
      prevRoot.unmount();
      div.addEventListener("click", (e: any) => handleClick(e.currentTarget));
    },
    [handleClick]
  );

  return { handleClick, handleClickOut };
};

export default useEditorTitle;
