import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Transition, Dialog } from "@headlessui/react";
import { BsChevronLeft } from "react-icons/bs";
//forms
import Home from "./form/Home";
import Color from "./form/Color";
import Font from "./form/Font";

import type { DesignDrawerProps, DesignDrawerHandler } from "./type";

type DesignDrawerContextType = {
  activeStep: string;
  setActiveStep: React.Dispatch<React.SetStateAction<string>>;
};

export const DesignDrawerContext = React.createContext<DesignDrawerContextType>(
  {
    activeStep: "home",
    setActiveStep: () => {},
  }
);

const DesignDrawer: React.ForwardRefRenderFunction<
  DesignDrawerHandler,
  DesignDrawerProps
> = ({ ...props }, innerRef) => {
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState<string>("home");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  React.useImperativeHandle(innerRef, () => ({
    handleOpen,
    handleClose,
  }));

  return (
    <DesignDrawerContext.Provider value={{ activeStep, setActiveStep }}>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="bg-indigo-700 px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            {activeStep === "home" && (
                              <FormattedMessage id="LangsEditDesign" />
                            )}
                            {activeStep === "color" && (
                              <>
                                <button
                                  className="flex items-center justify-center gap-1"
                                  onClick={() => setActiveStep("home")}
                                >
                                  <BsChevronLeft />
                                  <FormattedMessage id="LangsColorPalette" />
                                </button>
                              </>
                            )}
                            {activeStep === "font" && (
                              <>
                                <button
                                  className="flex items-center justify-center gap-1"
                                  onClick={() => setActiveStep("home")}
                                >
                                  <BsChevronLeft />
                                  <FormattedMessage id="LangsFontSet" />
                                </button>
                              </>
                            )}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white"
                              onClick={handleClose}
                            >
                              <FormattedMessage id="LangsDone" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex-1 p-4">
                        {activeStep === "home" && <Home />}
                        {activeStep === "color" && <Color />}
                        {activeStep === "font" && <Font />}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </DesignDrawerContext.Provider>
  );
};

export default React.forwardRef(DesignDrawer);
