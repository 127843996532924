import _ from "lodash";
import chroma from "chroma-js";

export type HexColor = string & { __hexColorBrand: never };

export type RGB = {
  r: number;
  g: number;
  b: number;
};

export type HSL = {
  h: number;
  s: number;
  l: number;
};

export const useColor = () => {
  //Helper function to check if a string is a hex color
  const isHexColor = (hex: string): boolean => {
    return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.test(hex);
  };

  // Helper function to convert hex color codes to RGB values
  const hexToRgb = (hex: HexColor | string): RGB => {
    if (!isHexColor(hex)) return { r: 0, g: 0, b: 0 };
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) return { r: 0, g: 0, b: 0 };

    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  };

  // Helper function to convert RGB color values to HSL values
  const rgbToHsl = (r: number, g: number, b: number): HSL => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
          break;
      }
      h /= 6;
    }

    return { h: h * 360, s: s * 100, l: l * 100 };
  };

  // Helper function to convert HSL color values to RGB values
  const hslToRgb = (h: number, s: number, l: number): RGB => {
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255),
    };
  };

  // Helper function to convert RGB color values to hex color codes
  const rgbToHex = (r: number, g: number, b: number): HexColor => {
    const componentToHex = (c: number) => {
      const hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    return ("#" +
      componentToHex(r) +
      componentToHex(g) +
      componentToHex(b)) as HexColor;
  };

  // Helper function to convert hsl color codes to Hex color values
  const hslToHex = (h: number, s: number, l: number): HexColor => {
    //hsl to rgb
    const rgb = hslToRgb(h, s, l);
    return rgbToHex(rgb.r, rgb.g, rgb.b);
  };

  // Helper function to convert hex color codes to hsl color values
  const hexToHsl = (hex: HexColor): HSL => {
    if (!isHexColor(hex)) {
      console.log("invalid hex color");
      return { h: 0, s: 0, l: 0 };
    }
    const rgb = hexToRgb(hex);
    return rgbToHsl(rgb.r, rgb.g, rgb.b);
  };

  // Helper function to generate a random number within a specified range
  const getRandomInRange = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  // Helper function to calculate the contrast ratio between two colors
  const getContrastRatio = (color1: RGB, color2: RGB) => {
    const luminance1 = getRelativeLuminance(color1);
    const luminance2 = getRelativeLuminance(color2);
    const lighter = Math.max(luminance1, luminance2);
    const darker = Math.min(luminance1, luminance2);
    return (lighter + 0.05) / (darker + 0.05);
  };

  // Helper function to calculate the relative luminance of a color
  const getRelativeLuminance = (color: RGB): number => {
    return 0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b;
  };

  // Helper function to adjust the lightness of a color based on a contrast ratio (4.5) WCAG2.0
  const adjustLightnessForContrast = (
    foregroundHSL: HSL,
    backgroundHSL: HSL
  ): HSL => {
    const MIN_CONTRAST_RATIO = 4.5;
    const MAX_TRY = 100;
    let backgroundLuminance = backgroundHSL.l;
    let contrastRatio = 0;
    let tryCount = 0;
    let direction = 1;
    while (contrastRatio < MIN_CONTRAST_RATIO && tryCount < MAX_TRY) {
      const backgroundRgb = hslToRgb(
        backgroundHSL.h,
        backgroundHSL.s,
        backgroundLuminance
      );
      const foregroundRgb = hslToRgb(
        foregroundHSL.h,
        foregroundHSL.s,
        foregroundHSL.l
      );
      contrastRatio = getContrastRatio(foregroundRgb, backgroundRgb);
      if (contrastRatio < MIN_CONTRAST_RATIO) {
        // 调整背景颜色的亮度值
        backgroundLuminance += 0.01 * direction;
        backgroundLuminance = Math.min(Math.max(backgroundLuminance, 0), 100);
        if (backgroundLuminance === 0 || backgroundLuminance === 100) {
          direction *= -1;
        }
        tryCount++;
      }
    }
    return { ...backgroundHSL, l: backgroundLuminance };
  };

  const getBrightness = (color: HexColor): number => {
    const rgb = hexToRgb(color);

    const r = (rgb.r / 255) * 100;
    const g = (rgb.g / 255) * 100;
    const b = (rgb.b / 255) * 100;

    // calculate brightness
    return Math.max(r, g, b);
  };

  const isBlack = (color: HexColor): boolean => {
    return getBrightness(color) < 30;
  };
  const isWhite = (color: HexColor): boolean => {
    const HSL = hexToHsl(color);
    return HSL.l > 90;
  };

  const isGray = (color: HexColor): boolean => {
    const rgb = hexToRgb(color);
    return rgb.r === rgb.g && rgb.g === rgb.b;
  };

  // momochromatic color theory generator
  const getMochromaticColors = (color: HexColor): HexColor[] => {
    const newColor1 = chroma.mix(color, "#000000", 0.2).hex() as HexColor;
    const newColor2 = chroma.mix(color, "#ffffff", 0.2).hex() as HexColor;
    // const brightness = getBrightness(color);
    // const hsl = hexToHsl(color);
    // const lightness = hsl.l;
    // const saturation = hsl.s;
    // const hue = hsl.h;
    // let lightness1, lightness2;
    // if (brightness < 30) {
    //   lightness1 = Math.min(lightness + 15, 80);
    //   lightness2 = Math.min(lightness + 30, 60);
    // } else if (hsl.l > 70) {
    //   lightness1 = Math.max(lightness - 20, 30);
    //   lightness2 = Math.max(lightness - 30, 50);
    // } else {
    //   lightness1 = Math.max(lightness - 20, 30);
    //   lightness2 = Math.min(lightness + 20, 80);
    // }
    // let newColor1 = hslToHex(hue, saturation, lightness1);
    // let newColor2 = hslToHex(hue, saturation, lightness2);

    return [newColor1, newColor2];
  };

  // split-complementary color theory generator
  const getSplitComplementaryColors = (color: HexColor): HexColor[] => {
    const hsl = hexToHsl(color);
    const hue = hsl.h;
    const saturation = hsl.s;
    const lightness = hsl.l;

    const hue1 = (hue + 72) % 360;
    const hue2 = (hue - 72 + 360) % 360;

    const newColor1 = hslToHex(hue1, saturation, lightness);
    const newColor2 = hslToHex(hue2, saturation, lightness);
    const newColor3 = chroma.mix(color, "#000000", 0.2).hex() as HexColor;
    return [newColor1, newColor2, newColor3];
  };

  // triadic color theory generator
  const getTriadicColors = (color: HexColor): HexColor[] => {
    const hsl = hexToHsl(color);
    const hue = hsl.h;
    const saturation = hsl.s;
    const lightness = hsl.l;

    const hue1 = (hue + 120) % 360;
    const hue2 = (hue + 240) % 360;

    const newColor1 = hslToHex(hue1, saturation, lightness);
    const newColor2 = hslToHex(hue2, getRandomInRange(60, 80), lightness);
    return [newColor1, newColor2];
  };

  // complementary color theory generator
  const getComplementaryColors = (color: HexColor): HexColor[] => {
    const hsl = hexToHsl(color);
    const hue = hsl.h;
    const saturation = hsl.s;
    const lightness = hsl.l;

    const hue1 = (hue + 180) % 360;

    const newColor1 = hslToHex(hue1, saturation, lightness);
    return [newColor1];
  };

  // analogous color theory generator
  const getAnalogousColors = (color: HexColor): HexColor[] => {
    // const analogousColors: HexColor[] = chroma
    //   .scale([color])
    //   .colors(4)
    //   .map(
    //     (c: any) =>
    //       chroma(c)
    //         .set("hsl.h", chroma(c).get("hsl.h") + (20 % 360))
    //         .hex() as HexColor
    //   );
    // return [...analogousColors];
    const hsl = hexToHsl(color);
    const hue = hsl.h;
    const saturation = hsl.s;
    const lightness = hsl.l;

    const hue1 = (hue + 30) % 360;
    const hue2 = (hue - 30 + 360) % 360;

    const newColor1 = hslToHex(hue1, saturation, lightness);
    const newColor2 = hslToHex(hue2, saturation, lightness);
    return [newColor1, newColor2];
  };

  // random color generator
  const getRandomColor = (color: HexColor): HexColor[] => {
    let randomColor: HexColor;
    randomColor = chroma.random().hex() as HexColor;
    while (
      !randomColor &&
      !isBlack(randomColor) &&
      !isWhite(randomColor) &&
      !isGray(randomColor)
    ) {
      randomColor = chroma.random().hex() as HexColor;
    }
    const newColor = chroma
      .bezier([color, randomColor])
      .scale()
      .colors(4) as HexColor[];
    return newColor;

    // Convert primary and secondary colors from hex to HSL
    // const hsl = hexToHsl(color);
    // const hue = (hsl.h + getRandomInRange(80, 100)) % 360;
    // const saturation = getRandomInRange(30, 80);
    // const lightness = getRandomInRange(30, 80);
    // const newColor1 = hslToHex(hue, saturation, lightness);
    // return [newColor1];
  };

  // helper function draw element form array
  const _drawOne = (arr: string[] | HexColor[]): string | HexColor => {
    arr = _.shuffle(arr);
    return arr[0];
  };

  // compare two colors with input color, if choose the contrast greater one;
  const getFurthest = ({
    color,
    sample,
  }: {
    color: HexColor;
    sample: HexColor[];
  }): HexColor => {
    const deltaESample = _.map(sample, (sc: HexColor) =>
      chroma.deltaE(color, sc)
    );
    const far = _.max(deltaESample);
    if (!far) return sample[0];
    const index = deltaESample.indexOf(far);
    return sample[index];
  };

  // generate random color
  const getNewColors = ({
    colors,
    theory,
    isWCAG2,
    changeHSL,
  }: {
    colors: HexColor[];
    theory?:
      | "mochromatic"
      | "split-complementary"
      | "triadic"
      | "complementary"
      | "analogous"
      | "random"
      | string;
    isWCAG2?: boolean;
    changeHSL?: HSL;
  }): HexColor[] => {
    let theories = [
      "mochromatic",
      "split-complementary",
      "triadic",
      "complementary",
      "analogous",
      "random",
    ];
    if (!theory) theory = _drawOne(theories);

    let newColors: HexColor[] = [];

    newColors = _.map(colors, (primaryColor: HexColor, i: number): HexColor => {
      let newColorFromTheory;

      //get newColor with the selected theory
      switch (theory) {
        case "mochromatic":
          newColorFromTheory = getMochromaticColors(primaryColor);
          break;
        case "split-complementary":
          newColorFromTheory = getSplitComplementaryColors(primaryColor);
          break;
        case "triadic":
          newColorFromTheory = getTriadicColors(primaryColor);
          break;
        case "complementary":
          newColorFromTheory =
            i === 0
              ? getComplementaryColors(primaryColor)
              : getMochromaticColors(primaryColor);
          break;
        case "analogous":
          newColorFromTheory = getAnalogousColors(primaryColor);
          break;
        case "random":
          newColorFromTheory = getRandomColor(primaryColor);
          break;
      }
      // draw newColor
      const newColor = _drawOne(newColorFromTheory as HexColor[]) as HexColor;
      if (!isWCAG2) return newColor;
      let newColorHSL = hexToHsl(newColor);
      const newColorRGB = hexToRgb(newColor);

      // Convert primary and secondary colors from hex to RGB
      const primaryRGB = hexToRgb(primaryColor);
      // Calculate the hue, saturation, and lightness of the primary and secondary colors
      const primaryHSL = rgbToHsl(primaryRGB.r, primaryRGB.g, primaryRGB.b);
      // Check the contrast ratio between each new color and the primary and secondary colors
      const contrast = getContrastRatio(primaryRGB, newColorRGB);
      if (contrast < 4.5) {
        newColorHSL = adjustLightnessForContrast(primaryHSL, newColorHSL);
      }
      //if changeHSL is true, change HSL
      if (changeHSL) {
        newColorHSL.h = (newColorHSL.h + changeHSL.h) % 360;
        newColorHSL.s = Math.min(newColorHSL.s + changeHSL.s, 100);
        newColorHSL.l = Math.min(newColorHSL.l + changeHSL.l, 100);
      }
      // Convert the adjusted new colors from HSL to RGB
      const adjustedNewColorRGB = hslToRgb(
        newColorHSL.h,
        newColorHSL.s,
        newColorHSL.l
      );
      // Convert the new colors and adjusted new colors from RGB to hex
      const newColor1 = rgbToHex(
        adjustedNewColorRGB.r,
        adjustedNewColorRGB.g,
        adjustedNewColorRGB.b
      ) as HexColor;
      return newColor1;
    });

    return newColors;
  };

  return {
    isHexColor,
    hexToRgb,
    rgbToHsl,
    hslToRgb,
    rgbToHex,
    hslToHex,
    hexToHsl,
    getBrightness,
    isBlack,
    isWhite,
    isGray,
    getRandomInRange,
    getContrastRatio,
    getFurthest,
    getRelativeLuminance,
    adjustLightnessForContrast,
    getMochromaticColors,
    getSplitComplementaryColors,
    getTriadicColors,
    getComplementaryColors,
    getAnalogousColors,
    getRandomColor,
    getNewColors,
  };
};
