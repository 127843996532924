import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider, FormattedMessage } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { Provider, useSelector, connect, ConnectedProps } from "react-redux";
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/solid";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";

import { ReactComponent as Logo } from "../../media/svg/logo/logo_white.svg";
//components
import MainLayout from "../../layouts/MainLayout";
import MyDropdown from "../../components/MyDropdown";
import MyDialog from "../../components/MyDialog";
import { MyDropdownItem } from "../../components/MyDropdown/type";
import BlockDrawer from "../../components/WebBuilder/BlockDrawer";
import DesignDrawer from "../../components/WebBuilder/DesignDrawer";
import PageDrawer from "../../components/WebBuilder/PageDrawer";
import PreviewDrawer from "../../components/WebBuilder/PreviewDrawer";
import BuilderRow from "../../components/WebBuilder/BuilderRow";
import type { WebBuilderPageProps } from "../type";
import type { BlockDrawerHandler } from "../../components/WebBuilder/BlockDrawer/type";
import type { DesignDrawerHandler } from "../../components/WebBuilder/DesignDrawer/type";
import type { PageDrawerHandler } from "../../components/WebBuilder/PageDrawer/type";
import type { PreviewDrawerHandler } from "../../components/WebBuilder/PreviewDrawer/type";
import type { MyDialogHandler } from "../../components/MyDialog/type";
import type { WebBuilderContextType, Design, BuilderHistory } from "./type";
//hook
import {
  useDesign,
  SectionStyle,
  EditorColors,
  EditorFontFamily,
  getEditorColorsKey,
  EditorFontSize,
} from "../../components/WebBuilder/Editor/hooks/useDesign";
import { useProject } from "../../hooks/useProject";
//models
import * as PageModel from "../../models/Page";
//store
import { store } from "../../stores";
import { projectActions } from "../../stores/actions";
//langs
import Langs from "../../langs/index";

//
const initDesign = {
  style: "Modern",
  colors: {
    primary: "#afd3d3",
    secondary: "#417575",
    tertiary: "#2f5454",
    tiny: "#6eaeae",
    white: "#ffffff",
    black: "#000000",
    image: undefined,
  },
  font: { title: "Noto Sans TC", content: "Noto Sans TC" },
  fontSize: "medium",
  button: "sharp",
  animation: "none",
};

export const WebBuilderContext = React.createContext<WebBuilderContextType>({
  design: initDesign,
  setDesign: () => {},
  openBlockDrawer: () => {},
  closeBlockDrawer: () => {},
  openDesignDrawer: () => {},
  openPageDrawer: () => {},
  addSection: () => {},
  editSection: () => {},
  removeSection: () => {},
  editSectionStyle: () => {},
  getSectionStyle: () => ({
    backgroundColor: EditorColors.white,
    title: {
      color: EditorColors.black,
      fontFamily: EditorFontFamily.title,
      fontSize: EditorFontSize.title,
    },
    content: {
      color: EditorColors.black,
      fontFamily: EditorFontFamily.content,
      fontSize: EditorFontSize.content,
    },
    button: {
      backgroundColor: EditorColors.black,
      color: EditorColors.white,
      borderRadius: "var(--editor-button-radius)",
    },
    link: {
      color: EditorColors.white,
    },
  }),
  getSectionBackground: () => undefined,
  handleUnpublish: () => {},
  handlePublish: () => {},
});

type Props = ConnectedProps<typeof connector> & WebBuilderPageProps;

const WebBuilderPage: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getLink: getProjectLink } = useProject();
  const searchParams = new URLSearchParams(location.search);
  const publishStatus = searchParams.get("status");
  const isPreview = searchParams.get("preview");
  const pageId = searchParams.get("pageId");

  const {
    styleContent,
    getSectionStyle: getDesignSectionStyle,
    editSectionStyle: editDesignSectionStyle,
  } = useDesign();

  const dialogRef = React.useRef<MyDialogHandler>(null);
  const blockDrawRef = React.useRef<BlockDrawerHandler>(null);
  const designDrawerRef = React.useRef<DesignDrawerHandler>(null);
  const pageDrawerRef = React.useRef<PageDrawerHandler>(null);
  const previewDrawerRef = React.useRef<PreviewDrawerHandler>(null);
  const undoRef = React.useRef<HTMLButtonElement>(null);
  const redoRef = React.useRef<HTMLButtonElement>(null);
  const mainRef = React.useRef<HTMLDivElement>(null);

  const project = useSelector(({ project }) => project);
  const [isEditing, setIsEditing] = React.useState(false);
  const [disabledUndo, setDisabledUndo] = React.useState(false);
  const [disabledRedo, setDisabledRedo] = React.useState(false);
  const [currentState, setCurrentState] = React.useState<number>(0);
  const [saves, setSaves] = React.useState<BuilderHistory[]>([]);
  const [page, setPage] = React.useState<any>(null);
  const [design, setDesign] = React.useState<Design>({
    ...initDesign,
    colors: { ...initDesign.colors, ...project?.info?.color },
    font: project?.info?.font || initDesign.font,
    fontSize: project?.info?.fontSize || initDesign.fontSize,
    button: project?.info?.button || initDesign.button,
    animation: project?.info?.animation || initDesign.animation,
  });
  //eslint-disable-next-line
  const [, setSections] = React.useState<any>([]);

  //request PageContent
  const requestPageContent = React.useCallback(async () => {
    let slug = !pageId ? "home" : pageId;
    try {
      const resp = await PageModel.get({
        projectId: project.id,
        slug: slug,
      });
      const data = resp.data;

      if (data.Items && data.Items.length > 0) {
        setPage(data.Items[0]);
        if (!!isPreview && mainRef.current) {
          setTimeout(() => openPreviewDrawer(), 1000);
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [pageId, project.id, isPreview]);

  //request page add / edit
  const requestPageEdit = React.useCallback(async () => {
    let preContent = page?.content || "";
    let preSubmitDiv = document.querySelector("div#pre-submit") as HTMLElement;
    if (!preSubmitDiv) {
      preSubmitDiv = document.createElement("div") as HTMLElement;
      preSubmitDiv.id = "pre-submit";
      preSubmitDiv.style.display = "none";
    }
    preSubmitDiv.innerHTML = preContent;
    preSubmitDiv
      .querySelectorAll(".builder-row-tool, div[data-holder='editor-grid']")
      .forEach((item) => {
        item.remove();
      });
    try {
      await PageModel.edit({
        ...page,
        content: preSubmitDiv.innerHTML,
      });
    } catch (error) {
      console.log(error);
    }
  }, [page]);

  //function set website unpublish
  const handleUnpublish = () => {
    if (!dialogRef?.current) return;
    dialogRef.current.setConfig({
      title: <FormattedMessage id="WebBuilderUnpublish" />,
      children: <FormattedMessage id="WebBuilderUnpublishDesc" />,
      buttons: [
        {
          label: <FormattedMessage id="LangsUnpublishWebsite" />,
          className:
            "bg-red-500 text-white rounded-none !px-4 !py-2 hover:bg-red-600",
          onClick: () => {
            navigate("/cms/unpublish");
          },
        },
        {
          label: <FormattedMessage id="LangsCancel" />,
          className:
            "text-indigo-500 border-2 border-indigo-500 rounded-none !px-4 !py-2",
        },
      ],
    });
    dialogRef.current.handleOpen();
  };

  const [dropdowns] = React.useState<MyDropdownItem[]>([
    {
      label: "Unpublish",
      disabled: project?.status !== "published",
      onClick: handleUnpublish,
    },
    { label: "SEO", href: "#" },
  ]);

  //function set website publish
  const handlePublish = React.useCallback(() => {
    if (!mainRef.current) return;
    const content = mainRef.current.querySelector(".content");
    if (!content) return;
    setPage((prev: any) => ({ ...prev, content: content.innerHTML }));
    navigate("/cms/publish");
    //eslint-disable-next-line
  }, [navigate]);

  //function for save currentState
  const handleSave = React.useCallback(
    (save: BuilderHistory) => {
      let newSaves = saves;
      let newCurrentState = saves.length - 1;
      newCurrentState++;
      newSaves[newCurrentState] = save;
      setSaves(newSaves);
      setCurrentState(newCurrentState);
    },
    [saves]
  );

  //function open BlockDrawer
  const openBlockDrawer = React.useCallback(
    (index: number, action: "add" | "edit", sectionType: string) => {
      if (!blockDrawRef?.current) return;
      blockDrawRef.current.handleOpen(index, action, sectionType);
    },
    [blockDrawRef]
  );

  //function close BlockDrawepreviepreviepreviewwwr
  const closeBlockDrawer = React.useCallback(() => {
    if (!blockDrawRef?.current) return;
    blockDrawRef.current.handleClose();
  }, []);

  //function open DesignDrawer
  const openDesignDrawer = React.useCallback(() => {
    if (!designDrawerRef?.current) return;
    designDrawerRef.current.handleOpen();
  }, [designDrawerRef]);

  //function open PageDrawer
  const openPageDrawer = React.useCallback(() => {
    if (!pageDrawerRef?.current) return;
    pageDrawerRef.current.handleOpen();
  }, [pageDrawerRef]);

  //function open PreviewDrawer
  const openPreviewDrawer = React.useCallback(() => {
    if (!mainRef.current) return;
    const content = mainRef.current.querySelector(".content");
    if (!content) return;
    setPage((prev: any) => ({ ...prev, content: content.innerHTML }));

    if (!previewDrawerRef?.current) return;
    previewDrawerRef.current.handleOpen();
    //eslint-disable-next-line
  }, [previewDrawerRef]);

  //function get section selected bgcolor
  const getSectionBackground = React.useCallback(
    (index: number | HTMLElement) => {
      if (!mainRef?.current) return;
      let section: HTMLElement;
      if (typeof index === "number") {
        section = mainRef.current.querySelector(
          `.section:nth-child(${index + 1})`
        ) as HTMLElement;
      } else {
        section = index;
      }
      if (!section) return;
      const background = section.style.backgroundColor;
      if (!background) return;
      const key = getEditorColorsKey(background);
      if (
        key &&
        ["primary", "secondary", "tertiary", "tiny", "white", "black"].includes(
          key
        )
      )
        return key as
          | "primary"
          | "secondary"
          | "tertiary"
          | "tiny"
          | "white"
          | "black";
      return undefined;
    },
    []
  );

  //function add section in builder
  const addSection = React.useCallback(
    (index: number, section: HTMLElement) => {
      if (!mainRef?.current) return;
      const sectionList = mainRef.current.querySelectorAll(".section");
      console.log(sectionList);
      console.log(index);
      sectionList[index].after(section);
      const newSectionList = mainRef.current.querySelectorAll(".section");
      for (let i = index; i < newSectionList.length; i++) {
        appendToolbar(newSectionList[i] as HTMLElement, i);
      }
      setSections(newSectionList);
      handleSave({ design, sections: newSectionList });
    },
    // eslint-disable-next-line
    [mainRef]
  );

  //function edit section in builder
  const editSection = React.useCallback(
    (index: number, section: HTMLElement) => {
      if (!mainRef?.current) return;
      const sectionList = mainRef.current.querySelectorAll(".section");
      sectionList[index].replaceWith(section);
      const newSectionList = mainRef.current.querySelectorAll(".section");
      setSections(newSectionList);
      appendToolbar(section, index);
      handleSave({ design, sections: newSectionList });
    },
    // eslint-disable-next-line
    [mainRef]
  );

  //function remove section in builder
  const removeSection = React.useCallback(
    (section: number | HTMLElement) => {
      console.log("removeSection:", section);
      if (!mainRef?.current) return;
      const sectionList = mainRef.current.querySelector(".content");
      if (!sectionList) return;
      if (typeof section === "number") {
        const sectionTarget = mainRef.current.querySelector(
          `.content > .section:nth-child(${section + 1})`
        ) as HTMLElement;
        console.log("sectionTarget", sectionTarget);
        sectionList.removeChild(sectionTarget);
      } else {
        sectionList.removeChild(section);
      }
      const newSectionList = mainRef.current.querySelectorAll(".section");
      handleSave({ design, sections: newSectionList });
    },
    // eslint-disable-next-line
    [mainRef]
  );

  // function to add toolbar in builder each row
  const appendToolbar = React.useCallback(
    (section: HTMLElement, index: number) => {
      if (section?.querySelector(".builder-row-tool")) {
        section.querySelector(".builder-row-tool")?.remove();
      }
      const div = document.createElement("div");
      div.classList.add("builder-row-tool");
      const root = ReactDOM.createRoot(div);
      section.appendChild(div);
      section.classList.add("builder-row");
      root.render(
        <Provider store={store}>
          <IntlProvider messages={Langs.en} locale="en" defaultLocale="en">
            <WebBuilderContext.Provider
              value={{
                design,
                setDesign: editDesign,
                openBlockDrawer,
                closeBlockDrawer,
                addSection,
                editSection,
                removeSection,
                editSectionStyle,
                openDesignDrawer,
                openPageDrawer,
                getSectionStyle,
                getSectionBackground,
                handlePublish,
                handleUnpublish,
              }}
            >
              <BuilderRow root={root} index={index} />
            </WebBuilderContext.Provider>
          </IntlProvider>
        </Provider>
      );
    },
    // eslint-disable-next-line
    [
      page,
      openBlockDrawer,
      addSection,
      editSection,
      removeSection,
      openDesignDrawer,
      openPageDrawer,
      design,
      setDesign,
      getSectionBackground,
    ]
  );

  // function for initialize design for builder includes color, font size font family, button, and animation
  const initDesignStyle = React.useCallback(() => {
    if (!mainRef.current) return;
    //check design style is exist
    let styleTag = document.getElementById("editor-design");
    if (!styleTag) {
      //create design style
      styleTag = document.createElement("style");
      styleTag.id = "editor-design";
      mainRef.current.prepend(styleTag);
    }

    let innerHTML = styleContent({
      colors: design.colors,
      font: design.font,
      fontSize: design.fontSize,
      button: design.button,
    });
    styleTag.innerHTML = innerHTML;
  }, [design, styleContent]);

  //function for get section style
  const getSectionStyle = React.useCallback(
    (
      selectedColor:
        | "primary"
        | "secondary"
        | "tertiary"
        | "tiny"
        | "white"
        | "black"
    ) => {
      return getDesignSectionStyle(selectedColor, design);
    },
    // eslint-disable-next-line
    [design.colors]
  );

  //function for edit section style
  const editSectionStyle = React.useCallback(
    (sectionOrIndex: HTMLElement | number, sectionStyle: SectionStyle) => {
      let section: HTMLElement;
      if (typeof sectionOrIndex === "number" && mainRef.current) {
        const sectionList = mainRef.current.querySelectorAll(".section");
        section = sectionList[sectionOrIndex] as HTMLElement;
      } else {
        section = sectionOrIndex as HTMLElement;
      }

      if (!section) return;
      editDesignSectionStyle(section, sectionStyle);

      // if sectionOrIndex is number , save
      if (typeof sectionOrIndex === "number" && mainRef.current) {
        const newSectionList = mainRef.current.querySelectorAll(".section");
        handleSave({ design, sections: newSectionList });
      }
    },
    [design, handleSave, editDesignSectionStyle]
  );

  //undo function for click undo button
  const handleUndo = React.useCallback(() => {
    if (isEditing === true) {
      document.execCommand("Undo");
    } else {
      if (!mainRef.current) return;
      const content = mainRef.current.querySelector(".content") as HTMLElement;
      const prevCurrentState = currentState - 1;
      const prevSections = saves[prevCurrentState].sections;
      const prevDesign = saves[prevCurrentState].design;
      if (prevSections && content) {
        content.innerHTML = "";
        prevSections.forEach((section: any) => {
          content.appendChild(section);
        });
        setSections(prevSections);
      }
      setDesign(prevDesign);
      setCurrentState(prevCurrentState);
    }
  }, [isEditing, currentState, saves]);

  //redo function for click redo button
  const handleRedo = React.useCallback(() => {
    if (isEditing === true) {
      document.execCommand("Redo");
    } else {
      if (!mainRef.current) return;
      const content = mainRef.current.querySelector(".content") as HTMLElement;
      const nextCurrentState = currentState + 1;
      const nextSections = saves[nextCurrentState].sections;
      const nextDesign = saves[nextCurrentState].design;
      if (nextSections && content) {
        content.innerHTML = "";
        nextSections.forEach((section: any) => {
          content.appendChild(section);
        });
        setSections(nextSections);
      }
      setDesign(nextDesign);
      setCurrentState(nextCurrentState);
    }
  }, [isEditing, currentState, saves]);

  // edit design style
  const editDesign = React.useCallback(
    (d: Design) => {
      if (!mainRef.current) return;
      const sectionList = mainRef.current.querySelectorAll(".section");
      if (!sectionList) return;
      setDesign(d);

      props.changeProject({
        ...project,
        info: {
          ...project.info,
          color: d.colors,
          font: d.font,
          fontSize: d.fontSize,
          button: d.button,
        },
      });

      handleSave({
        design: d,
        sections: sectionList,
      });
    },
    // eslint-disable-next-line
    [handleSave, project, setDesign]
  );

  //section add toolbar and init design style
  React.useEffect(() => {
    if (!mainRef?.current) return;
    //init design style (color and font family)
    initDesignStyle();
    // add toolbar for each section row
    const sectionList = mainRef.current.querySelectorAll(".section");
    sectionList.forEach((section: any, index: number) => {
      appendToolbar(section, index);
      let selectedColor = getEditorColorsKey(section.style.backgroundColor);
      if (!selectedColor) selectedColor = "white";
      let bgSelectedColor:
        | "primary"
        | "secondary"
        | "tertiary"
        | "tiny"
        | "white"
        | "black";
      bgSelectedColor = selectedColor as
        | "primary"
        | "secondary"
        | "tertiary"
        | "tiny"
        | "white"
        | "black";
      editSectionStyle(
        section,
        getSectionStyle(bgSelectedColor) as SectionStyle
      );
    });
    setSections(sectionList);

    handleSave({
      design,
      sections: sectionList,
    });

    //save content
    const content = mainRef.current.querySelector(".content");
    if (!content) return;
    requestPageEdit();

    //eslint-disable-next-line
  }, [page]);

  //publish page dialog
  React.useEffect(() => {
    if (!mainRef?.current) return;
    if (!dialogRef?.current) return;
    if (publishStatus === "published") {
      // capture the first image for project thumbnail
      const firstImage = mainRef.current.querySelector("img:first-child");
      if (firstImage) {
        const src = firstImage.getAttribute("src");
        props.changeProject({ ...project, thumbnail: src });
      }

      dialogRef.current.setConfig({
        title: <Logo className="w-20" />,
        size: "md",
        className: {
          content: "p-0",
          title: "bg-indigo-600 px-4 py-0 text-white",
          closeButton: "text-white",
        },
        children: (
          <>
            <div className="flex flex-col items-center justify-center bg-indigo-600 p-6 text-white">
              <p className="text-center">
                <FormattedMessage id="WebBuilderpublished" />
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="flex text-xl font-bold text-white underline"
                // href={`https://p.buildlink.com/${project.domain}`}
                href={getProjectLink()}
              >
                <span>{getProjectLink()}</span>{" "}
                <HiArrowTopRightOnSquare fontSize={24} />
              </a>
            </div>
            <div className="p-6 text-center">
              <h2 className="mb-4 text-2xl font-bold">
                <FormattedMessage id="WebBuilderpublishedNextLevel" />
              </h2>
              <p className="mb-4 text-sm">
                <FormattedMessage id="WebBuilderpublishedNextLevelDesc1" />
                <b>david-chu.net</b>
                <FormattedMessage id="WebBuilderpublishedNextLevelDesc2" />
              </p>
              <button className="rounded-none bg-pink-600 px-6 py-4 font-bold text-white">
                <FormattedMessage id="LangsGetYouDomain" />
              </button>
            </div>
          </>
        ),
      });
      dialogRef.current.handleOpen();
    }

    //eslint-disable-next-line
  }, [publishStatus, project.domain, mainRef, props]);

  //add content mutationObserver to save content and for undo redo function
  React.useEffect(() => {
    if (!mainRef?.current) return;
    const observer = new MutationObserver((mutations) => {
      //check mutations is editing in editor-input
      if (mutations.length === 1) {
        const element = mutations[0].target as HTMLElement;
        if (element.classList.contains("editor-input")) {
          setIsEditing(true);
        }
      } else {
        const lastMutation = mutations[mutations.length - 1]
          .target as HTMLElement;
        const dataholder = lastMutation.getAttribute("data-holder");
        if (
          dataholder === "editor-title" ||
          dataholder === "editor-content" ||
          dataholder === "editor-button" ||
          dataholder === "editor-link" ||
          dataholder === "editor-image"
        ) {
          setIsEditing(false);
        }
      }
      if (isEditing === true) {
        setDisabledUndo(!document.queryCommandEnabled("Undo"));
        setDisabledRedo(!document.queryCommandEnabled("Redo"));
      }
    });
    observer.observe(mainRef.current, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["value"],
    });

    return () => {
      observer.disconnect();
    };
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    requestPageContent();
  }, [requestPageContent]);

  React.useEffect(() => {
    initDesignStyle();
  }, [initDesignStyle]);

  return (
    <WebBuilderContext.Provider
      value={{
        design,
        setDesign: editDesign,
        openBlockDrawer,
        closeBlockDrawer,
        addSection,
        editSection,
        removeSection,
        editSectionStyle,
        openDesignDrawer,
        openPageDrawer,
        getSectionStyle,
        getSectionBackground,
        handlePublish,
        handleUnpublish,
      }}
    >
      <MainLayout selected="cms">
        <div className="flex h-full w-full flex-col bg-gray-200">
          {/*head control panel START*/}
          <header className="mx-auto my-3 grid w-[80%] grid-flow-col grid-cols-12 content-between">
            <div className="col-span-6 flex h-full flex-row gap-3">
              <button
                ref={undoRef}
                data-rel="undo"
                type="button"
                className="h-10 w-10 p-2 disabled:text-gray-400"
                onClick={handleUndo}
                {...(((isEditing !== true && currentState === 0) ||
                  (isEditing === true && disabledUndo === true)) && {
                  disabled: true,
                })}
              >
                <ArrowUturnLeftIcon />
              </button>
              <button
                ref={redoRef}
                data-rel="redo"
                type="button"
                className="h-10 w-10 p-2 disabled:text-gray-400"
                onClick={handleRedo}
                {...(((isEditing !== true &&
                  currentState === saves.length - 1) ||
                  (isEditing === true && disabledRedo === true)) && {
                  disabled: true,
                })}
              >
                <ArrowUturnRightIcon />
              </button>
              <div className="h-10 border-l border-gray-300 py-1 pl-4">
                <FormattedMessage id="LangsLastUpdatedAt" />
                2022-08-01 5:00 PM
              </div>
            </div>
            <div className="col-span-6 flex h-full flex-row content-center justify-end gap-3">
              <button
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm ring-2 ring-inset ring-indigo-600 hover:bg-indigo-50"
                onClick={openPreviewDrawer}
              >
                <FormattedMessage id="LangsPreview" />
              </button>
              <button
                onClick={handlePublish}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FormattedMessage id="LangsPublish" />
              </button>
              <MyDropdown list={dropdowns}>
                <FormattedMessage id="LangsMore" />
                <EllipsisVerticalIcon className="h-full w-5" />
              </MyDropdown>
            </div>
          </header>
          {/*head control panel END*/}

          {/*content START */}
          <main className="mb-6">
            <div className="builder-row grid w-[80%] grid-cols-3 bg-white p-3">
              <div className="col-span-1">
                <button className="bg-white px-2 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm ring-2 ring-inset ring-indigo-600 hover:bg-indigo-50">
                  <FormattedMessage id="LangsAddFavicon" />
                </button>
              </div>
              <div className="col-span-2 flex items-center justify-end">
                <span className="p-2">{getProjectLink(page?.slug)}</span>
                <button className="bg-red-600 px-2 py-1.5 text-sm font-semibold text-white shadow-sm ring-2 ring-inset ring-red-600 hover:bg-red-500">
                  <FormattedMessage id="LangsGetYouOwnDomain" />
                </button>
              </div>
            </div>
            <div ref={mainRef} className="main-container">
              {/*sections Start*/}
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: page?.content || "",
                }}
              />
              {/*sections End*/}
            </div>
          </main>
          <MyDialog ref={dialogRef} />
          <BlockDrawer ref={blockDrawRef} />
          <DesignDrawer ref={designDrawerRef} />
          <PageDrawer ref={pageDrawerRef} />
          <PreviewDrawer ref={previewDrawerRef} mainRef={mainRef} />
          {/*content END*/}
        </div>
      </MainLayout>
    </WebBuilderContext.Provider>
  );
};

const connector = connect(null, { ...projectActions });

export default connector(WebBuilderPage);
