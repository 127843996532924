import React, { Fragment } from "react";
import {
  FaBold,
  FaItalic,
  FaAlignLeft,
  FaAlignRight,
  FaAlignCenter,
  FaLink,
  FaList,
  FaListOl,
} from "react-icons/fa";
import { RiFontSize } from "react-icons/ri";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import Tooltip from "../../Tooltip";

import { EditorContext } from "./index";
import { FormattedMessage } from "react-intl";

const TextToolbar: React.FC = () => {
  const {
    isBold,
    isItalic,
    align,
    textStyle,
    isList,
    handleBold,
    handleItalic,
    handleAlign,
    handleTextStyle,
    handleList,
  } = React.useContext(EditorContext);

  return (
    <div className="editor-toolbar absolute left-0 top-0 z-50 flex -translate-y-[150%] flex-row text-base text-black drop-shadow-xl">
      <Tooltip content={<FormattedMessage id="LangsBold" />}>
        <button
          type="button"

          onClick={handleBold}
          className={classNames(
            isBold && "bg-indigo-600 text-white hover:bg-indigo-700",
            "flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
          )}
        >
          <FaBold />
        </button>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsItalic" />}>
        <button
          type="button"

          onClick={handleItalic}
          className={classNames(
            isItalic && "bg-indigo-600 text-white hover:bg-indigo-700",
            "flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
          )}
        >
          <FaItalic />
        </button>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsAlign" />}>
        <Menu as="div" className="relative">
          <Menu.Button className="flex h-12 w-12 items-center justify-center bg-gray-100 p-1 text-center hover:bg-gray-200">
            {align === "right" && <FaAlignRight />}
            {align === "left" && <FaAlignLeft />}
            {align === "center" && <FaAlignCenter />}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className="absolute right-0 mt-2.5 flex origin-top-right flex-col rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
              style={{ zIndex: 999 }}
            >
              <Menu.Item>
                <button
                  type="button"
                  title="align left"
                  onClick={() => handleAlign("left")}
                  className={classNames(
                    align === "left" &&
                    "bg-indigo-600 text-white hover:bg-indigo-700",
                    "flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
                  )}
                >
                  <FaAlignLeft />
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  type="button"
                  title="align center"
                  onClick={() => handleAlign("center")}
                  className={classNames(
                    align === "center" &&
                    "bg-indigo-600 text-white hover:bg-indigo-700",
                    "flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
                  )}
                >
                  <FaAlignCenter />
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  type="button"
                  title="align right"
                  onClick={() => handleAlign("right")}
                  className={classNames(
                    align === "right" &&
                    "bg-indigo-600 text-white hover:bg-indigo-700",
                    "flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
                  )}
                >
                  <FaAlignRight />
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </Tooltip>
      <Tooltip content="Style">
        <Menu as="div" className="relative">
          <Menu.Button className="flex h-12 w-12 items-center justify-center bg-gray-100 p-1 text-center hover:bg-gray-200">
            <RiFontSize />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute -left-20 z-50 mt-2.5 flex w-48 origin-top-right flex-col rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <Menu.Item>
                <button
                  type="button"
                  title="Large heading"
                  onClick={() => handleTextStyle("large")}
                  className={classNames(
                    textStyle === "large" && "text-indigo-600",
                    "flex h-12 w-full items-center justify-center bg-gray-100 p-1 hover:bg-gray-200 hover:text-indigo-600"
                  )}
                >

                  <FormattedMessage id="LangsLargeHeading" />

                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  type="button"
                  title="Medium heading"
                  onClick={() => handleTextStyle("medium")}
                  className={classNames(
                    textStyle === "medium" && "text-indigo-600",
                    "flex h-12 w-full items-center justify-center bg-gray-100 p-1 hover:bg-gray-200 hover:text-indigo-600"
                  )}
                >
                  <FormattedMessage id="LangsMediumHeading" />

                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  type="button"
                  title="Small heading"
                  onClick={() => handleTextStyle("small")}
                  className={classNames(
                    textStyle === "small" && "text-indigo-600",
                    "flex h-12 w-full items-center justify-center bg-gray-100 p-1 hover:bg-gray-200 hover:text-indigo-600"
                  )}
                >
                  <FormattedMessage id="LangsSmallHeading" />

                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  type="button"
                  title="normal"
                  onClick={() => handleTextStyle("normal")}
                  className={classNames(
                    textStyle === "normal" && "text-indigo-600",
                    "flex h-12 w-full items-center justify-center bg-gray-100 p-1 hover:bg-gray-200 hover:text-indigo-600"
                  )}
                >
                  <FormattedMessage id="LangsNormal1" />

                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsList" />}>
        <button
          type="button"

          onClick={() =>
            handleList(isList === false ? "ul" : isList === "ul" ? "ol" : false)
          }
          className={classNames(
            isList !== false && "bg-indigo-600 text-white hover:bg-indigo-700",
            "flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
          )}
        >
          {isList === "ul" || isList === false ? <FaList /> : <FaListOl />}
        </button>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsLink" />}>
        <button
          type="button"

          className="flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
        >
          <FaLink />
        </button>
      </Tooltip>
    </div>
  );
};

export default TextToolbar;
