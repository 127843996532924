import React from "react";

import classNames from "classnames";

import { MapPinIcon, ArrowRightIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, MinusIcon, } from "@heroicons/react/20/solid";

import businessImg from "../../media/img/marketing00.png"
import { FormattedMessage } from "react-intl";


const setList = [
  {
    id: "01",
    title: "BusinessListingsUpgradePlan",
    href: "/cms/upgrade",
    butText: "LangsUpgrade",
    status: "Done",
  },
  {
    id: "02",
    title: "BusinessListingsGetDomain",
    href: "/cms/upgrade",
    butText: "LangsSecureDomain",
    status: "",
  },
];

const BusinessListingsPage: React.FC = () => {
  return (
    <div className="px-4 py-10 sm:px-6 lg:px-8 max-w-5xl mx-auto text-zinc-800">
      <h1 className="text-4xl font-bold mb-16">
        <FormattedMessage id="LangsBusinessListings" />
      </h1>
      <div className="bg-white mb-15">
        <div className="flex flex-col-reverse justify-between items-center px-5 pt-5 border-b-2 border-gray-100  md:flex-row md:px-10 md:pt-0 ">
          <div className="text-center md:">
            <h3 className="mb-2  text-lg font-bold text-center md:mb-4 md:text-2xl md:text-left">
              <FormattedMessage id="BusinessListingsTitle" />

            </h3>
            <p className="mb-6 text-base font-normal leading-normal text-center md:mb-0 md:text-left text-neutral-500">
              <FormattedMessage id="BusinessListingsDescription" />


            </p>
          </div>
          <div className="pb-5  md:pt-10 md:pl-10">
            <div className="flex justify-center items-center  w-16 h-16 bg-gray-100 rounded md:hidden ">
              <MapPinIcon className="w-8 h-8 text-blue-600" />

            </div>
            <img src={businessImg} alt="" className="hidden w-auto  md:block" />
          </div>
        </div>
        <ul className="px-6">

          {setList.map((list) => (
            <li className="  flex justify-between items-center py-5 text-left border-b-2 border-gray-100 border-solid md:py-4">
              <a
                className={classNames(list.status === "Done" ? "cursor-default" : "cursor-pointer ", "w-full max-w-full text-base leading-5  text-blue-600  truncate decoration-white")}
                href={list.href}  >
                <div className="flex justify-between items-center  w-full leading-5  text-blue-600 whitespace-nowrap ">
                  <div className="flex items-center mr-5 leading-5  text-gray-300 whitespace-normal ">
                    {list.status === "Done" ?
                      (<CheckCircleIcon
                        className="text-gray-800 mr-4 h-5 w-5 text-left "
                        aria-hidden="true"
                      />) :
                      (<MinusIcon
                        className="text-gray-300 mr-4 h-5 w-5 "
                        aria-hidden="true"
                      />)}

                    <div className="flex flex-col p-0 m-0 leading-5  text-gray-400 ">
                      <span className={classNames(list.status === "Done" ? "text-gray-400" : "text-gray-800", "text-base font-normal leading-normal  ")}>
                        <FormattedMessage id={list.title} />
                      </span>
                    </div>
                  </div>

                  {list.status === "Done" ? (<p className="text-gray-300">{list.status}</p>

                  ) : (
                    <>
                      <button type="button" className="flex justify-center items-center w-12 h-12  text-sm text-center cursor-pointer text-blue-600 whitespace-nowrap rounded-none  md:hidden focus:text-blue-600 hover:text-blue-700">
                        <ArrowRightIcon
                          className="white -mr-1 h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                      <button className="hidden  md:inline-flex overflow-hidden relative gap-2 justify-center items-center py-1 px-3 m-0 min-w-0 max-w-full h-8 text-sm font-bold text-center text-white whitespace-nowrap bg-blue-600 rounded-none border-2 border-blue-600 border-solid appearance-none cursor-pointer select-none">
                        <span className="text-sm font-bold text-center text-white cursor-pointer truncate">
                          <FormattedMessage id={list.butText} />
                        </span>
                      </button>
                    </>
                  )}
                </div>
              </a>
            </li>

          ))}
          <li className="  flex justify-between items-center py-5 text-left  md:py-4">
            <div className="text-left text-gray-300 flex items-center mr-5 whitespace-normal">
              <LockClosedIcon className="text-gray-300 mr-4 h-5 w-5 " aria-hidden="true" />
              <span>
                <FormattedMessage id="BusinessListingsFinishSteps" />
              </span>
            </div>
          </li>

        </ul>
      </div>
    </div >
  );
};
export default BusinessListingsPage;
