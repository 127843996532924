import { useEffect, useState } from "react";
import moment from "moment";
import classNames from "classnames";

//icon
import {
  HiCheck,
  // HiMinusSmall, HiOutlineLockClosed
} from "react-icons/hi2";
import { AiFillCaretDown } from "react-icons/ai";
import { FiInfo } from "react-icons/fi";

//images
import statistics_promo_cover from "../../../media/svg/statisticPage/buildLink-statistics-teaser.svg";
import statistics_promo_blur from "../../../media/svg/statisticPage/blurry-statistics-content.png";
import { FormattedMessage } from "react-intl";

const visitDatas = [
  {
    id: 1,
    title: "Visits",
    data: 0,
    dataPercentage: +4,
    planRequired: false,
  },
  {
    id: 2,
    title: "Visitors",
    data: 40,
    dataPercentage: 0,
    planRequired: true,
  },
  {
    id: 3,
    title: "Engagement Rate",
    data: 22,
    dataPercentage: -14,
    planRequired: true,
  },
];

const StatisticPagePublished: React.FC = () => {
  // Date range State
  const [selectedValue, setSelectedValue] = useState<string>("week");
  const [dataRange, setDateRange] = useState<[number, number]>([
    +moment().subtract(1, "months").startOf("month").format("x"),
    +moment().subtract(1, "months").endOf("month").format("x"),
  ]);

  //Unlock State
  const [isUnlocked] = useState<boolean>(false);

  useEffect(() => {
    if (selectedValue === "week") {
      // setDateRange("week");
      setDateRange([
        +moment().subtract(1, "weeks").startOf("week").format("x"),
        +moment().subtract(1, "weeks").endOf("week").format("x"),
      ]);
    } else if (selectedValue === "month") {
      // setDateRange("month");
      setDateRange([
        +moment().subtract(1, "months").startOf("month").format("x"),
        +moment().subtract(1, "months").endOf("month").format("x"),
      ]);
    } else if (selectedValue === "threeMonths") {
      // setDateRange("threeMonths");
      setDateRange([
        +moment().subtract(3, "months").startOf("month").format("x"),
        +moment().format("x"),
      ]);
    } else if (selectedValue === "year") {
      // setDateRange("year");
      setDateRange([
        +moment().subtract(1, "years").format("x"),
        +moment().format("x"),
      ]);
    }
  }, [selectedValue]);

  return (
    <>
      {/* whole group */}
      <div className="grid grid-cols-12 gap-5">
        {/* title & date group */}
        <div className="col-span-full mb-2 flex flex-col items-stretch md:flex-row md:items-center">
          {/* title */}
          <div className="flex-1 md:mr-20 ">
            <h3 className="mb-1 flex-1 text-lg font-bold text-zinc-800">
              {isUnlocked ? (<FormattedMessage id="LangsOverview" />) : (<FormattedMessage id="LangsSnapshot" />)}
            </h3>
            {/* description show in unlocked state */}
            {isUnlocked ? (
              <p className="mb-1 flex-1 text-base font-normal leading-normal text-zinc-800">
                <FormattedMessage id="StatisticpublishCheckUsers" />

              </p>
            ) : null}
          </div>
          {/* date group */}
          <div className="flex flex-col-reverse items-stretch gap-5 md:flex-row md:items-center">
            {/* date */}
            <p className="text-right text-base font-normal leading-normal text-zinc-800">
              {moment(new Date(dataRange[0])).format("MMM D, YYYY")} -
              {moment(new Date(dataRange[1])).format("MMM D, YYYY")}
            </p>

            {/* date select group */}
            <div className="inline-flex flex-col">
              {/* date select flex */}
              <div className="relative h-12 w-full cursor-pointer overflow-hidden border-2 border-gray-300 transition-all duration-300 ease-in-out">
                {/* date select */}
                <select
                  className={classNames(
                    isUnlocked ? "" : "pointer-events-none",
                    "block h-full w-full whitespace-nowrap border-0 bg-gray-200 pl-4 pr-10 text-zinc-400"
                  )}
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.target.value)}
                >
                  <option value="week"><FormattedMessage id="LangsLastWeek" /></option>
                  <option value="month"><FormattedMessage id="LangsLastMonth" /></option>
                  <option value="threeMonths"><FormattedMessage id="LangsLast3Month" /></option>
                  <option value="year"><FormattedMessage id="LangsLastYear" /></option>
                </select>
                {/* icon */}
                <span className="absolute right-2 top-3.5 inline-block h-5 w-5 cursor-default">
                  <AiFillCaretDown className="h-4 w-4 text-gray-900" />
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* main content */}
        <div className="col-span-full">
          {/* Visits card Group */}
          <div className="mb-5 flex">
            {/* Visits card map */}
            {visitDatas.map((visitData, index: number) => (
              <div
                key={visitData.id}
                style={
                  !isUnlocked && visitData.planRequired
                    ? { filter: "blur(6px)" }
                    : {}
                }
                className={classNames(
                  !isUnlocked && visitData.planRequired
                    ? "relative flex flex-1 before:absolute before:inset-0 before:bg-gray-200 before:opacity-30 before:content-['']"
                    : "",
                  "relative flex flex-1"
                )}
              >
                {/* visits button */}
                <div
                  className={classNames(
                    visitData.title === "Visits"
                      ? "border-b-2 border-indigo-600 bg-white"
                      : "bg-gray-200",
                    "flex flex-1 cursor-pointer py-5"
                  )}
                >
                  <div className="flex-auto px-4">
                    {/* icon */}
                    <div className="flex place-content-end">
                      <span className="">
                        <span className="inline-block h-5 w-5">
                          <FiInfo className="h-full w-full text-right text-sm text-black" />
                        </span>
                      </span>
                    </div>
                    {/* content */}
                    <div className="-mt-4 justify-self-center">
                      <p className="text-center text-base font-bold leading-normal text-black">
                        {visitData.title}
                      </p>
                      <h2 className="text-center text-2xl font-bold leading-normal text-black">
                        {visitData.data}
                      </h2>
                      <p
                        className={classNames(
                          visitData.dataPercentage > 0
                            ? "text-green-600"
                            : visitData.dataPercentage < 0
                              ? "text-red-600"
                              : "text-gray-600",
                          "text-center text-sm font-bold leading-normal"
                        )}
                      >
                        {visitData.dataPercentage > 0 ? "+" : ""}
                        {visitData.dataPercentage}%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Unlock Main card */}
          <div className="mb-5 rounded bg-white shadow-lg">
            {/* Content section 01 */}
            <div className="flex flex-col p-5 md:flex-row md:p-10">
              {/* section 01 left content */}
              <div className="md:mr-6 lg:mr-16">
                {/* section 01 title */}
                <h3 className="mb-2 text-left text-2xl font-bold leading-normal text-zinc-800 md:mb-3">
                  <FormattedMessage id="StatisticpublishGetMore" />

                </h3>
                {/* section 01 description */}
                <p className="mb-5 text-left text-base font-normal leading-normal text-zinc-800">
                  <FormattedMessage id="StatisticpublishGetMoreDescription" />

                  {/*eslint-disable-next-line */}
                  <a
                    className="max-w-full cursor-pointer truncate text-left text-base leading-5 text-indigo-600 transition-all duration-300 ease-in-out hover:text-indigo-700 hover:underline"
                    href="#"
                  >
                    &nbsp;
                    <FormattedMessage id="LangsLearnMore" />

                  </a>
                </p>
                {/* section 01 List */}
                <ul className="mb-10">
                  {/* List 01 */}
                  <li className="mb-2 flex list-none">
                    <span className="mr-2 mt-1 inline-block h-5 w-5">
                      <HiCheck className="h-full w-full text-zinc-900" />
                    </span>
                    <span className="w-full">
                      <div className="flex">
                        <p className="text-left text-base font-normal leading-normal text-zinc-800">
                          <FormattedMessage id="LangsVisitsVisitorsAndEngagementRate" />

                        </p>
                      </div>
                    </span>
                  </li>
                  {/* List 02 */}
                  <li className="mb-2 flex list-none">
                    <span className="mr-2 mt-1 inline-block h-5 w-5">
                      <HiCheck className="h-full w-full text-zinc-900" />
                    </span>
                    <span className="flex w-full">
                      <p className="text-left text-base font-normal leading-normal text-zinc-800">
                        <FormattedMessage id="LangsSourcesOfTraffic" />

                      </p>
                    </span>
                  </li>
                  {/* List 03 */}
                  <li className="mb-2 flex list-none">
                    <span className="mr-2 mt-1 inline-block h-5 w-5">
                      <HiCheck className="h-full w-full text-zinc-900" />
                    </span>
                    <span className="flex w-full">
                      <p className="text-left text-base font-normal leading-normal text-zinc-800">
                        <FormattedMessage id="LangsBestPerformingPages" />

                      </p>
                    </span>
                  </li>
                  {/* List 04 */}
                  <li className="mb-2 flex list-none">
                    <span className="mr-2 mt-1 inline-block h-5 w-5">
                      <HiCheck className="h-full w-full text-zinc-900" />
                    </span>
                    <span className="flex w-full">
                      <p className="text-left text-base font-normal leading-normal text-zinc-800">
                        <FormattedMessage id="StatisticpublishAllMetrics" />

                      </p>
                    </span>
                  </li>
                </ul>
                {/* Unlock Statistics button go to price page */}
                {/*eslint-disable-next-line */}
                <a
                  href="#"
                  className="mb-4 inline-flex h-auto w-full max-w-full cursor-pointer items-center justify-center bg-pink-600 px-5 py-3 transition-all duration-300 ease-in-out hover:bg-pink-700 md:w-auto"
                >
                  <span className="truncate text-base font-bold text-white">
                    <FormattedMessage id="LangsUnlockStatistics" />

                  </span>
                </a>
              </div>
              {/* section 01 image */}
              <img className="" src={statistics_promo_cover} alt="" />
            </div>
          </div>
          {/* Unlock Statistics function image */}
          <img src={statistics_promo_blur} alt="" className="w-full" />
        </div>
      </div>
    </>
  );
};
export default StatisticPagePublished;
