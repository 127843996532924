import React from "react";
import MyCookiesDrawer from "../../../components/Setting/MyCookiesDrawer";

import type { MyCookiesDrawerHandler } from "../../../components/Setting/MyCookiesDrawer/type";
import { FormattedMessage } from "react-intl";

const CookieSettingsPage: React.FC = () => {
  const cookiesDialogRef = React.useRef<MyCookiesDrawerHandler>(null);

  const handleCookiesOpen = () => {
    if (!cookiesDialogRef.current) return;
    cookiesDialogRef.current?.handleOpen();
  };

  return (
    <div>
      <h2 className="mb-5 font-sans text-lg font-bold text-zinc-800">
        <FormattedMessage id="LangsCookieSettings" />
      </h2>
      <p className="mb-3 font-sans text-base font-normal leading-normal text-zinc-800">
        <FormattedMessage id="CookieSettingsDescription" />
      </p>
      {/* TODO: 開啟Manage cookies */}
      <button
        onClick={handleCookiesOpen}
        type="button"
        className="mb-4 h-12 cursor-pointer select-none appearance-none  items-center justify-center gap-2 rounded-none border-2 border-solid border-blue-600 bg-blue-600 px-5 text-center text-base font-bold text-white  "
      >
        <span className="cursor-pointer truncate text-center font-bold text-white">
          <FormattedMessage id="LangsManageCookies" />
        </span>
      </button>
      <MyCookiesDrawer ref={cookiesDialogRef} />
    </div>
  );
};
export default CookieSettingsPage;
