import { API } from "aws-amplify";

type GetProps = {
  projectId: string;
  status?: "unpublish" | "publish" | "deleted";
  isRecursive?: "1";
  slug?: string;
};

export const get: any = async (params: GetProps) => {
  return await API.get("buildLinkApi", "/page/list", {
    response: true,
    queryStringParameters: params || {},
  });
};

export const getOne: any = async (params?: object) => {
  return await API.get("buildLinkApi", "/page/data", {
    response: true,
    queryStringParameters: params || {},
  });
};

export const add: any = async (params: object) => {
  return await API.post("buildLinkApi", "/page/data", {
    response: true,
    body: params,
  });
};

export const submitDrawer: any = async (params: object) => {
  return await API.post("buildLinkApi", "/page/drawer", {
    response: true,
    body: params,
  });
};

export const edit: any = async (params: object) => {
  return await API.put("buildLinkApi", "/page/data", {
    response: true,
    body: params,
  });
};
