import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, ConnectedProps, useSelector } from "react-redux";
// Pages
import PersonsPage from "./PersonsPage";
import PurposePage from "./PurposePage";
import NamePage from "./NamePage";
import CategoryPage from "./CategoryPage";
import GoalPage from "./GoalPage";
import TopicPage from "./TopicPage";
import StylePage from "./StylePage";
import ColorPage from "./ColorPage";
import SubpagesPage from "./SubpagesPage";
import SelectPage from "./SelectPage";
import UpgradePage from "./UpgradePage";
import NotFoundPage from "../NotFoundPage";
//store
import { projectActions, wizardActions } from "../../stores/actions";

type Props = ConnectedProps<typeof connector> & {};

type WizardContextType = {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

export const WizardContext = React.createContext<WizardContextType>({
  activeStep: 0,
  setActiveStep: () => {},
});

const WizardIndexPage: React.FC<Props> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [stepper] = React.useState([
    "/wizard/persons",
    "/wizard/purpose-refinement",
    "/wizard/name",
    "/wizard/business-category",
    "/wizard/goal",
    "/wizard/topic",
    "/wizard/style",
    "/wizard/color-palette",
    "/wizard/subpages",
    "/wizard/website-select",
    "/wizard/upgrade",
  ]);
  const [activeStep, setActiveStep] = React.useState(
    stepper.indexOf(location.pathname)
  );

  const formData = useSelector(({ wizard }) => wizard);

  React.useEffect(() => {
    if (formData.size === undefined && activeStep > 0) {
      setActiveStep(0);
      return;
    }
    if (formData.purpose === undefined && activeStep > 1) {
      setActiveStep(0);
      return;
    }
    if (formData.name === undefined && activeStep > 2) {
      setActiveStep(0);
      return;
    }
    if (
      formData.business_category === undefined &&
      activeStep > 3 &&
      formData.purpose !== undefined &&
      !["personal project", "hobby", "skip"].includes(formData.purpose)
    ) {
      setActiveStep(0);
      return;
    }
    if (formData.goal.length === 0 && activeStep > 4) {
      setActiveStep(0);
      return;
    }
    if (formData.topic === undefined && activeStep > 5) {
      setActiveStep(0);
      return;
    }
    if (formData.style === undefined && activeStep > 6) {
      setActiveStep(0);
      return;
    }
    if (formData.color === undefined && activeStep > 7) {
      setActiveStep(0);
      return;
    }
    // console.log("formData", formData);
    //eslint-disable-next-line
  }, [formData]);

  React.useEffect(() => {
    if (location.pathname !== stepper[activeStep]) {
      navigate(stepper[activeStep]);
    }
  }, [activeStep, navigate, location.pathname, stepper]);

  return (
    <WizardContext.Provider value={{ activeStep, setActiveStep }}>
      {activeStep === 0 ? (
        <PersonsPage />
      ) : activeStep === 1 ? (
        <PurposePage />
      ) : activeStep === 2 ? (
        <NamePage />
      ) : activeStep === 3 ? (
        <CategoryPage />
      ) : activeStep === 4 ? (
        <GoalPage />
      ) : activeStep === 5 ? (
        <TopicPage />
      ) : activeStep === 6 ? (
        <StylePage />
      ) : activeStep === 7 ? (
        <ColorPage />
      ) : activeStep === 8 ? (
        <SubpagesPage />
      ) : activeStep === 9 ? (
        <SelectPage />
      ) : activeStep === 10 ? (
        <UpgradePage />
      ) : (
        <NotFoundPage />
      )}
    </WizardContext.Provider>
  );
};

const connector = connect(null, { ...projectActions, ...wizardActions });

export default connector(WizardIndexPage);
