import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { projectActions } from "../../../stores/actions";
import MyToast from "../../../components/MyToast";
import type { MyToastHandler } from "../../../components/MyToast/type";

const languages = [
  {
    id: "en",
    title: "English",
    status: "active",
  },
  {
    id: "zh-tw",
    title: "繁體中文",
    status: "active",
  },
  {
    id: "zh-cn",
    title: "簡體中文",
    status: "active",
  },
];

type Props = ConnectedProps<typeof connector>;

const SettingGeneralPage: React.FC<Props> = (props) => {
  const toastRef = React.useRef<MyToastHandler>(null);
  const project = useSelector(({ project }) => project);
  const [selected, setSelected] = useState<string>(
    project?.language || languages[0]["id"]
  );

  const handleChangeLanguage = (language: any) => {
    setSelected(language.id);

    props
      .changeProject({
        ...project,
        language: language.id,
      })
      .then(() => {
        toastRef.current?.handleToastOpen();
      });
  };

  return (
    <>
      <MyToast ref={toastRef} timeout={10000}>
        <FormattedMessage id="WebsiteLanguageChangeSuccessMessage" />
      </MyToast>
      <div className="mb-16  md:flex md:justify-between md:gap-10">
        <p className="font-bold leading-normal md:w-1/5 ">
          <FormattedMessage id="LangsWebsiteLanguage" />
        </p>
        <div className="mb-5 md:flex-1">
          <div className="relative mb-5 ">
            <div className="inline-flex flex-col">
              <Listbox value={selected} onChange={handleChangeLanguage}>
                <div className="relative mt-1 w-40">
                  <Listbox.Button className="relative w-full cursor-default rounded-none border-2 border-solid border-zinc-200 bg-white py-2 pl-3 pr-10 text-left hover:border-gray-300 focus:border-2 focus:border-solid focus:border-blue-600  sm:text-sm">
                    <span className="block truncate">
                      {selected &&
                        languages.find((l) => l?.id === selected)?.title}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {languages.map((language, languageIdx) => (
                        <Listbox.Option
                          key={languageIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-4 pr-10 ${
                              active ? "bg-gray-300 " : "text-gray-900"
                            }`
                          }
                          value={language}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {language.title}
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>

          <p>
            <FormattedMessage id="SettingGeneralDescription" />
          </p>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...projectActions });

export default connector(SettingGeneralPage);
