import _ from "lodash";
import { useColor, HexColor } from "../../../../hooks/useColor";

export type Design = {
  colors: {
    primary: HexColor | string;
    secondary: HexColor | string;
    tertiary: HexColor | string;
    tiny: HexColor | string;
    white: HexColor | string;
    black: HexColor | string;
    image: any;
  };
  font: {
    title: string;
    content: string;
  };
  fontSize: string;
  button: string;
};

export enum EditorColors {
  primary = "var(--editor-colors-primary)",
  secondary = "var(--editor-colors-secondary)",
  tertiary = "var(--editor-colors-tertiary)",
  tiny = "var(--editor-colors-tiny)",
  white = "var(--editor-colors-white)",
  black = "var(--editor-colors-black)",
}

export enum EditorFontFamily {
  title = "var(--editor-title-fontFamily)",
  content = "var(--editor-content-fontFamily)",
}

export enum EditorFontSize {
  title = "var(--editor-title-fontSize)",
  content = "var(--editor-content-fontSize)",
}

export const getEditorColorsKey = (value: string): string | undefined => {
  for (const key in EditorColors) {
    if (EditorColors[key as keyof typeof EditorColors] === value) {
      return key;
    }
  }
  return undefined;
};

export type SectionStyle = {
  backgroundColor: EditorColors;
  title: {
    color: EditorColors | string;
    fontFamily: EditorFontFamily.title;
    fontSize: EditorFontSize.title;
  };
  content: {
    color: EditorColors | string;
    fontFamily: EditorFontFamily.content;
    fontSize: EditorFontSize.content;
  };
  button: {
    backgroundColor: EditorColors;
    color: EditorColors;
    borderRadius: "var(--editor-button-radius)";
  };
  link: {
    color: EditorColors;
  };
};

export const defaultSectionStyle: SectionStyle = {
  backgroundColor: EditorColors.white,
  title: {
    color: EditorColors.black,
    fontFamily: EditorFontFamily.title,
    fontSize: EditorFontSize.title,
  },
  content: {
    color: EditorColors.black,
    fontFamily: EditorFontFamily.content,
    fontSize: EditorFontSize.content,
  },
  button: {
    backgroundColor: EditorColors.black,
    color: EditorColors.white,
    borderRadius: "var(--editor-button-radius)",
  },
  link: {
    color: EditorColors.black,
  },
};

export const useDesign = () => {
  const { getContrastRatio, getFurthest, hexToRgb } = useColor();

  const styleContent = (design: Design): string => {
    let innerHTML = "";
    //color style
    innerHTML = _.reduce(
      design.colors,
      (prev: string, color: string, key: string) => {
        prev += `--editor-colors-${key}: ${color}; \n`;
        return prev;
      },
      innerHTML
    );
    //font style
    innerHTML += `--editor-title-fontFamily: "${design.font.title}", sans-serif;
    --editor-content-fontFamily: "${design.font.content}", sans-serif ; \n`;
    //font size
    if (design.fontSize === "small") {
      innerHTML += `--editor-title-fontSize: 40px; \n`;
      innerHTML += `--editor-content-fontSize: 16px; \n`;
    } else if (design.fontSize === "normal") {
      innerHTML += `--editor-title-fontSize: 48px; \n`;
      innerHTML += `--editor-content-fontSize: 18px; \n`;
    } else if (design.fontSize === "medium") {
      innerHTML += `--editor-title-fontSize: 56px; \n`;
      innerHTML += `--editor-content-fontSize: 20px; \n`;
    } else if (design.fontSize === "large") {
      innerHTML += `--editor-title-fontSize: 64px; \n`;
      innerHTML += `--editor-content-fontSize: 20px; \n`;
    }

    //button radius
    if (design.button === "sharp") {
      innerHTML += `--editor-button-radius: 0; \n`;
    } else if (design.button === "rounded") {
      innerHTML += `--editor-button-radius: 5px; \n`;
    } else if (design.button === "oval") {
      innerHTML += `--editor-button-radius: 500px; \n`;
    }
    innerHTML = `:root{\n ${innerHTML} \n}`;

    return innerHTML;
  };

  const getSectionStyle = (
    selectedColor:
      | "primary"
      | "secondary"
      | "tertiary"
      | "tiny"
      | "white"
      | "black",
    design: Design
  ) => {
    if (selectedColor === "white") {
      const colorMatch =
        getContrastRatio(
          hexToRgb(design.colors.primary),
          hexToRgb("#FFFFFF" as HexColor)
        ) >= 4.5
          ? EditorColors.primary
          : EditorColors.black;
      return {
        backgroundColor: EditorColors[selectedColor]
          ? EditorColors[selectedColor]
          : EditorColors.white,
        title: {
          color: colorMatch,
          fontFamily: EditorFontFamily.title,
          fontSize: EditorFontSize.title,
        },
        content: {
          color: EditorColors.black,
          fontFamily: EditorFontFamily.content,
          fontSize: EditorFontSize.content,
        },
        button: {
          backgroundColor: colorMatch,
          color: EditorColors.white,
          borderRadius: "var(--editor-button-radius)",
        },
        link: {
          color: colorMatch,
        },
      };
    } else if (selectedColor === "black") {
      const colorMatch =
        getContrastRatio(
          hexToRgb(design.colors.primary),
          hexToRgb("#000000" as HexColor)
        ) >= 4.5
          ? EditorColors.primary
          : EditorColors.white;
      return {
        backgroundColor: EditorColors.black,
        title: {
          color: colorMatch,
          fontFamily: EditorFontFamily.title,
          fontSize: EditorFontSize.title,
        },
        content: {
          color: EditorColors.white,
          fontFamily: EditorFontFamily.content,
          fontSize: EditorFontSize.content,
        },
        button: {
          backgroundColor: colorMatch,
          color: EditorColors.white,
          borderRadius: "var(--editor-button-radius)",
        },
        link: {
          color: colorMatch,
        },
      };
    } else {
      const colorMatch = getFurthest({
        color: design.colors[selectedColor] as HexColor,
        sample: ["#FFFFFF" as HexColor, "#000000" as HexColor],
      });
      return {
        backgroundColor: EditorColors[selectedColor]
          ? EditorColors[selectedColor]
          : EditorColors.white,
        title: {
          color:
            colorMatch === "#FFFFFF" ? EditorColors.white : EditorColors.black,
          fontFamily: EditorFontFamily.title,
          fontSize: EditorFontSize.title,
        },
        content: {
          color:
            colorMatch === "#FFFFFF" ? EditorColors.white : EditorColors.black,
          fontFamily: EditorFontFamily.content,
          fontSize: EditorFontSize.content,
        },
        button: {
          backgroundColor:
            colorMatch === "#FFFFFF" ? EditorColors.white : EditorColors.black,
          color:
            colorMatch !== "#FFFFFF" ? EditorColors.white : EditorColors.black,
          borderRadius: "var(--editor-button-radius)",
        },
        link: {
          color:
            colorMatch === "#FFFFFF" ? EditorColors.white : EditorColors.black,
        },
      };
    }
  };

  const editSectionStyle = (
    section: HTMLElement,
    sectionStyle: SectionStyle
  ) => {
    if (!section?.style) return;
    section.style.color = sectionStyle.content.color || EditorColors.black;
    section.style.backgroundColor =
      sectionStyle.backgroundColor || EditorColors.white;
    section.style.fontFamily = sectionStyle.content.fontFamily;
    section.style.fontSize = sectionStyle.content.fontSize;

    //editor-title style
    let titleList = section.querySelectorAll(".editor-title");
    if (titleList.length > 0) {
      titleList.forEach((title: any) => {
        title.style.color = sectionStyle.title.color || EditorColors.black;
        title.style.fontFamily = sectionStyle.title.fontFamily;
        title.style.fontSize = sectionStyle.title.fontSize;
      });
    }

    //editor-button style
    let buttonList = section.querySelectorAll(
      ".editor-button > a, .editor-button > button"
    );
    if (buttonList.length > 0) {
      buttonList.forEach((butt: any) => {
        if (!butt?.style) return;
        butt.style.backgroundColor =
          sectionStyle.button.backgroundColor || EditorColors.black;
        butt.style.borderColor =
          sectionStyle.button.backgroundColor || EditorColors.black;
        butt.style.color = sectionStyle.button.color || EditorColors.white;
        butt.style.borderRadius = sectionStyle.button.borderRadius;
      });
    }

    //editor-link style
    let linkList = section.querySelectorAll(".editor-link > a");
    if (linkList.length > 0) {
      linkList.forEach((link: any) => {
        if (!link?.style) return;
        link.style.color = sectionStyle.link.color || EditorColors.black;
      });
    }

    //section-carousel
    if (section.classList.contains("section-carousel")) {
      section.querySelectorAll(".splide__slide .cover").forEach((el) => {
        const cover = el as HTMLElement;
        cover.style.backgroundColor = sectionStyle.backgroundColor;
      });
    }
  };

  return {
    styleContent,
    getSectionStyle,
    editSectionStyle,
  };
};
