import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { BsChevronDown, BsArrowRight } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
//context
import { PageDrawerContext } from "../index";
//component
import { SortableList } from "../SortableList";
import PageRow from "../PageRow";
import { FormattedMessage } from "react-intl";

const systemPages = [
  {
    name: "Imprint",
    id: "imprint",
  },
  {
    name: "Privacy Policy",
    id: "privacy",
  },
  {
    name: "Cookie Settings",
    id: "cookie",
  },
  {
    name: "Cookie Banner",
    id: "cookie_banner",
  },
  {
    name: "Page not found / 404",
    id: "404_page",
  },
];

const Home: React.FC = () => {
  const { pageList, setPageList, setActiveStep, setParentId } =
    React.useContext(PageDrawerContext);
  const [openSystemPage, setOpenSystemPage] = React.useState<boolean>(false);

  return (
    <div className="flex flex-col gap-2 px-2 py-6">
      <SortableList
        items={pageList}
        getItemId={(item) => item.id}
        renderItem={({
          item,
          isActive,
          isDragged,
          ref,
          props,
          handleProps,
          index,
        }) => {
          return (
            <PageRow
              key={item.id}
              itemRef={ref}
              page={item}
              isActive={isActive}
              isDragged={isDragged}
              itemProps={props}
              handleProps={handleProps}
              index={index}
            />
          );
        }}
        onSort={(oldIndex, newIndex) => {
          const newPageList = pageList.slice();
          newPageList.splice(newIndex, 0, newPageList.splice(oldIndex, 1)[0]);
          setPageList(newPageList);
        }}
      />
      {/* Add Page Button START*/}
      <button
        className="flex items-center justify-between border border-gray-100 bg-white px-4 py-3.5 drop-shadow hover:bg-gray-100"
        onClick={() => {
          setParentId("");
          setActiveStep("pageAdd");
        }}
      >
        <div className="flex gap-4">
          <AiOutlinePlus fontSize={24} />
          <span className="font-bold"><FormattedMessage id="LangsAddPage" />
          </span>
        </div>
      </button>
      {/* Add Page Button END*/}
      {/* Toast START */}
      <div className="flex flex-col gap-4 border-l-4 border-pink-600 bg-gray-100 p-4">
        <h2 className="text-lg font-bold">
          <FormattedMessage id="LangsNeedMoreSpace" />

        </h2>
        <p>
          <FormattedMessage id="LangsYourCurrent" />
          <span className="font-bold">
            <FormattedMessage id="LangsPlay" />
          </span>
          <FormattedMessage id="LangsPlanSupportsUpTo" />

          <span className="font-bold">5</span>
          <FormattedMessage id="homePagesUpgrade" />

        </p>
        <div className="mb-4">
          <button className="bg-pink-600 px-6 py-2.5 font-bold text-white ">
            <FormattedMessage id="LangsUnlockMorePages" />

          </button>
        </div>
      </div>
      {/* Toast END*/}
      <div className="mb-1 border-b-2 border-black p-1"></div>
      <button
        className="flex items-center justify-between border border-gray-100 bg-white px-4 py-3.5 drop-shadow"
        onClick={() => setOpenSystemPage(!openSystemPage)}
      >
        <span className="font-bold">
          <FormattedMessage id="LangsSystemPages" />
        </span>

        <BsChevronDown
          className={classNames(
            "transform duration-100 ease-linear",
            !openSystemPage ? "-rotate-90" : ""
          )}
          fontSize={24}
        />
      </button>
      {openSystemPage && (
        <div className="flex flex-col">
          {systemPages.map((page: any) => (
            <Link
              key={page.id}
              className="flex items-center justify-between gap-2 p-4"
              to={`/cms/pages/${page.id}`}
            >
              <span>{page.name}</span>
              <BsArrowRight fontSize={24} />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Home;
