import React from "react";
import { Formik, Form, FormikHelpers, FormikProps } from "formik";
import { HiOutlineExternalLink, HiOutlineSearch } from "react-icons/hi";
import * as Yup from "yup";
import classNames from "classnames";
//components
import Alert from "../../../Alert/";
//context
import { EditorContext } from "../index";

import type { FormPageProps } from "../type";
import { FormattedMessage } from "react-intl";

const pageList = [
  { id: "1", name: "Home", url: "/home" },
  { id: "2", name: "Store", url: "/store" },
  { id: "3", name: "About", url: "/about" },
];

type FormValues = {
  url: string;
  pageId: string;
  active: boolean;
};

const FormPage: React.FC<FormPageProps> = ({ link, pageId }) => {
  const { handleLink, clearLink } = React.useContext(EditorContext);
  const [search, setSearch] = React.useState("");
  const [pages, setPages] = React.useState<any[]>(pageList);
  const [initialValues, setInitialValues] = React.useState<FormValues>({
    url: "",
    pageId: "",
    active: false,
  });

  const validationSchema = Yup.object().shape({});

  const handleSubmit = (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    let { url, pageId } = values;
    try {
      setSubmitting(false);
      setInitialValues({
        url: url,
        pageId: pageId,
        active: !!url,
      });
      handleLink(url, pageId);
    } catch (e: any) {
      setStatus(e.message);
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  const handleChangeLink = () => {
    setInitialValues({
      url: "",
      pageId: "",
      active: false,
    });
    clearLink();
  };

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setPages(
      pageList.filter((page) =>
        page.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  React.useEffect(() => {
    setInitialValues({
      url: link || "",
      pageId: "",
      active: !!link,
    });
  }, [link]);

  return (
    <>
      <div className={classNames(initialValues.active && "hidden")}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ status, handleSubmit }: FormikProps<FormValues>) => (
            <Form>
              <Alert status="error" open={!!status} enableClose={false}>
                {status}
              </Alert>
              <div className="flex flex-col">
                <div className="flex grow flex-col border-b border-gray-300">
                  <div className="flow-row flex">
                    <label className="flex items-center p-4">
                      <HiOutlineSearch fontSize={22} />
                    </label>
                    <input
                      type="text"
                      className="w-full border-0 bg-gray-100 p-4 focus:ring-0"
                      placeholder="Search existing pages"
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                  {(!pages || pages.length === 0) && (
                    <span className="font-sm px-4 py-2 text-sm text-red-500">
                      <FormattedMessage id="LangsNoResults" />

                    </span>
                  )}
                </div>
                <div className="flex flex-col pb-4">
                  {pages &&
                    pages.length > 0 &&
                    pages.map((page, pageIdx) => (
                      <div
                        key={pageIdx}
                        onClick={() => {
                          setInitialValues({
                            url: page.url,
                            pageId: page.id,
                            active: !!page.url,
                          });
                          handleSubmit();
                        }}
                        className="flex cursor-pointer justify-start p-4 hover:bg-gray-200"
                      >
                        {page.name}
                      </div>
                    ))}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classNames(!initialValues?.active && "hidden")}>
        <div className="flex">
          <div className="grow">
            <div className="w-full overflow-hidden border-0 bg-gray-100 p-4 focus:ring-0">
              {pages.find((page) => page.id === pageId)?.name}
            </div>
          </div>
          <button
            type="button"
            onClick={() =>
              window.open(pages.find((page) => page.id === pageId)?.url)
            }
            className="flex w-1/3 items-center justify-end gap-1 px-4 py-2"
          >
            <HiOutlineExternalLink fontSize={22} />
          </button>
        </div>
        <div className="border-t border-gray-300 text-sm">
          <button
            type="button"
            className="flex w-full items-center justify-center p-4 text-lg text-red-500 hover:bg-gray-200"
            onClick={handleChangeLink}
          >
            <FormattedMessage id="LangsChangeLink" />

          </button>
        </div>
      </div>
    </>
  );
};

export default FormPage;
