import React from "react";
import { ChangeEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { twMerge } from "tailwind-merge";
import { BiCheck } from "react-icons/bi";
import {
  EyeIcon,
  EyeSlashIcon,
  MinusSmallIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import Alert from "../../../components/Alert";

const passwordMust = [
  {
    id: 1,
    title: "Langs8Characters",
  },
  {
    id: 2,
    title: "LangsUpperLowercaseLetters",
  },
  {
    id: 3,
    title: "LangsOneNumber",
  },
  {
    id: 4,
    title: "LangsOneSpecialCharacter",
  },
];

type ChangePasswordPageResult = {
  currentPassword: string;
  newPassword: string;
};

export type ChangePasswordPageHandler = {
  submit: () => false | ChangePasswordPageResult;
  handleStatus: (status: string) => void;
};

const ChangePasswordPage: React.ForwardRefRenderFunction<
  ChangePasswordPageHandler,
  {}
> = (props, innerRef) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReEnterPassword, setShowReEnterPassword] = useState(false);

  const [isCondition1, setIsCondition1] = useState(false);
  const [isCondition2, setIsCondition2] = useState(false);
  const [isCondition3, setIsCondition3] = useState(false);
  const [isCondition4, setIsCondition4] = useState(false);

  const [strengthStatus, setStrengthStatus] = useState("Weak");
  const [isMatching, setIsMatching] = useState(false);
  const [status, setStatus] = useState<string | null>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "currentPassword") {
      setCurrentPassword(value);
    } else if (name === "newPassword") {
      setIsCondition1(/.{8,}/.test(value));
      setIsCondition2(/(?=.*[a-z])(?=.*[A-Z])/.test(value));
      setIsCondition3(/(?=.*\d)/.test(value));
      setIsCondition4(/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(value));
      if (
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[a-zA-Z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{12,}$/.test(
          value
        )
      ) {
        setStrengthStatus("Strong");
      } else if (
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[a-zA-Z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/.test(
          value
        )
      ) {
        setStrengthStatus("Medium");
      } else {
        setStrengthStatus("Weak");
      }
      setNewPassword(value);
    } else if (name === "reEnterPassword") {
      setReEnterPassword(value);
      setIsMatching(value === newPassword);
    }
  };

  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleReEnterPassword = () => {
    setShowReEnterPassword(!showReEnterPassword);
  };

  const handleStatus = (status: string) => {
    setStatus(status);
  };

  const handleSubmit = () => {
    if (!isCondition1 || !isCondition2 || !isCondition3 || !isCondition4) {
      return false;
    }
    if (!isMatching) {
      return false;
    }
    return {
      currentPassword,
      newPassword,
    };
  };

  React.useImperativeHandle(innerRef, () => ({
    submit: handleSubmit,
    handleStatus,
  }));

  return (
    <div>
      <Alert status="error" open={!!status} enableClose={false}>
        {status}
      </Alert>
      {/* TODO:驗證目前密碼 */}
      <div className="mb-10 flex w-full flex-col">
        <label
          className="mb-2 cursor-default  text-base font-normal leading-normal"
          htmlFor="currentPassword"
        >
          <FormattedMessage id="LangsCurrentPassword" />
        </label>
        <div className="relative  flex items-center gap-5 border-2 border-solid border-zinc-200 bg-white  text-zinc-800 hover:border-gray-300 focus:border-2 focus:border-solid focus:border-blue-600">
          <input
            name="currentPassword"
            id="currentPassword"
            type={showCurrentPassword ? "text" : "password"}
            className="block w-full flex-1 cursor-text appearance-none border-0 py-3 pl-3 text-base focus:ring-0"
            aria-invalid="true"
            value={currentPassword}
            onChange={handleInputChange}
          />
          <button
            type="button"
            aria-label="Toggle password visibility"
            onClick={handleToggleCurrentPassword}
            className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-none text-center  text-sm text-neutral-500 hover:text-blue-600 focus:text-blue-600"
          >
            {showCurrentPassword ? (
              <EyeSlashIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="false"
              />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-500" aria-hidden="false" />
            )}
          </button>
        </div>
      </div>
      <p
        className="mb-3  text-base font-bold leading-normal text-gray-800"
        data-windy-selected="AaEUdnUn9eI4dLcQQK7BQ"
      >
        {" "}
        <FormattedMessage id="LangsYourPasswordMustHave" />:
      </p>
      <ul className="mb-6 ">
        {passwordMust.map((item, idx) => (
          <li
            key={item.id}
            className="mx-0 mb-2 mt-0 flex list-none text-left text-gray-300"
            data-windy-selected="-OWZFE_3WXLvKD7GyanKI"
          >
            {(idx === 0 && isCondition1) ||
            (idx === 1 && isCondition2) ||
            (idx === 2 && isCondition3) ||
            (idx === 3 && isCondition4) ? (
              <BiCheck fontSize={24} className="text-green-600" />
            ) : (
              <MinusSmallIcon className="h-5 w-5" />
            )}
            <span className="text-left  text-sm font-normal leading-normal text-gray-800">
              <FormattedMessage id={item.title} />
            </span>
          </li>
        ))}
      </ul>

      {/* TODO:創建新密碼 */}
      <div className="mb-10 flex w-full flex-col">
        <label
          className="mb-2 cursor-default  text-base font-normal leading-normal"
          htmlFor="newPassword"
        >
          <FormattedMessage id="LangsNewPassword" />
        </label>
        <div className="relative flex items-center gap-5 border-2 border-solid border-zinc-200 bg-white text-zinc-800 hover:border-gray-300 focus:border-2 focus:border-solid focus:border-blue-600">
          <input
            name="newPassword"
            id="newPassword"
            type={showNewPassword ? "text" : "password"}
            className="block w-full flex-1 cursor-text appearance-none border-0 py-3 pl-3  text-base focus:ring-0"
            aria-invalid="true"
            value={newPassword}
            onChange={handleInputChange}
          />
          <button
            type="button"
            aria-label="Toggle password visibility"
            onClick={handleToggleNewPassword}
            className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-none text-center  text-sm text-neutral-500 hover:text-blue-600 focus:text-blue-600"
          >
            {showNewPassword ? (
              <EyeSlashIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="false"
              />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-500" aria-hidden="false" />
            )}
          </button>
        </div>
        {/* TODO:驗證密碼強度 */}
        <span className=" text-sm font-normal leading-normal text-gray-500">
          <FormattedMessage id="LangsPasswordStrength" />:
          {strengthStatus === "Weak" ? (
            <span className="text-sm font-bold leading-normal text-red-500">
              {strengthStatus}
            </span>
          ) : strengthStatus === "Medium" ? (
            <span className="text-sm font-bold leading-normal text-yellow-500">
              {strengthStatus}
            </span>
          ) : strengthStatus === "Strong" ? (
            <span className="text-sm font-bold leading-normal text-green-600">
              {strengthStatus}
            </span>
          ) : null}
        </span>
      </div>

      {/* TODO:確認新密碼 */}
      <div className="mb-10 flex w-full flex-col">
        <label
          className="mb-2 cursor-default text-base font-normal leading-normal"
          htmlFor="reEnterPassword"
        >
          <FormattedMessage id="LangsReEnterYourNewPassword" />
        </label>
        <div
          className={twMerge(
            "relative flex items-center gap-5 border-2 border-solid border-zinc-200 bg-white text-zinc-800 hover:border-gray-300 focus:border-2 focus:border-solid focus:border-blue-600",
            !isMatching && "border-red-500 focus:border-red-500"
          )}
        >
          <input
            name="reEnterPassword"
            id="reEnterPassword"
            type={showReEnterPassword ? "text" : "password"}
            className="block w-full flex-1 cursor-text appearance-none border-0 py-3 pl-3 text-base focus:ring-0"
            aria-invalid="true"
            value={reEnterPassword}
            onChange={handleInputChange}
          />
          <br />

          <button
            type="button"
            aria-label="Toggle password visibility"
            onClick={handleToggleReEnterPassword}
            className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-none text-center  text-sm text-neutral-500 hover:text-blue-600 focus:text-blue-600"
          >
            {showReEnterPassword ? (
              <EyeSlashIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="false"
              />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-500" aria-hidden="false" />
            )}
          </button>
        </div>
      </div>

      <div className="mb-5 flex text-gray-500">
        <InformationCircleIcon className="h-5 w-5 " />
        <p className=" text-sm font-bold leading-normal ">
          <FormattedMessage id="ChangePasswordSaveNewPassword" />
        </p>
      </div>
    </div>
  );
};

export default React.forwardRef(ChangePasswordPage);
