import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Switch } from "@headlessui/react";
import classNames from "classnames";
//icon
import { HiArrowRight, HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { FiInfo } from "react-icons/fi";

//MyToast
import MyToast from "../../components/MyToast";
import type { MyToastHandler } from "../../components/MyToast/type";
import { FormattedMessage } from "react-intl";

const StatisticSettingsPage: React.FC = () => {
  //
  // show section State
  const [isShow, setShow] = useState<boolean>(true);
  const handledShow = () => {
    setShow(!isShow);
  };

  // Active Toggle switch State
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  // MyToast
  const toastRef = React.useRef<MyToastHandler>(null);

  function handleToastOpen() {
    if (!toastRef.current) return;
    toastRef.current.handleToastOpen();
  }

  return (
    <div className="h-full w-full px-3 sm:px-6">
      {/* background */}
      <div className="mx-auto mb-auto mt-24 flex h-auto w-full max-w-[996px] flex-1 flex-col">
        {/* Main Title */}
        <h1 className="mb-16 w-full justify-start whitespace-nowrap text-left text-3xl font-bold text-zinc-800 md:text-4xl">
          <FormattedMessage id="LangsSettings" defaultMessage="Settings" />
        </h1>
        {/* Description */}
        <div className="mb-5">
          {/* description 01 */}
          <p className="text-base font-normal leading-normal text-zinc-800">
            <FormattedMessage id="StatisticSettingsDescription" />

          </p>
          {/* description 02 */}
          <p className="text-base font-normal leading-normal text-zinc-800">
            <FormattedMessage id="StatisticSettingsServices" defaultMessage="Here you can opt in and out of the services you need." />

          </p>
        </div>
        {/* Main card */}
        <div className="flex flex-col bg-white">
          {/* flex */}
          <div className="flex items-start p-5 md:items-center">
            {/* content */}
            <div className="flex-grow">
              {/* content wrapper */}
              <div className="flex items-center">
                {/* title */}
                <h3 className="text-lg font-bold leading-5 text-zinc-800">
                  <FormattedMessage id="LangsBuildLinkStatistics" defaultMessage="BuildLink Statistics" />

                </h3>
                {/* tracking on and off */}
                {isEnabled ? (
                  <div className="ml-3 inline-flex h-6 items-center rounded border border-transparent bg-green-400 bg-opacity-[0.1] px-2">
                    <span className="text-xs font-bold leading-5 tracking-wide text-green-800">
                      <FormattedMessage id="LangsTrackingOn" defaultMessage={"Tracking On"} />

                    </span>
                  </div>
                ) : (
                  <div className="ml-3 inline-flex h-6 items-center rounded border border-transparent bg-gray-400 bg-opacity-[0.1] px-2">
                    <span className="text-xs font-bold leading-5 tracking-wide text-zinc-800">
                      <FormattedMessage id="LangsTrackingOff" defaultMessage={"Tracking Off"} />

                    </span>
                  </div>
                )}
              </div>
            </div>
            {/* toggle button */}
            <button
              onClick={() => {
                handledShow();
              }}
            >
              {isShow ? (
                <span className="mt-1 inline-block h-5 w-5 md:mt-0">
                  <HiChevronUp className="h-full w-full text-zinc-800" />
                </span>
              ) : (
                <span className="mt-1 inline-block h-5 w-5 md:mt-0">
                  <HiChevronDown className="h-full w-full text-zinc-800" />
                </span>
              )}
            </button>
          </div>
          {/* Activate buildLink Statistics group */}

          <section
            className={classNames(
              isShow ? "opacity-100" : "hidden",
              "duration-600 overflow-hidden transition-all ease-in-out"
            )}
          >
            <div className="flex flex-col">
              {/* divider */}
              <hr className="border-b-2 border-gray-100" />
              {/* content */}
              <div className="px-5 pb-6 pt-5 md:pb-5">
                {/* content wrapper */}
                <div className="">
                  {/* Activate on and off toggle */}
                  <div className="mb-5 bg-zinc-100 px-3 py-4">
                    <div className="flex items-center justify-between">
                      {/* title */}
                      <p className="text-base font-normal leading-normal text-zinc-800">
                        <FormattedMessage id="LangsActivateBuildLinkStatistics" defaultMessage="Activate BuildLink Statistics" />
                      </p>
                      {/* toggle button */}
                      <Switch
                        checked={isEnabled}
                        onChange={() => {
                          setIsEnabled(!isEnabled);
                          handleToastOpen();
                        }}
                        className={classNames(
                          isEnabled
                            ? "bg-indigo-600"
                            : "border-2 border-gray-500 bg-white",
                          "relative inline-flex h-6 w-12 items-center rounded-full"
                        )}
                      >
                        <span
                          className={classNames(
                            isEnabled
                              ? "translate-x-6 bg-white"
                              : "translate-x-0.5 bg-gray-500",
                            "inline-block h-5 w-5 transform rounded-full border transition-all duration-300 ease-in-out"
                          )}
                        />
                      </Switch>
                      <MyToast ref={toastRef}>
                        <p className="mt-1 text-sm text-gray-500">
                          {isEnabled
                            ? (<FormattedMessage id="StatisticSettingsSuccessfully" defaultMessage={"You've successfully activated BuildLink Statistics! It can take a few hours before you start seeing your traffic."} />)
                            : (<FormattedMessage id="StatisticSettingsDeactivated" defaultMessage={"You've deactivated BuildLink Statistics!"} />)}
                        </p>
                      </MyToast>
                    </div>
                  </div>
                  {/* Terms Content */}
                  <p className="mb-4 max-w-[580px] text-sm font-normal leading-normal text-zinc-800">
                    <FormattedMessage id="StatisticSettingsFeature" defaultMessage={"When you activate this feature, our"} />

                    {/* Terms link */}
                    {/*eslint-disable-next-line*/}
                    <a
                      href="#"
                      className="max-w-full cursor-pointer text-sm leading-5 text-blue-500 transition-all duration-300 ease-in-out hover:underline"
                    >
                      &nbsp;
                      <FormattedMessage id="LangsTermsOfService" defaultMessage={"Terms of Service"} />
                      &nbsp;
                    </a>
                    <FormattedMessage id="LangsAndThe" defaultMessage={"and the"} />

                    {/* Additional link */}
                    {/*eslint-disable-next-line*/}
                    <a
                      href="#"
                      className="max-w-full cursor-pointer text-sm leading-5 text-blue-500 transition-all duration-300 ease-in-out hover:underline"
                    >
                      &nbsp;
                      <FormattedMessage id="LangsAdditionalInformation" defaultMessage={"Additional Information"} />
                      &nbsp;
                    </a>
                    <FormattedMessage id="StatisticStatisticsApply" defaultMessage={" on BuildLink Statistics apply. If you have further questions on how BuildLink processes your personal data please check our"} />

                    {/* Privacy Policy link */}
                    {/*eslint-disable-next-line*/}
                    <a
                      href="#"
                      className="max-w-full cursor-pointer text-sm leading-5 text-blue-500 transition-all duration-300 ease-in-out hover:underline"
                    >
                      &nbsp;
                      <FormattedMessage id="LangsPrivacyPolicy" defaultMessage={"Privacy Policy"} />
                      &nbsp;
                    </a>
                    .
                  </p>
                  {/* notice content */}
                  <div className="mb-4 flex items-center">
                    {/* notice icon */}
                    <span className="mr-2 inline-block h-5 w-5">
                      <FiInfo className="h-full w-full text-zinc-800" />
                    </span>
                    <p className="max-w-[605px] text-sm font-normal leading-normal text-zinc-800">
                      <FormattedMessage id="StatisticDataToAppear" defaultMessage={"Once you turn this on, it can take a few hours for data to appear.on BuildLink Statistics apply. If you have further questions on how BuildLink processes your personal data please check our"} />

                    </p>
                  </div>
                  <Link
                    to="/statistic"
                    className="flex max-w-max cursor-pointer transition-all duration-300 ease-in-out hover:border-b hover:border-indigo-600"
                  >
                    <p className="text-base font-bold leading-5 text-indigo-600 hover:text-indigo-700">
                      <FormattedMessage id="StatisticVisit" defaultMessage={"Visit BuildLink Statistics"} />

                    </p>
                    {/* icon */}
                    <span className="ml-3 inline-block h-5 w-5">
                      <HiArrowRight className="h-full w-full text-indigo-600" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default StatisticSettingsPage;
