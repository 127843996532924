import React from "react";
import ReactDOMClient from "react-dom/client";
import classNames from "classnames";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import Editor from "../index";
//language
import Langs from "../../../../langs/";
//store
import { store } from "../../../../stores/";

const useEditorImage = () => {
  //target is the .editor-image
  const handleClick = React.useCallback((target: HTMLElement) => {
    let i = 0;
    let content = target.childNodes[i] as HTMLImageElement;
    while (content && content.nodeType === 3) {
      //if content is text node just skip
      i++;
      content = target.childNodes[i] as HTMLImageElement;
    }
    if (!content) return;
    const div = document.createElement("div");
    const id = `editor-holder-${new Date().getTime()}`;
    div.id = id;
    div.setAttribute("data-holder", "editor-image");
    const parentNode = target.parentNode as HTMLElement;
    if (!parentNode) return;
    // get parentNode size
    const rectParent = parentNode.getBoundingClientRect();
    parentNode.style.overflow = "hidden";
    parentNode.style.position = "relative";
    parentNode.style.height = +rectParent.height + "px";
    div.className = classNames(parentNode?.classList.value, "w-full h-full");
    // console.log("content", content);
    // console.log("content.classList", content.classList);

    // if dragger is exists
    if (content.classList.contains("image-dragger")) {
      const r = ReactDOMClient.createRoot(div);
      r.render(
        <Provider store={store}>
          <IntlProvider messages={Langs.en} locale="en" defaultLocale="en">
            <Editor
              root={r}
              holderId={div.id}
              content={content.outerHTML}
              type="editor-image"
            />
          </IntlProvider>
        </Provider>
      );
      target.replaceWith(div);
    } else {
      //dragger create
      const dragger = document.createElement("div");
      dragger.setAttribute("draggable", "true");
      // get image natural size
      const naturalWidth = content.naturalWidth,
        naturalHeight = content.naturalHeight;

      let smallWidth = 0,
        smallHeight = 0;
      let x = 0,
        y = 0;
      // check cover is ok
      if (smallWidth < rectParent.width) {
        smallWidth = rectParent.width;
        smallHeight = Math.round(
          (rectParent.width / naturalWidth) * naturalHeight
        );
        x = 0;
        y = Math.round((rectParent.height - smallHeight) / 2);
      }
      if (smallHeight < rectParent.height) {
        smallHeight = rectParent.height;
        smallWidth = Math.round(
          (rectParent.height / naturalHeight) * naturalWidth
        );
        y = 0;
        x = Math.round((rectParent.height - smallHeight) / 2);
      }

      dragger.className = "image-dragger";
      dragger.setAttribute("data-width", rectParent.width + "");
      dragger.setAttribute("data-height", rectParent.height + "");
      dragger.style.position = "absolute";
      dragger.style.cursor = "move";
      dragger.style.width = smallWidth + "px";
      dragger.style.height = smallHeight + "px";
      dragger.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
      dragger.appendChild(content);
      content.setAttribute("draggable", "false");
      content.style.position = "relative";
      const r = ReactDOMClient.createRoot(div);
      r.render(
        <Provider store={store}>
          <IntlProvider messages={Langs.en} locale="en" defaultLocale="en">
            <Editor
              root={r}
              holderId={div.id}
              content={dragger.outerHTML}
              type="editor-image"
            />
          </IntlProvider>
        </Provider>
      );
      target.replaceWith(div);
    }
  }, []);

  //click Out editor-image
  const handleClickOut = React.useCallback(
    (target: HTMLElement, data: any, prevRoot: ReactDOMClient.Root) => {
      //div.editor-image replace data-holder
      const holder = target?.parentNode as HTMLElement;
      if (!holder) return;
      const holderType = holder.getAttribute("data-holder");
      if (holderType !== "editor-image") return;
      const div = document.createElement("div");
      div.className = "editor-image";
      data.setAttribute("draggable", "false");
      div.innerHTML = data.getInnerHTML();
      holder.replaceWith(div);
      prevRoot.unmount();
      div.addEventListener("click", (e: any) => handleClick(e.currentTarget));

      //for slide cover recovery
      const container = div.parentNode;
      if (!container) return;
      const coverNode = container.querySelector(".cover") as HTMLElement;
      if (!coverNode) return;
      coverNode.style.display = "block";
    },
    [handleClick]
  );

  return { handleClick, handleClickOut };
};

export default useEditorImage;
