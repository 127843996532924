import React from "react";
import { twMerge } from "tailwind-merge";
import {
  offset,
  flip,
  shift,
  autoUpdate,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useFloating,
} from "@floating-ui/react";
import type { TooltipProps } from "./type";

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  placement = "bottom",
  className,
  ...props
}) => {
  const [show, setShow] = React.useState(false);

  const { refs, x, y, strategy, floatingStyles, context } = useFloating({
    open: show,
    onOpenChange: setShow,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      <span
        className="flex items-center justify-start"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </span>
      {show && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={twMerge(
            "mytooltip-floating z-[999] w-max break-inside-avoid whitespace-nowrap rounded-lg bg-gray-900 p-2 text-sm text-white transition-opacity duration-200 ease-in-out",
            className ? className : ""
          )}
          {...getFloatingProps({
            style: {
              position: strategy,
              top: y ?? "",
              left: x ?? "",
            },
          })}
        >
          {content}
        </div>
      )}
    </>
  );
};

export default Tooltip;
