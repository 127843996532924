import React from "react";
import { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FormikProps, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, connect, useDispatch, ConnectedProps } from "react-redux";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { Auth } from "aws-amplify";

//store
import { authActions } from "../../../stores/actions";
//components
import MyDialog from "../../../components/MyDialog";
import type { MyDialogHandler } from "../../../components/MyDialog/type";
import Alert from "../../../components/Alert";

import AppleLogo from "../../../media/img/apple-logo.png";
import FacebookLogo from "../../../media/img/facebook.png";
import GoogleLogo from "../../../media/img/search.png";
import ChangPasswordPage, {
  ChangePasswordPageHandler,
} from "./ChangePasswordPage";
import DeleteAccountPage, {
  DeleteAccountPageHandler,
} from "./DeleteAccountPage";

// const user = {
//   FirstName: "gary",
//   LastName: "Wu",
// };
interface FormValues {
  name: string;
  family_name: string;
  locale: string;
  newsletter: string;
}

const logins = [
  {
    id: 1,
    title: "Apple",
    logo: AppleLogo,
    href: "",
    status: "Inactive",
  },
  {
    id: 2,
    title: "Facebook",
    logo: FacebookLogo,
    href: "",
    status: "Inactive",
  },
  {
    id: 3,
    title: "Google",
    logo: GoogleLogo,
    href: "",
    status: "active",
  },
];

const languages = [
  {
    id: "en",
    title: "English",
    status: "active",
  },
  {
    id: "zh-TW",
    title: "繁體中文",
    status: "active",
  },
  {
    id: "zh",
    title: "簡體中文",
    status: "active",
  },
];

type Props = ConnectedProps<typeof connector>;

const ProfilesPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dialogRef = React.useRef<MyDialogHandler>(null);
  const deleteDialogRef = React.useRef<MyDialogHandler>(null);
  const changePasswordPageRef = React.useRef<ChangePasswordPageHandler>(null);
  const deleteAccountPageRef = React.useRef<DeleteAccountPageHandler>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const { user } = useSelector(({ auth }) => auth);
  const [initialValues, setInitialValues] = useState<FormValues>({
    name: user.name,
    family_name: user.family_name,
    locale: user.locale,
    newsletter: user["custom:newsletter"],
  });

  const [selected, setSelected] = useState(
    initialValues.locale
      ? languages.find((l) => l.id === initialValues.locale)
      : languages[0]
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    family_name: Yup.string().required("Required"),
  });

  const handleClick = () => {
    if (!dialogRef.current) return;
    dialogRef.current?.handleOpen();
  };

  const updateLocale = () => {
    buttonRef.current?.click();
  };

  const updateNewsletter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setInitialValues((prev) => ({ ...prev, newsletter: "1" }));
    } else {
      setInitialValues((prev) => ({ ...prev, newsletter: "0" }));
    }
    buttonRef.current?.click();
  };

  const handleSubmit = async (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        name: values.name,
        family_name: values.family_name,
        locale: selected?.id || languages[0].id,
        ...(values.newsletter === "1"
          ? { "custom:newsletter": "1" }
          : { "custom:newsletter": "0" }),
      });
      const { attributes } = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      dispatch(props.filledUser(attributes));
    } catch (error: any) {
      setStatus(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleChangePassword = async () => {
    const result = changePasswordPageRef.current?.submit();
    if (!result) return;
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        result.currentPassword,
        result.newPassword
      );
      navigate("/auth/logout");
    } catch (error: any) {
      console.log(error);
      changePasswordPageRef.current?.handleStatus(error.message);
    }
  };

  const handleDeleteAccount = async () => {
    const result = deleteAccountPageRef.current?.submit();
    if (!result) return;
    try {
      await Auth.deleteUser();

      navigate("/auth/logout");
    } catch (error) {
      console.log("Error deleting user", error);
    }
  };

  //update profile when locale change
  React.useEffect(() => {
    updateLocale();
    // eslint-disable-next-line
  }, [selected]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          status,
          values,
          handleChange,
          isSubmitting,
        }: FormikProps<FormValues>) => (
          <Form className="space-y-6" autoComplete="off">
            <Alert status="error" open={!!status} enableClose={false}>
              {status}
            </Alert>
            <div className="mb-16 text-zinc-800 md:flex md:justify-between md:gap-10">
              <p className="font-sans text-base font-bold leading-normal md:w-1/5">
                <FormattedMessage id="LangsName" defaultMessage="Name" />
              </p>
              <div className="md:flex-1">
                <div className="mb-10">
                  <label className="mb-2 cursor-default font-sans text-base font-normal leading-normal ">
                    <FormattedMessage
                      id="LangsFirstName"
                      defaultMessage="First Name"
                    />
                  </label>
                  <div className=" relative flex items-center gap-5 border-2 border-solid border-zinc-200 bg-white text-zinc-800 hover:border-gray-300 focus:border-2 focus:border-solid focus:border-blue-600 ">
                    <input
                      className="m-0 block w-full flex-1 cursor-text appearance-none border-0 p-3 font-sans text-base "
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={() => buttonRef.current?.click()}
                      type="text"
                      aria-invalid="false"
                    />
                    <span className="font-sm text-sm text-red-500">
                      <ErrorMessage name="name" />
                    </span>
                  </div>
                </div>
                <div className="mb-10">
                  <label className="mb-2 cursor-default font-sans text-base font-normal leading-normal ">
                    <FormattedMessage
                      id="LangsLastName"
                      defaultMessage="Last Name"
                    />
                  </label>
                  <div className=" relative flex items-center gap-5 border-2 border-solid border-zinc-200 bg-white text-zinc-800 hover:border-gray-300 focus:border-2 focus:border-solid focus:border-blue-600 ">
                    <input
                      className="m-0 block w-full flex-1 cursor-text appearance-none border-0 p-3 font-sans text-base "
                      name="family_name"
                      value={values.family_name}
                      onChange={handleChange}
                      onBlur={() => buttonRef.current?.click()}
                      type="text"
                      aria-invalid="false"
                    />
                    <span className="font-sm text-sm text-red-500">
                      <ErrorMessage
                        name="family_name"
                        className="font-sm text-sm text-red-500"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* TODO: 登錄帳戶選項 */}
            <div className="mb-16 !hidden text-zinc-800 md:flex md:justify-between md:gap-10">
              <p className="font-sans text-base font-bold leading-normal md:w-1/5">
                <FormattedMessage
                  id="LangsLoginOptions"
                  defaultMessage="Login Options"
                />
              </p>
              <div className="md:flex-1">
                <div className="flex flex-col flex-wrap gap-4 md:flex-row">
                  {logins.map((login) => (
                    <div
                      className="m-0 flex flex-col gap-1 p-0 md:items-center"
                      key={login.id}
                    >
                      {/* eslint-disable-next-line */}
                      {login.status === "active" ? (
                        <div className="flex flex-col gap-1 md:items-center">
                          <button className="inline-flex max-w-full cursor-default select-none appearance-none items-center justify-center truncate rounded-none border-2 border-solid border-blue-500 bg-white py-2 pl-3 pr-5 text-center text-base font-bold leading-6">
                            <CheckCircleIcon className="h-5 w-5 text-blue-600" />
                            <span>
                              <FormattedMessage id="LangsConnectedTo" />
                              {login.title}
                            </span>
                          </button>
                          <button className="m-0 flex h-12 max-w-full cursor-pointer items-center truncate bg-none px-5 py-3 text-center text-base font-bold leading-5 text-blue-600">
                            <span>
                              <FormattedMessage id="LangsDisconnectFrom" />
                              {login.title}
                            </span>
                          </button>
                        </div>
                      ) : (
                        <>
                          {/*eslint-disable-next-line */}
                          <a className="inline-flex max-w-full select-none appearance-none items-center justify-center truncate rounded-none border-2 border-solid border-gray-300 bg-white py-2 pl-3 pr-5 text-base font-bold leading-6">
                            <img
                              src={login.logo}
                              alt="logo"
                              className="h-5 w-auto"
                            />
                            <span>
                              <FormattedMessage id="LangsLoginWith" />
                              {login.title}
                            </span>
                          </a>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="mb-16 text-zinc-800 md:flex md:justify-between md:gap-10">
              <p className="font-sans text-base font-bold leading-normal md:w-1/5">
                <FormattedMessage id="LangsPassword" />
              </p>
              <div className="md:flex-1">
                <button
                  onClick={handleClick}
                  className="relative mb-4 inline-flex h-12 w-full min-w-0 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2 overflow-hidden rounded-none border-2 border-solid border-blue-600 bg-blue-600 px-5 text-center text-base font-bold text-white md:w-auto"
                >
                  <FormattedMessage id="LangsChangePassword" />
                </button>
                <MyDialog
                  ref={dialogRef}
                  title="Change password"
                  size="md"
                  buttons={[
                    {
                      label: "Save password",
                      className:
                        "text-white bg-blue-600 font-bold !border-2  !border-blue-600 !px-4 !py-2 rounded-none hover:!bg-blue-800",
                      onClick: async () => await handleChangePassword(),
                    },
                    {
                      label: "Cancel",
                      className:
                        "!text-blue-600 font-bold !border-2  !border-blue-600 !px-4 !py-2 rounded-none ",
                    },
                  ]}
                  className={{ footer: "!border-0 bg-white" }}
                >
                  <ChangPasswordPage ref={changePasswordPageRef} />
                </MyDialog>
              </div>
            </div>

            <div className="mb-16 text-zinc-800 md:flex md:justify-start md:gap-10">
              <p className="font-sans text-base font-bold leading-normal md:w-1/5">
                <FormattedMessage id="LangsSystemlanguage" />
              </p>
              <div className="relative ">
                <div className="inline-flex flex-col">
                  <Listbox value={selected} onChange={setSelected}>
                    <div className="relative mt-1 w-40">
                      <Listbox.Button className="relative w-full cursor-default rounded-none border-2 border-solid border-zinc-200 bg-white py-2 pl-3 pr-10 text-left hover:border-gray-300 focus:border-2 focus:border-solid focus:border-blue-600  sm:text-sm">
                        <span className="block truncate">
                          {selected?.title}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {languages.map((language, languageIdx) => (
                            <Listbox.Option
                              key={languageIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-4 pr-10 ${
                                  active ? "bg-gray-300 " : "text-gray-900"
                                }`
                              }
                              value={language}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {language.title}
                                  </span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>
            </div>

            <div className="mb-16 text-zinc-800 md:flex md:justify-between md:gap-10">
              <p className="font-sans text-base font-bold leading-normal md:w-1/5">
                <FormattedMessage id="LangsNewsletter" />
              </p>
              <div className="md:flex-1">
                <label className="m-0 flex cursor-default items-baseline gap-3 p-0 text-zinc-800">
                  <input
                    type="checkbox"
                    className="h-4 w-4 flex-shrink-0 appearance-none rounded-none border-2 border-solid bg-white text-black hover:border-blue-600"
                    name="newsletter"
                    value="1"
                    checked={values.newsletter === "1"}
                    onChange={updateNewsletter}
                  />
                  <span>
                    <p className="cursor-default font-sans text-base font-normal leading-normal">
                      <FormattedMessage id="ProfilesNewsletterDescriptionNo1" />

                      {/*eslint-disable-next-line */}
                      <a
                        href="#"
                        className="max-w-full cursor-pointer truncate bg-none leading-5 text-blue-600 decoration-white"
                      >
                        <FormattedMessage id="LangsHere" />
                      </a>
                      <FormattedMessage id="ProfilesNewsletterDescriptionNo2" />

                      {/*eslint-disable-next-line */}
                      <a
                        href="#"
                        className="max-w-full cursor-pointer truncate bg-none leading-5 text-blue-600 decoration-white"
                      >
                        https://buildlink.app/info/privacy
                      </a>
                    </p>
                  </span>
                </label>
              </div>
            </div>

            <button type="submit" ref={buttonRef} className="hidden">
              Submit
            </button>
          </Form>
        )}
      </Formik>

      {/* TODO: Delete account */}
      <br />
      <div className="mb-16 text-zinc-800 md:flex md:justify-between md:gap-10">
        <p className="font-sans text-base font-bold leading-normal md:w-1/5">
          <FormattedMessage id="LangsDeleteAccount" />
        </p>
        <div className="md:flex-1">
          <button
            className="relative mx-0 mb-4 mt-0 inline-flex h-12 w-full min-w-0 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2 overflow-hidden rounded-none border-2 border-solid border-red-500 bg-red-500 px-5 py-0 text-center text-base font-bold text-white md:w-auto"
            onClick={() => deleteDialogRef.current?.handleOpen()}
          >
            <FormattedMessage id="LangsDeleteAccount" />
          </button>
          <MyDialog
            ref={deleteDialogRef}
            title={<FormattedMessage id="LangsDeleteAccount" />}
            size="md"
            buttons={[
              {
                label: <FormattedMessage id="LangsDelete" />,
                className:
                  "text-white bg-red-600 font-bold !border-2 !border-red-600 !px-4 !py-2 rounded-none hover:!bg-red-800",
                onClick: async () => await handleDeleteAccount(),
              },
              {
                label: <FormattedMessage id="LangsCancel" />,
                className:
                  "!text-blue-600 font-bold !border-2  !border-blue-600 !px-4 !py-2 rounded-none ",
              },
            ]}
            className={{ footer: "!border-0 bg-white" }}
          >
            <DeleteAccountPage ref={deleteAccountPageRef} />
          </MyDialog>
          <p className="mb-3 font-sans text-base font-normal leading-normal">
            <FormattedMessage id="ProfilesDeleteAccountDescriptionNo1" />
          </p>
          <div className="mb-1">
            {/*eslint-disable-next-line */}
            <a
              href="#"
              className="max-w-full cursor-pointer truncate bg-none text-base leading-5 text-blue-600 decoration-white"
            >
              <FormattedMessage id="ProfilesDeleteAccountDescriptionNo2" />
            </a>
          </div>
          <div className="mb-1">
            {/*eslint-disable-next-line */}
            <a
              href="#"
              className="max-w-full cursor-pointer truncate bg-none text-base leading-5 text-blue-600 decoration-white"
            >
              <FormattedMessage id="ProfilesDeleteAccountDescriptionNo3" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...authActions });

export default connector(ProfilesPage);
