import React from "react";
import { Formik, Form, ErrorMessage, FormikHelpers, FormikProps } from "formik";
import { HiCheck, HiOutlineExternalLink } from "react-icons/hi";
import * as Yup from "yup";
import classNames from "classnames";
//components
import Alert from "../../../Alert/";
//context
import { EditorContext } from "../index";

import type { FormUrlProps } from "../type";
import { FormattedMessage } from "react-intl";

type FormValues = {
  url: string;
  active: boolean;
};

const FormCall: React.FC<FormUrlProps> = ({ link }) => {
  const { handleLink, clearLink } = React.useContext(EditorContext);
  const [initialValues, setInitialValues] = React.useState<FormValues>({
    url: "",
    active: false,
  });

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .required("Required")
      .test("url", "Invalid phone number", (value) => {
        if (!value) return true;
        //if  value is email or start with http:// or https:// , then return true, please check email with regex
        if (
          /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value)
        ) {
          return true;
        } else {
          return false;
        }
      }),
  });

  const handleSubmit = (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    let { url } = values;
    try {
      if (!url.startsWith("tel://")) {
        //if url is email then add mailto: in front of url
        if (
          /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(url)
        ) {
          url = `tel:${url}`;
        }
        handleLink(url, "");
      } else {
        handleLink(url, "");
      }
      setSubmitting(false);
      setInitialValues({
        url: values.url,
        active: !!values.url,
      });
    } catch (e: any) {
      setStatus(e.message);
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  const handleChangeLink = () => {
    setInitialValues({
      url: "",
      active: false,
    });
    clearLink();
  };

  React.useEffect(() => {
    setInitialValues({
      url: link || "",
      active: !!link,
    });
  }, [link]);

  return (
    <>
      <div className={classNames(initialValues.active && "hidden")}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            status,
            values,
            handleBlur,
            handleChange,
            isSubmitting,
          }: FormikProps<FormValues>) => (
            <Form>
              <Alert status="error" open={!!status} enableClose={false}>
                {status}
              </Alert>
              <div className="flex">
                <div className="grow">
                  <input
                    type="text"
                    className="w-full p-4 bg-gray-100 border-0 focus:ring-0"
                    placeholder="Enter phone number here"
                    name="url"
                    value={values.url || ""}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <span className="px-4 py-2 text-sm text-red-500 font-sm">
                    <ErrorMessage name="url" />
                  </span>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={classNames(
                    (!values.url || values.url.length === 0) && "hidden",
                    "p-2"
                  )}
                >
                  <HiCheck fontSize={22} />
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="p-4 text-sm border-t border-gray-300">
          <FormattedMessage id="FormCallDesc" />

        </div>
      </div>
      <div className={classNames(!initialValues?.active && "hidden")}>
        <div className="flex">
          <div className="grow">
            <div className="w-full p-4 overflow-hidden bg-gray-100 border-0 focus:ring-0">
              {link}
            </div>
          </div>
          <button
            type="button"
            onClick={() => window.open(link)}
            className="flex items-center justify-end w-1/3 px-4 py-2 gap-1"
          >
            <HiOutlineExternalLink fontSize={22} />
          </button>
        </div>
        <div className="text-sm border-t border-gray-300">
          <button
            type="button"
            className="flex items-center justify-center w-full p-4 text-lg text-red-500 hover:bg-gray-200"
            onClick={handleChangeLink}
          >
            <FormattedMessage id="LangsChangeLink" />

          </button>
        </div>
      </div>
    </>
  );
};

export default FormCall;
