import React from "react";

// icon
import { ShieldCheckIcon } from "@heroicons/react/20/solid";

const service = {
  id: 1,
  description: "This is a notification and changelog tool.",
};

const processingCompany = {
  companyName: "Joincube, Inc.",
  address: "3800 South Dupont, Dover, DE 19901, United States of America",
};

const purposes = ["Functionality", "Notifications"];

const used = ["Cookies"];

const collected = [
  "User ID",
  "Username",
  "Date and time of submission",
  "Language information",
  "Anonymised IP address",
  "Device type",
  "Geographic location",
  "Referrer URL",
  "Domain name",
  "Pages visited",
];

const legalBasis = [
  "Art. 6 para. 1 s. 1 lit. a GDPR",
  "§ 25 para 2 no. 2 TTDSG",
];

const thirdCountries = ["United States of America"];

const recipients = ["Joincube, Inc."];

const historys = [
  {
    id: 1,
    Decision: "yes",
    Date: "05.08.2023, 16:36",
  },
  {
    id: 2,
    Decision: "yes",
    Date: "05.08.2023, 18:21",
  },
  {
    id: 3,
    Decision: "yes",
    Date: "05.08.2023, 18:21",
  },
];

const Beamer: React.FC = () => {
  // eslint-disable-next-line

  return (
    <div className="cookies-content text-xs">
      <div className="pb-4">
        <h6>Description of Service</h6>
        <p>{service.description}</p>
      </div>

      <div className="pb-4">
        <h6 className="text-sm opacity-70">Processing Company</h6>
        <p>
          {processingCompany.companyName}
          <br />
          {processingCompany.address}
        </p>
      </div>

      <div className="pb-4">
        <h6 className="text-sm opacity-70">
          Data Protection Officer of Processing Company
        </h6>
        <p className="pb-2">
          Below you can find the email address of the data protection officer of
          the processing company.
        </p>
        <a href="info@getbeamer.com" className="">
          info@getbeamer.com
        </a>
      </div>

      <div className="pb-4">
        <h6 className="text-sm opacity-70">Data Purposes</h6>
        <p className="pb-2">
          This list represents the purposes of the data collection and
          processing.
        </p>
        <ul className="flex flex-wrap justify-start gap-2">
          {purposes.map((purpose, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {purpose}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Technologies Used</h6>
        <p className="pb-2">
          This list represents all technologies this service uses to collect
          data. Typical technologies are Cookies and Pixels that are placed in
          the browser.
        </p>
        <ul className="flex flex-wrap justify-start gap-2">
          {used.map((used, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {used}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Data Collected</h6>
        <p className="pb-2">
          This list represents all (personal) data that is collected by or
          through the use of this service.
        </p>
        <ul className="flex flex-wrap justify-start gap-2">
          {collected.map((collected, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {collected}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Legal Basis</h6>
        <p className="pb-2">
          In the following the required legal basis for the processing of data
          is listed.
        </p>
        <ul className="flex flex-wrap justify-start gap-2">
          {legalBasis.map((legalBasis, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {legalBasis}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Location of Processing</h6>
        <p className="pb-2">
          This is the primary location where the collected data is being
          processed. If the data is also processed in other countries, you are
          informed separately.
        </p>
        <ul className="flex list-disc flex-col justify-start px-4">
          <li className="">
            <div>United States of America</div>
          </li>
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Retention Period</h6>
        <p className="pb-2">
          The retention period is the time span the collected data is saved for
          the processing purposes. The data needs to be deleted as soon as it is
          no longer needed for the stated processing purposes.
        </p>
        <ul className="flex list-disc flex-col justify-start px-4">
          <li className="">
            <div>
              The data will be deleted as soon as they are no longer needed for
              the processing purposes.
            </div>
          </li>
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Transfer to Third Countries</h6>
        <p className="pb-2">
          This service may forward the collected data to a different country.
          Please note that this service might transfer the data to a country
          without the required data protection standards. If the data is
          transferred to the USA, there is a risk that your data can be
          processed by US authorities, for control and surveillance measures,
          possibly without legal remedies. Below you can find a list of
          countries to which the data is being transferred. For more information
          regarding safeguards please refer to the website provider’s privacy
          policy or contact the website provider directly.
        </p>
        <ul className="flex flex-wrap justify-start gap-2">
          {thirdCountries.map((thirdCountries, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {thirdCountries}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">Data Recipients</h6>
        <p className="pb-2">
          In the following the recipients of the data collected are listed.
        </p>
        <ul className="flex flex-wrap justify-start gap-2">
          {recipients.map((recipients, index) => (
            <li key={index} className=" rounded border border-white  p-2">
              {recipients}
            </li>
          ))}
        </ul>
      </div>

      <div className="pb-4">
        <h6 className="opacity-70">
          Click here to read the privacy policy of the data processor
        </h6>
        <a href="https://www.getbeamer.com/privacy-policy/#third-parties-transfers">
          {" "}
          https://www.getbeamer.com/privacy-policy/#third-parties-transfers
        </a>
      </div>

      <div className="mb-4">
        <h6>History</h6>

        <div className="mt-2 rounded border">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="px-3 py-2">
                <th scope="col" className="px-3 py-2 text-left font-normal">
                  Decision
                </th>
                <th scope="col" className="text-left font-normal">
                  Date
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {historys.map((item) => (
                <tr key={item.id} className="">
                  <td className="flex w-1/2 whitespace-nowrap px-3 py-2 text-left">
                    <ShieldCheckIcon className="mr-3 h-4 w-3" />
                    {item.Decision}
                  </td>
                  <td className="w-1/2 whitespace-nowrap  ">{item.Date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Beamer;
