import en from "./en.json";
import zhTW from "./zh-TW.json";
import zh from "./zh.json";

type LanguageMessage = {
  [key: string]: any;
};
const langs: LanguageMessage = {
  en: en,
  "zh-TW": zhTW,
  zh: zh,
};

export default langs;
