import React from "react";

const useIntersectionObserver = (
  ref: React.RefObject<HTMLElement>,
  options: any,
  forward: boolean
) => {
  const [element, setElement] = React.useState<HTMLElement | null>(null);
  const [isIntersecting, setIsIntersecting] = React.useState<boolean>(false);
  const observer = React.useRef<IntersectionObserver | null>(null);

  const cleanObserver = () => {
    if (observer?.current) observer.current.disconnect();
  };

  React.useEffect(() => {
    setElement(ref?.current);
  }, [ref]);

  React.useEffect(() => {
    if (!element) return;
    cleanObserver();
    observer.current = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;
        if (!forward) {
          setIsIntersecting(isElementIntersecting);
        } else if (forward && !isIntersecting && isElementIntersecting) {
          setIsIntersecting(isElementIntersecting);
          cleanObserver();
        }
      },
      { ...options }
    );
    const ob = observer.current;
    ob.observe(element);
    return () => {
      cleanObserver();
    };

    // eslint-disable-next-line
  }, [element, options]);

  return isIntersecting;
};

export default useIntersectionObserver;
