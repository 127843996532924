import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//redux
import { store, persistor } from "./stores/";
import { SplashScreenProvider, LayoutSplashScreen } from "./SplashScreen";

//
// Amplify START
import { Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";
Amplify.configure(awsmobile);
// Amplify END
//

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SplashScreenProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          <Router>
            <App />
          </Router>
        </PersistGate>
      </Provider>
    </SplashScreenProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
