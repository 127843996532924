// Layout
import MainLayout from "../layouts/MainLayout";
// Pages
import NotFoundPage from "../pages/NotFoundPage";
import DashboardPage from "../pages/DashboardPage";
// Routes
import AuthRoutes from "./AuthRoutes";
import CmsRoutes from "./CmsRoutes";
import WizardRoutes from "./WizardRoutes";
import ProjectRoutes from "./ProjectRoutes";
import SettingRoutes from "./SettingRoutes";
import MarketingRoutes from "./MarketingRoutes";
import ContactRoutes from "./ContactRoutes";
import EmailRoutes from "./EmailRoutes";
import StatisticRoutes from "./StatisticRoutes";
import UpgradeRoutes from "./UpgradeRoutes";
import CheckOutRoutes from "./CheckOutRoutes";

const routes = [
  ...AuthRoutes,
  ...CmsRoutes,
  ...WizardRoutes,
  ...ProjectRoutes,
  ...SettingRoutes,
  ...MarketingRoutes,
  ...ContactRoutes,
  ...EmailRoutes,
  ...StatisticRoutes,
  ...UpgradeRoutes,
  ...CheckOutRoutes,
  {
    path: "/",
    element: <MainLayout selected="home" children={<DashboardPage />} />,
  },
  {
    path: "/404",
    element: <NotFoundPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export default routes;
