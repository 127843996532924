import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, connect, ConnectedProps } from "react-redux";
import { useCountries, Country } from "../../hooks/useCountries";
import { Menu, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

// MyDialog import
import MyDialog from "../../components/MyDialog";
import type { MyDialogHandler } from "../../components/MyDialog/type";
import { projectActions } from "../../stores/actions";

type Props = ConnectedProps<typeof connector> & {};

const ContactsPage: React.FC<Props> = (props) => {
  // use Countries state
  const countries: Country[] = useCountries();
  const project = useSelector(({ project }) => project);
  const [country, setCountry] = React.useState<Country | null>(
    project?.contact?.country || countries[0]
  );
  const [phone, setPhone] = useState<string>(project?.contact?.phone || "");
  //
  // Add WhatsApp button state
  const [isActive, setIsActive] = useState(
    project?.contact?.country && project?.contact.phone
  );
  const handleActive = () => {
    if (!isActive) {
      if (!phone && phone === "") return;
      props.changeProject({
        ...project,
        contact: {
          country: {
            name: country?.name,
            flags: country?.flags,
            countryCallingCode: country?.countryCallingCode,
          },
          phone,
        },
      });
    } else {
      //remove contact
      props.changeProject({
        ...project,
        contact: undefined,
      });
    }
  };

  // MyDialog
  const dialogRef = React.useRef<MyDialogHandler>(null);

  function handleClick() {
    if (!dialogRef.current) return;
    dialogRef.current?.handleOpen();
  }

  React.useEffect(() => {
    setIsActive(project?.contact?.country && project?.contact.phone);
    setCountry(project?.contact?.country || countries[0]);
    setPhone(project?.contact?.phone || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <div className="relative mx-auto min-h-[1133px] w-full max-w-7xl gap-7 overflow-auto sm:px-6 lg:px-8">
      <main className="m-auto max-w-[1088px] px-3">
        {/* title */}
        <div className="pt-8">
          <div className="mb-6 flex flex-col justify-between text-black md:flex-row">
            <h1 className="mb-3 break-words text-3xl font-bold text-black md:mb-0 md:text-4xl">
              <FormattedMessage id="LangsWhatsAppChat" />
            </h1>
          </div>
        </div>
        {/* input form */}
        <div className="mb-6 flex flex-grow flex-col bg-white p-5 text-black">
          {/* input form title */}
          <div className="flex items-center">
            <div className="mr-3 text-black">
              <h3 className="text-lg font-bold text-black">
                <FormattedMessage id="LangsWhatsAppChat" />
              </h3>
            </div>
            {/*  Active狀態功能 */}
            <div className="mr-auto text-black">
              <div
                className={twMerge(
                  "inline-flex h-6 cursor-default items-center truncate rounded border border-solid border-transparent bg-opacity-[0.1] px-2 tracking-wide",
                  isActive
                    ? "bg-green-700 text-green-800"
                    : "bg-gray-700 text-gray-800"
                )}
              >
                <span className="cursor-default whitespace-nowrap text-xs font-bold tracking-wide">
                  {isActive ? (
                    <FormattedMessage id="LangsActive" />
                  ) : (
                    <FormattedMessage id="LangsNotActive" />
                  )}
                </span>
              </div>
            </div>
          </div>
          {/* input form description */}
          <div className="mt-4">
            <p className="text-base font-normal leading-normal text-gray-500">
              <FormattedMessage id="ContactDescription" />
            </p>
          </div>
          {/* input form label */}
          <div className="mb-2 mt-4 inline-block">
            <p className="text-base font-normal leading-normal text-black">
              <FormattedMessage id="LangsYourWhatsAppNumber" />
            </p>
          </div>
          {/* TODO: Add WhatsApp  from */}
          <Menu
            as="div"
            className="relative  flex w-full flex-col gap-2 text-left sm:flex-row"
          >
            {/* Add WhatsApp Input */}
            <div className="flex w-full">
              <Menu.Button className="border-blue-gray-200 bg-blue-gray-500/10 flex h-auto items-center gap-2 rounded-r-none border border-r-0 px-3 pr-6">
                <img
                  src={country?.flags?.svg}
                  alt={country?.name}
                  className="h-4 w-4 rounded-full object-cover"
                />
                <span className="flex">{country?.countryCallingCode}</span>
              </Menu.Button>
              <Transition
                className="absolute z-10 w-auto"
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transfrom scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items className="mt-16 h-96 origin-top-left overflow-auto overscroll-contain rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {countries.map((c, index) => (
                    <Menu.Item as={Fragment} key={index}>
                      {({ active }) => (
                        <button
                          className={twMerge(
                            "flex w-full items-center justify-between gap-3 px-4 py-2",
                            active ? "bg-slate-500/10" : ""
                          )}
                          onClick={() => setCountry(c)}
                        >
                          <img
                            src={c.flags.svg}
                            alt={c.name}
                            className="h-5 w-5 rounded-full object-cover"
                          />
                          {c.name}
                          <span className="ml-auto">
                            {c.countryCallingCode}
                          </span>
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
              {/* TODO: Set input active WhatsApp number */}
              <input
                autoComplete="tel"
                name="whatsappNumber"
                id="whatsappNumber"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="tel"
                placeholder="Enter your phone number"
                className="border-t-blue-gray-200 w-full rounded-l-none focus:border-t-blue-500"
              />
            </div>
            {/* Add WhatsApp button */}
            <div className="self-end">
              <div className="flex w-full flex-wrap-reverse md:w-auto">
                <button
                  onClick={handleActive}
                  className={twMerge(
                    "relative inline-flex h-full w-full max-w-full cursor-pointer items-center justify-center overflow-hidden border-2 border-solid px-5 py-3 md:w-auto",
                    isActive
                      ? "bg-red-500 text-white hover:bg-red-600"
                      : "hover:bg-blue-gray-500/10 border-indigo-500 text-indigo-500 hover:border-indigo-600 hover:text-indigo-600"
                  )}
                >
                  <span className="truncate text-center font-bold">
                    {isActive ? (
                      <FormattedMessage id="LangsRemoveWhatsApp" />
                    ) : (
                      <FormattedMessage id="LangsAddWhatsApp" />
                    )}
                  </span>
                </button>
              </div>
            </div>
          </Menu>
        </div>
        {/* Send us your ideas group */}
        <div className="mb-6 flex flex-col items-center justify-between border-2 border-solid border-black p-3 md:flex-row">
          <p className="text-base font-normal leading-normal text-black">
            <FormattedMessage id="ContactHelpDescription" />
          </p>
          <button
            onClick={handleClick}
            className="flex h-12 max-w-full cursor-pointer items-center px-5 py-3"
          >
            <span className="cursor-pointer truncate text-center font-bold leading-5 text-indigo-500 hover:text-indigo-600 hover:underline">
              <FormattedMessage id="ContactSendIdeas" />
            </span>
          </button>
        </div>
      </main>
      <MyDialog
        ref={dialogRef}
        title="We want to hear your ideas"
        size="sm"
        position="top"
        className={{ title: "!bg-slate-900 text-white" }}
        buttons={[
          {
            label: "Send",
            className:
              "bg-indigo-500 text-white hover:bg-indigo-600 !py-2 !px-4",
            onClick: () => {
              dialogRef?.current?.handleClose();
            },
          },
        ]}
      >
        <div className="mt-2">
          <div className="block w-auto border-b border-solid border-gray-300 bg-transparent pb-4">
            <legend>
              <label
                htmlFor="comment"
                className="block w-auto text-left text-base font-bold leading-6 text-gray-900"
              >
                <FormattedMessage id="ContactManageCustomers" />
              </label>
            </legend>
            <div className="mt-2">
              <textarea
                id="comment"
                rows={6}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <fieldset className="mt-4">
            <div className="flex flex-1 flex-col">
              <label className="w-auto text-left text-base font-bold text-black">
                <FormattedMessage id="ContactTellUsMore" />
              </label>
              <div className="mt-4 flex">
                <input
                  type="checkbox"
                  name="comment_checkbox"
                  id="comment_checkbox"
                  value="Yes, I'm happy for BuildLink to contact me about my answers and ideas."
                  className="ml-1 h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor="comments"
                  className="ml-4 flex text-left text-sm font-normal text-gray-500"
                >
                  <FormattedMessage id="ContactAnswersAndIdeas" />
                </label>
              </div>
            </div>
          </fieldset>
        </div>
      </MyDialog>
    </div>
  );
};

const connector = connect(null, { ...projectActions });

export default connector(ContactsPage);
