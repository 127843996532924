import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { RadioGroup } from "@headlessui/react";
import {
  CheckCircleIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { HeartIcon } from "@heroicons/react/24/outline";

import Progress from "../../components/Progress";

//image
import logo from "../../media/svg/logo/logo.svg";
import modern from "../../media/svg/wizard/style/modern.svg";
import minimal from "../../media/svg/wizard/style/minimal.svg";
import fresh from "../../media/svg/wizard/style/fresh.svg";
import dark from "../../media/svg/wizard/style/dark.svg";
import natural from "../../media/svg/wizard/style/natural.svg";
import elegant from "../../media/svg/wizard/style/elegant.svg";
import style_image from "../../media/svg/wizard/style/style_image.png";

//context
import { WizardContext } from "./index";
import { wizardActions } from "../../stores/actions";

type StyleType = {
  id: string;
  name: string;
  image: string;
};

const recommended_styles = [
  {
    id: "modern",
    name: "Modern",
    image: modern,
    fontFamily: "font-poppins",
  },
  {
    id: "minimal",
    name: "Minimal",
    image: minimal,
    fontFamily: "font-raleway",
  },
];
const might_be_styles = [
  {
    id: "fresh",
    name: "Fresh",
    image: fresh,
    fontFamily: "font-josefinSans",
  },
  {
    id: "dark",
    name: "Dark",
    image: dark,
    fontFamily: "font-montserrat",
  },
  {
    id: "natural",
    name: "Natural",
    image: natural,
    fontFamily: "font-newsCycle",
  },
  {
    id: "elegant",
    name: "Elegant",
    image: elegant,
    fontFamily: "font-playfairDisplay",
  },
];
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = ConnectedProps<typeof connector>;

const StylePage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector(({ wizard }) => wizard);
  const { activeStep, setActiveStep } = React.useContext(WizardContext);
  const [selectedstyle, setSelectedstyle] = React.useState<
    StyleType | undefined
  >(
    recommended_styles.find((item: StyleType) => item.id === formData.style) ||
      might_be_styles.find((item: StyleType) => item.id === formData.style)
  );

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    dispatch(props.editWizard("style", "skip"));
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    if (!selectedstyle) return;
    dispatch(props.editWizard("style", selectedstyle.id));
    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <div className="tex-black m-0 min-h-screen overflow-auto p-0 leading-6">
        {/* // header */}
        <div className="min-h-screen overflow-auto bg-gray-50 transition">
          <header
            className="fixed left-0 top-0 z-10 m-0 flex h-[88px] w-full items-center justify-center border-b border-solid border-gray-200 bg-gray-50 bg-opacity-[0.9] p-0 leading-6 text-black transition md:w-1/2"
            aria-label="Global"
          >
            <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
          </header>
          {/* // main content Group */}
          <div className="relative flex min-h-screen w-full flex-col bg-gray-50 pb-24 leading-6 text-black transition md:w-1/2">
            <div className="relative m-0 mt-[88px] flex flex-1 flex-col p-0 leading-6 text-black">
              {/* // main content */}
              <div className="mx-auto my-0 w-full max-w-[660px] px-5 py-0 leading-6 text-black ">
                {/* Title */}
                <h1 className="mx-1 mb-8 mt-10 p-0 text-center text-2xl font-bold leading-[1.2] text-black md:mb-16 md:mt-20 md:text-[4.8vh]">
                  <FormattedMessage id="StyleTitle" />
                </h1>
                {/* TODO: Radio form setup */}
                {/* // Recommended form  */}
                {/* // Title 1 */}
                <div className="fint-bold mx-0 my-5 flex items-center p-0 text-lg leading-none text-zinc-800">
                  <HeartIcon className="my-0 ml-0 mr-2 h-5 w-5 p-0 align-middle text-lg font-bold leading-4 text-zinc-800" />
                  <h5 className="m-0 p-0 text-sm font-bold leading-4 text-zinc-800">
                    <FormattedMessage id="LangsRecommended" />
                  </h5>
                </div>
                {/* // Recommended Radio Group */}
                <RadioGroup
                  name="style"
                  value={selectedstyle}
                  onChange={setSelectedstyle}
                >
                  <div className="mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-6">
                    {recommended_styles.map((recommended_style) => (
                      <RadioGroup.Option
                        key={recommended_style.id}
                        value={recommended_style}
                        className={({ checked }) =>
                          classNames(
                            checked ? "box-selected" : "",
                            "wizard stylePage box box-hover "
                          )
                        }
                      >
                        {({ checked }) => (
                          <>
                            <span className="relative h-24 w-full overflow-hidden">
                              <RadioGroup.Description as="span" className="">
                                <img
                                  className="center absolute -bottom-4 h-auto w-auto"
                                  src={recommended_style.image}
                                  alt=""
                                />
                              </RadioGroup.Description>
                              <RadioGroup.Description
                                as="span"
                                // style={{ fontFamily: recommended_style.fontFamily }}
                                className={recommended_style.fontFamily}
                              >
                                <h3 className="absolute left-5 top-1/2 text-2xl font-black text-white">
                                  {recommended_style.name}
                                </h3>
                              </RadioGroup.Description>
                            </span>
                            {checked ? (
                              <CheckCircleIcon
                                className="wizard stylePage checkIcon"
                                aria-hidden="true"
                              />
                            ) : null}
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
                {/* // You might also like form */}
                {/* // Title 2 */}
                <div className="fint-bold mx-0 my-5 flex items-center p-0 text-lg leading-none text-zinc-800">
                  <EllipsisHorizontalIcon className="my-0 ml-0 mr-2 h-5 w-5 p-0 align-middle text-lg font-bold leading-4 text-zinc-800" />
                  <h5 className="m-0 p-0 text-sm font-bold leading-4 text-zinc-800">
                    <FormattedMessage id="YouMightAlsoLike" />
                  </h5>
                </div>
                {/* // You might also like RadioGroup */}
                <RadioGroup
                  name="color"
                  value={selectedstyle}
                  onChange={setSelectedstyle}
                >
                  <div className="mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-6">
                    {might_be_styles.map((might_be_style) => (
                      <RadioGroup.Option
                        key={might_be_style.id}
                        value={might_be_style}
                        className={({ checked }) =>
                          classNames(
                            checked ? "box-selected" : "",
                            "wizard stylePage box box-hover"
                          )
                        }
                      >
                        {({ checked }) => (
                          <>
                            <span className="h-24 w-full overflow-hidden">
                              <RadioGroup.Description as="span" className="">
                                <img
                                  className="h-auto w-full"
                                  src={might_be_style.image}
                                  alt=""
                                />
                              </RadioGroup.Description>
                              <RadioGroup.Description
                                as="span"
                                // style={{
                                //   fontFamily: might_be_style.fontFamily,
                                // }}
                                className={might_be_style.fontFamily}
                              >
                                <h3
                                  className="absolute left-5 top-1/2 text-2xl
                            font-black text-white"
                                >
                                  {might_be_style.name}
                                </h3>
                              </RadioGroup.Description>
                            </span>
                            {checked ? (
                              <CheckCircleIcon
                                className="wizard stylePage checkIcon"
                                aria-hidden="true"
                              />
                            ) : null}
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>

          {/* // button group */}
          <div className="fixed bottom-[84px] flex w-full items-center transition md:w-1/2">
            <Progress className="bg-gray-300/20" value={70} size="sm" />
            <div className="fixed bottom-0 flex w-full items-center justify-between bg-gray-50 bg-opacity-[0.9] p-5 leading-6 transition md:w-1/2">
              <button
                type="button"
                onClick={handleBack}
                className="inline-flex border-2 border-indigo-600 bg-gray-50 px-5 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FormattedMessage id="LangsBack" />
              </button>
              {/*eslint-disable-next-line*/}
              <div
                onClick={handleSkip}
                className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500"
              >
                <FormattedMessage id="LangsSkipThisStep" />
              </div>
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <FormattedMessage id="LangsContinue" />
              </button>
            </div>
          </div>
        </div>

        {/* // Right side Content */}
        <div className="fixed left-1/2 right-0 top-0 hidden h-full bg-white leading-6 text-black md:inline">
          <div className="relative flex h-full animate-none flex-col items-center justify-center overflow-hidden bg-white leading-6 text-black transition">
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-start pt-[88px] leading-6 text-black">
              <div className=" relative max-w-[45vw] overflow-hidden bg-white shadow transition-transform">
                <img className="block h-full w-full" src={style_image} alt="" />
              </div>
              <div className="relative mx-16 mb-0 mt-6 hidden w-[30vw] p-0 leading-6 text-black opacity-100 transition-opacity sm:inline lg:max-w-xl">
                <h2 className="text-4xl font-bold leading-none text-indigo-600">
                  07
                </h2>
                <p className="flex min-h-[40px] items-center text-base font-normal leading-6 text-gray-900">
                  <FormattedMessage id="StyleSubTitle" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...wizardActions });

export default connector(StylePage);
