// layouts
import MainLayout from "../layouts/MainLayout";
// import StorePage from "../pages/Cms/StorePage";

import WebBuilderPage from "../pages/Cms/WebBuilderPage";
import PublishPage from "../pages/Cms/PublishPage";
import SettingPage from "../pages/Cms/SettingPage";
import DomainsPage from "../pages/Cms/DomainsPage";
// import UpgradePage from "../pages/Wizard/UpgradePage";

const routes = [
  {
    path: "/cms/",
    element: <WebBuilderPage />,
  },
  {
    path: "/cms/preview",
    element: <WebBuilderPage />,
  },
  {
    path: "/cms/publish",
    element: <PublishPage status="published" />,
  },
  {
    path: "/cms/unpublish",
    element: <PublishPage status="unpublish" />,
  },
  {
    path: "/cms/domains",
    element: (
      <MainLayout selected="cms">
        <DomainsPage />
      </MainLayout>
    ),
  },
  {
    path: "/cms/setting/general",
    element: (
      <MainLayout selected="cms">
        <SettingPage />
      </MainLayout>
    ),
  },
  {
    path: "/cms/setting/delete",
    element: (
      <MainLayout selected="cms">
        <SettingPage />
      </MainLayout>
    ),
  },

  // {
  //   path: "/cms/store",
  //   element: (
  //     <MainLayout selected="cms">
  //       <StorePage />
  //     </MainLayout>
  //   ),
  // },
  // {
  //   path: "/cms/upgrade",
  //   element: (
  //     <MainLayout selected="cms">
  //       <UpgradePage />
  //     </MainLayout>
  //   ),
  // },
];

export default routes;
