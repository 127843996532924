// layouts
import CheckoutLayout from "../layouts/CheckoutLayout";
// pages
import CheckoutPageOne from "../pages/CheckOut/CheckoutPageOne";
import CheckoutPageTwo from "../pages/CheckOut/CheckoutPageTwo";
import CheckoutPageThree from "../pages/CheckOut/CheckoutPageThree";

const CheckOutRoutes = [
  {
    path: "/checkout/:tierId/billing-cycle/",
    element: <CheckoutLayout children={<CheckoutPageOne/>} />,
  },
  {
    path: "/checkout/:tierId/",
    element: <CheckoutLayout children={<CheckoutPageTwo/>} />,
  },
  {
    path: "/checkout/:tierId/confirm/",
    element: <CheckoutLayout children={<CheckoutPageThree/>} />,
  }
];
export default CheckOutRoutes;
