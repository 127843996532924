import React from "react";
import classNames from "classnames";
//components
import TextToolbar from "./TextToolbar";
import ButtonToolbar from "./ButtonToolbar";
import ImageToolbar from "./ImageToolbar";
//hooks
import useEditorTitle from "./hooks/useEditorTitle";
import useEditorContent from "./hooks/useEditorContent";
import useEditorButton from "./hooks/useEditorButton";
import useEditorImage from "./hooks/useEditorImage";
//type
import type { EditorProps } from "./type";

type EditorContextType = {
  isBold: boolean;
  isItalic: boolean;
  align: "left" | "center" | "right" | string;
  buttonAlign: "left" | "center" | "right" | string;
  textStyle: "normal" | "small" | "medium" | "large" | string;
  link: string | undefined;
  isList: "ol" | "ul" | boolean;
  handleBold: () => void;
  handleItalic: () => void;
  handleAlign: (type: "left" | "center" | "right") => void;
  handleButtonAlign: (type: "left" | "center" | "right") => void;
  handleTextStyle: (type: "normal" | "small" | "medium" | "large") => void;
  handleList: (type: "ol" | "ul" | false) => void;
  clearLink: () => void;
  handleLink: (link: string, pageId: string) => void;
  getLinkType: (link: string) => "page" | "url" | "booking" | "call" | "";
  handleImage: (imageLink: string) => void;
  handleZoomIn: (size: number) => void;
  handleYoutube: (youtubeLink: string) => void;
  getYoutube: () => string | undefined;
};

export const EditorContext = React.createContext<EditorContextType>({
  isBold: false,
  isItalic: false,
  align: "left",
  buttonAlign: "left",
  textStyle: "normal",
  link: "",
  isList: false,
  handleBold: () => {},
  handleItalic: () => {},
  handleAlign: () => {},
  handleButtonAlign: () => {},
  handleTextStyle: () => {},
  handleList: () => {},
  handleLink: () => {},
  clearLink: () => {},
  handleImage: () => {},
  getLinkType: () => {
    return "";
  },
  handleZoomIn: () => {},
  handleYoutube: () => {},
  getYoutube: () => undefined,
});

const Editor: React.FC<EditorProps> = ({ holderId, content, root, type }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [anchorClassName, setAnchorClassName] = React.useState<string>("");
  const [link, setLink] = React.useState<string>("");
  const [linkType, setLinkType] = React.useState<
    "page" | "url" | "booking" | "call" | ""
  >("");
  const [linkPageId, setLinkPageId] = React.useState<string>("");
  const [editiableContent, setEditiableContent] = React.useState<any>();
  const { handleClickOut: handleUnmountTitle } = useEditorTitle();
  const { handleClickOut: handleUnmountContent } = useEditorContent();
  const { handleClickOut: handleUnmountButton } = useEditorButton();
  const { handleClickOut: handleUnmountImage } = useEditorImage();
  const [isBold, setIsBold] = React.useState<boolean>(false);
  const [isItalic, setIsItalic] = React.useState<boolean>(false);
  const [align, setAlign] = React.useState<
    "left" | "center" | "right" | string
  >("left");
  const [buttonAlign, setButtonAlign] = React.useState<
    "left" | "center" | "right" | string
  >("left");
  const [textStyle, setTextStyle] = React.useState<
    "normal" | "small" | "medium" | "large" | string
  >("normal");
  const [isList, setIsList] = React.useState<"ol" | "ul" | boolean>(false);
  const [mousePos, setMousePos] = React.useState<
    { x: number; y: number } | undefined
  >();

  //get link type
  const getLinkType = React.useCallback(
    (l: string): "page" | "url" | "booking" | "call" | "" => {
      if (linkPageId && linkPageId !== "") {
        return "page";
      }
      if (l === window.location.href || l === window.location.href + "#") {
        return "";
      }
      if (
        l.startsWith("https://") ||
        l.startsWith("http://") ||
        l.startsWith("mailto:")
      ) {
        return "url";
      }
      if (l.startsWith("tel:")) {
        return "call";
      }

      return "";
    },
    [linkPageId]
  );

  const initEditor = React.useCallback(() => {
    const holder = document.querySelector(`#${holderId}`);
    if (!holder) return;
    //init title and content
    if (type === "editor-content" || type === "editor-title") {
      setEditiableContent(content);
    }
    // init button
    if (type === "editor-button") {
      if (
        content.href !== "" &&
        content.href !== window.location.href &&
        content.href !== window.location.href + "#"
      ) {
        setLink(content.href);
        setLinkType(getLinkType(content.href));
      }
      const pageId = content.getAttribute("data-pageid");
      if (pageId) {
        setLinkPageId(pageId);
      }
      setAnchorClassName(content.classList.value);
      setEditiableContent(content.innerHTML);
      //check button align
      const parentNode = holder.parentNode as HTMLElement;
      if (!parentNode) return;
      const buttonAlign =
        parentNode.style.getPropertyValue("justify-content") || "left";
      setButtonAlign(buttonAlign);
    }
    //init image
    if (type === "editor-image") {
      setEditiableContent(content);
    }
  }, [content, type, holderId, getLinkType]);

  const handleClickOut = React.useCallback(
    (e: any) => {
      if (!editiableContent) return;
      if (!ref.current) return;
      if (ref.current.contains(e.target)) return;
      const parentTarget = e.target.parentNode as HTMLElement;
      if (
        e.target.getAttribute("data-rel") === "redo" ||
        e.target.getAttribute("data-rel") === "undo" ||
        parentTarget?.getAttribute("data-rel") === "undo" ||
        parentTarget?.getAttribute("data-rel") === "redo"
      ) {
        return;
      }
      const holder = document.querySelector(`#${holderId}`);
      if (!holder) return;
      if (type === "editor-title") {
        handleUnmountTitle(
          ref?.current,
          holder.querySelector(".editor-input"),
          root
        );
      }
      if (type === "editor-content") {
        handleUnmountContent(
          ref?.current,
          holder.querySelector(".editor-input"),
          root
        );
      }
      if (type === "editor-button") {
        handleUnmountButton(
          ref?.current,
          holder.querySelector(".editor-input"),
          root
        );
      }
      if (type === "editor-image") {
        handleUnmountImage(
          ref?.current,
          holder.querySelector(".editor-input"),
          root
        );
      }
    },
    [
      ref,
      type,
      holderId,
      handleUnmountTitle,
      handleUnmountContent,
      handleUnmountButton,
      handleUnmountImage,
      root,
      editiableContent,
    ]
  );

  //handle Text Bold
  const handleBold = React.useCallback(() => {
    document.execCommand("bold");
    setIsBold(!isBold);
  }, [isBold]);

  //handle Text Italic
  const handleItalic = React.useCallback(() => {
    document.execCommand("italic");
    setIsItalic(!isItalic);
  }, [isItalic]);

  //handle Text Align left
  const handleAlign = React.useCallback((a: "left" | "center" | "right") => {
    if (a === "right") {
      document.execCommand("justifyRight");
    } else if (a === "center") {
      document.execCommand("justifyCenter");
    } else {
      document.execCommand("justifyLeft");
    }
    setAlign(a);
  }, []);

  //handle Button Align left
  const handleButtonAlign = React.useCallback(
    (a: "left" | "center" | "right") => {
      const holder = document.querySelector(`#${holderId}`);
      if (!holder) return;
      const parentNode = holder.parentNode as HTMLElement;
      if (!parentNode) return;
      parentNode.style.setProperty("display", "flex");
      parentNode.style.setProperty("flex-direction", "flex-row");
      if (a === "right") {
        parentNode.style.setProperty("justify-content", "flex-end");
      } else if (a === "center") {
        parentNode.style.setProperty("justify-content", "center");
      } else {
        parentNode.style.setProperty("justify-content", "flex-start");
      }
      setButtonAlign(a);
    },
    [holderId]
  );

  //handle Text Style
  const handleTextStyle = React.useCallback(
    (s: "normal" | "small" | "medium" | "large") => {
      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) return;
      const range = selection.getRangeAt(0);
      const commonAncestor = range.commonAncestorContainer as HTMLElement;

      let caHolder: any = commonAncestor.parentElement;
      if (
        !["P", "H1", "H2", "H3", "H4", "H5", "H6"].includes(caHolder.tagName)
      ) {
        caHolder = caHolder.closest("p, h1, h2, h3, h4, h5, h6");
      }
      if (!caHolder) return;

      if (s === "normal") {
        caHolder.style.fontWeight = "normal";
        caHolder.style.fontSize = "16px";
        caHolder.style.lineHeight = "1.6";
      } else if (s === "small") {
        caHolder.style.fontWeight = "600";
        caHolder.style.fontSize = "24px";
        caHolder.style.lineHeight = "1.3";
      } else if (s === "medium") {
        caHolder.style.fontWeight = "600";
        caHolder.style.fontSize = "36px";
        caHolder.style.lineHeight = "1.3";
      } else if (s === "large") {
        caHolder.style.fontWeight = "600";
        caHolder.style.fontSize = "64px";
        caHolder.style.lineHeight = "1.3";
      }
    },
    []
  );

  //handle List
  const handleList = React.useCallback((type: "ul" | "ol" | false) => {
    if (type === "ul") {
      document.execCommand("insertUnorderedList");
    } else if (type === "ol") {
      document.execCommand("insertOrderedList");
    } else {
      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) return;
      const range = selection.getRangeAt(0);
      if (!range?.commonAncestorContainer) return;
      let list: any = range.commonAncestorContainer as HTMLElement;
      list = list.parentElement;
      if (!list || !list?.tagName) return;
      if (list?.tagName !== "UL" && list?.tagName !== "OL") {
        list = list.closest("ul, ol");
      }
      if (!list) return;
      list.after(list.textContent);
      list.remove();
    }
    if (type === "ul" || type === "ol") {
      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) return;
      const range = selection.getRangeAt(0);
      const commonAncestor = range.commonAncestorContainer as HTMLElement;
      let caHolder = commonAncestor.parentElement;
      if (!caHolder || !caHolder?.tagName) return;
      if (caHolder?.tagName !== "UL" && caHolder?.tagName !== "OL") {
        caHolder = caHolder.closest("ul, ol");
      }
      if (!caHolder) return;
      caHolder.style.listStyle = "list-item";
      caHolder.style.paddingLeft = "40px";
      if (type === "ul") caHolder.style.listStyleType = "disc";
      if (type === "ol") caHolder.style.listStyleType = "decimal";
    }
    setIsList(type);
  }, []);

  // change anchor link
  const handleLink = React.useCallback(
    (l: string, pid: string) => {
      setLinkPageId(pid);
      setLink(l);
      const lType = getLinkType(l);
      setLinkType(lType);
    },
    [getLinkType]
  );

  // clear anchor link
  const clearLink = React.useCallback(() => {
    if (!ref?.current) return;
    setLinkType("");
    const editorInput = ref?.current?.querySelector(".editor-input");
    if (!editorInput) return;
    const editorParent = editorInput.parentNode as HTMLElement;
    editorParent.setAttribute("data-href", "");
    editorParent.setAttribute("data-pageid", "");
  }, []);

  // handle image insert
  const handleImage = React.useCallback((imageLink: string) => {
    if (!ref?.current) return;
    const editorInput = ref?.current?.querySelector(
      ".editor-input"
    ) as HTMLElement;
    if (!editorInput) return;
    const editImage = editorInput.querySelector("img");
    if (!editImage) return;
    editImage.setAttribute("src", imageLink);
    editImage.setAttribute("data-video-id", "");
    editImage.setAttribute("data-video-url", "");
    editImage.setAttribute("data-video-type", "");
  }, []);

  //handle youtube link
  const handleYoutube = React.useCallback(
    (youtubeLink: string) => {
      if (!ref.current) return;
      const editorInput = ref?.current?.querySelector(
        ".editor-input"
      ) as HTMLElement;
      if (!editorInput) return;
      const editImage = editorInput.querySelector("img");
      if (!editImage) return;
      const url = new URL(youtubeLink);
      const video = url.searchParams.get("v");
      console.log(video);
      if (!video) return;
      const thumbnailLink = `https://img.youtube.com/vi/${video}/maxresdefault.jpg`;
      handleImage(thumbnailLink);
      editImage.setAttribute("data-video-id", video);
      editImage.setAttribute(
        "data-video-url",
        "https://www.youtube.com/embed/" + video
      );
      editImage.setAttribute("data-video-type", "youtube");
    },
    [handleImage]
  );

  //get youtube
  const getYoutube = React.useCallback(() => {
    if (!ref.current) return;
    const editorInput = ref?.current?.querySelector(
      ".editor-input"
    ) as HTMLElement;
    if (!editorInput) return;
    const editImage = editorInput.querySelector("img");
    if (!editImage) return;
    const video = editImage.getAttribute("data-video-id");
    if (!video) return;
    return "https://www.youtube.com/watch?v=" + video;
  }, []);

  // get translate position from csss
  const _getTranslatePos = (
    transform: string
  ): { x: number; y: number; z: number } | undefined => {
    const m = /^translate3d\((-*\d+)px, (-*\d+)px, (-*\d+)px\)$/.exec(
      transform
    );
    if (!m) return;
    return {
      x: +m[1],
      y: +m[2],
      z: +m[3],
    };
  };

  // handle image zoom in or out
  const handleZoomIn = React.useCallback((size: number) => {
    if (!ref?.current) return;
    const dragger = ref?.current?.querySelector(
      ".image-dragger"
    ) as HTMLElement;
    if (!dragger) return;
    // get dragger size
    const rect = dragger.getBoundingClientRect();
    const originWidth = rect.width,
      originHeight = rect.height;
    //get container size
    const containerRect = ref.current.getBoundingClientRect();
    const originTransform = dragger.style.transform;
    const m = _getTranslatePos(originTransform);
    if (!m) return;
    let newWidth = Math.round(rect.width * (1 + size / 100)),
      newHeight = Math.round(rect.height * (1 + size / 100));
    let x = Math.round(m.x - (newWidth - originWidth) / 2),
      y = Math.round(m.y - (newHeight - originHeight) / 2);
    if (newWidth < containerRect.width) {
      newWidth = containerRect.width;
      newHeight = Math.round(newWidth * (rect.height / rect.width));
      x = 0;
      y = Math.round((originHeight - newHeight) / 2);
    }
    if (newHeight < containerRect.height) {
      newHeight = containerRect.height;
      newWidth = Math.round(newHeight * (rect.width / rect.height));
      y = 0;
      x = Math.round((originWidth - newWidth) / 2);
    }
    const xMin = Math.round(containerRect.width - newWidth);
    const xMax = 0;
    const yMin = Math.round(rect.height - newHeight);
    const yMax = 0;
    if (x < xMin) x = xMin;
    if (x > xMax) x = xMax;
    if (y < yMin) y = yMin;
    if (y > yMax) y = yMax;
    dragger.style.width = newWidth + "px";
    dragger.style.height = newHeight + "px";
    dragger.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
  }, []);

  //function to check selected text
  const checkText = React.useCallback(() => {
    const holder = document.querySelector(`#${holderId}`);
    if (!holder) return;
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const range = selection.getRangeAt(0);
    const selectedText = selection.toString();
    if (selectedText === "") {
      setIsBold(false);
    }
    const commonAncestor = range.commonAncestorContainer as HTMLElement;
    if (!commonAncestor || !commonAncestor.parentElement) {
      setIsBold(false);
      setIsItalic(false);
      return;
    }
    // check selected is bold or not
    if (!ref?.current) return;
    const editorInput = ref?.current?.querySelector(".editor-input");
    if (!editorInput) return;
    // check selected is bold or not
    let countb = 0;
    editorInput.querySelectorAll("b").forEach((b: any) => {
      if (
        commonAncestor === b.getInnerHTML() ||
        commonAncestor.parentElement?.outerHTML === b.getInnerHTML() ||
        commonAncestor.parentElement?.innerHTML === b.getInnerHTML()
      )
        countb++;
    });
    setIsBold(countb > 0);
    // check selected is italic or not
    let counti = 0;
    editorInput.querySelectorAll("i").forEach((i: any) => {
      if (
        commonAncestor === i.getInnerHTML() ||
        commonAncestor.parentElement?.outerHTML === i.getInnerHTML() ||
        commonAncestor.parentElement?.innerHTML === i.getInnerHTML()
      )
        counti++;
    });
    setIsItalic(counti > 0);

    // check select text align
    const textAlign = commonAncestor.parentElement?.style?.textAlign || "left";
    setAlign(textAlign);

    // selected text style
    let caHolder: any = commonAncestor.parentElement;
    if (!["P", "H1", "H2", "H3", "H4", "H5", "H6"].includes(caHolder.tagName)) {
      caHolder = caHolder.closest("p, h1, h2, h3, h4, h5, h6");
    }
    if (caHolder?.style && caHolder?.style?.fontSize === "64px") {
      setTextStyle("large");
    } else if (caHolder?.style && caHolder?.style?.fontSize === "36px") {
      setTextStyle("medium");
    } else if (caHolder?.style && caHolder?.style?.fontSize === "24px") {
      setTextStyle("small");
    } else {
      setTextStyle("normal");
    }

    // check text is list or not
    let liHolder: any = commonAncestor.parentElement;
    if (!["OL", "UL"].includes(liHolder.tagName)) {
      liHolder = liHolder.closest("ol, ul");
    }
    if (liHolder?.tagName === "OL") {
      setIsList("ol");
    } else if (liHolder?.tagName === "UL") {
      setIsList("ul");
    } else {
      setIsList(false);
    }
  }, [holderId]);

  // get mouse position related to editor-input
  const _getRelativePosition = (e: any) => {
    if (!ref.current) return;
    const container = ref.current.querySelector(".editor-input") as HTMLElement;
    const rect = container.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    return { x, y };
  };

  //function for image dragger drag moving
  const moveImageDragger = React.useCallback(
    (e: any) => {
      if (!mousePos) return;
      const currentPos = _getRelativePosition(e);
      if (!currentPos) return;
      const moveX = (currentPos.x - mousePos.x) / 20;
      const moveY = (currentPos.y - mousePos.y) / 20;
      if (!ref?.current) return;
      const imageDragger = ref?.current?.querySelector(
        ".image-dragger"
      ) as HTMLElement;
      if (!imageDragger) return;

      const trPos = _getTranslatePos(imageDragger.style.transform);
      if (!trPos) return;

      const editorInput = ref?.current?.querySelector(
        ".editor-input"
      ) as HTMLElement;
      if (!editorInput) return;
      const rect = editorInput.getBoundingClientRect();
      const xMin = Math.round(rect.width - imageDragger.offsetWidth);
      const xMax = 0;
      const yMin = Math.round(rect.height - imageDragger.offsetHeight);
      const yMax = 0;

      let newX = Math.round(trPos.x + moveX);
      let newY = Math.round(trPos.y + moveY);
      if (newX < xMin) newX = xMin;
      if (newX > xMax) newX = xMax;
      if (newY < yMin) newY = yMin;
      if (newY > yMax) newY = yMax;

      imageDragger.style.transform = `translate3d(${newX}px, ${newY}px, 0px)`;
    },
    [mousePos]
  );

  //function for image dragger start drag
  const initImageDragger = React.useCallback((e: any) => {
    const div = document.createElement("div");
    div.style.display = "none";
    const pos = _getRelativePosition(e);
    setMousePos(pos);
  }, []);

  // event listener click outside editor and select text
  React.useEffect(() => {
    initEditor();
    const node = ref.current;
    document.addEventListener("click", handleClickOut);
    document.addEventListener("mouseup", checkText);
    if (node) {
      const imageDragger = node.querySelector(".image-dragger");
      if (imageDragger) {
        imageDragger.addEventListener("mousedown", initImageDragger);
        imageDragger.addEventListener("mousemove", moveImageDragger);
        imageDragger.addEventListener("mouseup", () => {
          imageDragger.removeEventListener("mousemove", moveImageDragger);
        });
      }
    }

    return () => {
      const currentNode = node;
      document.removeEventListener("click", handleClickOut);
      document.removeEventListener("mouseup", checkText);
      if (currentNode) {
        const imageDragger = currentNode.querySelector(".image-dragger");
        if (imageDragger) {
          imageDragger.removeEventListener("mousedown", initImageDragger);
          imageDragger.removeEventListener("mousemove", moveImageDragger);
          imageDragger.removeEventListener("mouseup", () => {
            imageDragger.removeEventListener("mousemove", moveImageDragger);
          });
        }
      }
    };
  }, [
    handleClickOut,
    initEditor,
    checkText,
    initImageDragger,
    moveImageDragger,
  ]);

  // editor-input autofocus
  React.useEffect(() => {
    if (!ref?.current) return;
    const editorInput = ref.current.querySelector(
      ".editor-input"
    ) as HTMLElement;
    if (!editorInput) return;
    editorInput.focus();
  }, []);

  return (
    <div
      ref={ref}
      className={classNames(
        type === "editor-image" && "h-full w-full",
        "editor-container relative"
      )}
    >
      <EditorContext.Provider
        value={{
          isBold,
          isItalic,
          isList,
          align,
          link,
          buttonAlign,
          textStyle,
          handleBold,
          handleItalic,
          handleAlign,
          handleButtonAlign,
          handleTextStyle,
          handleList,
          handleLink,
          clearLink,
          getLinkType,
          handleImage,
          handleZoomIn,
          handleYoutube,
          getYoutube,
        }}
      >
        {type === "editor-button" || type === "editor-link" ? (
          <>
            <div
              data-holder="anchor"
              className={anchorClassName}
              data-href={link}
              data-pageid={linkPageId}
            >
              <div
                className="editor-input px-2 outline outline-blue-500"
                contentEditable="true"
                dangerouslySetInnerHTML={{ __html: editiableContent }}
              />
            </div>
            <ButtonToolbar type={linkType} link={link} pageId={linkPageId} />
          </>
        ) : type === "editor-title" || type === "editor-content" ? (
          <>
            <div
              className="editor-input relative h-full w-full px-2 outline outline-blue-500"
              contentEditable="true"
              dangerouslySetInnerHTML={{ __html: editiableContent }}
            />
            <TextToolbar />
          </>
        ) : type === "editor-image" ? (
          <>
            <div
              className="editor-input relative h-full w-full overflow-hidden outline outline-blue-500"
              dangerouslySetInnerHTML={{ __html: editiableContent }}
            />
            <ImageToolbar />
          </>
        ) : null}
      </EditorContext.Provider>
    </div>
  );
};

export default Editor;
