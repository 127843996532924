import React from "react";
import { Link } from "react-router-dom";
import type { ProjectLayoutProps } from "./type";

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

import Topmenu from "../components/Topmenu";
import Sidemenu from "../components/Sidemenu/";
import AuthInit from "../pages/Auth/AuthInit";
//import ProjectInit from "../pages/Project/ProjectInit";

import Logo from "../media/svg/logo/logo.svg";
import { FormattedMessage } from "react-intl";

const ProjectLayout: React.FC<ProjectLayoutProps> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <AuthInit>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">
                        <FormattedMessage id="LangsCloseSidebar" />
                      </span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <Sidemenu selected="project" />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}

      <div className="">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center  gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <div className="mt-2 hidden shrink-0 items-center lg:inline">
            <Link to="/">
              <img className="h-8 w-auto" src={Logo} alt="Buildlink" />
            </Link>
          </div>
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">
              <FormattedMessage id="LangsOpenSidebar" />
            </span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

          {/* Topmenu */}
          <div className="hidden w-full lg:block">
            <Topmenu />
          </div>
          {/* bell show when small screen */}
          <div className="w-full lg:hidden">
            <div className="flex justify-end">
              <button
                type="button"
                className="p-2.5 text-gray-400 hover:text-gray-500 "
              >
                <span className="sr-only">
                  <FormattedMessage id="LangsViewNotifications" />
                </span>
                <BellIcon
                  className="text-slate-900 h-6 w-6"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>

        <main className=" h-full">
          <div className="relative h-full ">{children}</div>
        </main>
      </div>
    </AuthInit>
  );
};

export default ProjectLayout;
