import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { projectActions } from "../../../stores/actions";
import MyToast from "../../../components/MyToast";
import type { MyToastHandler } from "../../../components/MyToast/type";

type Props = ConnectedProps<typeof connector>;

const SettingDeletePage: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const toastRef = React.useRef<MyToastHandler>(null);
  const project = useSelector(({ project }) => project);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);
    props
      .changeProject({
        ...project,
        status: "deleted",
      })
      .then(() => {
        toastRef.current?.handleToastOpen();
        navigate("/projects");
        setIsLoading(false);
      });
  };

  return (
    <div>
      <MyToast ref={toastRef} timeout={10000}>
        <FormattedMessage id="WebsiteDeleteSuccessMessage" />
      </MyToast>
      <p className="mb-10">
        <FormattedMessage id="SettingDeleteDescriptionMessage" />
      </p>
      <form className="max-w-[450px]">
        <div className="mx-0 mb-5 mt-0 flex cursor-default items-baseline gap-3 space-y-5 p-0">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="delete-website"
                aria-describedby="delete-website"
                name="delete-website"
                type="checkbox"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <span id="delete-website" className="text-gray-900">
                <FormattedMessage id="SettingDeleteCheckBoxMessage" />
              </span>
            </div>
          </div>
        </div>
        <button
          disabled={!isChecked || isLoading}
          className={twMerge(
            "mb-4 h-12 max-w-full select-none  items-center  justify-center gap-2 px-5 text-base  font-bold  text-white",
            isChecked
              ? "cursor-pointer bg-red-500 transition duration-200 hover:bg-[#df041e] "
              : "pointer-events-none bg-[#ffabb5]",
            isLoading && "cursor-wait opacity-80"
          )}
          onClick={handleDelete}
        >
          <span>
            <FormattedMessage id="LangsDeleteWebsite" />
          </span>
        </button>
      </form>
    </div>
  );
};

const connector = connect(null, { ...projectActions });

export default connector(SettingDeletePage);
