//Layout
import MainLayout from "../layouts/MainLayout";

//page
import BusinessListingsPage from "../pages/Marketing/BusinessListingsPage";
import FbIgMarketingPage from "../pages/Marketing/FbIgMarketingPage";

const routes = [
  {
    path: "/marketing",
    element: (
      <MainLayout
        selected="marketing"
        children={<BusinessListingsPage />}
      />
    ),
  },
  {
    path: "/marketing/fb-ig-marketing",
    element: (
      <MainLayout
        selected="marketing"
        children={<FbIgMarketingPage />}
      />
    ),
  },
];

export default routes;
