import React from "react";
import { Formik, Form, ErrorMessage, FormikHelpers, FormikProps } from "formik";
import { HiCheck, HiOutlineExternalLink } from "react-icons/hi";
import * as Yup from "yup";
import classNames from "classnames";
//components
import Alert from "../../../Alert/";
//context
import { EditorContext } from "../index";

import type { FormYoutubeProps } from "../type";
import { FormattedMessage } from "react-intl";

type FormValues = {
  url: string;
  active: boolean;
};

const FormYoutube: React.FC<FormYoutubeProps> = ({ link }) => {
  const { handleYoutube } = React.useContext(EditorContext);
  const [initialValues, setInitialValues] = React.useState<FormValues>({
    url: "",
    active: false,
  });

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .required("Required")
      .test("url", "Invalid url", (value) => {
        if (!value) return true;
        //if  value is email or start with http:// or https:// , then return true, please check email with regex
        if (value.startsWith("http://") || value.startsWith("https://")) {
          return true;
        } else {
          return false;
        }
      }),
  });

  const handleSubmit = (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    let { url } = values;

    try {
      setSubmitting(false);
      handleYoutube(url);
      setInitialValues({
        url: values.url,
        active: !!values.url,
      });
    } catch (e: any) {
      setStatus(e.message);
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  const handleChangeLink = () => {
    setInitialValues({
      url: "",
      active: false,
    });
  };

  React.useEffect(() => {
    setInitialValues({
      url: link || "",
      active: !!link,
    });
  }, [link]);

  return (
    <>
      <div className={classNames(initialValues.active && "hidden")}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            status,
            values,
            handleBlur,
            handleChange,
            isSubmitting,
          }: FormikProps<FormValues>) => (
            <Form>
              <Alert status="error" open={!!status} enableClose={false}>
                {status}
              </Alert>
              <div className="flex">
                <div className="grow">
                  <input
                    type="text"
                    className="w-full border-0 bg-gray-100 p-4 focus:ring-0"
                    placeholder="Enter a YouTube URL here"
                    name="url"
                    value={values.url || ""}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <span className="font-sm px-4 py-2 text-sm text-red-500">
                    <ErrorMessage name="url" />
                  </span>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={classNames(
                    (!values.url || values.url.length === 0) && "hidden",
                    "p-2"
                  )}
                >
                  <HiCheck fontSize={22} />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className={classNames(!initialValues?.active && "hidden")}>
        <div className="flex">
          <div className="grow overflow-hidden">
            <div className="w-full overflow-hidden border-0 bg-gray-100 p-4 focus:ring-0">
              {initialValues?.url}
            </div>
          </div>
          <button
            type="button"
            onClick={() => window.open(initialValues?.url, "_blank")}
            className="flex w-1/3 items-center justify-end gap-1 px-4 py-2"
          >
            <HiOutlineExternalLink fontSize={22} />
          </button>
        </div>
        <div className="border-t border-gray-300 text-sm">
          <button
            type="button"
            className="flex w-full items-center justify-center p-4 text-lg text-red-500 hover:bg-gray-200"
            onClick={handleChangeLink}
          >
            <FormattedMessage id="LangsChangeLink" />

          </button>
        </div>
      </div>
    </>
  );
};

export default FormYoutube;
