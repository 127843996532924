import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, FormikHelpers, FormikProps, ErrorMessage, Form } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import * as Yup from "yup";
import { Auth } from "aws-amplify";

import Logo from "../../media/svg/logo/logo.svg";
import Alert from "../../components/Alert/";
import MyDialog from "../../components/MyDialog/";
import { FormattedMessage } from "react-intl";

interface FormValues {
  username: string;
  code: string;
  password: string;
  confirm_password: string;
}
type MyDialogHandler = React.ElementRef<typeof MyDialog>;

const ResetPasswordPage: React.FC = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const dialogRef = React.useRef<MyDialogHandler>(null);
  const login_at = useSelector(({ auth }) => auth.login_at, shallowEqual);
  const [initialValues] = React.useState<FormValues>({
    username: username || "",
    code: "",
    password: "",
    confirm_password: "",
  });

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Required"),
    code: Yup.string().required("Required"),
    password: Yup.string()
      .required("Required")
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
        "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
      ),
    confirm_password: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const handleSubmit = async (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    try {
      const { username, code, password } = values;
      await Auth.forgotPasswordSubmit(username, code, password);
      if (dialogRef?.current) {
        dialogRef.current.setConfig({
          title: "Success",
          children: "Password reset successfully",
          buttons: [
            {
              label: "Okay",
              className: "btn-primary",
              onClick: () => {
                navigate("/");
              },
            },
          ],
        });
        dialogRef.current.handleOpen();
      }
    } catch (error: any) {
      console.warn(error);
      if (error?.message) {
        setStatus(error.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (login_at) {
      navigate("/");
    }
  }, [navigate, login_at]);

  return (
    <>
      <MyDialog ref={dialogRef} />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-16 w-auto" src={Logo} alt="API Flow" />
        </div>

        <div className="mt-8 min-h-screen sm:flex sm:flex-col sm:items-center sm:justify-center sm:pb-80">
          <div className="bg-white px-6 py-12 shadow sm:w-96 sm:rounded-lg sm:px-12">
            <div className="flex">
              <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
                <FormattedMessage id="LangsVerifyYourEmail" />

              </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  status,
                  values,
                  handleBlur,
                  handleChange,
                  isSubmitting,
                }: FormikProps<FormValues>) => (
                  <Form className="space-y-6" autoComplete="off">
                    <Alert status="error" open={!!status} enableClose={false}>
                      {status}
                    </Alert>
                    <div className="hidden">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        <FormattedMessage id="LangsEmailAddress" />

                      </label>
                      <div className="mt-2">
                        <input
                          name="username"
                          type="email"
                          autoComplete="new-password"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          readOnly
                        />
                        <span className="font-sm text-sm text-red-500">
                          <ErrorMessage name="username" />
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="code"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          <FormattedMessage id="LangsVerificationCode" />

                        </label>
                      </div>
                      <div className="mt-2">
                        <input
                          name="code"
                          type="password"
                          autoComplete="new-password"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={values.code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="font-sm text-sm text-red-500">
                          <ErrorMessage name="code" />
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          <FormattedMessage id="LangsPassword" />

                        </label>
                      </div>
                      <div className="mt-2">
                        <input
                          name="password"
                          type="password"
                          autoComplete="new-password"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="font-sm text-sm text-red-500">
                          <ErrorMessage name="password" />
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          <FormattedMessage id="LangsConfirmPassword" />

                        </label>
                      </div>
                      <div className="mt-2">
                        <input
                          name="confirm_password"
                          type="password"
                          autoComplete="new-password"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="font-sm text-sm text-red-500">
                          <ErrorMessage name="confirm_password" />
                        </span>
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        disabled={isSubmitting}
                      >
                        <FormattedMessage id="LangsSubmit" />

                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="mt-20 flex flex-row justify-center gap-8">
            {/*eslint-disable-next-line*/}
            <a
              href="#"
              className="font-semibold text-indigo-600 hover:text-indigo-500"
            >
              <FormattedMessage id="LangsLegalNotice" />

            </a>
            {/*eslint-disable-next-line*/}
            <a
              href="#"
              className="font-semibold text-gray-600 hover:text-gray-500"
            >
              <FormattedMessage id="LangsPrivacyPolicy" />

            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordPage;
