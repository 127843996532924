import React from "react";
import ReactDOMClient from "react-dom/client";
import Editor from "../index";
import { IntlProvider } from "react-intl";
import Langs from "../../../../langs/";

const useEditorButton = () => {
  //target is the .editor-button
  const handleClick = React.useCallback((target: HTMLElement) => {
    let i = 0;
    let content = target.childNodes[i] as HTMLElement;
    while (content.nodeType === 3) {
      //if content is text node just skip
      i++;
      content = target.childNodes[i] as HTMLElement;
    }
    const div = document.createElement("div");
    const id = `editor-holder-${new Date().getTime()}`;
    div.id = id;
    div.setAttribute("data-holder", target.getAttribute("class") || "");
    div.setAttribute("data-style", content.getAttribute("style") || "");

    const r = ReactDOMClient.createRoot(div);
    r.render(
      <IntlProvider messages={Langs.en} locale="en" defaultLocale="en">
        <Editor
          root={r}
          holderId={div.id}
          content={content}
          type="editor-button"
        />
      </IntlProvider>
    );
    target.replaceWith(div);
  }, []);

  const handleClickOut = React.useCallback(
    (target: HTMLElement, data: any, prevRoot: ReactDOMClient.Root) => {
      const holder = target?.parentNode as HTMLElement;
      if (!holder) return;
      const holderType = holder.getAttribute("data-holder");
      if (holderType !== "editor-button" && holderType !== "editor-link")
        return;
      const anchorHolder = holder.querySelector('[data-holder="anchor"]');
      if (!anchorHolder) return;
      const div = document.createElement("div");
      div.className = holderType;
      const anchor = document.createElement("a");
      anchor.setAttribute("style", holder.getAttribute("data-style") || "");
      let href = anchorHolder.getAttribute("data-href");
      if (
        !href ||
        href === "" ||
        href === window.location.href ||
        href === window.location.href + "#"
      ) {
        href = "#";
      }
      anchor.setAttribute("href", href || "#");
      let pageId = anchorHolder.getAttribute("data-pageid");
      anchor.setAttribute("data-pageid", pageId || "");
      anchor.className = anchorHolder.classList.value;
      anchor.innerHTML = data.getInnerHTML();
      div.appendChild(anchor);
      holder.replaceWith(div);
      prevRoot.unmount();
      div.addEventListener("click", (e: any) => handleClick(e.currentTarget));
    },
    [handleClick]
  );

  return { handleClick, handleClickOut };
};

export default useEditorButton;
