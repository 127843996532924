import React, { useState } from "react";

// Statistic status Page
import StatisticPageUnPublished from "./subPage/StatisticPageUnPublished";
import StatisticPagePublished from "./subPage/StatisticPagePublished";
import { FormattedMessage } from "react-intl";

const StatisticPage: React.FC = () => {
  // Published useState
  const [isPublished] = useState<boolean>(true);

  return (
    <div className="h-full w-full px-3 sm:px-6">
      {/* background */}
      <div className="mx-auto mb-auto mt-24 flex h-auto w-full max-w-[996px] flex-1 flex-col">
        {/* Main Title */}
        <h1 className="mb-16 w-full justify-start whitespace-nowrap text-left text-3xl font-bold text-zinc-800 md:text-4xl">
          <FormattedMessage id="BuildLinkStatistics" />

        </h1>
        {/* Main card */}
        {isPublished ? (
          <StatisticPagePublished />
        ) : (
          <StatisticPageUnPublished />
        )}
      </div>
    </div>
  );
};
export default StatisticPage;
