import React from "react";
import { BsImages } from "react-icons/bs";
import {
  // BiEditAlt,
  BiZoomIn,
  BiZoomOut,
} from "react-icons/bi";
import { GoVideo } from "react-icons/go";
import classNames from "classnames";
//context
import { EditorContext } from "./index";
//components
import Tooltip from "../../Tooltip";
import MediaDialog from "./media/index";
import FormYoutube from "./forms/FormYoutube";
// type
import type { MediaDialogHandler } from "./media/type";
import { FormattedMessage } from "react-intl";
/**
 * TODO: add youtube embedding tag
 * TODO: upload image size and save the size in the database
 * TODO: cancel drag shadow for image
 * TODO: edit picture: can add filter to image
 */

const ImageToolbar: React.FC = () => {
  const { handleImage, handleZoomIn, getYoutube } =
    React.useContext(EditorContext);
  const [libraryOpen, setLibraryOpen] = React.useState<
    "media" | "pexels" | "youtube" | undefined
  >();
  const youtubeLink = getYoutube();
  const ref = React.useRef<HTMLDivElement>(null);
  const dialogRef = React.useRef<MediaDialogHandler>(null);

  //insert image to image tag
  const handleInsertImage = React.useCallback(
    (imageUrl: any) => {
      if (!imageUrl) return;
      handleImage(imageUrl);
    },
    [handleImage]
  );

  React.useEffect(() => {
    if (!ref?.current) return;
    // const rect = ref.current.getBoundingClientRect();
    // // ref.current.style.position = "fixed";
    // ref.current.style.left = `${rect.x}px`;
    // ref.current.style.top = `${rect.y}px`;
    // ref.current.style.transform = "none";
  }, [ref]);

  return (
    <div
      ref={ref}
      className="editor-toolbar absolute left-1/2 top-2/3 z-50 m-auto flex -translate-x-1/2 -translate-y-1/2 flex-row text-black opacity-50 drop-shadow-xl hover:opacity-100"
    >
      <Tooltip content={<FormattedMessage id="LangsChooseImage" />}>
        <button
          type="button"
          className="flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
          onClick={() => {
            if (!dialogRef?.current) return;
            dialogRef?.current.handleOpen();
          }}
        >
          <BsImages fontSize={20} />
        </button>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsChooseVidoe" />}>
        <button
          type="button"
          className="flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
          onClick={() => {
            setLibraryOpen("youtube");
          }}
        >
          <GoVideo fontSize={20} />
        </button>
      </Tooltip>
      {/*<Tooltip content="Edit">
        <button
          type="button"
          title="Edit"
          className="flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
        >
          <BiEditAlt fontSize={20} />
        </button>
      </Tooltip>*/}
      <Tooltip content={<FormattedMessage id="LangsZoomIn" />}>
        <button
          type="button"
          className="flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
          onClick={() => handleZoomIn(10)}
        >
          <BiZoomIn fontSize={20} />
        </button>
      </Tooltip>
      <Tooltip content={<FormattedMessage id="LangsZoomOut" />}>
        <button
          type="button"
          className="flex h-12 w-12 items-center justify-center bg-gray-100 p-1 hover:bg-gray-200"
          onClick={() => handleZoomIn(-10)}
        >
          <BiZoomOut fontSize={20} />
        </button>
      </Tooltip>
      <div className="relative">
        <MediaDialog ref={dialogRef} onInsert={handleInsertImage} />
      </div>
      <div className="relative">
        <div
          className={classNames(
            libraryOpen === "youtube" && "!flex",
            "editor-link-dialog absolute -left-40 top-0 z-50 hidden w-80 -translate-y-[calc(100%+1rem)] flex-col bg-gray-100 drop-shadow-xl "
          )}
        >
          <div
            className={classNames(
              "flex justify-center border-b border-gray-300 px-2 py-4 font-medium"
            )}
          >
            <div className="flex grow justify-center">
              <FormattedMessage id="LangsYouTubeLink" />
            </div>
          </div>
          <div className={classNames("flex flex-col")}>
            <FormYoutube link={youtubeLink || ""} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageToolbar;
