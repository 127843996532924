import React from "react";
import { Formik, Form, ErrorMessage, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import {
  connect,
  ConnectedProps,
  useSelector,
  shallowEqual,
} from "react-redux";
import { useNavigate } from "react-router-dom";

import { Auth } from "aws-amplify";

//redux
import { authActions } from "../../stores/actions";
//type
import type { RegisterPageProps } from "../type";
//components
import Logo from "../../media/svg/logo/logo.svg";
import Alert from "../../components/Alert";
import { FormattedMessage } from "react-intl";

type Props = ConnectedProps<typeof connector> & RegisterPageProps;

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirm_password: string;
}

const RegisterPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const login_at = useSelector(({ auth }) => auth.login_at, shallowEqual);
  const [initialValues] = React.useState<FormValues>({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirm_password: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    password: Yup.string()
      .required("Required")
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
        "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
      ),
    confirm_password: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const handleSubmit = async (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    console.log(values);
    try {
      const { email, password, firstName, lastName } = values;
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          name: firstName,
          family_name: lastName,
        },
      });
      navigate("/auth/register_confirm/" + email);
    } catch (e: any) {
      setStatus(e.message);
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (login_at) {
      navigate("/");
    }
  }, [login_at, navigate]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={Logo} alt="Build Link" />
      </div>

      <div className="mt-8 min-h-screen sm:flex sm:flex-col sm:items-center sm:justify-center sm:pb-80">
        <div className="bg-white px-6 py-12 shadow sm:w-96 sm:rounded-lg sm:px-12">
          <div className="flex flex-col">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
              <FormattedMessage id="AuthSignUpFree" />
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              <FormattedMessage id="AuthSignUpFreeDesc" />
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              status,
              values,
              handleBlur,
              handleChange,
              isSubmitting,
            }: FormikProps<FormValues>) => (
              <Form className="space-y-6" autoComplete="off">
                <Alert status="error" open={!!status} enableClose={false}>
                  {status}
                </Alert>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <FormattedMessage id="LangsEmailAddress" />
                  </label>
                  <div className="mt-2">
                    <input
                      name="email"
                      type="email"
                      autoComplete="new-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="font-sm text-sm text-red-500">
                      <ErrorMessage name="email" />
                    </span>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <FormattedMessage id="LangsFirstName" />
                  </label>
                  <div className="mt-2">
                    <input
                      name="firstName"
                      type="text"
                      autoComplete="new-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="font-sm text-sm text-red-500">
                      <ErrorMessage name="firstName" />
                    </span>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <FormattedMessage id="LangsLastName" />
                  </label>
                  <div className="mt-2">
                    <input
                      name="lastName"
                      type="text"
                      autoComplete="new-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="font-sm text-sm text-red-500">
                      <ErrorMessage name="lastName" />
                    </span>
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      <FormattedMessage id="LangsPassword" />
                    </label>
                  </div>
                  <div className="mt-2">
                    <input
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="font-sm text-sm text-red-500">
                      <ErrorMessage name="password" />
                    </span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      <FormattedMessage id="LangsConfirmPassword" />
                    </label>
                  </div>
                  <div className="mt-2">
                    <input
                      name="confirm_password"
                      type="password"
                      autoComplete="new-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={values.confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="font-sm text-sm text-red-500">
                      <ErrorMessage name="confirm_password" />
                    </span>
                  </div>
                </div>
                <fieldset>
                  <legend className="sr-only">
                    <FormattedMessage id="LangsNotifications" />
                  </legend>
                  <div className="space-y-5">
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="promo-email"
                          aria-describedby="promo-email"
                          name="promo-email"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <span id="promo-email" className="text-gray-900">
                          <span className="sr-only">
                            <FormattedMessage id="LangsPromoEmail" />
                          </span>
                          <FormattedMessage id="AuthSignUpFreeOffers" />
                        </span>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="terms"
                          aria-describedby="terms"
                          name="terms"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <span
                          id="candidates-description"
                          className="text-gray-900"
                        >
                          <span className="sr-only">
                            <FormattedMessage id="LangsTerms" />
                          </span>
                          <FormattedMessage id="AuthTheBuildLink" />{" "}
                          <span>
                            {/*eslint-disable-next-line*/}
                            <a
                              href="#"
                              className="font-semibold text-indigo-600 hover:text-indigo-500"
                            >
                              <FormattedMessage id="LangsTermsAndConditions" />
                            </a>
                          </span>{" "}
                          <FormattedMessage id="LangsApply" />
                          .
                          <br />
                          <FormattedMessage id="AuthTheBuildLink" />{" "}
                          <span>
                            {/*eslint-disable-next-line*/}
                            <a
                              href="#"
                              className="font-semibold text-indigo-600 hover:text-indigo-500"
                            >
                              <FormattedMessage id="LangsPrivacyPolicy" />{" "}
                            </a>
                            <FormattedMessage id="LangsApplies" />.
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="LangsSignUp" />
                  </button>
                </div>
                <div className="w-full border-t border-gray-300" />
                <p className="mt-10 text-center text-sm text-gray-500">
                  <FormattedMessage id="LangsAlreadyRegistered" />{" "}
                </p>
                <a
                  href="/auth/login"
                  className="flex w-full justify-center rounded-md border-2 border-indigo-500 bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-500 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <FormattedMessage id="LangsSignIn" />
                </a>
              </Form>
            )}
          </Formik>
        </div>
        <div className="mt-20 flex flex-row justify-center gap-8">
          {/*eslint-disable-next-line*/}
          <a
            href="#"
            className="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            <FormattedMessage id="LangsLegalNotice" />
          </a>
          {/*eslint-disable-next-line*/}
          <a
            href="#"
            className="font-semibold text-gray-600 hover:text-gray-500"
          >
            <FormattedMessage id="LangsPrivacyPolicy" />
          </a>
        </div>
      </div>
    </div>
  );
};

const connector = connect(null, { ...authActions });

export default connector(RegisterPage);
