import React from "react";
import { Link } from "react-router-dom";
//icon
import { HiArrowRight } from "react-icons/hi2";
//images
import statisticsListings_background from "../../media/svg/statisticPage/statistics-enable-background-purple.svg";
import tracking_teaser from "../../media/svg/statisticPage/tracking-teaser.png";
import { FormattedMessage } from "react-intl";

const StatisticListingsPage: React.FC = () => {
  return (
    <div className="h-full w-full px-3 sm:px-6">
      {/* background */}
      <div className="mx-auto mb-auto mt-24 flex h-auto w-full max-w-[996px] flex-1 flex-col">
        {/* Main Title */}
        <h1 className="mb-16 w-full justify-start whitespace-nowrap text-left text-3xl font-bold text-zinc-800 md:text-4xl">
          <FormattedMessage id="LangsBusinessListings" />

        </h1>
        {/* Main content */}
        <div className="pt-3">
          {/* main description */}
          <p className="mb-3 text-base font-normal leading-normal text-zinc-800">
            <FormattedMessage id="LangSetUp" />{" "}
            <FormattedMessage id="LangsBusinessListings" />

          </p>
          {/* purple background and content */}
          <div
            style={{
              backgroundImage: `url(${statisticsListings_background})`,
              backgroundRepeat: "no-repeat",
            }}
            className="mb-10 px-5 pt-5 pb-10"
          >
            {/* title */}
            <h2 className="mb-5 text-2xl font-bold leading-normal text-white">
              <FormattedMessage id="StatisticListings" />

            </h2>
            <div className="md:items-end md:flex">
              {/* track image */}
              <img src={tracking_teaser} alt="" className="h-[136px] w-auto" />
              {/* description group */}
              <div className="md:ml-32 ">
                {/* content */}
                <p className="font-normaal mb-3 text-base leading-normal text-zinc-800">
                  <FormattedMessage id="StatisticListingsDescription" />

                </p>
                {/* Set up Business Listings link */}
                <div className="">
                  <Link
                    to="/marketing"
                    className="flex max-w-max cursor-pointer transition-all duration-300 ease-in-out hover:border-b hover:border-indigo-600"
                  >
                    <p className="text-base leading-5 text-indigo-600 hover:text-indigo-700">
                      <FormattedMessage id="LangSetUp" />{" "}
                      <FormattedMessage id="LangsBusinessListings" />
                    </p>
                    {/* icon */}
                    <span className="ml-3 inline-block w-5 h-5">
                      <HiArrowRight className="w-full h-full text-indigo-600" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatisticListingsPage;
