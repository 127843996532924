import React from "react";
import { Formik, Form, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import {
  connect,
  ConnectedProps,
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import { Auth } from "aws-amplify";

//redux
import { authActions } from "../../stores/actions";
// types
import type { LoginPageProps } from "../type";

import Logo from "../../media/svg/logo/logo.svg";
import Alert from "../../components/Alert";
import { FormattedMessage } from "react-intl";

type Props = ConnectedProps<typeof connector> & LoginPageProps;

interface FormValues {
  email: string;
  password: string;
}

const TermsOfService: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const login_at = useSelector(({ auth }) => auth.login_at, shallowEqual);
  const { login } = props;

  const [initialValues] = React.useState<FormValues>({
    email: "",
    password: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const handleSubmit = async (
    values: FormValues,
    { setStatus, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    try {
      const resp = await Auth.signIn(values.email, values.password);
      const { signInUserSession } = resp;
      if (signInUserSession?.accessToken?.jwtToken) {
        dispatch(login(signInUserSession.accessToken.jwtToken));
      }
      if (location.pathname === "/") {
        window.location.reload();
      } else {
        navigate("/");
      }
    } catch (e: any) {
      setStatus(e.message);
      setSubmitting(false);
      console.log(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (login_at) {
      navigate("/");
    }
  }, [login_at, navigate]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={Logo} alt="API Flow" />
      </div>

      <div className="mt-8 min-h-screen sm:flex sm:flex-col sm:items-center sm:justify-center sm:pb-80">
        <div className="bg-white px-6 py-12 shadow sm:w-96 sm:rounded-lg sm:px-12">
          <div className="flex flex-col">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
              <FormattedMessage id="LangsTermsOfService" />

            </h2>
            <p className="mt-2 text-sm text-gray-600">
              <FormattedMessage id="AuthTermsToContinue" />

            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              status,
              values,
              handleBlur,
              handleChange,
              isSubmitting,
            }: FormikProps<FormValues>) => (
              <Form className="space-y-6" autoComplete="off">
                <Alert status="error" open={!!status} enableClose={false}>
                  {status}
                </Alert>
                <fieldset>
                  <legend className="sr-only">
                    <FormattedMessage id="LangsNotifications" />
                  </legend>
                  <div className="space-y-5">
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="promo-email"
                          aria-describedby="promo-email"
                          name="promo-email"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <span id="promo-email" className="text-gray-900">
                          <span className="sr-only">
                            <FormattedMessage id="LangsPromoEmail" />
                          </span>
                          <FormattedMessage id="AuthSignUpFreeOffers" />

                        </span>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="terms"
                          aria-describedby="terms"
                          name="terms"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <span
                          id="candidates-description"
                          className="text-gray-900"
                        >
                          <span className="sr-only">
                            <FormattedMessage id="LangsTerms" />
                          </span>
                          <FormattedMessage id="AuthTheBuildLink" />
                          {" "}
                          <span>
                            {/*eslint-disable-next-line*/}
                            <a
                              href="#"
                              className="font-semibold text-indigo-600 hover:text-indigo-500"
                            >
                              <FormattedMessage id="LangsTermsAndConditions" />

                            </a>
                          </span>{" "}
                          <FormattedMessage id="LangsApply" />
                          .
                          <FormattedMessage id="AuthTheBuildLink" />
                          {" "}
                          <span>
                            {/*eslint-disable-next-line*/}
                            <a
                              href="#"
                              className="font-semibold text-indigo-600 hover:text-indigo-500"
                            >
                              <FormattedMessage id="LangsPrivacyPolicy" />
                              {" "}
                            </a>
                            <FormattedMessage id="LangsApplies" />
                            .
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="LangsContinue" />

                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="mt-20 flex flex-row justify-center gap-8">
          {/*eslint-disable-next-line*/}
          <a
            href="#"
            className="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            <FormattedMessage id="LangsLegalNotice" />

          </a>
          {/*eslint-disable-next-line*/}
          <a
            href="#"
            className="font-semibold text-gray-600 hover:text-gray-500"
          >
            <FormattedMessage id="LangsPrivacyPolicy" />

          </a>
        </div>
      </div>
    </div>
  );
};

const connector = connect(null, { ...authActions });

export default connector(TermsOfService);
