import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

export type DeleteAccountPageHandler = {
  submit: () => boolean;
};

const DeleteAccountPage: React.ForwardRefRenderFunction<
  DeleteAccountPageHandler,
  {}
> = (props, innerRef) => {
  const { user } = useSelector(({ auth }) => auth);
  const [email, setEmail] = React.useState("");
  const [isConfirm, setIsConfirm] = React.useState(false);

  const hanldeSubmit = () => {
    if (!email || !isConfirm) {
      return false;
    }

    if (email !== user.email) {
      return false;
    }

    return true;
  };

  React.useImperativeHandle(innerRef, () => ({
    submit: hanldeSubmit,
  }));

  return (
    <div className="flex flex-col gap-4">
      <FormattedMessage
        id="ProfilesDeleteAccountTitle"
        defaultMessage="Please re-enter your login data. Deleting you account will cause all corresponding websites to be permanently delete as well."
      />

      <div className="mb-10 flex w-full flex-col">
        <label
          className="mb-2 cursor-default  text-base font-normal leading-normal"
          htmlFor="email"
        >
          <FormattedMessage id="LangsEmail" defaultMessage="Email" />
        </label>
        <div className="relative  flex items-center gap-5 border-2 border-solid border-zinc-200 bg-white  text-zinc-800 hover:border-gray-300 focus:border-2 focus:border-solid focus:border-blue-600">
          <input
            name="email"
            id="email"
            type="text"
            className="block w-full flex-1 cursor-text appearance-none border-0 py-3 pl-3 text-base focus:ring-0"
            aria-invalid="true"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <label className="m-0 flex cursor-default items-baseline gap-3 p-0 text-zinc-800">
        <input
          type="checkbox"
          className="h-4 w-4 flex-shrink-0 appearance-none rounded-none border-2 border-solid bg-white text-black hover:border-blue-600"
          name="isConfirm"
          value="1"
          checked={isConfirm}
          onChange={(e) => setIsConfirm(e.target.checked)}
        />
        <span>
          <p className="cursor-default font-sans text-base font-normal leading-normal">
            <FormattedMessage
              id="ProfilesDeleteAccountConfirm"
              defaultMessage="I confirm that my Buildlink account and all of my websites will be permanently deleted."
            />
          </p>
        </span>
      </label>
    </div>
  );
};

export default React.forwardRef(DeleteAccountPage);
