import { API } from "aws-amplify";

type GetProps = {
  userId: string;
  id?: string;
  [key: string]: any;
};

export const get: any = async (params: GetProps) => {
  return await API.get("buildLinkApi", "/project/list", {
    response: true,
    queryStringParameters: params || {},
  });
};

export const getOne: any = async (params?: object) => {
  return await API.get("buildLinkApi", "/project/data", {
    response: true,
    queryStringParameters: params || {},
  });
};

export const add: any = async (params: object) => {
  return await API.post("buildLinkApi", "/project/data", {
    response: true,
    body: params,
  });
};

export const edit: any = async (params: object) => {
  return await API.put("buildLinkApi", "/project/data", {
    response: true,
    body: params,
  });
};
