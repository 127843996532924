import { API } from "aws-amplify";

export const get: any = async (params?: object) => {
  return await API.get("buildLinkApi", "/section/list", {
    response: true,
    queryStringParameters: params || {},
  });
};

export const getCategory: any = async (params?: object) => {
  return await API.get("buildLinkApi", "/section/category/list", {
    response: true,
    queryStringParameters: params || {},
  });
};

export const getColorChoice: any = async (params?: object) => {
  return await API.get("buildLinkApi", "/section/color_choice", {
    response: true,
    queryStringParameters: params || {},
  });
};

export const getPageSelection: any = async (params?: object) => {
  return await API.get("buildLinkApi", "/section/page_selection", {
    response: true,
    queryStringParameters: params || {},
  });
};
