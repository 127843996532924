import React, { Fragment } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Transition, Dialog } from "@headlessui/react";
import { BsChevronLeft } from "react-icons/bs";
import type {
  Page,
  PageDrawerProps,
  PageDrawerHandler,
  PageDrawerContextType,
} from "./type";
import type { MyDialogHandler } from "../../MyDialog/type";
//hook
import usePage from "./hooks/usePage";
//components
import Home from "./form/Home";
import PageAdd from "./form/PageAdd";
import MyDialog from "../../MyDialog";
//model
import * as PageModel from "../../../models/Page";
import { FormattedMessage } from "react-intl";

export const PageDrawerContext = React.createContext<PageDrawerContextType>({
  parentId: "",
  setParentId: () => { },
  activeStep: "home",
  setActiveStep: () => { },
  pageList: [],
  showPageAdd: () => { },
  setPageList: () => { },
  handleAddPage: () => { },
  handleIsHidden: () => { },
  handleEditName: () => { },
  handleDuplicate: () => { },
  handleDeletePage: () => { },
});

const PageDrawer: React.ForwardRefRenderFunction<
  PageDrawerHandler,
  PageDrawerProps
> = ({ ...props }, innerRef) => {
  const project = useSelector(({ project }) => project);
  const dialogRef = React.useRef<MyDialogHandler>(null);
  const {
    findPageRecursive,
    addPageRecursive,
    removePageRecursive,
    editPage,
    editPageRecursive,
  } = usePage();
  const [activeStep, setActiveStep] = React.useState<string>("home");
  const [parentId, setParentId] = React.useState<string>("");
  const [pageList, setPageList] = React.useState<Page[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
  };

  const submitDrawer = async () => {
    await PageModel.submitDrawer({ data: pageList, projectId: project.id });
  };

  const showPageAdd = (pid: string) => {
    setParentId(pid);
    setActiveStep("pageAdd");
  };

  const handleAddPage = React.useCallback(
    (pageName: string) => {
      const page = {
        id: moment().format("x") + "-" + project.id,
        name: pageName,
        isHidden: false,
        children: [],
        parentId,
        projectId: project.id,
      };
      const newPageList = addPageRecursive({ list: pageList, page, parentId });
      setPageList(newPageList);
    },
    [addPageRecursive, parentId, pageList, project.id]
  );

  const handleIsHidden = (id: string) => {
    let page = findPageRecursive({ id, list: pageList });
    if (!page) return;
    const newPageList = editPageRecursive({
      list: pageList,
      id,
      data: { isHidden: !page.isHidden },
    });
    setPageList([...newPageList]);
  };

  const handleEditName = (id: string, name: string) => {
    let page = findPageRecursive({ id, list: pageList });
    if (!page) return;
    const newPageList = editPage({
      list: pageList,
      id,
      page: { ...page, name },
    });

    setPageList([...newPageList]);
  };

  const handleDuplicate = (id: string) => {
    let page = findPageRecursive({ id, list: pageList });
    if (!page) return;
    const newPageList = addPageRecursive({
      list: pageList,
      page: {
        ...page,
        id: moment().format("x") + "-" + project.id,
        children: [],
      },
      parentId: page.parentId || "",
    });
    setPageList([...newPageList]);
  };

  const handleDeletePage = React.useCallback(
    (id: string) => {
      if (!dialogRef.current) return;
      const page = findPageRecursive({ id, list: pageList });
      if (!page) return;
      dialogRef.current.setConfig({
        children: `Are you sure you want to delete the "${page.name}"page?`,
        buttons: [
          {
            label: "Delete",
            className:
              "bg-red-500 text-white rounded-none px-4 py-2 hover:bg-red-600",
            onClick: () => {
              const deleteTarget = findPageRecursive({ id, list: pageList });
              const { children, ...target } = deleteTarget;
              const newPageList = removePageRecursive({ id, list: pageList });
              console.log(deleteTarget);
              PageModel.edit({
                ...target,
                status: "deleted",
              }).then(() => {
                dialogRef?.current?.handleClose();
                setPageList(newPageList);
              });
            },
          },
          {
            label: "Cancel",
            className:
              "text-indigo-500 border-2 border-indigo-500 rounded-none px-4 py-2",
          },
        ],
      });
      dialogRef.current.handleOpen();
    },
    [pageList, findPageRecursive, removePageRecursive]
  );

  const requestPage = React.useCallback(async () => {
    try {
      const resp = await PageModel.get({
        isRecursive: "1",
        projectId: project.id,
        status: "published",
      });
      const { data } = resp;
      setPageList([...data]);
    } catch (error) {
      console.log(error);
    }
  }, [project.id]);

  React.useImperativeHandle(innerRef, () => ({
    handleClose,
    handleOpen,
  }));

  React.useEffect(() => {
    requestPage();
  }, [requestPage]);

  return (
    <PageDrawerContext.Provider
      value={{
        parentId,
        setParentId,
        activeStep,
        setActiveStep,
        pageList,
        showPageAdd,
        setPageList,
        handleAddPage,
        handleIsHidden,
        handleEditName,
        handleDuplicate,
        handleDeletePage,
      }}
    >
      <MyDialog
        ref={dialogRef}
        className={{
          title: "hidden",
          content: "!pb-0",
          footer: "!mt-0 justify-center !border-0 bg-white ",
        }}
      />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="bg-indigo-700 px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            {activeStep === "home" && (<FormattedMessage id="LangsAllPages" />)}
                            {activeStep === "pageAdd" && (
                              <>
                                <button
                                  className="flex items-center justify-center gap-1"
                                  onClick={() => setActiveStep("home")}
                                >
                                  <BsChevronLeft />
                                  <FormattedMessage id="LangsNewPage" />

                                </button>
                              </>
                            )}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white"
                              onClick={() => {
                                handleClose();
                                submitDrawer();
                              }}
                            >
                              {activeStep === "home" && (<FormattedMessage id="LangsDone" />)}
                              {activeStep === "pageAdd" && (<FormattedMessage id="LangsCancel" />)}
                            </button>
                          </div>
                        </div>
                      </div>
                      {activeStep === "home" && <Home />}
                      {activeStep === "pageAdd" && <PageAdd />}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </PageDrawerContext.Provider>
  );
};

export default React.forwardRef(PageDrawer);
