import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
//icon
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
//type
import type { MyToastHandler, MyToastProps } from "./type";
import { FormattedMessage } from "react-intl";

const MyToast: React.ForwardRefRenderFunction<MyToastHandler, MyToastProps> = (
  { open = false, size = "sm", children, timeout = 5000 },
  innerRef
) => {
  // Open & close Toast state
  const [isOpen, setIsOpen] = React.useState<boolean>(open);

  const handleToastClose = (): void => {
    setIsOpen(false);
  };
  const handleToastOpen = (): void => {
    setIsOpen(true);
  };

  // MyToast props forward to parent hook
  React.useImperativeHandle(innerRef, () => ({
    handleToastOpen,
    handleToastClose,
  }));

  // useEffect
  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  React.useEffect(() => {
    if (isOpen === true) {
      setTimeout(() => {
        setIsOpen(false);
      }, timeout);
    }
  }, [isOpen, timeout]);

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={isOpen}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={twMerge(
                "pointer-events-auto w-full overflow-hidden border-l-4 border-l-green-600 bg-white shadow-lg ring-1 ring-black ring-opacity-5",
                size === "sm" && "sm:max-w-sm",
                size === "md" && "sm:max-w-md",
                size === "lg" && "sm:max-w-lg",
                size === "xl" && "sm:max-w-xl",
                size === "2xl" && "sm:max-w-2xl"
              )}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">{children}</div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <span className="sr-only">
                        <FormattedMessage id="LangsClose" />

                      </span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default React.forwardRef(MyToast);
