import React from "react";
import { FormattedMessage } from "react-intl";
import { twMerge } from "tailwind-merge";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import Progress from "../../components/Progress";
//image
import logo from "../../media/svg/logo/logo.svg";
//hook
import {
  SectionStyle,
  useDesign,
} from "../../components/WebBuilder/Editor/hooks/useDesign";
//stores
//context
import { WizardContext } from "./index";
//models
import { getPageSelection } from "../../models/Section";
import { wizardActions } from "../../stores/actions";

type Props = ConnectedProps<typeof connector>;

const initDesign = {
  colors: {
    primary: "#afd3d3",
    secondary: "#417575",
    tertiary: "#2f5454",
    tiny: "#6eaeae",
    white: "#ffffff",
    black: "#000000",
    image: undefined,
  },
  font: { title: "Noto Sans TC", content: "Noto Sans TC" },
  fontSize: "medium",
  button: "sharp",
};

const SelectPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { styleContent, getSectionStyle, editSectionStyle } = useDesign();
  const formData = useSelector(({ wizard }) => wizard);
  const { activeStep, setActiveStep } = React.useContext(WizardContext);
  const ref1 = React.useRef<HTMLDivElement>(null);
  const ref2 = React.useRef<HTMLDivElement>(null);
  const iframeRef1 = React.useRef<HTMLIFrameElement>(null);
  const iframeRef2 = React.useRef<HTMLIFrameElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    const index = Math.random() > 0.5 ? 0 : 1;
    const ref = index === 0 ? iframeRef1 : iframeRef2;
    if (!ref?.current) return;
    if (!ref.current.contentDocument) return;
    const root = ref.current.contentDocument.querySelector(
      "#root"
    ) as HTMLElement;
    if (!root) return;
    const content = root.innerHTML;
    const navbar = root.querySelector(".section:first-child")?.outerHTML;
    const footer = root.querySelector(".section:last-child")?.outerHTML;
    dispatch(props.editWizard("navbar", navbar));
    dispatch(props.editWizard("content", content));
    dispatch(props.editWizard("footer", footer));
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    const ref = selectedIndex === 0 ? iframeRef1 : iframeRef2;
    if (!ref?.current) return;
    if (!ref.current.contentDocument) return;
    const root = ref.current.contentDocument.querySelector(
      "#root"
    ) as HTMLElement;
    if (!root) return;
    const content = root.innerHTML;
    const navbar = root.querySelector(".section:first-child")?.outerHTML;
    const footer = root.querySelector(".section:last-child")?.outerHTML;
    dispatch(props.editWizard("navbar", navbar));
    dispatch(props.editWizard("content", content));
    dispatch(props.editWizard("footer", footer));
    setActiveStep(activeStep + 1);
  };

  const handleSelect = (index: number) => {
    setSelectedIndex(index);
  };

  const createStyleElement = React.useCallback(() => {
    const innerHTML = styleContent({
      ...initDesign,
      colors: {
        primary: formData.color.primary,
        secondary: formData.color.secondary,
        tertiary: formData.color.tertiary,
        tiny: formData.color.tiny,
        black: "#000000",
        white: "#FFFFFF",
        image: undefined,
      },
    });

    if (iframeRef1?.current && iframeRef1.current.contentDocument) {
      let style1 = iframeRef1.current?.contentDocument.querySelector(
        "style#editor-design"
      ) as HTMLElement;
      if (!style1) {
        style1 = document.createElement("style");
        style1.id = "editor-design";
        iframeRef1.current?.contentDocument
          ?.querySelector("body")
          ?.prepend(style1);
      }
      style1.innerHTML = innerHTML;
    }
    if (iframeRef2?.current && iframeRef2.current.contentDocument) {
      let style2 = iframeRef2.current?.contentDocument.querySelector(
        "style#editor-design"
      ) as HTMLElement;
      if (!style2) {
        style2 = document.createElement("style");
        style2.id = "editor-design";
        iframeRef2.current?.contentDocument
          ?.querySelector("body")
          ?.prepend(style2);
      }
      style2.innerHTML = innerHTML;
    }
  }, [formData, styleContent, iframeRef1, iframeRef2]);

  const requestPageSelection = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await getPageSelection({
        topic: formData.topic,
        size: formData.size,
        name: formData.name,
        purpose: formData.purpose,
        "goal[0]": formData.goal.join(","),
      });
      const { data } = resp;
      if (iframeRef1?.current?.contentDocument) {
        const root1 = iframeRef1.current?.contentDocument.querySelector(
          "div#root"
        ) as HTMLElement;
        if (!root1) return;
        root1.innerHTML = data.content;
        iframeRef1.current?.contentDocument
          .querySelectorAll("div#root > .section")
          .forEach((el) => {
            const section = el as HTMLElement;
            const sectionStyle = getSectionStyle("white", {
              ...initDesign,
              colors: formData.color,
            }) as SectionStyle;
            editSectionStyle(section, sectionStyle);
          });
      }
      if (iframeRef2?.current?.contentDocument) {
        const root2 = iframeRef2.current?.contentDocument.querySelector(
          "div#root"
        ) as HTMLElement;
        if (!root2) return;
        root2.innerHTML = data.content;

        iframeRef2.current?.contentDocument
          .querySelectorAll("div#root > .section")
          .forEach((el, index: number) => {
            const section = el as HTMLElement;
            let selectedColor: "primary" | "secondary" | "black" | "white" =
              "white";
            selectedColor = index === 0 ? "secondary" : selectedColor;
            selectedColor = index === 1 ? "primary" : selectedColor;
            selectedColor = index === 3 ? "primary" : selectedColor;
            selectedColor = index === 5 ? "primary" : selectedColor;
            const sectionStyle = getSectionStyle(selectedColor, {
              ...initDesign,
              colors: formData.color,
            }) as SectionStyle;
            editSectionStyle(section, sectionStyle);
          });
      }

      createStyleElement();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [formData]);

  React.useEffect(() => {
    createStyleElement();
    requestPageSelection();
    // eslint-disable-next-line
  }, [requestPageSelection]);

  return (
    <div className="tex-black m-0 min-h-screen overflow-auto p-0 leading-6">
      {/* // header */}
      <div className="min-h-screen overflow-auto bg-gray-50 transition">
        <header
          className="fixed inset-x-0 left-0 top-0 z-20 m-0 flex h-[88px] w-full items-center justify-center border-b border-solid border-gray-200 bg-gray-50 bg-opacity-[0.9] p-0 leading-6 text-black transition-all  lg:overflow-hidden"
          aria-label="Global"
        >
          <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
        </header>

        {/* // main content Group */}
        <div className="relative z-10 flex min-h-screen w-full flex-col bg-gray-50 pb-40 leading-6 text-black transition">
          <div className="relative m-0 mt-[88px] flex flex-1 flex-col p-0 leading-6 text-black">
            {/* // main content */}
            <div className="mx-auto my-0 w-full max-w-[660px] px-5 py-0 leading-6 text-black ">
              {/* Title */}
              <h1 className="mx-1 mb-8 mt-10 p-0 text-center text-2xl font-bold leading-[1.2] text-black md:mb-16 md:mt-20 md:text-[4.8vh]">
                <FormattedMessage id="WebsiteSelectTitle" />
              </h1>
              {/* // subtitle */}
              <p className="mx-auto mb-16 mt-0 max-w-[480px] p-0 text-center text-base font-medium leading-normal text-neutral-500">
                <FormattedMessage id="WebsiteSelectDescription" />
              </p>
            </div>
            {/* // website select form */}
            <div className="flex w-full">
              <div className="relative flex w-1/2 ">
                {/* skeleton START */}
                <div
                  className={twMerge(
                    "absolute right-2 top-2 flex h-full w-[400px] animate-pulse flex-col gap-8 space-x-4 p-8",
                    isLoading === false && "hidden"
                  )}
                >
                  <div>
                    <div className="h-20 rounded bg-gray-400"></div>
                  </div>
                  <div>
                    <div className="mb-3 h-2 rounded bg-gray-400"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 h-2 rounded bg-gray-400"></div>
                        <div className="col-span-1 h-2 rounded bg-gray-400"></div>
                      </div>
                      <div className="h-2 rounded bg-gray-400"></div>
                    </div>
                  </div>
                  <div>
                    <div className="mb-3 h-2 rounded bg-gray-400"></div>
                    <div className="mb-3 h-2 rounded bg-gray-400"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 ">
                          <div className="mb-3 h-2 rounded bg-gray-400"></div>
                          <div className="mb-3 h-2 rounded bg-gray-400"></div>
                          <div className="mb-3 h-2 rounded bg-gray-400"></div>
                          <div className="mb-3 h-2 rounded bg-gray-400"></div>
                          <div className="mb-3 h-2 rounded bg-gray-400"></div>
                        </div>
                        <div className="col-span-1 h-20 rounded bg-gray-400"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* skeleton END */}
                <div
                  className={twMerge(
                    "absolute right-2 top-2 table h-[1500px] w-[1024px] origin-top-right scale-[calc(400/1024)] transform flex-col drop-shadow-xl",
                    !isLoading && "style-box enlarge",
                    selectedIndex === 0 && !isLoading && "selected"
                  )}
                  ref={ref1}
                  onClick={() => handleSelect(0)}
                >
                  <iframe
                    ref={iframeRef1}
                    title="preview1"
                    className={twMerge(
                      "h-full w-full",
                      isLoading === true && "hidden"
                    )}
                    src="/preview.html"
                  ></iframe>
                </div>
              </div>
              <div className="relative flex w-1/2 ">
                {/* skeleton START */}
                <div
                  className={twMerge(
                    "absolute left-2 top-2 flex h-full w-[400px] animate-pulse flex-col gap-8 space-x-4 p-8",
                    isLoading === false && "hidden"
                  )}
                >
                  <div>
                    <div className="h-20 rounded bg-gray-400"></div>
                  </div>
                  <div>
                    <div className="mb-3 h-2 rounded bg-gray-400"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 h-2 rounded bg-gray-400"></div>
                        <div className="col-span-1 h-2 rounded bg-gray-400"></div>
                      </div>
                      <div className="h-2 rounded bg-gray-400"></div>
                    </div>
                  </div>
                  <div>
                    <div className="mb-3 h-2 rounded bg-gray-400"></div>
                    <div className="mb-3 h-2 rounded bg-gray-400"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 ">
                          <div className="mb-3 h-2 rounded bg-gray-400"></div>
                          <div className="mb-3 h-2 rounded bg-gray-400"></div>
                          <div className="mb-3 h-2 rounded bg-gray-400"></div>
                          <div className="mb-3 h-2 rounded bg-gray-400"></div>
                          <div className="mb-3 h-2 rounded bg-gray-400"></div>
                        </div>
                        <div className="col-span-1 h-20 rounded bg-gray-400"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* skeleton END */}
                <div
                  className={twMerge(
                    "absolute left-2 top-2 table h-[1500px] w-[1024px] origin-top-left scale-[calc(400/1024)] transform flex-col drop-shadow-xl",
                    !isLoading && "style-box enlarge",
                    selectedIndex === 1 && !isLoading && "selected"
                  )}
                  ref={ref2}
                  onClick={() => handleSelect(1)}
                >
                  <iframe
                    ref={iframeRef2}
                    title="preview2"
                    className={twMerge(
                      "h-full w-full",
                      isLoading === true && "hidden"
                    )}
                    src="/preview.html"
                  >
                    <div className="style-box"></div>
                  </iframe>

                  <div className="flex h-full w-full animate-pulse flex-col p-8">
                    <div className="h-10 w-full rounded-full" />
                    <div className="h-10 w-full rounded-full" />
                    <div className="h-10 w-full rounded-full" />
                    <div className="h-10 w-full rounded-full" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* // button group */}
        <div className="fixed bottom-[96px] left-1/2 z-20 flex -translate-x-1/2 transform items-center justify-center">
          <div
            className="cursor-pointer text-base font-bold text-indigo-600 hover:text-indigo-500"
            onClick={handleSkip}
          >
            <FormattedMessage id="LangsSurpriseMe" />
          </div>
          <p className="ml-1">
            <FormattedMessage id="LangsOr" />
            <FormattedMessage id="WebsiteSelectEditIt" />
          </p>
        </div>
        <div className="fixed bottom-[84px] left-0 z-10 flex w-full items-center transition md:w-1/2 lg:w-full lg:overflow-hidden">
          <Progress className="bg-gray-300/20" value={95} size="sm" />
          <div className="fixed bottom-0 flex w-full items-center justify-between bg-gray-50 bg-opacity-[0.9] p-5 leading-6 transition ">
            <button
              type="button"
              className="inline-flex border-2 border-indigo-600 bg-gray-50 px-5 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleBack}
            >
              <FormattedMessage id="LangsBack" />
            </button>
            <button
              type="button"
              className="inline-flex bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleNext}
            >
              <FormattedMessage id="LangsContinue" />
            </button>
          </div>
        </div>
      </div>

      {/* // Right side Content */}
      {/*
      <div className="fixed left-1/2 right-0 top-0 hidden h-full bg-gray-50 leading-6 text-black md:inline">
        <div className="relative flex h-full animate-none flex-col items-center justify-center overflow-hidden bg-gray-50 leading-6 text-black transition">
          <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center leading-6 text-black">
            <div className="shadow-xs relative h-[22.5vw] max-h-[435px] min-h-[240px] w-[30vw] min-w-[320px] max-w-[580px] overflow-hidden rounded-lg  bg-white shadow transition-transform">
              <img
                className="block h-full w-full"
                src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                alt=""
              />
            </div>
            <div className="relative mx-16 mb-0 mt-6 hidden w-[30vw] p-0 leading-6 text-black opacity-100 transition-opacity sm:inline lg:max-w-xl">
              <h2 className="text-4xl font-bold leading-none text-indigo-600">
                10
              </h2>
              <p className="flex min-h-[40px] items-center text-base font-normal leading-6 text-gray-900">
                A custom domain makes your site more trustworthy, and easier to
                find on Google.
              </p>
            </div>
          </div>
        </div>
      </div>
      */}
    </div>
  );
};

const connector = connect(null, { ...wizardActions });

export default connector(SelectPage);
