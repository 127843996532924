import React from "react";
import { useSelector, connect, ConnectedProps } from "react-redux";
import { AiOutlineArrowRight } from "react-icons/ai";
import classNames from "classnames";
import { RadioGroup } from "@headlessui/react";
//icon
import { ReactComponent as RectangleIcon } from "../../../../media/svg/cms/Rectangle.svg";
import { ReactComponent as RectangleRoundedIcon } from "../../../../media/svg/cms/RectangleRounded.svg";
import { ReactComponent as RectangleOvalIcon } from "../../../../media/svg/cms/RectangleOval.svg";
import { LiaImage } from "react-icons/lia";
import { PiImages } from "react-icons/pi";
import { CiImageOff } from "react-icons/ci";

//context
import { WebBuilderContext } from "../../../../pages/Cms/WebBuilderPage";
import { DesignDrawerContext } from "../index";
import { FormattedMessage } from "react-intl";
import { projectActions } from "../../../../stores/actions";

const buttonsArr = [
  {
    icon: RectangleIcon,
    name: "LangsSharp",
    value: "sharp",
  },
  {
    icon: RectangleRoundedIcon,
    name: "LangsRounded",
    value: "rounded",
  },
  {
    icon: RectangleOvalIcon,
    name: "LangsOval",
    value: "oval",
  },
];

//eslint-disable-next-line
const animationsArr = [
  {
    icon: LiaImage,
    name: "LangsNone",
    value: "none",
  },
  {
    icon: PiImages,
    name: "LangsSlideIn",
    value: "slide-in",
  },
  {
    icon: CiImageOff,
    name: "LangsFadeIn",
    value: "fade-in",
  },
];

type Props = ConnectedProps<typeof connector>;

const Home: React.FC<Props> = (props) => {
  const project = useSelector(({ project }) => project);
  const { design, setDesign } = React.useContext(WebBuilderContext);
  const { setActiveStep } = React.useContext(DesignDrawerContext);

  const handleChangeButton = (v: "string") => {
    setDesign({ ...design, button: v });
    props.changeProject({ ...project, info: { ...project.info, button: v } });
  };

  const handleChangeAnimation = (v: "string") => {
    setDesign({ ...design, animation: v });
    props.changeProject({
      ...project,
      info: { ...project.info, animation: v },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      {/*Color palette START*/}
      <div
        className="style-box flex flex-col drop-shadow-lg "
        onClick={() => setActiveStep("color")}
      >
        <div
          className="bl-primary-color relative h-40 w-full overflow-hidden"
          style={{ backgroundColor: design.colors.primary }}
        >
          <h2
            className="bl-black-color absolute bottom-4 left-4 z-10 text-2xl font-bold uppercase"
            style={{
              color: design.colors.black,
            }}
          >
            {design.style}
          </h2>
          <h3
            className="bl-white-color absolute left-4 top-4 z-10 text-sm font-bold uppercase"
            style={{
              color: design.colors.white,
            }}
          >
            <FormattedMessage id="LangsColorPalette" />
          </h3>
          <div
            className="bl-secondary-color  absolute left-0 top-0 w-[60%] -translate-x-[20%] -translate-y-[70%] rotate-[80deg] transform rounded-2xl pb-[60%]"
            style={{
              backgroundColor: design.colors.secondary,
            }}
          ></div>
          <div
            className="bl-tertiary-color  absolute bottom-0 right-0 w-[60%] translate-x-[70%] translate-y-[75%] rotate-[80deg] transform rounded-2xl pb-[60%]"
            style={{
              backgroundColor: design.colors.tertiary,
            }}
          ></div>
        </div>
        <div className="relative flex-1 border-t border-gray-300 bg-white p-4">
          <div className="flex w-full justify-between text-indigo-600">
            <span className="font-bold text-indigo-600">
              <FormattedMessage id="LangsEditColorPalette" />
            </span>
            <AiOutlineArrowRight fontSize={24} />
          </div>
        </div>
      </div>
      {/*Color palette END*/}
      {/*Font START*/}
      <div
        className="style-box flex flex-col drop-shadow-lg "
        onClick={() => setActiveStep("font")}
      >
        <div
          className="bl-black-color relative h-40 w-full overflow-hidden"
          style={{ backgroundColor: design.colors.white }}
        >
          <h2
            className="bl-black-color absolute bottom-4 left-4 z-10 text-4xl font-bold uppercase"
            style={{
              color: design.colors.black,
              fontFamily: design.font.title + ", sans-serif",
            }}
          >
            {design.font.title}
            <br />
            <span
              className="text-sm font-normal"
              style={{
                fontFamily: design.font.content + ", sans-serif",
              }}
            >
              {design.font.content}
            </span>
          </h2>
          <h3
            className="bl-black-color absolute left-4 top-4 z-10 text-sm font-bold uppercase"
            style={{
              color: design.colors.black,
            }}
          >
            <FormattedMessage id="LangsFontSet" />
          </h3>
        </div>
        <div className="relative flex-1 border-t border-gray-300 bg-white p-4">
          <div className="flex w-full justify-between text-indigo-600">
            <span className="font-bold text-indigo-600">
              <FormattedMessage id="LangsEditFontSet" />
            </span>
            <AiOutlineArrowRight fontSize={24} />
          </div>
        </div>
      </div>
      {/*Font END*/}
      {/*Button START*/}
      <h3 className="text-base font-bold">
        <FormattedMessage id="LangsButtons" />
      </h3>

      <RadioGroup
        value={design.button}
        onChange={handleChangeButton}
        className="flex w-full flex-row bg-white drop-shadow-lg"
      >
        {buttonsArr.map((item) => (
          <RadioGroup.Option
            key={item.value}
            value={item.value}
            className={({ checked }) =>
              classNames(
                checked ? "bg-indigo-600 text-white" : "hover:bg-indigo-50",
                "flex w-1/3 cursor-pointer flex-col items-center rounded-none p-2 py-6"
              )
            }
          >
            {({ checked }) => (
              <>
                <item.icon stroke={checked ? "#ffffff" : "#000000"} />
                <span className="mt-2">
                  <FormattedMessage id={item.name} />
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      {/*Button END*/}
      {/*Animation START*/}
      <h3 className="text-base font-bold">
        {/* Animations */}
        <FormattedMessage id="LangsAnimations" />
      </h3>
      <RadioGroup
        value={design.animation}
        onChange={handleChangeAnimation}
        className="flex w-full flex-row bg-white drop-shadow-lg"
      >
        {animationsArr.map((item) => (
          <RadioGroup.Option
            key={item.value}
            value={item.value}
            className={({ checked }) =>
              classNames(
                checked ? "bg-indigo-600 text-white" : "hover:bg-indigo-50",
                "flex w-1/3 cursor-pointer flex-col items-center rounded-none p-2 py-6"
              )
            }
          >
            {({ checked }) => (
              <>
                <item.icon
                  stroke={checked ? "#ffffff" : "#000000"}
                  fontSize={36}
                />
                <span className="mt-2">
                  <FormattedMessage id={item.name} />
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      {/*Animation END*/}
    </div>
  );
};

const connector = connect(null, { ...projectActions });

export default connector(Home);
