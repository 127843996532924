// sidemenu
import MainLayout from "../layouts/MainLayout";
// page
import StatisticPage from "../pages/Statistic/StatisticPage";
import GoogleAnalyticsPage from "../pages/Statistic/GoogleAnalyticsPage";
import StatisticListingsPage from "../pages/Statistic/StatisticListingsPage";
import StatisticSettingsPage from "../pages/Statistic/StatisticSettingsPage";

const routes = [
  {
    path: "/statistic",
    element: <MainLayout selected="statistic" children={<StatisticPage />} />,
  },
  {
    path: "/statistic/google",
    element: <MainLayout selected="statistic" children={<GoogleAnalyticsPage />} />,
  },
  {
    path: "/statistic/listing",
    element: <MainLayout selected="statistic" children={<StatisticListingsPage />} />,
  },
  {
    path: "/statistic/settings",
    element: <MainLayout selected="statistic" children={<StatisticSettingsPage />} />,
  },
];

export default routes;
