import React from "react";

const Office365Page: React.FC = () => {
  return (
    <div>
      <h1>Mx Records</h1>
    </div>
  );
};
export default Office365Page;
