import React from "react";

import {
  InformationCircleIcon,
  MegaphoneIcon,
  ArrowTopRightOnSquareIcon,
  HomeModernIcon,
  ArrowTrendingUpIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";

import fbigImg from "../../media/img/fbig--manuImg.png";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const setList = [
  {
    id: "01",
    describe: "setListDescribe1",
    status: true,
  },
  {
    id: "02",
    describe:
      "setListDescribe2",
    status: true,
  },
  {
    id: "03",
    describe:
      "setListDescribe3",
    status: true,
  },
];

const setListStatus = {
  status: true,
};

const Advantages = [
  {
    id: "01",
    icon: HomeModernIcon,
    title: "FbIgMarketingAdvantageTitle1",
    describe:
      "FbIgMarketingAdvantageDescribe1",
  },
  {
    id: "02",
    icon: ArrowTrendingUpIcon,
    title: "FbIgMarketingAdvantageTitle2",
    describe:
      "FbIgMarketingAdvantageDescribe2",
  },
  {
    id: "03",
    icon: CalendarDaysIcon,
    title: "FbIgMarketingAdvantageTitle3",
    describe:
      "FbIgMarketingAdvantageDescribe3",
  },
];

const FbIgMarketingPage: React.FC = () => {
  return (
    <div className="mx-auto max-w-5xl px-4 py-10 text-zinc-800 sm:px-6 lg:px-8">
      <h1 className="mb-16 text-4xl font-bold">
        <FormattedMessage id="LangsFacebookInstagram" />
      </h1>
      <div className="relative mx-0 mb-5 mt-0 flex flex-col justify-between rounded-none border-y-2 border-l-8 border-r-2 border-pink-600 bg-white p-4 md:flex-row ">
        <div className="mb-4 flex items-center md:mb-0">
          <div className="mr-5 hidden md:block">
            <InformationCircleIcon className="h-5 w-5" />{" "}
          </div>
          <div className="">
            <p className="text-base font-normal leading-normal">
              <FormattedMessage id="FbIgMarketingUpgrade" />
            </p>
          </div>
        </div>
        <div className="flex w-full flex-shrink-0 flex-wrap-reverse items-center  justify-end md:ml-5 md:w-auto">
          <a
            href="/cms/upgrade"
            className="relative inline-flex h-8 w-full max-w-full cursor-pointer select-none appearance-none items-center  justify-center gap-2 overflow-hidden bg-blue-600 px-3 py-1 text-sm font-bold text-white transition-colors duration-300 hover:bg-blue-700 md:w-auto"
          >
            <span className="cursor-pointer truncate text-sm font-bold text-white">
              <FormattedMessage id="LangsUpgradeNow" />
            </span>
          </a>
        </div>
      </div>

      <div className="flex flex-col items-center justify-between  bg-white p-10  shadow-sm md:flex-col lg:flex-row">
        <div className="mb-5 flex h-12 w-12 items-center justify-center bg-gray-100 text-blue-600 lg:hidden ">
          <MegaphoneIcon className="h-5 w-5" />
        </div>

        <div className="flex max-w-xl flex-col text-center lg:text-left">
          <h2 className="mb-2  text-2xl font-bold leading-normal   lg:mb-4">
            <FormattedMessage id="FbIgMarketingReachCustomers" />

          </h2>
          <p className="mb-2 text-base font-normal leading-normal  text-neutral-500 lg:mb-4">
            <FormattedMessage id="FbIgMarketingCreateAds" />
          </p>
          <div className="pr-2">
            <p className="text-center lg:text-left">
              {/*eslint-disable-next-line*/}
              <a className="relative mb-4 inline-flex h-12 max-w-full cursor-pointer  select-none  appearance-none  items-center justify-center gap-2 overflow-hidden  px-5 text-base font-bold text-blue-600 transition-colors duration-300 hover:bg-blue-500 hover:bg-opacity-10 lg:pl-0">
                <span>
                  <FormattedMessage id="FbIgMarketingLearnSet" />
                </span>
                <ArrowTopRightOnSquareIcon className="h-5 w-5" />
              </a>
            </p>
          </div>
          <ol className="list-decimal ">
            {setList.map((list) => (
              <li key={list.id} className="mb-6 ml-4 pl-5 text-left">
                <FormattedMessage id={list.describe} />
              </li>
            ))}
          </ol>
          <button
            className={classNames(
              setListStatus.status === true
                ? "cursor-pointer bg-blue-600 transition-colors duration-300 hover:bg-blue-700"
                : "pointer-events-none  cursor-default select-none appearance-none bg-indigo-300 ",
              "relative inline-flex h-12 min-w-0 max-w-full items-center justify-center gap-2 self-stretch overflow-hidden px-5 text-center text-base font-bold text-white lg:self-start"
            )}
          >
            <span>
              <FormattedMessage id="LangsConnect" />
            </span>
          </button>
        </div>

        <img
          className="ml-5  hidden w-full min-w-0 lg:block"
          src={fbigImg}
          alt="fbig"
        />
      </div>

      <span className=" pl-3 text-xs font-bold text-neutral-500">
        <div className="flex flex-row items-center pr-4 leading-4 ">
          <div className="pr-2 text-xs leading-4 ">
            <InformationCircleIcon className="h-5 w-5" />
          </div>
          <FormattedMessage id="FbIgMarketingDisableWindow" />

        </div>
      </span>
      <div className="flex flex-col justify-between pt-5 md:flex-row">
        {Advantages.map((item) => (
          <div className="m-3 text-zinc-800">
            <item.icon className="mb-3 h-5 w-5  text-blue-600" />
            <p className="mb-3 text-base font-bold leading-normal">
              <FormattedMessage id={item.title} />
            </p>
            <p className="mb-2 text-sm font-normal leading-normal">
              <FormattedMessage id={item.describe} />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default FbIgMarketingPage;
