import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import classNames from "classnames";
import Progress from "../../components/Progress";

//image
import logo from "../../media/svg/logo/logo.svg";
import p1 from "../../media/svg/wizard/purpose/p1.png";
import p2 from "../../media/svg/wizard/purpose/p2.png";
import p3 from "../../media/svg/wizard/purpose/p3.png";
import p4 from "../../media/svg/wizard/purpose/p4.png";
import p5 from "../../media/svg/wizard/purpose/p5.png";
import purpose_image from "../../media/svg/wizard/purpose/purpose_image.png";
//context
import { WizardContext } from "./index";
import { wizardActions } from "../../stores/actions";

const purposeModel = [
  {
    id: "personal project",
    // title: "A personal project or event",
    // description: "something for family and friends",
    title: "purposeModelTitle1",
    description: "purposeModelDesc1",
    image: p1,
  },
  {
    id: "hobby",
    // title: "A project or hobby",
    // description: "I do regularly, that I'd like to expand",
    title: "purposeModelTitle2",
    description: "purposeModelDesc2",
    image: p2,
  },
  {
    id: "a project",
    // title: "A project",
    // description: "that I might like to turn into a business",
    title: "purposeModelTitle3",
    description: "purposeModelDesc3",
    image: p3,
  },
  {
    id: "a new business",
    // title: "A new business ",
    // description: "or professional project",
    title: "purposeModelTitle4",
    description: "purposeModelDesc4",
    image: p4,
  },
  {
    id: "established business",
    // title: "My established business",
    // description: "or professional service",
    title: "purposeModelTitle5",
    description: "purposeModelDesc5",
    image: p5,
  },
];

type Props = ConnectedProps<typeof connector>;

const PurposePage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector(({ wizard }) => wizard);
  const { activeStep, setActiveStep } = React.useContext(WizardContext);
  const [selectPurpose, setSelectPurpose] = React.useState(
    purposeModel.find((x) => x.id === formData.purpose) || purposeModel[0]
  );
  const selectPurposeIndex = purposeModel.findIndex(
    (x) => x.id === selectPurpose.id
  );
  const [range, setRange] = React.useState(
    Math.min(selectPurposeIndex * 20 + 10, 100)
  );

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const handleSkip = () => {
    dispatch(props.editWizard("purpose", "skip"));
    setActiveStep(activeStep + 1);
  };
  const handleNext = () => {
    dispatch(props.editWizard("purpose", selectPurpose.id));
    setActiveStep(activeStep + 1);
  };

  const handleChangeRange = (e: any) => {
    const { value } = e.target;
    setRange(value);
  };

  React.useEffect(() => {
    let index = Math.floor(range / 20);
    index = index > 4 ? 4 : index;
    setSelectPurpose(purposeModel[index]);
  }, [range]);

  return (
    <>
      <div className="tex-black m-0 min-h-screen overflow-auto p-0 leading-6">
        {/* // header */}
        <div className="min-h-screen overflow-auto bg-gray-50 transition">
          <header
            className="fixed left-0 top-0 z-10 m-0 flex h-[88px] w-full items-center justify-center border-b border-solid border-gray-200 bg-gray-50 bg-opacity-[0.9] p-0 leading-6 text-black transition md:w-1/2"
            aria-label="Global"
          >
            <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
          </header>

          {/* // main content Group */}
          <div className="relative flex min-h-screen w-full flex-col bg-gray-50 pb-24 leading-6 text-black transition md:w-1/2">
            <div className="relative m-0 mt-[88px] flex flex-1 flex-col p-0 leading-6 text-black">
              {/* // main content */}
              <div className="mx-auto my-0 w-full max-w-[660px] px-5 py-0 leading-6 text-black ">
                {/* Title */}
                <h1 className="mx-1 mb-8 mt-10 p-0 text-center text-2xl font-bold leading-[1.2] text-black md:mb-16 md:mt-20 md:text-[4.8vh]">
                  <FormattedMessage id="purposeTitle" />
                </h1>
                {/* // input form */}
              </div>
              <div className="relative mx-auto mt-10 flex w-full justify-center px-5">
                <button
                  type="button"
                  className="absolute left-16 top-1/2 z-10 -translate-y-1/2 transform rounded-full bg-gray-50 p-4 text-gray-900 shadow hover:bg-gray-100 disabled:opacity-10"
                  disabled={range < 20}
                  onClick={() => setRange(range - 19)}
                >
                  <AiOutlineLeft fontSize={20} />
                </button>
                <button
                  type="button"
                  className="absolute right-16 top-1/2 z-10 -translate-y-1/2 transform rounded-full bg-gray-50 p-4 text-gray-900 shadow hover:bg-gray-100 disabled:opacity-10"
                  disabled={range >= 80}
                  onClick={() => setRange(range + 19)}
                >
                  <AiOutlineRight fontSize={20} />
                </button>
                <div
                  className="purpose-carousel transform duration-150 "
                  style={{
                    transform: `translateX(-${Math.min(Math.floor(range / 20), 4) * 300
                      }px)`,
                  }}
                >
                  <div className="purpose-container ">
                    {purposeModel.map((purpose, index: number) => (
                      <div
                        className={classNames(
                          selectPurpose.id === purpose.id && "active",
                          "m-1 h-full w-72 border border-gray-200 p-5 bg-white"
                        )}
                        key={purpose.id}
                      >
                        <span className="flex flex-col">
                          <span className="block text-lg font-bold text-indigo-600">
                            <FormattedMessage id={purpose.title} />
                          </span>
                          <span className="mt-1 flex items-center text-sm text-gray-500 sm:mb-12">
                            <FormattedMessage id={purpose.description} />
                          </span>
                          <img
                            className="h-auto w-auto"
                            src={purpose.image}
                            alt=""
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mx-auto mt-10 w-full px-5">
                <div className="purpose-range">
                  <input
                    type="range"
                    min={0}
                    max={100}
                    value={range}
                    className="range w-full rounded-full"
                    onChange={handleChangeRange}
                  />
                  <div
                    className="absolute left-[0%] top-2 z-10 h-[10px] rounded-full bg-indigo-600"
                    style={{ width: `${Math.min(range, 100)}%` }}
                  ></div>
                  <span className="absolute left-[20%] top-2 z-10 h-2 w-2 rounded-full bg-white"></span>
                  <span className="absolute left-[40%] top-2 z-10 h-2 w-2 rounded-full bg-white"></span>
                  <span className="absolute left-[60%] top-2 z-10 h-2 w-2 rounded-full bg-white"></span>
                  <span className="absolute left-[80%] top-2 z-10 h-2 w-2 rounded-full bg-white"></span>
                </div>
                <div className="mt-5 flex h-6 select-none justify-between text-base font-bold leading-6 text-neutral-500">
                  <p>
                    <FormattedMessage id="LangsHobby" />{" "}
                  </p>
                  <p>
                    <FormattedMessage id="LangsEstablishedBusiness" />
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* // button group */}
          <div className="fixed bottom-[84px] flex w-full items-center transition md:w-1/2">
            <Progress className="bg-gray-300/20" value={20} size="sm" />
            <div className="fixed bottom-0 flex w-full items-center justify-between bg-gray-50 bg-opacity-[0.9] p-5 leading-6 transition md:w-1/2">
              <button
                type="button"
                className="inline-flex  border-2 border-indigo-600 bg-white px-5 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleBack}
              >
                <FormattedMessage id="LangsBack" />
              </button>
              {/*eslint-disable-next-line*/}
              <div
                onClick={handleSkip}
                className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500 "
              >
                <FormattedMessage id="LangsSkipThisStep" />
              </div>
              <button
                type="button"
                className="inline-flex  bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleNext}
              >
                <FormattedMessage id="LangsContinue" />
              </button>
            </div>
          </div>
        </div>

        {/* // Right side Content */}
        <div className="fixed left-1/2 right-0 top-0 hidden h-full bg-white leading-6 text-black md:inline">
          <div className="relative flex h-full animate-none flex-col items-center justify-center overflow-hidden bg-white leading-6 text-black transition">
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-start pt-[88px] leading-6 text-black">
              <div className="shadow-xs relative max-w-[45vw] overflow-hidden  bg-white shadow transition-transform">
                <img
                  className="block h-full w-full"
                  src={purpose_image}
                  alt=""
                />
              </div>
              <div className="relative mx-16 mb-0 mt-6 hidden w-[30vw] p-0 leading-6 text-black opacity-100 transition-opacity sm:inline lg:max-w-xl">
                <h2 className="text-4xl font-bold leading-none text-indigo-600">
                  02
                </h2>
                <p className="flex min-h-[40px] items-center text-base font-normal leading-6 text-gray-900">
                  <FormattedMessage id="purposeDescription" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...wizardActions });

export default connector(PurposePage);
