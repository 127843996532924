import React from "react";

const GsuitePage: React.FC = () => {
  return (
    <div>
      <h1>Google Workspace</h1>
    </div>
  );
};
export default GsuitePage;
