import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { AiOutlineHeart } from "react-icons/ai";
import { WebBuilderContext } from "../../../../pages/Cms/WebBuilderPage";

const fontSets = [
  { title: "Poppins", content: "Roboto" },
  { title: "Rubik", content: "Martel" },
  { title: "Fredoka One", content: "Varela Round" },
  { title: "Fira Sans", content: "Merriweather" },
  { title: "Work Sans", content: "Raleway" },
  { title: "Passion One", content: "Source Sans Pro" },
  { title: "Prompt", content: "Roboto" },
  { title: "Abel", content: "Martel Sans" },
  { title: "Abril Fatface", content: "Open Sans" },
  { title: "BioRhyme", content: "Roboto" },
  { title: "BioRhyme Expanded", content: "Open Sans" },
  { title: "Buenard", content: "Nunito Sans" },
  { title: "Dancing Script", content: "EB Garamond" },
  { title: "Fahkwang", content: "PT Serif" },
  { title: "Fjalla One", content: "Roboto" },
  { title: "Gotu", content: "Roboto" },
  { title: "Gruppo", content: "Martel" },
  { title: "Josefin Sans", content: "Bitter" },
  { title: "Lobster", content: "Arimo" },
  { title: "Marcellus", content: "Roboto" },
  { title: "Merriweather", content: "Ubuntu" },
  { title: "Montserrat", content: "PT Serif" },
  { title: "News Cycle", content: "Catamaran" },
  { title: "Notable", content: "Open Sans" },
  { title: "Old Standard TT", content: "Raleway" },
  { title: "Oswald", content: "Lato" },
  { title: "Pacifico", content: "Open Sans" },
  { title: "Patua One", content: "Rubik" },
  { title: "Playfair Display", content: "Ek Mukta" },
  { title: "Raleway", content: "Roboto" },
  { title: "Roboto", content: "Overpass Mono" },
  { title: "Roboto Mono", content: "Roboto" },
  { title: "Spectral", content: "Rubik" },
  { title: "Stint Ultra Expanded", content: "Martel Sans" },
  { title: "Teko", content: "PT Serif" },
  { title: "Varela Round", content: "Oxygen" },
  { title: "Volkhov", content: "Frank Ruhl Libre" },
];

const chinessFonts = [
  { title: "Noto Sans TC", content: "Noto Sans TC" },
  { title: "Noto Serif TC", content: "Noto Serif TC" },
  { title: "Noto Sans SC", content: "Noto Sans SC" },
  { title: "Noto Serif SC", content: "Noto Serif SC" },
  { title: "Liu Jian Mao Cao", content: "Liu Jian Mao Cao" },
  { title: "Long Cang", content: "Long Cang" },
  { title: "Ma Shan Zheng", content: "Ma Shan Zheng" },
  { title: "ZCOOL KuaiLe", content: "ZCOOL KuaiLe" },
  { title: "ZCOOL QingKe HuangYou", content: "ZCOOL QingKe HuangYou" },
  { title: "ZCOOL XiaoWei", content: "ZCOOL XiaoWei" },
  { title: "Zhi Mang Xing", content: "Zhi Mang Xing" },
];

const Font: React.FC = () => {
  const { design, setDesign } = React.useContext(WebBuilderContext);
  const [fontSize, setFontSize] = React.useState<number>(
    design.fontSize === "small"
      ? 1
      : design.fontSize === "normal"
      ? 2
      : design.fontSize === "medium"
      ? 3
      : design.fontSize === "large"
      ? 4
      : 2
  );

  const handleFontSize = (e: any) => {
    const value = e.target.value;
    setFontSize(value);
    setDesign({
      ...design,
      fontSize:
        +value === 1
          ? "small"
          : +value === 2
          ? "normal"
          : +value === 3
          ? "medium"
          : +value === 4
          ? "large"
          : "normal",
    });
  };

  const handleChangeFont = (font: any) => {
    setDesign({
      ...design,
      font: font,
    });
  };

  return (
    <>
      {/*Font size START*/}
      <div className="my-2 border-b border-gray-300">
        <div className="flex justify-between">
          <h3 className="mb-2 text-lg font-bold">
            <FormattedMessage id="LangsFontSize" />
          </h3>
        </div>
        <input
          type="range"
          className="w-full"
          step={1}
          min={1}
          max={4}
          value={fontSize}
          onChange={handleFontSize}
        />
        <div className="mb-4 flex justify-between">
          <span>
            <FormattedMessage id="LangsSmall" />
          </span>
          <span>
            <FormattedMessage id="LangsNormal" />
          </span>
          <span>
            <FormattedMessage id="LangsMedium" />
          </span>
          <span>
            <FormattedMessage id="LangsLarge" />
          </span>
        </div>
      </div>
      {/*Font size END*/}
      <div className="flex items-center gap-2 py-4">
        <AiOutlineHeart fontSize={20} />
        <h1 className="text-lg font-bold">
          <FormattedMessage id="LangsRecommended" />
        </h1>
      </div>

      <div className="style-box selected my-2 bg-white p-4 drop-shadow-lg">
        <h1
          className="text-3xl"
          style={{
            fontFamily: `${design.font.title} , sans-serif`,
          }}
        >
          {design.font.title}
        </h1>
        <p
          style={{
            fontFamily: `${design.font.content} , sans-serif`,
          }}
        >
          {design.font.content}
        </p>
      </div>

      {[...chinessFonts, ...fontSets]
        .filter(
          (item) =>
            item.title !== design.font.title &&
            item.content !== design.font.content
        )
        .slice(0, 5)
        .map((item, i) => (
          <div
            key={i}
            className={classNames(
              "style-box my-2 bg-white p-4 drop-shadow-lg",
              item.title === design.font.title &&
                item.content === design.font.content
                ? "selected"
                : ""
            )}
            onClick={() => {
              handleChangeFont(item);
            }}
          >
            <h1
              className="text-3xl"
              style={{
                fontFamily: `${item.title} , sans-serif`,
              }}
            >
              {item.title}
            </h1>
            <p
              style={{
                fontFamily: `${item.content} , sans-serif`,
              }}
            >
              {item.content}
            </p>
          </div>
        ))}
      <div className="flex items-center gap-2 py-4">
        <h1 className="text-lg font-bold">You might also like</h1>
      </div>
      {[...chinessFonts, ...fontSets].slice(5).map((item, i) => (
        <div
          key={i + 5}
          className={classNames(
            "style-box my-2 bg-white p-4 drop-shadow-lg",
            item.title === design.font.title &&
              item.content === design.font.content
              ? "selected"
              : ""
          )}
          onClick={() => {
            handleChangeFont(item);
          }}
        >
          <h1
            className="text-3xl"
            style={{
              fontFamily: `${item.title} , sans-serif`,
            }}
          >
            {item.title}
          </h1>
          <p
            style={{
              fontFamily: `${item.content} , sans-serif`,
            }}
          >
            {item.content}
          </p>
        </div>
      ))}
    </>
  );
};

export default Font;
