import React, { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { Menu, Transition } from "@headlessui/react";
import { MyDropdownProps } from "./type";

const MyDropdown: React.FC<MyDropdownProps> = ({ list, children }) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className=" flex items-center px-3.5 py-2.5">
        {children}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {list &&
            list.map((item: any, index) => (
              <Menu.Item key={index}>
                {({ active }: { active: boolean }) => (
                  <>
                    {item?.href ? (
                      <a
                        className={twMerge(
                          "block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-100",
                          active && "bg-blue-500",
                          item.disabled &&
                            "pointer-events-none cursor-not-allowed opacity-50"
                        )}
                        href={item?.href || "#"}
                      >
                        {item.label}
                      </a>
                    ) : (
                      <button
                        className={twMerge(
                          "block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 hover:bg-gray-100",
                          active && "bg-blue-500",
                          item.disabled &&
                            "pointer-events-none cursor-not-allowed opacity-50"
                        )}
                        onClick={item?.onClick}
                      >
                        {item.label}
                      </button>
                    )}
                  </>
                )}
              </Menu.Item>
            ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MyDropdown;
