//layout
import MainLayout from "../layouts/MainLayout";
//page
import GsuitePage from "../pages/Email/GsuitePage";
import Office365Page from "../pages/Email/Office365Page";
import EmailForwardingPage from "../pages/Email/EmailForwardingPage";
import MxRecordsPage from "../pages/Email/MxRecordsPage";

const routes = [
  {
    path: "/email",
    element: <MainLayout selected="email" children={<GsuitePage/>} />,
  },
  {
    path: "/email/office-365",
    element: <MainLayout selected="email" children={<Office365Page/>} />,
  },
  {
    path: "/email/email-forwarding",
    element: <MainLayout selected="email" children={<EmailForwardingPage/>} />,
  },
  {
    path: "/email/mx-records",
    element: <MainLayout selected="email" children={<MxRecordsPage/>} />,
  },
];

export default routes;
