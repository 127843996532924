import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { RadioGroup } from "@headlessui/react";
import {
  CheckCircleIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { HeartIcon } from "@heroicons/react/24/outline";
import Progress from "../../components/Progress";
import logo from "../../media/svg/logo/logo.svg";
//type
import { ColorsStateType as Color } from "../../stores/type";
//context
import { WizardContext } from "./index";
//model
import { getColorChoice } from "../../models/Section";
//images
import color_image from "../../media/svg/wizard/color/color_image.png";
import { wizardActions } from "../../stores/actions";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = ConnectedProps<typeof connector>;

const ColorPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const formData = useSelector(({ wizard }) => wizard);

  const { activeStep, setActiveStep } = React.useContext(WizardContext);

  const [colors, setColors] = React.useState([]);

  const [selectedColor, setSelectedColor] = React.useState<Color | undefined>();

  const requestColorChoice = React.useCallback(async () => {
    try {
      const resp = await getColorChoice({ keyword: formData.style });
      const { data } = resp;
      setColors(data);
    } catch (error) {
      console.log(error);
    }
  }, [formData.style]);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    dispatch(props.editWizard("color", colors[0]));
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    if (!selectedColor) return;
    dispatch(
      props.editWizard("color", {
        ...(selectedColor || colors[0]),
      })
    );
    setActiveStep(activeStep + 1);
  };

  React.useEffect(() => {
    requestColorChoice();
    // eslint-disable-next-line
  }, [requestColorChoice]);

  return (
    <>
      <div className="tex-black m-0 min-h-screen overflow-auto p-0 leading-6">
        {/* // header */}
        <div className="min-h-screen overflow-auto bg-gray-50 transition">
          <header
            className="fixed left-0 top-0 z-10 m-0 flex h-[88px] w-full items-center justify-center border-b border-solid border-gray-200 bg-gray-50 bg-opacity-[0.9] p-0 leading-6 text-black transition md:w-1/2"
            aria-label="Global"
          >
            <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
          </header>

          {/* // main content Group */}
          <div className="relative flex min-h-screen w-full flex-col bg-gray-50 pb-24 leading-6 text-black transition md:w-1/2">
            <div className="relative m-0 mt-[88px] flex flex-1 flex-col p-0 leading-6 text-black">
              {/* // main content */}
              <div className="mx-auto my-0 w-full max-w-[660px] px-5 py-0 leading-6 text-black ">
                {/* Title */}
                <h1 className="mx-1 mb-8 mt-10 p-0 text-center text-2xl font-bold leading-[1.2] text-black md:mb-16 md:mt-20 md:text-[4.8vh]">
                  <FormattedMessage id="ColorPaletteTitle" />
                </h1>
                {/* // subtitle */}
                <p className="mx-auto mb-16 mt-0 max-w-[480px] p-0 text-center text-base font-medium leading-normal text-neutral-500">
                  <FormattedMessage id="ColorPaletteDescription" />
                </p>
                {/* TODO: Radio Form setup */}
                {/* // Recommended form  */}
                {/* // Title 1 */}
                <div className="fint-bold mx-0 my-5 flex items-center p-0 text-lg leading-none text-zinc-800">
                  <HeartIcon className="my-0 ml-0 mr-2 h-5 w-5 p-0 align-middle text-lg font-bold leading-4 text-zinc-800" />
                  <h5 className="m-0 p-0 text-sm font-bold leading-4 text-zinc-800">
                    <FormattedMessage id="LangsRecommended" />
                  </h5>
                </div>
                {/* // Recommended Radio Group */}
                <RadioGroup
                  name="color"
                  value={selectedColor}
                  onChange={setSelectedColor}
                >
                  <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    {colors.length === 0 && (
                      <>
                        <div className="wizard color box animate-pulse">
                          <span className="relative flex h-full w-full">
                            <span className="h-full w-1/2 bg-gray-300 p-2" />
                            <span className="h-full w-1/2 bg-gray-100 p-2" />
                            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                              Loading...
                            </span>
                          </span>
                        </div>
                        <div className="wizard color box animate-pulse">
                          <span className="relative flex h-full w-full">
                            <span className="h-full w-1/2 bg-gray-300 p-2" />
                            <span className="h-full w-1/2 bg-gray-100 p-2" />
                            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                              Loading...
                            </span>
                          </span>
                        </div>
                        <div className="wizard color box animate-pulse">
                          <span className="relative flex h-full w-full">
                            <span className="h-full w-1/2 bg-gray-300 p-2" />
                            <span className="h-full w-1/2 bg-gray-100 p-2" />
                            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                              Loading...
                            </span>
                          </span>
                        </div>
                      </>
                    )}
                    {colors &&
                      _.isArray(colors) &&
                      colors?.slice(0, 3).map((recommended_color: any) => (
                        <RadioGroup.Option
                          key={recommended_color.primary}
                          value={recommended_color}
                          className={({ checked }) =>
                            classNames(
                              checked ? "box-selected" : "bg-white",
                              "wizard color box box-hover"
                            )
                          }
                        >
                          {({ checked }) => (
                            <>
                              <span className="relative flex h-full w-full">
                                <span
                                  className="h-full w-1/2 p-2 "
                                  style={{
                                    backgroundColor: recommended_color.primary,
                                  }}
                                />
                                <span
                                  className="h-full w-1/2 p-2 "
                                  style={{
                                    backgroundColor:
                                      recommended_color.secondary,
                                  }}
                                />
                              </span>
                              {checked && (
                                <span className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-white">
                                  <CheckCircleIcon
                                    className="h-8 w-8 cursor-pointer align-middle leading-6 text-indigo-600"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                  </div>
                </RadioGroup>
                {/* // You might also like form */}
                {/* // Title 2 */}
                <div className="fint-bold mx-0 my-5 flex items-center p-0 text-lg leading-none text-zinc-800">
                  <EllipsisHorizontalIcon className="my-0 ml-0 mr-2 h-5 w-5 p-0 align-middle text-lg font-bold leading-4 text-zinc-800" />
                  <h5 className="m-0 p-0 text-sm font-bold leading-4 text-zinc-800">
                    <FormattedMessage id="YouMightAlsoLike" />
                  </h5>
                </div>

                {/* // You might also like RadioGroup */}
                <RadioGroup
                  name="color"
                  value={selectedColor}
                  onChange={setSelectedColor}
                >
                  <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    {colors.length === 0 && (
                      <>
                        <div className="wizard color box animate-pulse">
                          <span className="relative flex h-full w-full">
                            <span className="h-full w-1/2 bg-gray-300 p-2" />
                            <span className="h-full w-1/2 bg-gray-100 p-2" />
                            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                              Loading...
                            </span>
                          </span>
                        </div>
                        <div className="wizard color box animate-pulse">
                          <span className="relative flex h-full w-full">
                            <span className="h-full w-1/2 bg-gray-300 p-2" />
                            <span className="h-full w-1/2 bg-gray-100 p-2" />
                            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                              Loading...
                            </span>
                          </span>
                        </div>
                        <div className="wizard color box animate-pulse">
                          <span className="relative flex h-full w-full">
                            <span className="h-full w-1/2 bg-gray-300 p-2" />
                            <span className="h-full w-1/2 bg-gray-100 p-2" />
                            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                              Loading...
                            </span>
                          </span>
                        </div>
                      </>
                    )}
                    {colors &&
                      _.isArray(colors) &&
                      colors?.slice(3, 6).map((might_be_color: any) => (
                        <RadioGroup.Option
                          key={might_be_color.primary}
                          value={might_be_color}
                          className={({ checked }) =>
                            classNames(
                              checked ? "box-selected" : "bg-white",
                              "wizard color box box-hover relative"
                            )
                          }
                        >
                          {({ checked }) => (
                            <>
                              <span className="relative flex h-full w-full">
                                <span
                                  className="h-full w-1/2 p-2 "
                                  style={{
                                    backgroundColor: might_be_color.primary,
                                  }}
                                />
                                <span
                                  className="h-full w-1/2 p-2 "
                                  style={{
                                    backgroundColor: might_be_color.secondary,
                                  }}
                                />
                              </span>
                              {checked ? (
                                <span className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-white">
                                  <CheckCircleIcon
                                    className="h-8 w-8 cursor-pointer align-middle leading-6 text-indigo-600"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>

          {/* // button group */}
          <div className="fixed bottom-[84px] flex w-full items-center transition md:w-1/2">
            <Progress className="bg-gray-300/20" value={80} size="sm" />
            <div className="fixed bottom-0 flex w-full items-center justify-between bg-gray-50 bg-opacity-[0.9] p-5 leading-6 transition md:w-1/2">
              <button
                type="button"
                className="inline-flex border-2 border-indigo-600 bg-gray-50 px-5 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleBack}
              >
                <FormattedMessage id="LangsBack" />
              </button>
              {/*eslint-disable-next-line*/}
              <div
                className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500"
                onClick={handleSkip}
              >
                <FormattedMessage id="LangsSkipThisStep" />
              </div>
              <button
                type="button"
                className="inline-flex bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleNext}
              >
                <FormattedMessage id="LangsContinue" />
              </button>
            </div>
          </div>
        </div>

        {/* // Right side Content */}
        <div className="fixed left-1/2 right-0 top-0 hidden h-full bg-white leading-6 text-black md:inline">
          <div className="relative flex h-full animate-none flex-col items-center justify-center overflow-hidden bg-white leading-6 text-black transition">
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-start pt-[88px] leading-6 text-black">
              <div className="relative max-w-[45vw] overflow-hidden bg-white shadow transition-transform">
                <img className="block h-full w-full" src={color_image} alt="" />
              </div>
              <div className="relative mx-16 mb-0 mt-6 hidden w-[30vw] p-0 leading-6 text-black opacity-100 transition-opacity sm:inline lg:max-w-xl">
                <h2 className="text-4xl font-bold leading-none text-indigo-600">
                  08
                </h2>
                <p className="flex min-h-[40px] items-center text-base font-normal leading-6 text-gray-900">
                  <FormattedMessage id="ColorPaletteSubTitle" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(null, { ...wizardActions });

export default connector(ColorPage);
