import MainLayout from "../layouts/MainLayout";
import ContactsPage from "../pages/Contact/ContactsPage";

const routes = [
  {
    path: "/contacts",
    element: <MainLayout selected="contacts" children={<ContactsPage />} />,
  },
];

export default routes;
