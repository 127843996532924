import React, { Fragment } from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { AiOutlineCheck } from "react-icons/ai";
import { BlockDrawerProps, BlockDrawerHandler } from "./type";
import { SectionStyle, defaultSectionStyle } from "../Editor/hooks/useDesign";
import PreviewBox from "../PreviewBox/";
// context
import { WebBuilderContext } from "../../../pages/Cms/WebBuilderPage";
// hook
import { useColor, HexColor } from "../../../hooks/useColor";
//module
import * as SectionModel from "../../../models/Section";

const BlockDrawer: React.ForwardRefRenderFunction<
  BlockDrawerHandler,
  BlockDrawerProps
> = (props, innerRef) => {
  const { getContrastRatio, hexToRgb } = useColor();
  const { design, getSectionStyle, editSectionStyle, getSectionBackground } =
    React.useContext(WebBuilderContext);
  const [open, setOpen] = React.useState<boolean>(false);
  const [categoryActive, setCategoryActive] = React.useState<number>(0);
  const [index, setIndex] = React.useState<number>(0);
  const [action, setAction] = React.useState<"add" | "edit">("add");
  const [sectionType, setSectionType] =
    React.useState<any>(defaultSectionStyle);
  const [selectedColor, setSelectedColor] = React.useState<
    "primary" | "secondary" | "tertiary" | "tiny" | "white" | "black"
  >();
  const [sectionStyle, setSectionStyle] = React.useState<SectionStyle>();
  const [sectionCategories, setSectionCategories] = React.useState<any[]>([]);
  const [sectionList, setSectionList] = React.useState<any[]>([]);

  const requestCategory = React.useCallback(async () => {
    try {
      const resp = await SectionModel.getCategory();
      const { data } = resp;
      setSectionCategories(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const requestSection = React.useCallback(async () => {
    if (sectionCategories?.length <= 0) return;
    try {
      const resp = await SectionModel.get({
        slug: sectionCategories.filter(
          (item) => item.slug !== "navbar" || item.slug !== "footer"
        )[categoryActive].slug,
      });
      const { data } = resp;
      setSectionList(data);
    } catch (error) {
      console.log(error);
    }
  }, [categoryActive, sectionCategories]);

  const changeTab = (index: number) => {
    setCategoryActive(index);
  };

  const handleOpen = React.useCallback(
    (index: number, action: "add" | "edit", sectionType: string): void => {
      let sectionIndex = 0;
      //get section current backgroundColor if action is edit
      if (action === "edit") {
        const bg = getSectionBackground(index);
        if (!bg) return;
        setSelectedColor(bg);
      }

      setAction(action);
      setIndex(index);
      if (sectionType === "") {
        const slug = sectionCategories.filter(
          (item) => item.slug !== "navbar" && item.slug !== "footer"
        )[0].slug;
        setSectionType("section-" + slug);
        sectionIndex = sectionCategories.findIndex(
          (item) => item.className === "section-" + slug
        );
      } else {
        setSectionType(sectionType);
        // find the index of scetionType  in sectionCategories
        sectionIndex = sectionCategories.findIndex(
          (item) => item.className === sectionType
        );
      }
      countSectionStyle();
      setCategoryActive(sectionIndex);
      setOpen(true);
    },
    //eslint-disable-next-line
    [sectionCategories, getSectionBackground, setOpen]
  );

  const handleClose = (): void => {
    setOpen(false);
  };

  const countSectionStyle = React.useCallback(() => {
    const newSectionStyle = getSectionStyle(selectedColor || "white");
    setSectionStyle(newSectionStyle);
    if (action === "edit") {
      editSectionStyle(index, newSectionStyle);
    }
    //eslint-disable-next-line
  }, [selectedColor, getSectionStyle, action, index]);

  React.useImperativeHandle(innerRef, () => ({
    handleClose,
    handleOpen,
  }));

  React.useEffect(() => {
    requestSection();
  }, [requestSection]);

  React.useEffect(() => {
    requestCategory();
    //eslint-disable-next-line
  }, [requestCategory]);

  React.useEffect(() => {
    countSectionStyle();
  }, [countSectionStyle]);

  // React.useEffect(() => {
  //   console.log("index in BlockDrawer", index);
  // }, [index]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-indigo-700 px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          {action === "add" ? (
                            <FormattedMessage id="LangsAddBlock" />
                          ) : (
                            <FormattedMessage id="LangsBlockDesign" />
                          )}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white"
                            onClick={() => setOpen(false)}
                          >
                            <FormattedMessage id="LangsDone" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex flex-1 flex-col">
                      {/*color picker start*/}
                      <div className="grid grid-cols-8 gap-3.5 border-b border-gray-200 p-4">
                        <div className="col-span-2 flex text-sm font-bold">
                          <FormattedMessage id="LangsBackgroundColor" />
                        </div>
                        {design?.colors &&
                          _.map(
                            design.colors,
                            (
                              color: any,
                              name:
                                | "primary"
                                | "secondary"
                                | "tertiary"
                                | "tiny"
                                | "white"
                                | "black"
                                | "image"
                            ) => {
                              if (name === "image") return null;
                              return (
                                <div
                                  key={name}
                                  className="col-span-1 flex items-center justify-center"
                                >
                                  <div
                                    className={classNames(
                                      selectedColor === name ? "selected" : "",
                                      "style-box color-picker flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-gray-200 shadow"
                                    )}
                                    style={{ backgroundColor: color }}
                                    onClick={() => {
                                      setSelectedColor(name);
                                    }}
                                  >
                                    {selectedColor === name && (
                                      <AiOutlineCheck
                                        fontSize={16}
                                        className={
                                          getContrastRatio(
                                            hexToRgb(color),
                                            hexToRgb("#ffffff" as HexColor)
                                          ) >= 2
                                            ? "text-white"
                                            : "text-indigo-600"
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                      {/*color picker end*/}
                      {/*block drawer start*/}
                      <div className="grid grow grid-flow-col grid-cols-3">
                        <div
                          className={classNames(
                            ["section-navbar", "section-footer"].includes(
                              sectionType
                            ) && "hidden",
                            "col-span-1 px-3 py-3.5"
                          )}
                        >
                          <ul>
                            {sectionCategories.map((category, categoryIdx) => {
                              if (
                                (action === "add" &&
                                  ["footer", "navbar"].includes(
                                    category.slug
                                  )) ||
                                (action === "edit" &&
                                  ![
                                    "section-navbar",
                                    "section-footer",
                                  ].includes(sectionType) &&
                                  ["footer", "navbar"].includes(
                                    category.slug
                                  )) ||
                                (action === "edit" &&
                                  sectionType === "section-navbar" &&
                                  category.slug !== "navbar") ||
                                (action === "edit" &&
                                  sectionType === "section-footer" &&
                                  category.slug !== "footer")
                              ) {
                                return null;
                              }
                              return (
                                <li key={categoryIdx} className="py-1">
                                  <div
                                    className={classNames(
                                      categoryActive === categoryIdx &&
                                        `bg-indigo-600 text-white`,
                                      `cursor-pointer rounded-md px-3.5 py-2.5 text-sm font-semibold`
                                    )}
                                    onClick={() => {
                                      changeTab(categoryIdx);
                                    }}
                                  >
                                    {category.name}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div
                          className={classNames(
                            ["section-navbar", "section-footer"].includes(
                              sectionType
                            )
                              ? "col-span-3"
                              : "col-span-2",
                            "flex flex-col gap-2 border-l border-gray-200 px-3 py-3.5"
                          )}
                        >
                          {sectionList &&
                            sectionList.length > 0 &&
                            sectionList.map((section: any, Idx) => (
                              <PreviewBox
                                key={Idx}
                                index={index}
                                action={action}
                                fullWidth={[
                                  "section-navbar",
                                  "section-footer",
                                ].includes(sectionType)}
                                section={section}
                                sectionType={sectionType}
                                sectionStyle={sectionStyle}
                              />
                            ))}
                        </div>
                      </div>
                      {/*block drawer end*/}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default React.forwardRef(BlockDrawer);
