import React from "react";
import classNames from "classnames";
import type { PreviewBoxProps } from "./type";
import { defaultSectionStyle } from "../Editor/hooks/useDesign";
import { WebBuilderContext } from "../../../pages/Cms/WebBuilderPage";
import { FormattedMessage } from "react-intl";
import { Splide } from "@splidejs/splide";
import "@splidejs/splide/css";

const PreviewBox: React.FC<PreviewBoxProps> = ({
  children,
  index,
  action,
  fullWidth,
  section,
  sectionType,
  sectionStyle,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const previewRef = React.useRef<HTMLDivElement>(null);
  const { addSection, editSection, editSectionStyle, closeBlockDrawer } =
    React.useContext(WebBuilderContext);

  const resize = React.useCallback(() => {
    if (!ref?.current || !previewRef?.current) return;
    ref.current.style.height = "";
    previewRef.current.style.height = "";
    const height = +window
      .getComputedStyle(ref.current)
      .height.replace("px", "");
    const newHeight = fullWidth
      ? `${Math.max(160, (height * 370) / 1024)}px`
      : `${Math.max(160, (height * 260) / 1024)}px`;
    if (!ref?.current?.style?.height) {
      ref.current.style.height = newHeight;
      previewRef.current.style.height = newHeight;
    }
  }, [fullWidth]);

  const addBlock = () => {
    if (!ref.current) return;
    if (action === "add") {
      addSection(index, ref.current.childNodes[0].cloneNode(true));
      closeBlockDrawer();
    } else if (action === "edit") {
      editSection(index, ref.current.childNodes[0].cloneNode(true));
    }
  };

  // function is for navbar and add extra in preview box
  const addBanner = React.useCallback(() => {
    if (sectionType !== "section-navbar") return;
    const bannerNode = document.querySelector(
      ".main-container > div > *:nth-child(2)"
    );
    if (!bannerNode) return;
    if (!(bannerNode instanceof HTMLElement)) return false;
    const newBannerNode = bannerNode.cloneNode(true) as HTMLElement;
    newBannerNode.classList.remove("builder-row");
    if (
      !ref.current ||
      !ref.current?.childNodes ||
      ref.current?.childNodes[1]
    ) {
      ref.current?.childNodes[1].remove();
    }
    ref.current?.appendChild(newBannerNode);
  }, [sectionType, ref]);

  React.useEffect(() => {
    if (!ref?.current || !previewRef?.current) return;
    if (section?.content) {
      ref.current.innerHTML = section.content;
    }
    addBanner();

    let i = 0;
    let div = ref.current.childNodes[i] as HTMLElement;
    while (div.nodeType === 3) {
      //if div is text node just skip
      i++;
      div = ref.current.childNodes[i] as HTMLElement;
    }
    editSectionStyle(div, sectionStyle || defaultSectionStyle);
    if (div && div.classList && div.classList.contains("section-carousel")) {
      new Splide(div, {
        heightRatio: 0.5625,
      }).mount();
    }
    const sectPreview = ref.current;

    resize();

    sectPreview
      .querySelectorAll("img")
      .forEach((img) => img.addEventListener("load", resize));

    return () => {
      sectPreview
        .querySelectorAll("img")
        .forEach((img) => img.addEventListener("load", resize));
    };
  }, [children, resize, addBanner, section, sectionStyle, editSectionStyle]);

  return (
    <div ref={previewRef} className="preview-box" onClick={addBlock}>
      <section
        ref={ref}
        className={classNames(
          fullWidth ? "scale-[calc(370/1024)]" : "scale-[calc(260/1024)]",
          "relative table w-[1024px] origin-top-left flex-col drop-shadow-xl"
        )}
      >
        {children}
      </section>
      <div className="button">
        <FormattedMessage id="LangsAddBlock" />
      </div>
    </div>
  );
};

export default PreviewBox;
