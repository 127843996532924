import React from "react";
import { BellIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import HelpPage from "../../pages/Help/HelpPage";
import MyDialog from "../MyDialog";
import type { MyDialogHandler } from "../MyDialog/type";
import MyNewsDrawer from "./MyNewsDrawer";
import type { MyNewsDrawerHandler } from "./MyNewsDrawer/type";
import { FormattedMessage } from "react-intl";

const Topmenu: React.FC = () => {
  const dialogRef = React.useRef<MyDialogHandler>(null);
  const newDialogRef = React.useRef<MyNewsDrawerHandler>(null);

  function handleClick() {
    if (!dialogRef.current) return;
    dialogRef.current?.handleOpen();
  }

  const handleNewsOpen = () => {
    if (!newDialogRef.current) return;
    newDialogRef.current?.handleOpen();
  };

  return (
    <div className="flex flex-1 justify-end gap-x-4  lg:gap-x-6">
      <ul className="mb-8 flex flex-1 flex-col items-start gap-x-0 gap-y-6 lg:mb-0 lg:flex-none lg:flex-row lg:items-center lg:gap-x-1 lg:gap-y-0">
        <li>
          {/*eslint-disable-next-line*/}
          <>
            <button
              className="rounded-sm px-6 py-2.5 text-base text-gray-900 hover:bg-gray-200"
              onClick={handleClick}
            >
              <FormattedMessage id="LangsHelp" />
            </button>

            <MyDialog
              ref={dialogRef}
              title={<FormattedMessage id="LangsHelpCenter" />}
              size="xl"
            >
              <HelpPage />
            </MyDialog>
          </>
        </li>
        <li>
          {/*eslint-disable-next-line*/}
          <a
            className="rounded-sm px-6 py-2.5 text-base text-red-500 hover:bg-red-500 hover:text-white"
            href="/upgrade/package-selection/"
          >
            <FormattedMessage id="LangsUpgrade" />
          </a>
        </li>
        <li>
          <Link to="/projects/">
            <span className="rounded-sm px-6 py-2.5 text-base text-gray-900 hover:bg-gray-200">
              <FormattedMessage id="LangsProjects" />
            </span>
          </Link>
        </li>
        <li>
          {/* News Drawer */}
          <div className="hidden lg:block">
            <button
              onClick={handleNewsOpen}
              type="button"
              className="p-2.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">
                <FormattedMessage id="LangsViewNotifications" />
              </span>
              <BellIcon className="h-6 w-6 text-slate-900" aria-hidden="true" />
            </button>
            <MyNewsDrawer ref={newDialogRef} />
          </div>
        </li>
        <li>
          {/* Profile dropdown */}
          <Link to="/profile/setting">
            <span className="sr-only">
              <FormattedMessage id="LangsOpenUserMenu" />
            </span>
            <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
              <svg
                className=" h-full w-full"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default Topmenu;
