import { useSelector } from "react-redux";

export const useProject = () => {
  const project = useSelector(({ project }) => project);

  const getLink = (slug?: string): string => {
    let prefix = "https://";
    if (project && project.plan === "free") {
      prefix += `p.buildlink.app/${project.slug}`;
    } else {
      prefix += project.slug;
    }

    if (slug) {
      return `${prefix}/${slug}`;
    } else {
      return `${prefix}/`;
    }
  };

  return { getLink };
};
