/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "buildLinkApi",
            "endpoint": "https://pinalizue4.execute-api.ap-southeast-1.amazonaws.com/master",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:ecbb1c54-b83d-4105-ba1a-3ea86d580020",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_79ik3XPdM",
    "aws_user_pools_web_client_id": "7th69qvn1ej52qji96fc1c6vcr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "buildlinkPage-master",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "buildlinkProject-master",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "buildlinks3103920-master",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
