import {
  BUILDER_CLIPBOARD_LOADED,
  BUILDER_CLIPBOARD_UNLOADED,
} from "./actions";

import type { Reducer } from "redux";
import type { StoreAction, BuilderStateType } from "./type";

const initBuilderState = {
  clipboard: undefined,
};

export const reducer: Reducer<BuilderStateType, StoreAction> = (
  state = initBuilderState,
  action
) => {
  switch (action.type) {
    case BUILDER_CLIPBOARD_LOADED: {
      const { section } = action.payload;
      return {
        ...state,
        clipboard: section,
      };
    }

    case BUILDER_CLIPBOARD_UNLOADED: {
      return {
        ...state,
        ...initBuilderState,
      };
    }

    default: {
      return state;
    }
  }
};
