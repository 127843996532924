// layouts
import WizardLayout from "../layouts/WizardLayout";
// pages
import WizardIndexPage from "../pages/Wizard/index";

const WizardRoutes = [
  {
    path: "/wizard/*",
    element: <WizardLayout children={<WizardIndexPage />} />,
  },
];

export default WizardRoutes;
