const usePage = () => {
  const findPageRecursive = ({ id, list }: { id: string; list: any[] }) => {
    let result: any;
    list.forEach((page: any) => {
      if (result && result.id) return;
      if (page.id === id) {
        result = page;
      } else if (page.children && page.children.length > 0) {
        result = findPageRecursive({ id, list: page.children });
      }
    });
    return result;
  };

  const getPageLevel = ({
    id,
    list,
    level = 0,
  }: {
    id: string;
    list: any[];
    level?: number;
  }) => {
    let result: any = null;
    list.forEach((page: any) => {
      if (result) return;
      if (page.id === id) {
        result = level;
      } else if (page.children && page.children.length > 0) {
        result = getPageLevel({ id, list: page.children, level: level + 1 });
      }
    });
    return result;
  };

  const addPageRecursive = ({
    list,
    page,
    parentId,
  }: {
    list: any[];
    page: any;
    parentId: string;
  }) => {
    if (parentId === "" || parentId === "empty") {
      list.push(page);
      return list;
    }

    list = list.map((p: any) => {
      if (!p?.children) return p;
      p.children = addPageRecursive({
        list: p.children,
        page,
        parentId: parentId === p.id ? "" : parentId,
      });
      return p;
    });

    return list;
  };

  const editPage = ({
    list,
    id,
    page,
  }: {
    list: any[];
    id: string;
    page: any;
  }) => {
    list = list.map((p: any) => {
      if (p.id === id) {
        return { ...p, ...page };
      }
      p.children = editPage({
        list: p.children,
        id,
        page,
      });
      return p;
    });
    return list;
  };

  const editPageRecursive = ({
    list,
    id,
    data,
    sub = false,
  }: {
    list: any[];
    id: string;
    data: any;
    sub?: boolean;
  }) => {
    list = list.map((p: any) => {
      if (sub === true) {
        p = { ...p, ...data };
        p.children = editPageRecursive({
          list: p.children,
          id,
          data,
          sub: true,
        });
      } else if (p.id === id) {
        p = { ...p, ...data };
        p.children = editPageRecursive({
          list: p.children,
          id,
          data,
          sub: true,
        });
      } else {
        p.children = editPageRecursive({
          list: p.children,
          id,
          data,
        });
      }
      return p;
    });
    return list;
  };

  const removePageRecursive = ({ id, list }: { id: string; list: any[] }) => {
    list = list.filter((page: any) => page.id !== id);
    list.map((p: any) => {
      if (!p?.children) return p;
      p.children = removePageRecursive({ id, list: p.children });
      return p;
    });
    return list;
  };

  return {
    findPageRecursive,
    addPageRecursive,
    removePageRecursive,
    editPage,
    editPageRecursive,
    getPageLevel,
  };
};

export default usePage;
