import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { twMerge } from "tailwind-merge";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import type { MyDialogProps, MyDialogButton, MyDialogHandler } from "./type";

export const MyDialogContext = React.createContext<MyDialogHandler>({
  handleOpen: () => {},
  handleClose: () => {},
  setTitle: () => {},
  setSubTitle: () => {},
  setContent: () => {},
  setButtons: () => {},
  setConfig: () => {},
});

const MyDialog: React.ForwardRefRenderFunction<
  MyDialogHandler,
  MyDialogProps
> = (
  {
    open = false,
    title = "",
    subtitle = "",
    enableClose = true,
    children,
    buttons = [],
    size = "sm",
    className,
    position = "center",
  },
  innerRef
) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(open);
  const [classes, setClasses] = React.useState<any>(className);
  const [titleText, setTitleText] = React.useState<string | React.ReactNode>(
    title
  );
  const [subTitleText, setSubTitleText] = React.useState<string>(subtitle);
  const [contentNode, setContentNode] = React.useState<React.ReactNode>(
    children || null
  );
  const [buttonsArr, setButtonsArr] = React.useState<MyDialogButton[] | any>(
    buttons
  );

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const setTitle = (t: string | any): void => {
    setTitleText(t);
  };

  const setSubTitle = (t: string | any): void => {
    setSubTitleText(t);
  };

  const setContent = (n: React.ReactNode): void => {
    setContentNode(n);
  };

  const setButtons = (b: MyDialogButton[]): void => {
    setButtonsArr(b);
  };

  const setConfig = ({
    title,
    subtitle,
    children,
    buttons,
    className,
  }: MyDialogProps): void => {
    setTitleText(title);
    setSubTitle(subtitle);
    setContentNode(children);
    setButtonsArr(buttons);
    setClasses(className);
  };

  React.useImperativeHandle(innerRef, () => ({
    handleOpen,
    handleClose,
    setTitle,
    setSubTitle,
    setContent,
    setButtons,
    setConfig,
  }));

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  React.useEffect(() => {
    setClasses(className || {});
    setTitleText(title);
    setContentNode(children);
    //eslint-disable-next-line
  }, [className, title, children]);

  return (
    <MyDialogContext.Provider
      value={{
        handleOpen,
        handleClose,
        setTitle,
        setSubTitle,
        setContent,
        setButtons,
        setConfig,
      }}
    >
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[999]" onClose={setIsOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className={twMerge(
                "flex min-h-full justify-center  p-4 text-center sm:p-0",
                position === "center" ? "sm:items-center" : "mt-6 items-start"
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={twMerge(
                    "relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full ",
                    size === "sm" && "sm:max-w-lg",
                    size === "md" && "sm:max-w-xl",
                    size === "lg" && "sm:max-w-2xl",
                    size === "xl" && "sm:max-w-3xl",
                    size === "2xl" && "sm:max-w-4xl"
                  )}
                >
                  {!!enableClose && (
                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                      <button
                        type="button"
                        className={twMerge(
                          "rounded-md text-gray-600 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                          classes?.closeButton || ""
                        )}
                        onClick={() => setIsOpen(false)}
                      >
                        <span className="sr-only">
                          <FormattedMessage id="LangsClose" />
                        </span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  )}
                  <div className="sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className={twMerge(
                          "bg-gray-200 px-6 py-4 text-base font-bold leading-6 text-gray-900",
                          classes?.title || ""
                        )}
                      >
                        {titleText}
                      </Dialog.Title>
                      {subTitleText && (
                        <Dialog.Description
                          className={twMerge(
                            "mt-2 text-sm leading-6 text-gray-500",
                            classes?.subtitle || ""
                          )}
                        >
                          {subTitleText}
                        </Dialog.Description>
                      )}
                      <div className={twMerge("p-6", classes?.content || "")}>
                        {contentNode}
                      </div>
                    </div>
                  </div>
                  <div
                    className={twMerge(
                      "mt-5 border-t border-gray-100 bg-gray-50 p-6 sm:mt-4 sm:flex sm:flex-row-reverse",
                      classes?.footer || ""
                    )}
                  >
                    {buttonsArr &&
                      buttonsArr.map((butt: MyDialogButton, index: number) => (
                        <button
                          key={index}
                          type="button"
                          className={twMerge(
                            "btn inline-flex w-full justify-center rounded-md px-6 py-4 text-base  font-semibold shadow-sm hover:bg-gray-50 sm:ml-3 sm:w-auto ",
                            butt.className || ""
                          )}
                          onClick={butt.onClick ? butt.onClick : handleClose}
                        >
                          {butt.label}
                        </button>
                      ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </MyDialogContext.Provider>
  );
};

export default React.forwardRef(MyDialog);
