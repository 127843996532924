import React from "react";
import { useState } from "react";
import {
  MagnifyingGlassIcon,
  XCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { MyDialogContext } from "../../components/MyDialog";
import HelpPage from "./HelpPage";

import HelpImg1 from "../../media/img/help1s.png";
import HelpImg2 from "../../media/img/help2s.png";

const mdcontent = [
  {
    id: "01",
    title: "Help Center",
    description:
      "I've just created my website, what should I take care of first?",
  },
  {
    id: "02",
    title: "First Steps",
    description:
      "I've just created my website, what should I take care of first?",
  },
];

const HelpDoc = [
  {
    id: "01",
    title: "Website Building: Your First Steps",
  },
  {
    id: "02",
    title: "Website Building: Pages",
  },
];

const HelpDocPage: React.FC = () => {
  const dialogRef = React.useContext(MyDialogContext);
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClearClick = () => {
    setSearchTerm("");
  };

  const handleSearchClick = () => {
    // TODO:在这里执行搜索功能的逻辑
    console.log("Perform search");
  };

  const handleTitleClick = () => {
    if (!dialogRef?.setContent) return;
    dialogRef.setContent(<HelpPage />);
  };

  return (
    <>
      <div className="helpDocPage flex flex-col bg-white ">
        <div className="relative mb-5 ">
          <input
            type="text"
            placeholder="Search BuildLink Dolphin Help"
            value={searchTerm}
            onChange={handleInputChange}
            className="w-full border-2 border-gray-300  py-2 pl-10  pr-8  focus:border-blue-500"
          />

          <button
            type="button"
            onClick={handleSearchClick}
            className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <MagnifyingGlassIcon className="h-5 w-5 " />
          </button>

          {searchTerm && (
            <button
              type="button"
              onClick={handleClearClick}
              className="absolute right-3 top-1/2 -translate-y-1/2 transform text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <XCircleIcon className="h-5 w-5 " />
            </button>
          )}
        </div>
        <div>
          <ul className="flex justify-start pl-10">
            {mdcontent.map((item) => (
              <li
                className="flex cursor-pointer items-center text-blue-600 "
                key={item.id}
                onClick={handleTitleClick}
              >
                <span>{item.title}</span>
                <ChevronRightIcon className="h-6 w-6 text-black" />
              </li>
            ))}
            <li>title</li>
          </ul>
        </div>
        <div className="ml-8 mt-1 flex flex-col p-2 text-black">
          <div className="my-markdown">
            <h1 className="mb-3 hidden break-words font-sans text-3xl font-bold text-black md:mb-0 md:text-4xl">
              Website Building: Your First Steps
            </h1>
            <h3 className="mb-5 font-sans text-2xl font-bold leading-normal text-black">
              Website Building: Your First Steps
            </h3>
            <p className="mt-1 py-2 pr-2 text-black">
              Congratulations to your newly created website! The first thing you
              see is the Website Builder you’ll use to customize your website by
              filling it with your own content. Everything you do can be changed
              later. Feel free to try out the different design elements and
              tools – have fun with it! Let’s look at the first point in the
              left side menu together:
            </p>

            <p className="mt-1 py-2 pr-2 text-black">
              <img
                src={HelpImg1}
                alt=""
                className="m-0 max-w-full p-0 text-black"
              />
            </p>

            <h2 className="mt-1 py-2 pl-0 pr-2 text-2xl font-bold text-black">
              Design
            </h2>
            <p className="mt-1 py-2 pr-2 text-black ">
              When thinking about your website design, first you’ll have to make
              some style choices. You’ll find the basic styling elements that
              will set the tone for your project or business here. Easily adjust
              colors, fonts, and button shapes across your website. We’ll talk
              about the other components of your website design, structured
              navigation, and engaging content later on.
            </p>

            <h3 className=" mb-5 font-sans text-2xl font-bold leading-normal text-black">
              Color Palette
            </h3>
            <p className="mt-1 flex-shrink-0 py-2 pr-2 text-black  ">
              <img
                src={HelpImg2}
                alt=""
                className="m-0 w-auto p-0 text-black"
              />
            </p>

            <h3 className=" mb-5 font-sans text-2xl font-bold leading-normal text-black">
              Font Choice
            </h3>
            <p className="mt-1 py-2 pr-2 text-black">
              We’ve created a selection of readable fonts for you to choose
              from. To ensure a unified look that makes your website that much
              more professional, the font is used for all text on your website.
              Choose one of the suggested fonts that fit with the overall mood
              of your website. Some fonts look more traditional and serious,
              while others have a younger, more urban feel.
            </p>

            <h4 className="font-bold">Saturation</h4>
            <p className="mt-1 py-2 pr-2 text-black">
              After deciding on your base colors, regulate the saturation of
              your palette. Tone your palette down for a softer look, or
              brighten it with the slider to make it louder!
            </p>

            <p className="mt-1 py-2 pr-2 text-black">
              {/*eslint-disable-next-line*/}
              <a href="#" className="cursor-pointer text-blue-700 underline">
                &gt;&gt; Continue here and find out how blocks make up your
                website &gt;&gt;
              </a>
            </p>
          </div>
          <div className="markdown_footer">
            <hr className="mx-0 my-8 h-0 border-x-0 border-b-2 border-t-0 border-solid border-gray-300 text-black" />
            <h3 className=" mb-5 font-sans text-2xl font-bold leading-normal text-black">
              Was this article helpful?
            </h3>
            <div className="flex justify-start gap-3">
              <button className="relative mb-0 inline-flex h-12 w-full min-w-0 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2 overflow-hidden rounded-none border-2 border-solid border-blue-600 px-5 py-0 text-center text-base font-bold text-blue-600 md:w-auto">
                Yes
              </button>
              <button className="relative mb-0 inline-flex h-12 w-full min-w-0 max-w-full cursor-pointer select-none appearance-none items-center justify-center gap-2 overflow-hidden rounded-none border-2 border-solid border-blue-600 px-5 py-0 text-center text-base font-bold text-blue-600 md:w-auto">
                No
              </button>
            </div>
            <hr className="mx-0 my-8 h-0 border-x-0 border-b-2 border-t-0 border-solid border-gray-300 text-black" />
            <h3 className=" mb-5 font-sans text-2xl font-bold leading-normal text-black">
              Related articles
            </h3>
            {HelpDoc.map((item) => (
              <div
                className="my-1 hover:cursor-pointer hover:text-blue-600"
                key={item.id}
                onClick={handleTitleClick}
              >
                <p className="font-sans text-base font-normal leading-normal ">
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpDocPage;
