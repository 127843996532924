import { USER_LOGIN, USER_LOADED, USER_LOGOUT } from "./actions";

import type { Reducer } from "redux";
import type { StoreAction, AuthStateType } from "./type";

const initAuthState = {
  user: undefined,
  login_at: undefined,
};

export const reducer: Reducer<AuthStateType, StoreAction> = (
  state = initAuthState,
  action
) => {
  switch (action.type) {
    case USER_LOGIN: {
      const { login_at } = action.payload;
      return {
        ...state,
        login_at: login_at,
      };
    }

    case USER_LOADED: {
      const { user } = action.payload;
      return {
        ...state,
        user: user,
      };
    }

    case USER_LOGOUT: {
      return {
        ...initAuthState,
      };
    }

    default: {
      return state;
    }
  }
};
