//layout
import ProjectLayout from "../layouts/ProjectLayout";
import MainLayout from "../layouts/MainLayout";

//pages
import ProjectPage from "../pages/Project/ProjectPage";
import LogosPage from "../pages/Logos/LogosPage";

const routes = [
  {
    path: "/projects",
    element: <ProjectLayout children={<ProjectPage />} />,
  },
  {
    path: "/logos",
    element: <MainLayout selected="home" children={<LogosPage />} />,
  },
];

export default routes;
