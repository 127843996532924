import React from "react";
import classNames from "classnames";

import type { ProgressProps } from "./type";

const Progress: React.FC<ProgressProps> = ({
  value,
  className,
  activeClassName,
  size = "md",
}) => {
  return (
    <div
      className={classNames(
        className ? className : "",
        size === "sm"
          ? "h-1"
          : size === "md"
          ? "h-2"
          : size === "lg"
          ? "h-2.5"
          : size === "xl"
          ? "h-3"
          : size === "2xl"
          ? "h-3.5"
          : "",
        "h-2 w-full rounded-lg bg-gray-200"
      )}
    >
      <div
        className={classNames(
          activeClassName ? activeClassName : "",
          "h-full bg-indigo-600"
        )}
        style={{ width: `${value}%` }}
      ></div>
    </div>
  );
};

export default Progress;
