import _ from "lodash";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import "./App.css";
import routes from "./routes/";

//langs
import Langs from "./langs/index";

// get browser language without the region code
let language = navigator.language || "en";
if (!_.includes(_.keys(Langs), language)) {
  language = "en";
}

function App() {
  const content = useRoutes(routes);
  const { user } = useSelector(({ auth }) => auth);
  if (user && user.locale) {
    language = user.locale;
  }

  return (
    <IntlProvider messages={Langs[language]} locale="en" defaultLocale="en">
      {content}
    </IntlProvider>
  );
}

export default App;
