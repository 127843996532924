import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
// my components
import HelpPage from "../pages/Help/HelpPage";
import CancelOrderPage from "../pages/CheckOut/subpage/CancelOrderPage";
import MyDialog from "../components/MyDialog";
// type
import type { MyDialogHandler } from "../components/MyDialog/type";
import type { CheckoutLayoutProps } from "./type";
// icon
import { HiXMark } from "react-icons/hi2";
// logo
import logo from "../media/svg/logo/logo.svg";

const CheckoutLayout: React.FC<CheckoutLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  //my dialog
  const helpDialogRef = React.useRef<MyDialogHandler>(null);
  const cancelDialogRef = React.useRef<MyDialogHandler>(null);

  function handleHelpClick() {
    if (!helpDialogRef.current) return;
    helpDialogRef.current?.handleOpen();
  }

  function handleCancelClick() {
    if (!cancelDialogRef.current) return;
    cancelDialogRef.current?.handleOpen();
  }

  return (
    <>
      <div className="flex h-full min-w-min flex-1 flex-col">
        <header className="sticky top-0 z-10 bg-white shadow md:mb-12">
          <div
            className="mx-auto flex items-center justify-between px-4 py-4 lg:px-8"
            aria-label="Global"
          >
            {/* company logo */}
            <div className="flex flex-1 justify-start">
              {/*eslint-disable-next-line*/}
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">
                  <FormattedMessage id="LangsYourCompany" />
                </span>
                <img
                  className="h-8 w-auto sm:h-10"
                  src={logo}
                  alt="bulidLink"
                />
              </a>
            </div>
            {/* Right side content */}
            <div className="flex flex-1 justify-end gap-2">
              <button
                onClick={handleHelpClick}
                className="flex cursor-pointer justify-center rounded-sm bg-white px-3 py-4 font-semibold text-slate-900 transition-all duration-200 ease-out hover:bg-gray-100 lg:px-5 lg:py-3"
              >
                <FormattedMessage id="LangsHelp" />
              </button>
              <MyDialog
                ref={helpDialogRef}
                title={<FormattedMessage id="LangsHelpCenter" />}
                size="xl"
              >
                <HelpPage />
              </MyDialog>
              {/* Cancel and Xmark icon */}
              <button
                onClick={handleCancelClick}
                className="flex cursor-pointer justify-center rounded-sm bg-white px-3 py-4 font-semibold text-slate-900 transition-all duration-200 ease-out hover:bg-gray-100 lg:px-5 lg:py-3"
              >
                <span className="hidden md:block ">
                  <FormattedMessage id="LangsCancel" />
                </span>
                <span className="block h-5 w-5 md:hidden">
                  <HiXMark className="h-full w-full text-slate-900 hover:rounded-md hover:text-slate-800 hover:ring-2 hover:ring-indigo-500" />
                </span>
              </button>

              <MyDialog
                ref={cancelDialogRef}
                title={<FormattedMessage id="LangsCancelYourOrder" />}
                size="md"
                buttons={[
                  {
                    label: <FormattedMessage id="LangsCancelOrder" />,
                    className:
                      "bg-red-600 text-white hover:bg-red-700 !py-2 !px-4 rounded-sm items-center",
                    onClick: () => {
                      navigate("/");
                      cancelDialogRef?.current?.handleClose();
                    },
                  },
                  {
                    label: <FormattedMessage id="LangsStayHere" />,
                    className:
                      "bg-white text-indigo-600 border-2 border-indigo-600 hover:bg-gray-300 !py-2 !px-4 rounded-sm items-center",
                    onClick: () => {
                      cancelDialogRef?.current?.handleClose();
                    },
                  },
                ]}
              >
                <CancelOrderPage />
              </MyDialog>
            </div>
          </div>
        </header>
        {children}
        <footer className="bg-zinc-100 pb-24 sm:pb-0">
          <div className="mx-auto max-w-7xl px-4 pb-5 pt-10 text-sm sm:p-4">
            <hr className="mb-10 mt-9 border-b-2 border-gray-300" />
            <section className="mb-10 block">
              <h2 className="mb-5 text-lg font-bold leading-5 text-gray-500">
                <FormattedMessage id="LangsLegalInformation" />
              </h2>
              <p className="block text-sm leading-6 text-gray-500">
                <FormattedMessage id="LangsLegalInformationDesc" />

                {/*eslint-disable-next-line*/}
                <a href="#" className="">
                  &nbsp;
                  <span className="underline">
                    <FormattedMessage id="LegalInformationDesc" />
                    <FormattedMessage id="LangsLegalInformationTerms" />
                  </span>
                </a>
                <FormattedMessage id="LegalInformationDomains" />
              </p>
            </section>
            <nav className="text-base font-normal text-gray-500">
              <ul className="grid max-w-3xl grid-flow-col grid-rows-2 sm:grid-rows-1">
                <li>
                  {/*eslint-disable-next-line*/}
                  <a href="#" className="mb-5 inline-block sm:mr-10 sm:w-auto">
                    <FormattedMessage id="LangsLegalNotice" />
                  </a>
                </li>
                <li>
                  {/*eslint-disable-next-line*/}
                  <a href="#" className="mb-5 inline-block sm:mr-10 sm:w-auto">
                    <FormattedMessage id="LangsTermsOfService" />
                  </a>
                </li>
                <li>
                  {/*eslint-disable-next-line*/}
                  <a href="#" className="mb-5 inline-block sm:mr-10 sm:w-auto">
                    <FormattedMessage id="LangsPrivacyPolicy" />
                  </a>
                </li>
                <li>
                  {/*eslint-disable-next-line*/}
                  <a href="#" className="mb-5 inline-block sm:mr-10 sm:w-auto">
                    <FormattedMessage id="LangsCookieSettings" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </footer>
      </div>
    </>
  );
};
export default CheckoutLayout;
