import React from "react";
import { useNavigate } from "react-router-dom";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { projectActions } from "../../stores/actions";

type PublishPageProps = {
  status: "unpublish" | "published";
};

type Props = ConnectedProps<typeof connector> & PublishPageProps;

const PublishPage: React.FC<Props> = ({ status, ...props }) => {
  const navigate = useNavigate();
  const project = useSelector(({ project }) => project);

  const publishProject = React.useCallback(() => {
    props.changeProject({ ...project, status });
    setTimeout(() => {
      if (status === "published") {
        navigate("/cms?status=published");
      } else {
        window.location.replace("/cms");
      }
    }, 800);
  }, [project, props, navigate, status]);

  React.useEffect(() => {
    publishProject();
  }, [publishProject]);

  return <></>;
};

const connector = connect(null, { ...projectActions });

export default connector(PublishPage);
