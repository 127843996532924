import React from "react";
import axios from "axios";
import classNames from "classnames";
import { BsChevronLeft } from "react-icons/bs";

import MyDialog from "../../../MyDialog";

import MediaLibrary from "./library";
import PexelsLibrary from "./pexels";
import type { MyDialogHandler } from "../../../MyDialog/type";
import type { MediaLibraryHandler, PexelsLibraryHandler } from "./type";
import type { MediaDialogHandler, MediaDialogProps } from "./type";
import { FormattedMessage } from "react-intl";

type MediaDialogContextType = {
  libraryOpen: "media" | "pexels";
  setLibraryOpen: React.Dispatch<React.SetStateAction<"media" | "pexels">>;
};

export const MediaDialogContext = React.createContext<MediaDialogContextType>({
  libraryOpen: "media",
  setLibraryOpen: () => {},
});

const MediaDialog: React.ForwardRefRenderFunction<
  MediaDialogHandler,
  MediaDialogProps
> = ({ onInsert, onClose, ...props }, innerRef) => {
  const dialogRef = React.useRef<MyDialogHandler>(null);
  const [libraryOpen, setLibraryOpen] = React.useState<"media" | "pexels">(
    "media"
  );
  const mediaLibraryRef = React.useRef<MediaLibraryHandler>(null);
  const pexelsLibraryRef = React.useRef<PexelsLibraryHandler>(null);

  //handleOpen
  const handleOpen = () => {
    if (!dialogRef?.current) return;
    dialogRef.current?.handleOpen();
  };

  //handleClose
  const handleClose = () => {
    if (!dialogRef?.current) return;
    dialogRef.current?.handleClose();
  };

  //return imageUrl
  const handleInsertImage = React.useCallback(() => {
    if (!mediaLibraryRef?.current) return;
    const imageUrl = mediaLibraryRef.current.getSelected();
    if (!imageUrl) return;
    onInsert(imageUrl);
    if (onClose) onClose();
    return imageUrl;
  }, [onInsert, onClose]);

  //save pexels image to our library
  const handleSavePexelsImage = React.useCallback(async () => {
    setLibraryOpen("media");
    if (!pexelsLibraryRef?.current) return;
    if (!mediaLibraryRef?.current) return;
    const images = pexelsLibraryRef.current.getSelected();
    if (!images || images.length === 0) return;

    const ps = images.map((image: any) =>
      axios.get(encodeURI(image.src.large2x), {
        responseType: "arraybuffer",
      })
    );
    const buffers = await Promise.all(ps);
    const uploadPs = buffers.map(({ data: file }) => {
      const fileName = new Date().getTime() + Math.random() + ".png";
      return mediaLibraryRef?.current?.handleUpload(file, fileName);
    });
    await Promise.all(uploadPs);
    mediaLibraryRef.current.requestImage();
  }, []);

  React.useImperativeHandle(innerRef, () => ({
    handleOpen,
    handleClose,
    handleInsertImage,
  }));

  React.useEffect(() => {
    if (!dialogRef?.current) return;
    //change dialog button to our media Library need buttons
    if (libraryOpen === "media") {
      dialogRef.current.setButtons([
        {
          label: "Insert Image",
          className: "btn btn-primary text-base !py-4 !px-6",
          onClick: () => {
            handleInsertImage();
            if (!dialogRef?.current) return;
            if (onClose) onClose();
            dialogRef?.current.handleClose();
          },
        },
        {
          label: "Cancel",
          className: "btn ",
          onClick: () => {
            if (!dialogRef?.current) return;
            if (onClose) onClose();
            dialogRef?.current.handleClose();
          },
        },
      ]);
    }
    //change dialog button to pexels need buttons
    if (libraryOpen === "pexels") {
      dialogRef.current.setButtons([
        {
          label: "Save",
          className: "btn btn-primary text-base !py-4 !px-6",
          onClick: () => {
            handleSavePexelsImage();
          },
        },
        {
          label: "Cancel",
          className: "btn ",
          onClick: () => {
            if (!dialogRef?.current) return;
            if (onClose) onClose();
            dialogRef?.current.handleClose();
          },
        },
      ]);
    }
  }, [libraryOpen, handleInsertImage, handleSavePexelsImage, onClose]);

  return (
    <MediaDialogContext.Provider value={{ libraryOpen, setLibraryOpen }}>
      <MyDialog
        ref={dialogRef}
        enableClose={false}
        title={
          libraryOpen === "media" ? (
            "Image Library"
          ) : libraryOpen === "pexels" ? (
            <>
              <button
                className="items-center justify-center p-2 pl-0"
                onClick={() => setLibraryOpen("media")}
              >
                <BsChevronLeft />
              </button>
              <FormattedMessage id="LangsFreePromotionalPhotos" />
            </>
          ) : (
            ""
          )
        }
        size="2xl"
        buttons={[
          {
            label: <FormattedMessage id="LangsInsertImage" />,
            className: "btn btn-primary text-base !py-4 !px-6",
            onClick: () => {
              if (!dialogRef?.current) return;
              dialogRef?.current.handleClose();
            },
          },
          {
            label: <FormattedMessage id="LangsCancel" />,
            className: "btn ",
            onClick: () => {
              if (!dialogRef?.current) return;
              dialogRef?.current.handleClose();
            },
          },
        ]}
      >
        <div className="grid grid-cols-4 gap-4">
          <div
            className={classNames(
              libraryOpen !== "media" && "hidden",
              "col-span-1 flex flex-col gap-2"
            )}
          >
            <h3 className="text-base font-bold">
              <FormattedMessage id="LangsAddNewImages" />
            </h3>
            <p className="mb-4 text-sm">
              <FormattedMessage id="EdutorMediaUploadDesc" />
            </p>
            <button
              className="border-2 border-indigo-500 px-6 py-1 text-sm font-bold text-indigo-500 hover:bg-gray-100"
              onClick={() => {
                if (!mediaLibraryRef?.current) return;
                mediaLibraryRef.current.handleUploadClick();
              }}
            >
              <FormattedMessage id="LangsFromThisDevice" />
            </button>
            <button
              className="border-2 border-indigo-500 px-6 py-1 text-sm font-bold text-indigo-500 hover:bg-gray-100"
              onClick={() => setLibraryOpen("pexels")}
            >
              <FormattedMessage id="LangsFreeHiresPhotos" />
            </button>
          </div>
          <div
            className={classNames(
              libraryOpen === "media" && "col-span-3 ml-4",
              libraryOpen !== "media" && "col-span-4"
            )}
          >
            <div className={classNames(libraryOpen !== "media" && "hidden")}>
              <MediaLibrary ref={mediaLibraryRef} />
            </div>
            {libraryOpen === "pexels" && (
              <PexelsLibrary ref={pexelsLibraryRef} />
            )}
          </div>
        </div>
      </MyDialog>
    </MediaDialogContext.Provider>
  );
};

export default React.forwardRef(MediaDialog);
