import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Storage } from "aws-amplify";
import { BiTrash } from "react-icons/bi";
import useIntersectionObserver from "../../../../hooks/useInterectionObserver";
import type { MediaLibraryHandler } from "./type";
import Progress from "../../../Progress";
import { FormattedMessage } from "react-intl";

const PAGE_SIZE = 20;

const MediaLibrary: React.ForwardRefRenderFunction<MediaLibraryHandler, {}> = (
  props,
  innerRef
) => {
  const uploadRef = React.useRef<HTMLInputElement>(null);
  const bottomRef = React.useRef<HTMLDivElement>(null);
  const { user } = useSelector(({ auth }) => auth);
  const [images, setImages] = React.useState<any>([]);
  const [selectedImage, setSelectedImage] = React.useState<any>();
  const [s3Result, setS3Result] = React.useState<any>();
  const [isUploading, setIsUploading] = React.useState<number | boolean>(false);
  const isBottomVisible = useIntersectionObserver(
    bottomRef,
    {
      threshold: 0.5,
      rootMargin: "40px",
    },
    false
  );

  //upload button click
  const handleUploadClick = React.useCallback(() => {
    if (!uploadRef.current) return;
    uploadRef.current.click();
  }, []);

  //upload image to aws s3
  const handleUpload = React.useCallback(
    async (file: any, fileName?: string) => {
      setIsUploading(true);
      if (!user?.sub) return;
      if (!file) return;

      const newFileName = (s: string) => {
        const ext = s.split(".").pop();
        const name = new Date().getTime() + Math.random();
        return `${name}.${ext}`;
      };

      const key = fileName
        ? user.sub + "/" + fileName
        : user.sub + "/" + newFileName(file.name);
      try {
        const res = await Storage.put(key, file, {
          contentType: file.type,
          level: "public",
          progressCallback(progress) {
            setIsUploading((progress.loaded / progress.total) * 100);
          },
        });

        // const url = await Storage.get(res.key);
        const url = `https://buildlinks3103920-master.s3.ap-southeast-1.amazonaws.com/public/${res.key}`;
        const image = {
          key: res.key,
          url,
        };
        setIsUploading(false);
        setImages([image, ...images]);
        setSelectedImage(image);
      } catch (error) {
        setIsUploading(false);
        console.log("error", error);
      }
    },
    [user, images]
  );

  //delete image in aws s3
  const handleDelete = React.useCallback(
    async (e: any) => {
      if (!selectedImage) return;
      await Storage.remove(selectedImage.key);
      const newImages = images.filter(
        (image: any) => image.key !== selectedImage.key
      );
      setImages(newImages);
    },
    [selectedImage, images]
  );

  //select image
  const handleSelectImage = React.useCallback((e: any) => {
    setSelectedImage(e);
  }, []);

  const requestImage = React.useCallback(async () => {
    try {
      const response = await Storage.list(user.sub + "/", {
        pageSize: PAGE_SIZE,
        nextToken: s3Result?.nextToken,
      });
      setS3Result(response);
      const { results } = response;
      // const ps = results.map((image: any) => Storage.get(image?.key));
      // let resultImages = await Promise.all(ps);
      // let newImages = resultImages.map((image: any, imageIdx: number) => ({
      //   // url: image,
      //   url: `https://buildlinks321004-main.s3.ap-southeast-1.amazonaws.com/public/${results[imageIdx].key}`,
      //   key: results[imageIdx].key,
      // }));
      let newImages = results.map((image: any) => ({
        key: image.key,
        url: `https://buildlinks3103920-master.s3.ap-southeast-1.amazonaws.com/public/${image.key}`,
      }));

      if (images.length === 0 || !s3Result?.nextToken) {
        setImages(newImages);
      } else {
        setImages([...images, ...newImages]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [images, s3Result, user]);

  React.useImperativeHandle(innerRef, () => ({
    getSelected: () => selectedImage?.url || undefined,
    handleUpload,
    handleUploadClick,
    requestImage,
  }));

  React.useEffect(() => {
    requestImage();
    // eslint-disable-next-line
  }, []);

  //requestImage when scroll to bottom
  React.useEffect(() => {
    if (!isBottomVisible || !s3Result?.hasNextToken) return;
    requestImage();
  }, [isBottomVisible, requestImage, s3Result?.hasNextToken]);

  return (
    <>
      <h3 className="text-base font-bold">
        <FormattedMessage id="LangsSavedImages" />
      </h3>
      <p className="text-sm">
        <FormattedMessage id="EdutorMediaSelectImage" />
      </p>
      <input
        ref={uploadRef}
        type="file"
        className="hidden"
        onChange={(e: any) => {
          const file = e.target.files[0];
          handleUpload(file);
        }}
        accept="image/*"
      />
      <div className="mt-4 grid max-h-[486px] grid-cols-4 gap-4 overflow-y-auto overflow-x-hidden">
        <div
          className={classNames(
            isUploading === false && "hidden",
            "relative col-span-1 m-1 border border-gray-200 pt-[calc(100%-0.25rem)]"
          )}
        >
          <div className="absolute left-[50%] top-[50%] h-2 w-[80%] -translate-x-1/2 -translate-y-1/2 transform">
            <Progress
              value={isUploading !== false ? +isUploading : 0}
              className="h-full"
            />
          </div>
        </div>
        {images &&
          images.map((image: any, imageIdx: number) => (
            <div
              key={imageIdx}
              onClick={() => handleSelectImage(image)}
              className={classNames(
                "image-thumbnail relative col-span-1 m-1 pt-[calc(100%-0.25rem)]",
                image === selectedImage && "selected"
              )}
            >
              <img
                alt=""
                src={image.url}
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
          ))}
        <div ref={bottomRef} className="col-span-4 h-1"></div>
      </div>
      <button
        className="flex items-center justify-center px-3 py-2 text-base font-semibold text-red-500 hover:bg-gray-100 disabled:opacity-50"
        onClick={handleDelete}
        disabled={!selectedImage}
      >
        <BiTrash fontSize={20} />
        <FormattedMessage id="LangsDelete" />
      </button>
    </>
  );
};

export default React.forwardRef(MediaLibrary);
